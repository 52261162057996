import React from 'react';
import {observer} from "mobx-react";
import {ingredientStore} from "../IngredientStore";
import {numberUtil} from "../../../../common/utils/NumberUtil";
import Input from "../../../../common/component/form/Input";


const AddOrEditIngredient = () => {

    return (
        <div className="modal fade" id="add_or_edit_ingredient" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Thiết lập nguyên vật liệu</h5>
                        <button type="button" className="close" id="close_add_edit" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">

                        <div className="w-100 d-flex align-items-center">
                            <div className="w-50 pr-2">
                                <div className="form-group">
                                    <label>Tên nguyên vật liệu <span className="text-danger">*</span></label>
                                    <Input isError={ingredientStore.errors.name}
                                           value={ingredientStore.dataRequest.name}
                                           onChange={(e: any) => ingredientStore.dataRequest.name = e.currentTarget.value}
                                           placeholder="Nhập nguyên vật liệu"/>
                                </div>
                            </div>
                            <div className="w-50 pl-2">
                                <div className="form-group">
                                    <label>Mã nguyên vật liệu</label>
                                    <Input value={ingredientStore.dataRequest.shortName}
                                           onChange={(e: any) => ingredientStore.dataRequest.shortName = e.currentTarget.value}
                                           placeholder="Nhập mã nguyên vật liệu"/>
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex align-items-center">
                            <div className="w-50 pr-2">
                                <div className="form-group">
                                    <label>Đơn vị <span className="text-danger">*</span></label>
                                    <Input isError={ingredientStore.errors.unit}
                                           value={ingredientStore.dataRequest.unit}
                                           onChange={(e: any) => ingredientStore.dataRequest.unit = e.currentTarget.value}
                                           placeholder="Nhập đơn vị"/>
                                </div>
                            </div>
                            <div className="w-50 pl-2">
                                <div className="form-group">
                                    <label>Giá thành <span className="text-danger">*</span></label>
                                    <Input isError={ingredientStore.errors.price}
                                           value={ingredientStore.dataRequest.price}
                                           onChange={(e: any) => ingredientStore.dataRequest.price = numberUtil.regexNumber(e)}
                                           placeholder="Nhập giá thành"/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Ghi chú</label>
                            <div className="input-group">
                                    <textarea value={ingredientStore.dataRequest.note}
                                              onChange={(e: any) => ingredientStore.dataRequest.note = e.currentTarget.value}
                                              className="form-control form-control-lg" placeholder="Nhập ghi chú"/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => ingredientStore.type == "add" ? ingredientStore.add() : ingredientStore.update()}
                                disabled={ingredientStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditIngredient);