import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import { number_format, slug, sttPage} from "../../../common/utils/Utils";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import Loading from "../../../common/component/Loading";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {medicineStore} from "../../setup/medicine/MedicineStore";


const MedicinePage = () => {

    const { t } = useTranslation();
    const changePage = async (data: any) => {
        medicineStore.page = data.selected;
        await medicineStore.getMedicine();
    }

    useEffect(() => {
        medicineStore.getMedicine().then()
        medicineStore.getMedicineAll().then()
    }, [])

    if (medicineStore.isLoading) {
        return <Loading/>
    } else {
        return (
            <div className='table-responsive'>
                {medicineStore.listMedicine && medicineStore.listMedicine.length > 0 ?
                    <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th className="text-center">STT</th>
                            <th>Tên nông dược</th>
                            <th className="text-center">Mã nông dược</th>
                            <th className="text-center">Tồn kho</th>
                            <th className="text-center">ĐVT</th>
                        </tr>
                        </thead>
                        <tbody>

                        {medicineStore.listMedicine.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">{sttPage(medicineStore.page, i)}</td>
                                    <td>{item.name}</td>
                                    <td className="text-center">{item.shortName}</td>
                                    <td className="text-center">{number_format(item.inventory)}</td>
                                    <td className="text-center">{item.unit?.code}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table> : <NoContent/>}

                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Paginate currentPage={medicineStore.page} totalPage={medicineStore.totalPage}
                              changePage={changePage}/>
                </div>
            </div>
        );
    }
}

export default observer(MedicinePage)