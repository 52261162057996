import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery"
import {fertilizerService} from "./FertilizerService";
import {profileStore} from "../../profile/ProfileStore";
import {questionStore} from "../../question/QuestionStore";
import {medicineService} from "../medicine/MedicineService";
import {warehouseStore} from "../../warehouse/WarehouseStore";
import Constants from "../../../common/constants/Constants";


class FertilizerStore {
    @observable id: any = '';
    @observable type: any = '';
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable dataRequest: any = {
        id: '',
        name: '',
        unitId: null,
        price: '',
        capacity: '',
        shortName: '',
        useForOneHa: '',
        element: ['', '', ''],
        supplier: ''
    }
    @observable listFertilizer: any[] = []
    @observable errors: any = {
        name: '',
        unit: '',
        capacity: '',
        useForOneHa: '',
        element: '',
    }

    clearError() {
        fertilizerStore.errors = {
            name: '',
            unit: '',
            capacity: '',
            useForOneHa: '',
            element: '',
        }
    }

    clearForm() {
        fertilizerStore.type = 'add'
        fertilizerStore.clearError()
        fertilizerStore.dataRequest = {
            id: '',
            name: '',
            unitId: null,
            shortName: '',
            price: '',
            capacity: '',
            useForOneHa: '',
            element: ['', '', ''],
            supplier: ''
        }
    }


    async getFertilizer(size?: any, isQuestion?: boolean) {
        fertilizerStore.isLoading = true
        const result = await fertilizerService.getFertilizer(size)
        fertilizerStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            if(isQuestion){
                result.body.data && result.body.data.map((item: any) => {
                    questionStore.listFertilizer.push({value: item.id, label: item.name})
                })
            }
            fertilizerStore.listFertilizer = result.body.data
            warehouseStore.listSupplies = result.body.data
            fertilizerStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getFertilizerAll() {
        fertilizerStore.isLoading = true
        const result = await fertilizerService.getFertilizerAll()
        fertilizerStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            warehouseStore.listSuppliesAll = result.body.data
        }
    }

    async importData(file: any) {
        let data = {
            fileImport: file
        }
        const result = await fertilizerService.importData(data);
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Import phân bón thành công!');
            await fertilizerStore.getFertilizer();
        } else {
            toastUtil.error(result.body.message);
        }
    }
    
    async getDetail(id: any) {
        fertilizerStore.type = "edit"
        this.clearError()
        const result = await fertilizerService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            fertilizerStore.dataRequest = result.body
        }else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async add() {
        let {name, price,shortName, element, capacity, supplier, unitId, useForOneHa} = fertilizerStore.dataRequest
        this.clearError()
        let data = {
            name: name,
            shortName: shortName,
            price: Number(price),
            element: [element[0] ? element[0] : 0, element[1] ? element[1] : 0, element[2] ? element[2] : 0],
            capacity: capacity,
            supplier: supplier,
            unitId: Number(unitId),
            useForOneHa: useForOneHa
        }
        const allExist = element.every((item: any) => item !== "" && item !== null && item !== 0);

        if (!name) {
            fertilizerStore.errors.name = "Tên phân bón không được để trống!"
        }

        if (!unitId) {
            fertilizerStore.errors.unit = "Vui lòng chọn đơn vị!"
        }

        if (!capacity) {
            fertilizerStore.errors.capacity = "Khối lượng không được để trống!"
        }

        if (!useForOneHa) {
            fertilizerStore.errors.useForOneHa = "Lượng thuốc sử dụng không được để trống!"
        }

        if(!allExist){
            fertilizerStore.errors.element = "Vui lòng nhập đầy đủ các tỉ lệ!"
        }
        if(!useForOneHa || !capacity || !unitId || !name || !allExist){
            return
        }

        fertilizerStore.isLoadingBt = true
        const result = await fertilizerService.create(data)
        fertilizerStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thêm phân bón thành công', 1)
            $('#close_add_edit').trigger('click')
            fertilizerStore.clearForm()
            await fertilizerStore.getFertilizer()
        }else {
            if (typeof result.body.message === "string") {
                toastUtil.error(result.body.message, 3);
            } else if (typeof result.body.message === "object") {
                this.errors = result.body.message;
            }
        }

    }

    async update() {
        let {id, name, price, shortName, element, capacity, supplier, unitId, useForOneHa} = fertilizerStore.dataRequest
        this.clearError()
        let data = {
            name: name,
            shortName: shortName,
            price: Number(price),
            element: [element[0] ? element[0] : 0, element[1] ? element[1] : 0, element[2] ? element[2] : 0],
            capacity: capacity,
            supplier: supplier,
            unitId: Number(unitId),
            useForOneHa: Number(useForOneHa)
        }
        const allExist = element.every((item: any) => item !== "" && item !== null && item !== 0);

        if (!name) {
            fertilizerStore.errors.name = "Tên phân bón không được để trống!"
        }

        if (!unitId) {
            fertilizerStore.errors.unit = "Vui lòng chọn đơn vị!"
        }

        if (!capacity) {
            fertilizerStore.errors.capacity = "Khối lượng không được để trống!"
        }

        if (!useForOneHa) {
            fertilizerStore.errors.useForOneHa = "Lượng thuốc sử dụng không được để trống!"
        }

        if(!allExist){
            fertilizerStore.errors.element = "Tỉ lệ không được để trống!"
        }
        if(!useForOneHa || !capacity || !unitId || !name || !allExist){
            return
        }

        fertilizerStore.isLoadingBt = true
        const result = await fertilizerService.update(id, data)
        fertilizerStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật phân bón thành công', 2)
            $('#close_add_edit').trigger('click')
            fertilizerStore.clearForm()
            await fertilizerStore.getFertilizer()
        }else {
            toastUtil.error('Thêm phân bón thất bại', 5)
        }
    }

    async delete() {
        fertilizerStore.isLoadingBt = true
        const result = await fertilizerService.delete(fertilizerStore.id)
        fertilizerStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa phân bón thành công', 3)
            await fertilizerStore.getFertilizer()
        }else {
            toastUtil.error(result.body.message, 6)
        }
    }


}

export const fertilizerStore = new FertilizerStore()