import React from 'react';
import {observer} from "mobx-react";
import {number_format, slug, urlImageSSO} from "../../../common/utils/Utils";
import {Link} from "react-router-dom";
import {dateUtils} from "../../../common/utils/DateUtils";
import {Icon} from "../../../common/component/Icon";


const ListQuestion = ({item}: any) => {

    return (
        <Link to={`/question/${slug(item.title)}/${item.id}`} className="item">
            <div className="info_question d-flex align-items-center">
                <div className={`${item.user?.avatar ? '' : 'no_'}avatar`}>
                    {item.user?.avatar ? <img src={urlImageSSO(item.user?.avatar)} alt=""/> : <Icon name={'person'} type="round"/>}
                </div>
                <div>
                    <p className="title">{item.name}</p>
                    <div className="d-flex align-items-center">
                        <span className="text">{item.user?.name}</span>
                        <span className="text">{dateUtils.formatDate(item.createdAt, 'DD/MM/YYYY hh:mm A')}</span>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center flex-shrink-0 px-2">
                <div className="d-flex align-items-center mr-5" style={{minWidth: 100}}>
                    <span className="text">Thư mục:</span>
                    <span className="value">{item.postTopic?.name}</span>
                </div>
                <div className="d-flex align-items-center">
                    <span className="text">Trả lời:</span>
                    <span className="value">{number_format(item?.countComment)}</span>
                </div>
            </div>
        </Link>
    );
}

export default observer(ListQuestion);