import React, {Component} from 'react';
import {observer} from "mobx-react";
import {observable} from "mobx";

interface IProps {
    isError?: any,
    value?: any,
    onChange: any,
    onKeyDown?: any,
    placeholder?: any
}


@observer
class InputPassword extends Component<IProps, any> {
    @observable isShow = false

    render() {
        let item = this.props
        return (
            <>
                <div className="input-group input-password position-relative">
                    <input type={!this.isShow ? 'password' : 'text'} value={item.value} onChange={(e: any) => item.onChange(e)} onKeyDown={(e: any) => item.onKeyDown(e)} className={`form-control form-control-lg ${item.isError ? 'border_error' : ''}`} placeholder={item.placeholder ?? "Nhập mật khẩu"}/>
                    <i className={`far ${this.isShow ? 'fa-eye-slash' : 'fa-eye' }`} onClick={() => this.isShow = !this.isShow}/>
                </div>
                {item.isError && <p className="error">{item.isError}</p>}
            </>
        );
    }
}

export default InputPassword;