import React from 'react';
import {observer} from "mobx-react";
import {medicineStore} from '../MedicineStore';
import {numberUtil} from "../../../../common/utils/NumberUtil";
import Input from "../../../../common/component/form/Input";
import Select from "../../../../common/component/form/Select";


const AddOrEditMedicine = () => {

    return (
        <div className="modal fade" id="add_or_edit_medicine" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Thiết lập nông dược</h5>
                        <button type="button" className="close" id="close_add_edit_medicine" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="d-flex w-100">
                            <div className="form-group w-50 pr-2">
                                <label>Tên nông dược <span className="text-danger">*</span></label>
                                <Input value={medicineStore.dataRequest.name}
                                       isError={medicineStore.errors.name}
                                       onChange={(e: any) => medicineStore.dataRequest.name = e.currentTarget.value}
                                       placeholder="Nhập nông dược"/>
                            </div>
                            <div className="form-group w-50 pl-2">
                                <label>Mã nông dược</label>
                                <Input value={medicineStore.dataRequest.shortName}
                                       onChange={(e: any) => medicineStore.dataRequest.shortName = e.currentTarget.value}
                                       placeholder="Nhập mã nông dược"/>
                            </div>
                        </div>

                        <div className="d-flex w-100">
                            <div className="form-group w-50 pr-2">
                                <label>Đơn vị <span className="text-danger">*</span></label>
                                <Select value={medicineStore.dataRequest.unitId}
                                        isError={medicineStore.errors.unit} options={medicineStore.listUnit}
                                        onChange={(e: any) => medicineStore.dataRequest.unitId = e}
                                        placeholder="Chọn đơn vị"/>
                            </div>
                            <div className="form-group w-50 pl-2">
                                <label>Giá thành (VND)</label>
                                <Input value={medicineStore.dataRequest.price}
                                       onChange={(e: any) => medicineStore.dataRequest.price = numberUtil.regexNumber(e)}
                                       placeholder="Nhập giá thành"/>
                            </div>
                        </div>

                        <div className="d-flex w-100">
                            <div className="form-group w-50 pr-2">
                                <label>Dung tích / Đơn vị (Đóng gói) <span className="text-danger">*</span></label>
                                <Input value={medicineStore.dataRequest.capacity}
                                       isError={medicineStore.errors.capacity}
                                       onChange={(e: any) => medicineStore.dataRequest.capacity = numberUtil.regexNumberDecimal(e)}
                                       placeholder="Nhập dung tích"/>
                            </div>

                            <div className="form-group w-50 pl-2">
                                <label>Lượng thuốc sử dụng/Ha<span className="text-danger">*</span></label>
                                <Input value={medicineStore.dataRequest.useForOneHa}
                                       isError={medicineStore.errors.useForOneHa}
                                       onChange={(e: any) => medicineStore.dataRequest.useForOneHa = numberUtil.regexNumberDecimal(e)}
                                       placeholder="Nhập lượng thuốc sử dụng"/>
                            </div>
                        </div>


                        <div className="form-group">
                            <label>Nồng độ pha loãng <span className="text-danger">*</span></label>
                            <Input value={medicineStore.dataRequest.concentration}
                                   isError={medicineStore.errors.concentration}
                                   onChange={(e: any) => medicineStore.dataRequest.concentration = e.currentTarget.value}
                                   placeholder="Nhập nồng độ pha loãng"/>
                        </div>

                        <div className="form-group">
                            <label>Nơi cung cấp</label>
                            <div className="input-group">
                                <input type="text" value={medicineStore.dataRequest.supplier}
                                       onChange={(e: any) => medicineStore.dataRequest.supplier = e.currentTarget.value}
                                       className="form-control form-control-lg"
                                       placeholder="Nhập nơi cung cấp"/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => medicineStore.type == "add" ? medicineStore.add() : medicineStore.update()}
                                disabled={medicineStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditMedicine)