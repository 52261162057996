import {getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {trackingSeafoodStore} from "./TrackingSeafoodStore";
import {trackingSeafoodDetailStore} from "./trackingDetail/TrackingSeafoodDetailStore";


class TrackingSeafoodService {

    public getTracking(): Promise<IApiResponse> {
        return getRequest(`/v1/data?page=${trackingSeafoodStore.page}&size=10${trackingSeafoodStore.keyword ? `&keyword=${trackingSeafoodStore.keyword.trim()}` : ''}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/data/${id}`);
    }

    public add(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/data`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/data/${id}`, data);
    }

    public getTrackingQR(): Promise<IApiResponse>{
        return getRequest(`/v1/data/${trackingSeafoodDetailStore.id}/qr_batch?page=${trackingSeafoodDetailStore.page}&size=10`)
    }

    public addTrackingQR(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/data/${trackingSeafoodDetailStore.id}/qr_batch`, data);
    }

    public editTrackingQR(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/data/tracking_batch_data/${id}`, data);
    }

    public detailTrackingBatch(id: any): Promise<IApiResponse>{
        return getRequest(`/v1/data/tracking_batch_data/${id}`)
    }

    public detailTrackingBatchQr(id: any): Promise<IApiResponse>{
        return getRequest(`/v1/data/tracking/${id}`)
    }

    public historyQr(id: any): Promise<IApiResponse>{
        return getRequest(`/v1/data/${id}/qr_files_data?page=${trackingSeafoodDetailStore.pageHistory}&size=5`)
    }

    public addLinkDownloadQr(id: any, qty: number): Promise<IApiResponse> {
        return getRequest(`/v1/data/${id}/qr_export?qty=${qty}`);
    }

    public printQr(id: any, path: string, itemOnRow: number, grayScale: boolean): Promise<IApiResponse> {
        return postRequest(`/v1/data/tracking_batch_data/${id}/pdf${path}`, {itemOnRow: itemOnRow, grayScale: grayScale});
    }

}

export const trackingSeafoodService = new TrackingSeafoodService()