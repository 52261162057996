import {observable} from "mobx";
import $ from "jquery";
import {trackingService} from "../TrackingService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import axios from "axios";
import StorageService from "../../../common/service/StorageService";
import {trackingStore} from "../TrackingStore";
import {slug} from "../../../common/utils/Utils";
import {infoStore} from "../../info/InfoStore";
import {profileStore} from "../../profile/ProfileStore";
import {reviewStore} from "../../infoTracking/ReviewStore";


class TrackingDetailStore {
    @observable id: any = '';
    @observable idBatch: any = '';
    @observable landId: any = '';
    @observable pageHelper: any = '';
    @observable type: any = '';
    @observable itemOnRow: any = 1;
    @observable errorBackgroundPrint: any = '';
    @observable backgroundPrint: any = 1;
    @observable grayScale: any = 2;
    @observable qty: any = '';
    @observable step: number = 1;
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable pageHistory: number = 0
    @observable totalPageHistory: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable listTracking: any[] = []
    @observable listImage: any[] = []
    @observable listHistoryQr: any[] = []
    @observable listPlanJob: any[] = []
    @observable errors = {
        qty: false,
        landId: false,
        distributor: false,
        netWeight: false,
        expireDate: false,
        sentDate: false,
        deliveryLocation: false,
        consumerUnit: false,
        consumerUnitCode: false,
        shippingPartner: false,
        shippingPartnerCode: false,
    }
    @observable dataRequest: any = {
        id: '',
        trackingId: '',
        logo: '',
        qrFile: "",
        countQrExported: '',
        bathCode: '',
        createdAt: '',
        state: "USING",
        info: {
            distributor: '',
            netWeight: "",
            sentDate: "",
            expireDate: "",
            deliveryLocation: "",
            consumerUnit: "",
            consumerUnitCode: '',
            shippingPartner: "",
            shippingPartnerCode: "",
            more: []
        },
        backgroundImage: '',
        qrSetting: {
            left: 0,
            top: 0,
            qrSize: 9,
            stampWith: 35,
            stampHeight: 22,
            label: true
        }
    }

    clearForm() {
        trackingDetailStore.type = 'add'
        trackingDetailStore.itemOnRow = 2
        trackingDetailStore.backgroundPrint = 1
        trackingDetailStore.grayScale = 2
        trackingDetailStore.dataRequest = {
            id: '',
            trackingId: '',
            logo: '',
            qrFile: "",
            countQrExported: '',
            bathCode: '',
            createdAt: '',
            state: "USING",
            info: {
                distributor: '',
                netWeight: "",
                sentDate: "",
                expireDate: "",
                deliveryLocation: "",
                consumerUnit: "",
                consumerUnitCode: '',
                shippingPartner: "",
                shippingPartnerCode: "",
                more: []
            },
            backgroundImage: '',
            qrSetting: {
                left: 0,
                top: 0,
                qrSize: 9,
                stampWith: 35,
                stampHeight: 22,
                label: true
            }
        }
    }

    clearErrors() {
        trackingDetailStore.errors = {
            qty: false,
            distributor: false,
            landId: false,
            netWeight: false,
            expireDate: false,
            sentDate: false,
            deliveryLocation: false,
            consumerUnit: false,
            consumerUnitCode: false,
            shippingPartner: false,
            shippingPartnerCode: false,
        }
    }

    async getTracking() {
        trackingDetailStore.isLoading = true
        const result = await trackingService.getTrackingQR()
        trackingDetailStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            trackingDetailStore.listTracking = result.body.data
            trackingDetailStore.totalPage = result.body.metadata.totalPage
        }
    }

    async printQr() {
        this.errorBackgroundPrint = ''
        let backgroundPrint: boolean = trackingDetailStore.backgroundPrint != 1
        if(backgroundPrint && !trackingDetailStore.dataRequest.backgroundImage){
            trackingDetailStore.errorBackgroundPrint = 'Bạn chưa có mẫu in, tải tem mẫu để sử dụng in theo mẫu!'
            return
        }

        trackingDetailStore.isLoadingBt = true
        const result = await trackingService.printQr(trackingDetailStore.dataRequest.id, trackingDetailStore.dataRequest.qrFile, Number(trackingDetailStore.itemOnRow), false, backgroundPrint)
        trackingDetailStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            let url = process.env.REACT_APP_API_QR + '/farm/v1/pdf/' + result.body.filePath;
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');
                    $('#close_print_code').trigger('click')
                    trackingDetailStore.grayScale = 2
                    trackingDetailStore.itemOnRow = 2
                    trackingDetailStore.backgroundPrint = 1
                    trackingDetailStore.errorBackgroundPrint = ''
                    trackingDetailStore.getTracking()
                })
                .catch(error => {
                    toastUtil.error(error);
                });
        }
    }

    setDefaultQrSetting(){
        if(!trackingDetailStore.dataRequest.qrSetting){
            trackingDetailStore.dataRequest.qrSetting = {
                left: 0,
                top: 0,
                qrSize: 9,
                stampWith: 35,
                stampHeight: 22,
                label: true
            }
        }
    }

    async getDetailTracking(id: number) {
        trackingDetailStore.type = 'edit'
        trackingDetailStore.clearDownloadQR()
        trackingDetailStore.clearErrors()
        const result = await trackingService.detailTrackingBatch(id)
        if (result.status === HttpStatusCode.OK) {
            trackingDetailStore.dataRequest = result.body
            this.setDefaultQrSetting()
        }
    }

    async getDetailTrackingQr(id: number) {
        const result = await trackingService.detailTrackingQRBatch(id)
        if (result.status === HttpStatusCode.OK) {
            trackingStore.dataRequest = result.body
            if (profileStore.path == "truy-xuat") {
                await reviewStore.getStatistic()
                await reviewStore.getReview()
            }
            infoStore.dataInfo = result.body.farm
            trackingDetailStore.listPlanJob = result.body.jobs
            trackingDetailStore.dataRequest = result.body;
        }
    }

    async getHistoryQr(id: any) {
        trackingDetailStore.isLoadingBt = false
        trackingDetailStore.pageHistory = 0
        trackingDetailStore.listHistoryQr = []
        const result = await trackingService.historyQr(id)
        if (result.status === HttpStatusCode.OK) {
            trackingDetailStore.listHistoryQr = result.body.data
            trackingDetailStore.totalPageHistory = result.body.metadata.totalPage
        }
    }

    async readMore() {
        const result = await trackingService.historyQr(trackingDetailStore.idBatch)
        if (result.status === HttpStatusCode.OK) {
            trackingDetailStore.listHistoryQr = trackingDetailStore.listHistoryQr.concat(result.body.data)
        }
    }

    async addEditTemSample(){
        let {id, info, logo, state, backgroundImage, qrSetting} = trackingDetailStore.dataRequest

        let error = false
        let data = {
            logo: logo,
            state: trackingDetailStore.type === 'add' ? "USING" : state,
            packageInfo: info,
            backgroundImage: backgroundImage,
            qrSetting: {
                left: qrSetting.left,
                top: qrSetting.top,
                qrSize: qrSetting.qrSize,
                stampWith: qrSetting.stampWith,
                stampHeight: qrSetting.stampHeight,
                label: qrSetting.label
            }
        }

        if(!qrSetting.qrSize || qrSetting.qrSize > 27 || qrSetting.qrSize < 4){
            error = true
        }

        if(!qrSetting.stampWith || qrSetting.stampWith > 105 || qrSetting.stampWith < 10){
            error = true
        }

        if(!qrSetting.stampHeight || qrSetting.stampHeight > 105 || qrSetting.stampHeight < 10){
            error = true
        }

        if(error){
            return
        }


        trackingDetailStore.isLoadingBt = true
        const result = trackingDetailStore.type === 'add' ? await trackingService.addTrackingQR(data) : await trackingService.editTrackingQR(id, data)
        trackingDetailStore.isLoadingBt = false

        if (result.status === HttpStatusCode.OK) {
            $('#close_tem_demo').trigger('click')
            toastUtil.success(`${trackingDetailStore.type === 'add' ? 'Thêm' : 'Cập nhật'} tem mẫu thành công!`, 1)
        } else {
            if (typeof result.body.message === "object") {
                this.errors = result.body.message
            } else {
                toastUtil.error(result.body.message ?? `${trackingDetailStore.type === 'add' ? 'Thêm' : 'Cập nhật'} tem mẫu xuất thất bại`, 2)
            }
        }

    }

    async addEditTracking(isClose?: boolean) {
        trackingDetailStore.clearErrors()
        let {id, info, logo, state, backgroundImage, qrSetting} = trackingDetailStore.dataRequest

        let data = {
            logo: logo,
            state: trackingDetailStore.type === 'add' ? "USING" : state,
            packageInfo: info,
            backgroundImage: backgroundImage,
            qrSetting: {
                left: qrSetting.left,
                top: qrSetting.top,
                qrSize: qrSetting.qrSize,
                stampWith: qrSetting.stampWith,
                stampHeight: qrSetting.stampHeight,
                label: qrSetting.label
            }
        }


        if (!info.distributor) {
            trackingDetailStore.errors.distributor = true
        }
        if (!info.netWeight) {
            trackingDetailStore.errors.netWeight = true
        }
        if (!info.sentDate) {
            trackingDetailStore.errors.sentDate = true
        }
        if (!info.expireDate) {
            trackingDetailStore.errors.expireDate = true
        }
        if (!info.deliveryLocation) {
            trackingDetailStore.errors.deliveryLocation = true
        }
        if (!info.consumerUnit) {
            trackingDetailStore.errors.consumerUnit = true
        }
        if (!info.consumerUnitCode) {
            trackingDetailStore.errors.consumerUnitCode = true
        }
        if (!info.shippingPartner) {
            trackingDetailStore.errors.shippingPartner = true
        }
        if (!info.shippingPartnerCode) {
            trackingDetailStore.errors.shippingPartnerCode = true
        }

        let {distributor, netWeight, expireDate, consumerUnitCode, sentDate, deliveryLocation, consumerUnit, shippingPartner, shippingPartnerCode} = trackingDetailStore.errors
        if (distributor || netWeight || expireDate || consumerUnitCode || sentDate || deliveryLocation || consumerUnit || shippingPartner || shippingPartnerCode) {
            toastUtil.warning('Vui lòng nhập đầy đủ thông tin', 2)
            return
        }

        trackingDetailStore.isLoadingBt = true
        const result = trackingDetailStore.type === 'add' ? await trackingService.addTrackingQR(data) : await trackingService.editTrackingQR(id, data)
        trackingDetailStore.isLoadingBt = false

        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${trackingDetailStore.type === 'add' ? 'Thêm' : 'Cập nhật'} mã qr thành công!`, 1)
            if (!isClose) {
                $('#close_qr_code').trigger('click')
            } else {
                trackingDetailStore.dataRequest = result.body
                trackingDetailStore.clearDownloadQR()
                trackingDetailStore.pageHelper = `${window.location.origin}/truy-xuat-lo/${slug(trackingStore.dataRequest?.plan?.procedure?.product?.name)}/${trackingStore.dataRequest.id}/${result.body.id}`
            }
            await trackingDetailStore.getTracking();
        } else {
            if (typeof result.body.message === "object") {
                this.errors = result.body.message
            } else {
                toastUtil.error(result.body.message ?? `${trackingDetailStore.type === 'add' ? 'Thêm' : 'Cập nhật'} mã qr xuất thất bại`, 2)
            }
        }
    }


    clearDownloadQR() {
        trackingDetailStore.type = "edit"
        trackingDetailStore.isLoadingBt = false
        trackingDetailStore.step = 1
        trackingDetailStore.qty = ''
        trackingDetailStore.landId = ''
    }

    async addLinkDownloadQr() {
        this.errors.qty = false

        if (!this.landId) {
            this.errors.landId = true
            toastUtil.warning('Vui lòng chọn thửa đất!', 1)
        }

        if (!this.qty) {
            this.errors.qty = true
            toastUtil.warning('Vui lòng nhập số QR tải về!', 2)
        }

        if (Number(this.qty) > 10000) {
            this.errors.qty = true
            toastUtil.warning('Số QR tải về tối đa là 10.000!', 3)
        }

        if (!this.landId || !this.qty || Number(this.qty) > 10000) {
            return
        }

        this.isLoadingBt = true
        const result = await trackingService.addLinkDownloadQr(this.dataRequest.id, this.qty)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Tạo link mã qr thành công!', 1)
            this.dataRequest = result.body
            this.setDefaultQrSetting()
            this.listTracking.map((item) => {
                if (item.id === this.dataRequest.id) {
                    item.countQrExported = result.body.countQrExported
                }
            })
            this.step = 2
            this.landId = ''
        } else {
            toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra!', 2)
        }
    }

    downloadQR = async (id: any, path: any) => {
        var newHeaders: any;
        if (StorageService.isTokenExits()) {
            newHeaders = {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                Authorization: 'Bearer ' + StorageService.getToken()
            }
        } else {
            newHeaders = {'Content-Type': 'application/json'}
        }
        trackingDetailStore.isLoadingBt = true
        return await axios
            .get(`${process.env.REACT_APP_API_DOMAIN}/farm/v1/tracking_batch/${id}${path}`, {
                headers: newHeaders,
                responseType: 'blob'
            })
            .then(({data: blob}) => {
                trackingDetailStore.isLoadingBt = false
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = 'qrFile.zip';
                link.click();
            });
    };


}

export const trackingDetailStore = new TrackingDetailStore()