import {observable} from "mobx";
import {IFilter} from "../../../common/component/filter/FilterComponent";
import {EventInput} from "@fullcalendar/core";


class ScheduleStore {
    @observable isLoading: boolean = false
    @observable searchYear: any = ''
    @observable planId: any = ''
    @observable dataFilter: IFilter[] = []
    @observable weekendsVisible = true;
    @observable events: EventInput[] = [];

    getEvents(): EventInput[] {
        return this.events;
    }



}

export const scheduleStore = new ScheduleStore()