import {
    getRequest,
    postRequest,
    deleteRequest,
    putRequest,
    IApiResponse,
    exportRequest
} from "../../../common/helpers/RequestHelper";
import {laborStore} from "./LaborStore";
import {profileStore} from "../../profile/ProfileStore";

class LaborService {

    public getLabors(size?: any): Promise<IApiResponse> {
        return getRequest(`/v1/labor?page=${laborStore.page}&size=${size ?? 10}`);
    }

    public detailLabors(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/labor/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/labor`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/labor/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/labor/${id}`, {id});
    }

    public role(): Promise<IApiResponse> {
        return getRequest(`/v1/role?farmId=${profileStore.dataFarm.userId}&page=0&size=100`);
    }

    public exportData(): Promise<IApiResponse> {
        laborStore.isLoadingBt = true
        const result = exportRequest(`/v1/labor/export`, 'Nhân công');
        laborStore.isLoadingBt = false
        return result
    }

}

export const laborService = new LaborService()