import React, { useEffect} from 'react';
import {checkPermission, number_format, sttPage} from "../../../common/utils/Utils";
import Paginate from "../../../common/component/Paginate";
import ButtonAdd from "../../../common/component/ButtonAdd";
import {observer} from "mobx-react";
import {laborStore} from "./LaborStore";
import NoContent from "../../../common/component/NoContent";
import Loading from "../../../common/component/Loading";
import {PermissionConst} from "../../../common/constants/Constants";
import AddOrEditLabor from "./components/AddOrEditLabor";
import {dateUtils} from "../../../common/utils/DateUtils";
import ModalConfirm from "./components/ModalConfirm";
import Button from "../../../common/component/Button";
import {laborService} from "./LaborService";
import {useTranslation} from "react-i18next";


const LaborComponent = () => {

    const { t } = useTranslation();

    const changePage = async (data: any) => {
        laborStore.page = data.selected;
        await laborStore.getLabors();
    }

    useEffect(() => {
         laborStore.getLabors().then()
         laborStore.getRole().then()
    }, [])

    if(checkPermission(PermissionConst.laborView)){
        if (laborStore.isLoading) {
            return <Loading/>
        } else {
            return (
                <div className='table-responsive'>
                    {checkPermission(PermissionConst.laborAdd) && <div className="bt_add">
                        <Button class="mr-2 button_add text-white" img={true} disabled={laborStore.isLoadingBt} onClick={() => laborService.exportData()} content={t('export')}/>
                        <ButtonAdd text={t('addLabor')} dataTarget={'add_or_edit_labor'} onClick={laborStore.clearForm}/>
                    </div>}
                    {laborStore.listLabor && laborStore.listLabor.length > 0 ?
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th className="text-center">STT</th>
                                <th className="text-center">{t('idLogin')}</th>
                                <th>{t('firstAndLastName')}</th>
                                <th className="text-center">{t('position')}</th>
                                <th className="text-center">{t('birthday')}</th>
                                <th className="text-center">{t('salary/hour')}</th>
                                <th className="text-center">{t('status')}</th>
                                {checkPermission(PermissionConst.laborUpdate) && <th className="text-center">{t('actions')}</th>}
                            </tr>
                            </thead>
                            <tbody>

                            {laborStore.listLabor.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(laborStore.page, i)}</td>
                                        <td className="text-center">{item.phone}</td>
                                        <td>{item.name}</td>
                                        <td className="text-center">{item.role.name}</td>
                                        <td className="text-center">{item.birthDay ? dateUtils.formatDate(item.birthDay) : '-'}</td>
                                        <td className="text-center">{item.wage ? number_format(item.wage) : '-'}</td>
                                        <td className="text-center">{item.status == "BLOCK" ? <span className="text-danger">Đã khóa</span> : <span className="color-green">Hoạt động</span>}</td>
                                        {checkPermission(PermissionConst.laborUpdate) && <td className="text-center">
                                            <div className="action">
                                                <button className="bt_action" title={t('edit')} data-toggle="modal"
                                                        data-target="#add_or_edit_labor"
                                                        onClick={() => laborStore.getDetailLabor(item.id)}>
                                                    <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                </button>
                                                <button className="bt_action" title={item.status == "BLOCK" ? t('openAccount') : t('lockAccount')} onClick={() => laborStore.getDetailLabor(item.id)} data-toggle="modal" data-target="#modal_confirm">
                                                    {item.status == "BLOCK" ? <img src="/assets/ico/action/ico_unlock.svg" alt=""/> : <img src="/assets/ico/action/ico_lock.svg" alt=""/>}
                                                </button>
                                            </div>
                                        </td>}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}

                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Paginate currentPage={laborStore.page} totalPage={laborStore.totalPage}
                                  changePage={changePage}/>
                    </div>
                    <AddOrEditLabor/>
                    <ModalConfirm/>
                </div>
            );
        }
    }else {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{height: 500}}>
                <h5>Không có quyền truy cập</h5>
            </div>
        )
    }
}

export default observer(LaborComponent)