import {toastUtil} from "../utils/ToastUtil";
import {genFileRequest} from "./RequestHelper";
import axios from "axios";
import HttpStatusCode from "../constants/HttpErrorCode";

export function isImage(type: any) {
    return type.startsWith('image/');
}

function getFileExtension(url: string) {
    return url.split('.').pop();
}

export function checkFileExtension(url: string, extension: string) {
    const fileExtension = getFileExtension(url);
    return fileExtension === extension;
}


async function uploadFileHelper(e: any, changeFile: any) {
    e.preventDefault()
    const reader = new FileReader();
    let file = e.target.files[0]

    if (isImage(file.type)) {
        if (file.size > 10000 * 1024) {
            toastUtil.warning("Ảnh của bạn quá lớn. Vui lòng chọn ảnh có kích thước nhỏ hơn 10MB.", 1)
            return
        }
    } else {
        if (file.size > 15000000) {
            toastUtil.warning("Vui lòng chọn files có kích thước không quá 15mb.", 1)
            return
        }
    }
    reader.readAsDataURL(file);

    await uploadFile(file, changeFile)
}

let bucket = process.env.REACT_APP_BUCKET

const uploadFile = async (files: any, changeFile: any) => {

    const result = await genFileRequest(`/v1/s3/generate-upload-url?fileName=${files.name}&bucket=${bucket}&type=${files.type === "video/mp4" ? 'VIDEO' : isImage(files.type) ? "IMAGE" : "FILE"}`)

    if (result.status === HttpStatusCode.OK) {
        let dataFile = result.body

        const uploadFile = axios.create({
            baseURL: dataFile.uploadUrl,
            method: 'PUT',
            headers: {
                'Content-Type': files.type
            }
        })

        let resultGenFile = await uploadFile.put(dataFile.uploadUrl, files)

        if (resultGenFile?.status === HttpStatusCode.OK) {
            changeFile(dataFile.imagePath)
        }
    }
}


export default uploadFileHelper