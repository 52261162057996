import {getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {trackingLivestockStore} from "./TrackingLivestockStore";
import {trackingLivestockDetailStore} from "./trackingDetail/TrackingLivestockDetailStore";


class TrackingLivestockService {

    public getTracking(): Promise<IApiResponse> {
        return getRequest(`/v1/data?type=LIVESTOCK_INDUSTRY&page=${trackingLivestockStore.page}&size=10${trackingLivestockStore.keyword ? `&keyword=${trackingLivestockStore.keyword.trim()}` : ''}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/data/${id}?type=LIVESTOCK_INDUSTRY`);
    }

    public add(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/data?type=LIVESTOCK_INDUSTRY`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/data/${id}?type=LIVESTOCK_INDUSTRY`, data);
    }

    public getTrackingQR(): Promise<IApiResponse>{
        return getRequest(`/v1/data/${trackingLivestockDetailStore.id}/qr_batch?page=${trackingLivestockDetailStore.page}&size=10&type=LIVESTOCK_INDUSTRY`)
    }

    public addTrackingQR(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/data/${trackingLivestockDetailStore.id}/qr_batch?type=LIVESTOCK_INDUSTRY`, data);
    }

    public editTrackingQR(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/data/tracking_batch_data/${id}?type=LIVESTOCK_INDUSTRY`, data);
    }

    public detailTrackingBatch(id: any): Promise<IApiResponse>{
        return getRequest(`/v1/data/tracking_batch_data/${id}?type=LIVESTOCK_INDUSTRY`)
    }

    public detailTrackingBatchQr(id: any): Promise<IApiResponse>{
        return getRequest(`/v1/data/tracking/${id}?type=LIVESTOCK_INDUSTRY`)
    }

    public historyQr(id: any): Promise<IApiResponse>{
        return getRequest(`/v1/data/${id}/qr_files_data?type=LIVESTOCK_INDUSTRY&page=${trackingLivestockDetailStore.pageHistory}&size=5`)
    }

    public addLinkDownloadQr(id: any, qty: number): Promise<IApiResponse> {
        return getRequest(`/v1/data/${id}/qr_export?qty=${qty}&type=LIVESTOCK_INDUSTRY`);
    }

    public printQr(id: any, path: string, itemOnRow: number, grayScale: boolean): Promise<IApiResponse> {
        return postRequest(`/v1/data/tracking_batch_data/${id}/pdf${path}?type=LIVESTOCK_INDUSTRY`, {itemOnRow: itemOnRow, grayScale: grayScale});
    }

}

export const trackingLivestockService = new TrackingLivestockService();