export const TYPE_TIMES = {
    YEARS: "YEARS",
    MONTHS: "MONTHS",
    DAYS: "DAYS",
}
export const TIMES = [
    { type: TYPE_TIMES.YEARS, name: "năm" },
    { type: TYPE_TIMES.MONTHS, name: "tháng" },
    { type: TYPE_TIMES.DAYS, name: "ngày" },
]

export const STATUS_PACKAGE = {
    APPROVED: "APPROVED",
    CANCEL: "CANCEL",
    DRAFT: "DRAFT",
    PENDING: "PENDING",
    REJECTED: "REJECTED",
}

export const TYPE_PACKAGE = {
    TRIAL: "TRIAL" //dùng thử
} 

export const TYPE_QR_PACKAGE = {
    PACKAGE_EXPORT_BATCH: "PACKAGE_EXPORT_BATCH", //lô
    PACKAGE_EXPORT_PRODUCT: "PACKAGE_EXPORT_PRODUCT" //sản phẩm
}