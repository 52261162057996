import Constants from "../../../common/constants/Constants";
import {getRequest, postRequest, deleteRequest, putRequest, exportRequest, IApiResponse} from "../../../common/helpers/RequestHelper";
import {equipmentStore} from "./EquipmentStore";

class EquipmentService{

    public getEquipments(size?: any): Promise<IApiResponse> {
        return getRequest(`/v1/equipment?page=${equipmentStore.page}&size=${size ?? 10}`);
    }

    public getEquipmentsAll(): Promise<IApiResponse> {
        return getRequest(`/v1/equipment?page=0&size=${Constants.MAX_SIZE}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/equipment/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/equipment`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/equipment/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/equipment/${id}`, {id});
    }

    public importData(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/equipment/import`, data);
    }

    public exportData(): Promise<IApiResponse> {
        equipmentStore.isLoadingBt = true
        const result = exportRequest(`/v1/equipment/export`, 'Thiết bị máy móc');
        equipmentStore.isLoadingBt = false
        return result
    }

}

export const equipmentService = new EquipmentService()