import React from 'react';
import {observer} from "mobx-react";
import {procedureStore} from "../ProcedureStore";
import Input from "../../../common/component/form/Input";
import {productStore} from "../../setup/product/ProductStore";

const AddOrEditProcedure = () => {


    return (
        <div className="modal fade" id="add_or_edit_procedure" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Thiết lập quy trình</h5>
                        <button type="button" className="close" id="close_add_edit" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">

                        <div className="form-group">
                            <label>Tên quy trình <span className="text-danger">*</span></label>
                            <Input value={procedureStore.dataRequest.name} isError={procedureStore.errors.name}
                                   onChange={(e: any) => procedureStore.dataRequest.name = e.currentTarget.value}
                                   placeholder="Nhập tên quy trình"/>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="w-50 pr-2">
                                <div className="form-group">
                                    <label>Nhóm sản phẩm <span className="text-danger">*</span></label>
                                    <div className="input-group">
                                        <select
                                            className={`form-control form-control-lg ${procedureStore.errors.cateLv1 ? 'border_error' : ''}`}
                                            value={procedureStore.dataRequest.cateLv1} onChange={(e: any) => procedureStore.changeCateLv1(e.currentTarget.value)}>
                                            <option value="" disabled>Chọn nhóm sản phẩm</option>
                                            {productStore.listCateLv1 && productStore.listCateLv1.map((item, i) => {
                                                return <option key={i} value={item.id}>{item.name}</option>
                                            })}
                                        </select>
                                    </div>

                                    <p className="error">{procedureStore.errors && procedureStore.errors.cateLv1}</p>
                                </div>
                            </div>
                            <div className="w-50 pl-2">
                                <div className="form-group">
                                    <label>Loại sản phẩm <span className="text-danger">*</span></label>
                                    <div className="input-group">
                                        <select disabled={!procedureStore.dataRequest.cateLv1}
                                            className={`form-control form-control-lg ${procedureStore.errors.cateLv2 ? 'border_error' : ''}`}
                                            value={procedureStore.dataRequest.cateLv2}  onChange={(e: any) => procedureStore.changeCateLv2(e.currentTarget.value)}>
                                            <option value="" disabled>Chọn loại sản phẩm</option>
                                            {productStore.listCateLv2 && productStore.listCateLv2.map((item, i) => {
                                                return <option key={i} value={item.id}>{item.name}</option>
                                            })}
                                        </select>
                                    </div>

                                    <p className="error">{procedureStore.errors && procedureStore.errors.cateLv2}</p>
                                </div>
                            </div>
                        </div>


                        <div className="d-flex form-group">
                            <div className="w-50 pr-2">
                                <label>Giống sản phẩm <span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <select disabled={!procedureStore.dataRequest.cateLv2}
                                        className={`form-control form-control-lg ${procedureStore.errors.cateLv3 ? 'border_error' : ''}`}
                                        value={procedureStore.dataRequest.cateLv3}  onChange={(e: any) => procedureStore.changeCateLv3(e.currentTarget.value)}>
                                        <option value="" disabled>Chọn giống sản phẩm</option>
                                        {productStore.listCateLv3 && productStore.listCateLv3.map((item, i) => {
                                            return <option key={i} value={item.id}>{item.name}</option>
                                        })}
                                    </select>
                                </div>

                                <p className="error">{procedureStore.errors && procedureStore.errors.cateLv3}</p>
                            </div>
                           <div className="w-50 pl-2">
                               <label>Sản phẩm <span className="text-danger">*</span></label>
                               <div className="input-group">
                                   <select value={procedureStore.dataRequest.productId} disabled={!procedureStore.dataRequest.cateLv3}
                                           onChange={(e: any) => {procedureStore.dataRequest.productId = e.currentTarget.value; procedureStore.errors.productId = ''}}
                                           className={`form-control form-control-lg ${procedureStore.errors.productId ? 'border_error' : ''}`}>
                                       <option value="" disabled>Chọn sản phẩm</option>
                                       {productStore.listProduct && productStore.listProduct.map((item, i) => {
                                           return <option key={i} value={item.id}>{item.name}</option>
                                       })}
                                   </select>
                               </div>
                               <p className="error">{procedureStore.errors && procedureStore.errors.productId}</p>
                           </div>
                        </div>

                        <div className="form-group">
                            <label>Ghi chú</label>
                            <div className="input-group">
                                    <textarea value={procedureStore.dataRequest.note}
                                              onChange={(e: any) => procedureStore.dataRequest.note = e.currentTarget.value}
                                              className={`form-control form-control-lg`} placeholder="Nhập ghi chú"/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => procedureStore.type == "add" ? procedureStore.add() : procedureStore.update()}
                                disabled={procedureStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}


export default observer(AddOrEditProcedure);