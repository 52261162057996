import React from 'react';
import {observer} from "mobx-react";
import "./LoginStyle.scss";
import {Navigate} from "react-router-dom";
import {profileStore} from "../../profile/ProfileStore";
import StorageService from "../../../common/service/StorageService";
import {Icon} from "../../../common/component/Icon";
import Footer from "./Footer";
import {useTranslation} from "react-i18next";


const LoginComponent = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng: any) => {
        i18n.changeLanguage(lng).then();
        localStorage.setItem('language', lng)
    };

    if (StorageService.getToken()) {
        if (profileStore.path == "cms") {
            return <Navigate to={'/cms/users'} replace/>
        } else return <Navigate to={'/dashboard'} replace/>
    } else return (
        <div className="login_main">
            <div className="box_1">
                <div className="container">
                    <div className="d-flex justify-content-end">
                        <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" id="changeLanguage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img className="mr-1" src={`/assets/ico/${localStorage.getItem('language') == 'en' ? 'england' : "vietnam"}.png`} alt=""/>
                                <span className="font-weight-bold">{localStorage.getItem('language') == 'en' ? 'ENG' : "VIE"}</span>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="changeLanguage">
                                <div className="dropdown-item cursor_pointer font-weight-medium" onClick={() => changeLanguage('vi')}><img src="/assets/ico/vietnam.png" alt=""/> Vietnam</div>
                                <div className="dropdown-item cursor_pointer font-weight-medium" onClick={() => changeLanguage('en')}><img src="/assets/ico/england.png" alt=""/> English</div>
                            </div>
                        </div>
                    </div>
                    <div className="row flex-grow">
                        <div className="col-12 col-md-6 d-flex align-items-center">
                            <div className="w-100">
                                <h2>OmFarm</h2>
                                <h4>{t('textLoginTitle')}</h4>
                                <p>{t('textLoginContent')}</p>
                               <div className="d-flex align-items-center">
                                   <a className="bt-register mr-3" href={`${process.env.REACT_APP_DOMAIN_SSO}/register?redirectTo=${window.location.origin}&service=farm${localStorage.getItem('language') ? `&lng=${localStorage.getItem('language')}` : ''}${profileStore.path === "cms" ? `&cms=true` : ''}`}>
                                       <button type="button"
                                               className="btn bt-login d-flex align-items-center justify-content-center">
                                           {t('signUp')}
                                       </button>
                                   </a>
                                   <a className="bt-login mr-4" href={`${process.env.REACT_APP_DOMAIN_SSO}/login?redirectTo=${window.location.origin}&service=farm${localStorage.getItem('language') ? `&lng=${localStorage.getItem('language')}` : ''}${profileStore.path === "cms" ? `&cms=true` : ''}`}>
                                       <button type="button"
                                               className="btn bt-login d-flex align-items-center justify-content-center">
                                           {t('login')} <Icon name="arrow_right_alt" type="outlined"
                                                                className="text-white ml-2"/>
                                       </button>
                                   </a>
                               </div>
                            </div>
                        </div>
                        <div className="d-none col-12 text-center col-md-6 d-lg-block">
                            <img src="/assets/images/login/banner_1.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box_2" style={{backgroundImage: `url("/assets/images/login/background.png")`}}>
                <div className="container h-100">
                    <div className="row flex-grow h-100">
                        <div className="col-12 col-md-6 d-none d-lg-flex align-items-center justify-content-end">
                            <img src="/assets/images/login/banner_2.svg" alt=""/>
                        </div>
                        <div className="col-12 col-md-6 pl-5 d-flex align-items-center">
                            <div className="pl-5">
                                <h2>Chức năng của <br/> OmFarm</h2>
                                <ul>
                                    <li>Quản lý thông tin dữ liệu nông dược, phân bón,<br/> nguyên vật liệu</li>
                                    <li>Quản lý quy trình sản xuất sản phẩm.</li>
                                    <li>Lập lô sản xuất và theo dõi sản xuất.</li>
                                    <li>Nhật ký điện tử</li>
                                    <li>Mã truy xuất nguồn gốc (QR)</li>
                                    <li>Theo dõi vùng trồng trực quan</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box_3">
                <div className="container h-100">
                    <div className="row flex-grow h-100">
                        <div className="col-12 col-md-6 pr-5 d-flex align-items-center justify-content-end">
                            <div className="pr-4">
                                <h2>Giá trị phần mềm<br/> OmFarm mang lại</h2>
                                <ul>
                                    <li>Lập kế hoạch sản xuất và chăm sóc cây trồng hợp lý</li>
                                    <li>Quản lý lao động</li>
                                    <li>Ghi chép nhật ký sản xuất cây trồng</li>
                                    <li>Quản lý tình hình sử dụng thuốc bảo vệ thực vật và phân bón</li>
                                    <li>Truy xuất nguồn gốc.</li>
                                    <li>Cảnh báo sớm sâu bệnh.</li>
                                    <li>Cảnh báo thiên tai.</li>
                                    <li>Tính toán lịch gieo trồng và đề xuất thời điểm thu hoạch hợp lý</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-lg-start pl-5">
                            <img src="/assets/images/login/banner_3.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box_4">
                <div className="container h-100">
                    <div className="row flex-grow h-100">
                        <div className="col-12 col-md-6 text-center text-lg-end">
                            <img src="/assets/images/login/banner_5.svg" alt=""/>
                        </div>
                        <div className="col-12 col-md-6 pl-4 pt-4 pr-4 pr-lg-0">
                            <div className="">
                                <p className="text_green">OmFarm</p>
                                <h2>Tương thích với <br/>mọi thiết bị</h2>
                                <span>NỀN TẢNG OMFARM ĐƯỢC VIẾT CHO HỆ ĐIỀU HÀNH</span>
                                <div className="d-flex logo align-items-center w-100 justify-content-start flex-wrap">
                                    <a className="app_ios mb-2 mr-4"
                                       href="https://apps.apple.com/vn/app/omfarm/id6444198165" target="_blank">
                                        <img className="w-100" src="/assets/images/login/ico_appStore.png" alt=""/>
                                    </a>
                                    <a className="app_android mb-2"
                                       href="https://play.google.com/store/apps/details?id=com.ommanisoft.omfarm"
                                       target="_blank">
                                        <img className="w-100" src="/assets/images/login/ico_android.png" alt=""/>
                                    </a>
                                    <img style={{marginRight: 34}} src="/assets/images/login/ico_windows.svg" alt=""/>
                                    <img src="/assets/images/login/ico_macOs.png" alt=""/>
                                </div>
                                <span className="text">Tương thích với smartphone, máy tính bảng, laptop, máy tính để bàn App được tải về & cài đặt miễn phí</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box_5">
                <img className="w-100" style={{height: 776}} src="/assets/images/login/banner_4.webp" alt=""/>
            </div>
            <div className="box_6">
                <img className="position-absolute img_left  d-lg-block d-none" src="/assets/images/login/banner_9.svg" alt=""/>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 d-flex justify-content-center">
                            <img className="ml-5" src="/assets/images/login/banner_7.png" alt=""/>
                        </div>
                        <div className="col-12 col-md-6 pt-2">
                            <div className="d-flex align-items-center banner_6" style={{backgroundImage: 'url("/assets/images/login/banner_6.svg")'}}>
                                <a className="app_ios position-absolute"
                                   href="https://apps.apple.com/vn/app/omfarm/id6444198165" target="_blank">
                                    <img src="/assets/images/login/ico_appStore.png" alt=""/>
                                </a>
                                <a className="app_android position-absolute"
                                   href="https://play.google.com/store/apps/details?id=com.ommanisoft.omfarm"
                                   target="_blank">
                                    <img src="/assets/images/login/ico_android.png" alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default observer(LoginComponent);