import React from 'react';
import {observer} from "mobx-react";
import DatePickerSingle from "../../../common/component/DatePickerSingle";
import {procedureStore} from "../../procedure/ProcedureStore";
import {planStore} from "../PlanStore";
import Select from "../../../common/component/form/Select";
import Input from "../../../common/component/form/Input";


const AddOrEditPlanOne = () => {

    const handleStartDate = async (startDate: Date) => {
        planStore.dataRequest.startDate = startDate.getTime()
        planStore.minEndDate = startDate
        if(planStore.dataRequest.startDate > planStore.dataRequest.endDate){
            planStore.dataRequest.endDate = ''
        }
    }

    const handleEndDate = async (endDate: Date) => {
        planStore.dataRequest.endDate = endDate.getTime()
    }

    return (
        <div className="modal fade" id="add_or_edit_plan" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{planStore.type == 'add' ? 'Tạo' : 'Sửa'} kế hoạch sản
                            xuất</h5>
                        <button type="button" className="close" id="close_add_edit_one" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="w-100 d-flex">
                            <div className="w-50 pr-4">
                                <div className="form-group">
                                    <label>Năm bắt đầu <span className="text-danger">*</span></label>
                                    <Select value={planStore.dataRequest.year}
                                            onChange={async (e: any) => planStore.dataRequest.year = e}
                                            isError={planStore.errors.year} options={planStore.listYear}
                                            placeholder="Chọn năm"/>
                                </div>
                            </div>
                            <div className="w-50 pl-4">
                                <div className="form-group">
                                    <label>Quy trình <span className="text-danger">*</span></label>
                                    <Select value={planStore.dataRequest.procedureId} disabled={planStore.type == 'edit' && true}
                                            onChange={(e: any) => planStore.dataRequest.procedureId = e}
                                            isError={planStore.errors.procedure}
                                            options={procedureStore.listProcedure} placeholder="Chọn quy trình"/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Tên kế hoạch <span className="text-danger">*</span></label>
                            <Input isError={planStore.errors.name} value={planStore.dataRequest.name}
                                   onChange={(e: any) => planStore.dataRequest.name = e.currentTarget.value}
                                   placeholder="Nhập tên kế hoạch"/>
                        </div>

                        <div className="d-flex align-items-center mb-4">
                            <div className="form-group w-50 mr-4">
                                <label>Ngày bắt đầu <span className="text-danger">*</span></label>
                                <DatePickerSingle selected={planStore.dataRequest.startDate}
                                                  isError={planStore.errors.startDate}
                                                  onChange={handleStartDate} placeholder="Chọn ngày bắt đầu"/>
                            </div>
                            <div className="form-group w-50 ml-4">
                                <label>Ngày kết thúc <span className="text-danger">*</span></label>
                                <DatePickerSingle selected={planStore.dataRequest.endDate}
                                                  minDate={planStore.minEndDate} isError={planStore.errors.endDate}
                                                  onChange={handleEndDate} placeholder="Chọn ngày kết thúc"/>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => planStore.checkValidate()}
                                disabled={planStore.isLoadingBt}>Tiếp tục
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditPlanOne);
