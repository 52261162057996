import humps from 'humps';
import axios, {Method} from "axios";
import $ from 'jquery'
import StorageService from "../service/StorageService";
import HttpStatusCode from "../constants/HttpErrorCode";
import {toastUtil} from "../utils/ToastUtil";
import {profileStore} from "../../modules/profile/ProfileStore";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { checkExpirationTime, getIDParameter } from '../utils/Utils';

export interface IApiResponse {
    readonly status: number;
    readonly body: any;
    readonly headers?: any
}

export interface IBodyError {
    readonly errorCode: number;
    readonly message: string
    readonly messageCode?: string
}


let API_DOMAIN = process.env.REACT_APP_API_DOMAIN
let API_DOMAIN_ORG = process.env.REACT_APP_API_DOMAIN_ORG
let API_UPLOAD_FILE = process.env.REACT_APP_API_UPLOADFILE
let apiRefreshToken = API_DOMAIN + '/sso/v1/auth/refresh-token'

const refreshAuthLogic = (failedRequest: { config: { headers: any } }) => axios.post(apiRefreshToken, {'refreshToken': StorageService.getRefreshToken()}).then(response => {
    let { token, claims } = response.data;
    failedRequest.config.headers['Authorization'] = 'Bearer ' + token;
    parseInt(token)
    StorageService.setToken(token);
    localStorage.setItem('name', claims.name)
    localStorage.setItem('avatar', claims.avatar)
    localStorage.setItem('listRole', claims.roles)
    localStorage.setItem('isAdmin', claims['is-admin'] || false)
    localStorage.setItem('expirationTime', claims['package-exp'] || "")
    localStorage.setItem('packageName', claims['package-name'] || "")
    localStorage.setItem('farmOwner', claims['farm-owner'] || null)

    if(checkExpirationTime(claims['package-exp'], true).isExpired && getIDParameter(1) !== "het-han-dung-thu") window.location.href = "het-han-dung-thu";
    return Promise.resolve();
}).catch(function (error) {
});

createAuthRefreshInterceptor(axios, refreshAuthLogic, {
    pauseInstanceWhileRefreshing: true
});

axios.interceptors.request.use((config: any) => {
    if(StorageService.getToken()){
        config.headers['Authorization'] = 'Bearer ' + StorageService.getToken();
    }
    return config;
});

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (originalRequest.url === apiRefreshToken) {
            toastUtil.error('Phiên đăng nhập hết hạn', 2);
            setTimeout(() => {
                localStorage.clear()
                StorageService.removeToken();
                StorageService.removeRefreshToken();
                if (profileStore.path === "cms") {
                    window.location.href = "/cms/login"
                } else {
                    window.location.href = "/login"
                }
            }, 800)
        }
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                await createAuthRefreshInterceptor(axios, refreshAuthLogic);
                return axios(originalRequest);
            } catch (refreshError) {
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);


export const responseError = (error: any) => {
    let bodyError: IBodyError;

    try {
        if (error && error?.status === HttpStatusCode.FORBIDDEN) {
            bodyError = {
                errorCode: HttpStatusCode.FORBIDDEN,
                message: "Không có quyền truy cập!"
            }
        }else if (error && error.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
            bodyError = {
                errorCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
                message: "Lỗi máy chủ nội bộ, vui lòng thử lại sau!"
            }
        } else {
            bodyError = {
                errorCode: error?.data?.errorCode,
                message: error?.data?.message
            }
        }
    } catch (e) {
        bodyError = {
            errorCode: HttpStatusCode.UNKNOW_ERROR,
            message: "Đã có lỗi xảy ra!"
        }
    }

    const apiResponse: IApiResponse = {
        status: error?.status,
        body: bodyError
    };

    return apiResponse;
}

export async function getRequest(path: string, noFarm?: any): Promise<IApiResponse> {
    var newHeaders: any = {'Content-Type': 'application/json'}

    if (StorageService.getToken()) {
        newHeaders = {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            Authorization: 'Bearer ' + StorageService.getToken()
        }
    }

    return await axios.get(API_DOMAIN + `${!noFarm ? '/farm' : ''}` + path, {headers: newHeaders})
        .then(
            (response) => {
                const apiResponse: IApiResponse = {
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                    headers: response.headers
                };
                return apiResponse;
            },
            (error) => {
                return responseError(error?.response)
            },
        )
}


export async function genFileRequest(path: string): Promise<IApiResponse> {
    var newHeaders: any = {'Content-Type': 'application/json'}

    if (StorageService.getToken()) {
        newHeaders = {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            Authorization: 'Bearer ' + StorageService.getToken()
        }
    }

    return await axios.get(API_UPLOAD_FILE + path, {headers: newHeaders})
        .then(
            (response) => {
                const apiResponse: IApiResponse = {
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                    headers: response.headers
                };
                return apiResponse;
            },
            (error) => {
                return responseError(error?.response)
            },
        )
}


export async function getRequestOrg(path: string): Promise<IApiResponse> {
    var newHeaders = {'Content-Type': 'application/json'}

    return await axios.get(API_DOMAIN_ORG + path, {headers: newHeaders})
        .then(
            (response) => {
                const apiResponse: IApiResponse = {
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                    headers: response.headers
                };
                return apiResponse;
            },
            (error) => {
                return responseError(error?.response)
            },
        )
}

export async function postRequest(path: string, params: object, noFarm?: boolean): Promise<IApiResponse> {
    return apiCall(path, "POST", params, noFarm);
}

export function apiCall(path: string, _method: Method = "POST", _params: object, noFarm?: boolean): Promise<IApiResponse> {
    var newHeaders: any = {'Content-Type': 'application/json'}

    if (StorageService.getToken()) {
        newHeaders = {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            Authorization: 'Bearer ' + StorageService.getToken()
        }
    }

    return new Promise<IApiResponse>((resolve) => {
        axios({
            data: JSON.stringify(_params),
            headers: newHeaders,
            method: _method,
            url: API_DOMAIN + `${!noFarm ? '/farm' : ''}` + path
        })
            .then(function (response) {
                resolve({
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                });
            })
            .catch(function (error) {
                resolve(responseError(error?.response))
            });

    });
}

export async function putRequest(path: string, params: object, noti?: boolean): Promise<IApiResponse> {
    return apiCall(path, "PUT", params, noti);
}

export async function deleteRequest(path: string, params: object, noFarm?:boolean): Promise<IApiResponse> {

    return apiCall(path, "DELETE", params, noFarm);
}

export async function patchRequest(path: string, params: object): Promise<IApiResponse> {
    return apiCall(path, "PATCH", params);
}

export async function exportRequest(path: string, name: string): Promise<any> {
    let newHeaders: any = {
        'Content-Type': 'text/html',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    };

    if (StorageService.getToken()) {
        newHeaders['Authorization'] = 'Bearer ' + StorageService.getToken();
    }

    return await axios.get(API_DOMAIN + '/farm' + path, {headers: newHeaders, responseType: "blob"})
        .then(
            (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${name}.xlsx`)
                document.body.appendChild(link)
                link.click();
                toastUtil.success('Xuất file thành công!', 1);
            },
            (error) => {
                toastUtil.error(error.response.data.message)
            }
        );
}


export async function uploadExcel(params: object): Promise<IApiResponse> {
    let newHeaders: any = {
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    };

    if (StorageService.getToken()) {
        newHeaders['Authorization'] = 'Bearer ' + StorageService.getToken();
    }

    return new Promise<IApiResponse>((resolve) => {
        axios({
            method: "POST",
            url: API_DOMAIN + '/farm/v1/upload/excel',
            data: params,
            headers: newHeaders,
        })
            .then(function (response) {
                resolve({
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                });

            })
            .catch(function (error) {
                resolve(responseError(error?.response))
            });
    });
}


export const importData = async (e: any, callBack: any) => {
    e.preventDefault()
    const reader = new FileReader();
    let file = e.target.files[0]
    await exportExcel(file, callBack);
    reader.readAsDataURL(file);
    $('#import_data').val('');
}


export async function exportExcel(files: any, callBack: any) {
    var formData = new FormData();
    formData.append("file", files);
    const result = await uploadExcel(formData);
    if (result.status === HttpStatusCode.OK) {
        await callBack(result.body.filePath);
    } else {
        toastUtil.error(result.body.message);
    }
}


