import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import Loading from "../../../common/component/Loading";
import {checkPermission, getIDParameter} from "../../../common/utils/Utils";
import NoContent from "../../../common/component/NoContent";
import PopupConfirm from "../../../common/component/PopupConfirm";
import ButtonAdd from "../../../common/component/ButtonAdd";
import AddOrEditWork from "./components/AddOrEditWork";
import {procedureJobStore} from "./ProcedureJobStore";
import {profileStore} from "../../profile/ProfileStore";
import {Link} from "react-router-dom";
import {procedureStore} from "../ProcedureStore";
import {PermissionConst} from "../../../common/constants/Constants";
import {stageStore} from "../stage/StageStore";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";


const ProcedureJobComponent = () => {

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return
        }
        procedureJobStore.reorder(
            result.source.index,
            result.destination.index
        );

        procedureJobStore.isChanged = false
    }

    useEffect(() => {
        profileStore.link = `/procedure`
        procedureJobStore.procedureId = getIDParameter();
        procedureStore.getDetail(procedureJobStore.procedureId)
        procedureJobStore.getProcedureJob()
        stageStore.getStages()

    }, [])


    if (procedureJobStore.isLoading) {
        return <Loading/>
    } else {
        return (
                <div className='procedures'>
                    <div className="d-flex header_back align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <Link to={`/procedure`}>Quy trình</Link>
                            <i className="fal fa-angle-right"/>
                            <span>{procedureStore.dataRequest.name}</span>
                        </div>
                        <Link to={'/procedure'} className="back">
                            <i className="far fa-arrow-left"/>
                            <span>Quay lại</span>
                        </Link>
                    </div>
                    {checkPermission(PermissionConst.jobAdd) && <div
                        className={`bt_add ${stageStore.listStage && stageStore.listStage.length < 1 && 'justify-content-between'}`}>
                        {stageStore.listStage && stageStore.listStage.length < 1 &&
                        <span>Bạn chưa có giai đoạn nào. Click <Link className="color-green"
                                                                     to={`/procedure/stage`}>vào đây</Link> để thêm giai đoạn</span>}
                        <div className="d-flex align-items-center">
                            <button type="button" onClick={() => procedureJobStore.sortJob()} style={{width: 70, height: 40}}
                                    className="btn send-request-outline mr-2"
                                    disabled={procedureJobStore.isChanged}>Lưu
                            </button>
                            <ButtonAdd text="Thêm công việc" dataTarget={'add_or_edit_work'} onClick={procedureJobStore.clearForm}/>
                        </div>
                    </div>}
                    <div className="table-responsive">
                        {procedureJobStore.listProcedureJob && procedureJobStore.listProcedureJob.length > 0 ?
                            <TableContainer component={(props) => <Paper {...props} />}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>STT</TableCell>
                                            <TableCell>Tên công việc</TableCell>
                                            <TableCell className="text-center">Công đoạn</TableCell>
                                            {(checkPermission(PermissionConst.jobUpdate) || checkPermission(PermissionConst.jobDelete)) &&
                                            <TableCell className="text-center">Thao tác</TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody component={DroppableComponent(onDragEnd)}>
                                        {procedureJobStore.listProcedureJob.map((item: any, i: number) => {
                                            return (
                                                <TableRow component={DraggableComponent(item.id.toString(), i)} key={i}>
                                                    <TableCell>{i + 1}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell className="text-center">{item.stage?.name}</TableCell>
                                                    {(checkPermission(PermissionConst.jobUpdate) || checkPermission(PermissionConst.jobDelete)) &&
                                                    <TableCell className="text-center" width="20%">
                                                        <div className="action">
                                                            {checkPermission(PermissionConst.jobUpdate) && <button className="bt_action"
                                                                    data-toggle="modal" data-target="#add_or_edit_work"
                                                                    onClick={() => procedureJobStore.getDetail(item.id)}>
                                                                <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                            </button>}
                                                            {checkPermission(PermissionConst.jobDelete) && <button className="bt_action"
                                                                    onClick={() => procedureJobStore.id = item.id}
                                                                    data-toggle="modal" data-target="#popup_confirm">
                                                                <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                                            </button>}
                                                        </div>
                                                    </TableCell>}
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer> : <NoContent/>}
                    </div>
                    <AddOrEditWork/>
                    <PopupConfirm isLoading={procedureJobStore.isLoadingBt} action={procedureJobStore.delete}/>
                </div>
            );
    }
}


export default observer(ProcedureJobComponent)


// @ts-ignore
const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
});

const DraggableComponent = (id: string, index: number) => (props: any) => {
    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

                    {...props}
                >
                    {props.children}
                </TableRow>
            )}
        </Draggable>
    )
};

const DroppableComponent = (
    onDragEnd: (result: any, provided: any) => void) => (props: any) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'1'} direction="vertical">
                {(provided) => {
                    return (
                        <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                            {props.children}
                            {provided.placeholder}
                        </TableBody>
                    )
                }}
            </Droppable>
        </DragDropContext>
    )
};

