import {getRequest, putRequest, IApiResponse} from "../../../common/helpers/RequestHelper";
import {userStore} from "./UserStore";

class UserService {

    getUsers(): Promise<IApiResponse> {
        return getRequest(`/v1/farm?page=${userStore.page}&size=10${userStore.searchStatus ? `&status=${userStore.searchStatus}` : ''}${userStore.keyword ? `&keyword=${userStore.keyword}` : ''}`)
    }

    updateStatus(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/farm/${id}/status`, data)
    }

}

export const userService = new UserService()