import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {checkPermission, slug} from "../../../common/utils/Utils";
import NoContent from "../../../common/component/NoContent";
import PopupConfirm from "../../../common/component/PopupConfirm";
import ButtonAdd from "../../../common/component/ButtonAdd";
import {landStore} from "./LandStore";
import {signUpStore} from "../../auth/signUp/SignUpStore";
import AddOrEditLand from "./components/AddOrEditLand";
import Loading from "../../../common/component/Loading";
import {PermissionConst} from "../../../common/constants/Constants";
import {planStore} from "../../planning/PlanStore";
import ButtonImport from "../../../common/component/ButtonImport";
import {importData} from "../../../common/helpers/RequestHelper";
import Button from "../../../common/component/Button";
import {landService} from "./LandService";
import {Icon} from "../../../common/component/Icon";

const LandComponent = () => {


    useEffect(() => {
        planStore.id = ''
        planStore.dataRequest.provinceId = ""
        signUpStore.getProvinces()
        landStore.getLands()
        return () => {
            landStore.listLand = []
        }
    }, [])


    if (landStore.isLoading) {
        return <Loading/>
    } else return (
        <div className='table-responsive'>
            <>
                <div className="bt_add">
                    <ButtonImport onChange={(e: any) => importData(e, landStore.importData)} className="mr-2"/>
                    <Button class="mr-2 button_add text-white" img={true} disabled={landStore.isLoadingBt} onClick={() => landService.exportData()} content="Xuất"/>
                    <a style={{width: 108}} className="btn mr-2 button_add text-white" href="/file/Đất cơ sở.xlsx" download><Icon name="download" className="text-white mr-1"/> <strong>Tải mẫu</strong></a>
                    {checkPermission(PermissionConst.landUpdate) &&
                    <a href="/maps" className="bt_map mr-2" target="_blank">
                        <img src="/assets/ico/ico_map.svg" alt=""/>
                        Xem bản đồ
                    </a>}
                    {checkPermission(PermissionConst.landAdd) &&
                    <ButtonAdd text="Thêm đất - cơ sở" dataTarget={'add_or_edit_land'}
                               onClick={landStore.clearForm}/>}
                </div>
            </>
            {landStore.listLand && landStore.listLand.length > 0 ?
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">STT</th>
                        <th>Tên đất cơ sở</th>
                        <th className="text-center">Địa điểm</th>
                        <th className="text-center">Diện tích (ha)</th>
                        <th className="text-center">Phân loại</th>
                        <th className="text-center">Nông hộ</th>
                        {(checkPermission(PermissionConst.landUpdate) || checkPermission(PermissionConst.landDelete)) &&
                        <th className="text-center">Thao tác</th>}
                    </tr>
                    </thead>
                    <tbody>

                    {landStore.listLand.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.ward.name}, {item.district.name}, {item.province.name}</td>
                                <td className="text-center">{item.area}</td>
                                <td className="text-center">{item.type == "OWN" ? "Sở hữu" : 'Đất thuê'}</td>
                                <td className="text-center">{item.farmHousehold}</td>
                                {(checkPermission(PermissionConst.landUpdate) || checkPermission(PermissionConst.landDelete)) &&
                                <td className="text-center">
                                    <div className="action">
                                        {checkPermission(PermissionConst.landUpdate) &&
                                        <button className="bt_action" title={"Bản đổ"}>
                                            <a href={`/map/${slug(item.name)}/${item.id}`} target="_blank">
                                                <img src="/assets/ico/action/ico_map.svg" alt=""/>
                                            </a>
                                        </button>
                                        }
                                        {checkPermission(PermissionConst.landUpdate) &&
                                        <button className="bt_action" title={"Sửa"} data-toggle="modal"
                                                data-target="#add_or_edit_land"
                                                onClick={() => landStore.getDetailLand(item.id)}>
                                            <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                        </button>}
                                        {checkPermission(PermissionConst.landDelete) &&
                                        <button className="bt_action" title={"Xóa"}
                                                onClick={() => landStore.id = item.id}
                                                data-toggle="modal" data-target="#popup_confirm">
                                            <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                        </button>}
                                    </div>
                                </td>}
                            </tr>
                        )
                    })}
                    </tbody>
                </table> : <NoContent/>}

            <AddOrEditLand/>
            <PopupConfirm isLoading={landStore.isLoadingBt} action={landStore.delete}/>
        </div>
    );
}

export default observer(LandComponent);