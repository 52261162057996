import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {checkPermission, number_format, sttPage} from "../../../common/utils/Utils";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import PopupConfirm from "../../../common/component/PopupConfirm";
import ButtonAdd from "../../../common/component/ButtonAdd";
import {medicineStore} from "./MedicineStore"
import AddOrEditMedicine from './components/AddOrEditMedicine';
import Loading from "../../../common/component/Loading";
import {PermissionConst} from "../../../common/constants/Constants";
import ButtonImport from "../../../common/component/ButtonImport";
import {importData} from "../../../common/helpers/RequestHelper";
import Button from "../../../common/component/Button";
import {medicineService} from "./MedicineService";
import {Icon} from "../../../common/component/Icon";


const MedicineComponent = () => {

    const changePage = async (data: any) => {
        medicineStore.page = data.selected;
        await medicineStore.getMedicine();
    }

    useEffect(() => {
        medicineStore.getMedicine()
        medicineStore.getUnit()
    }, [])

    if (medicineStore.isLoading) {
        return <Loading/>
    } else {
        return (
            <div className='table-responsive'>
                {checkPermission(PermissionConst.medicineAdd) && <div className="bt_add">
                    <ButtonImport onChange={(e: any) => importData(e, medicineStore.importData)} className="mr-2"/>
                    <Button class="mr-2 button_add text-white" img={true} onClick={() => medicineService.exportData()} content="Xuất"/>
                    <a style={{width: 108}} className="btn mr-2 button_add text-white" href="/file/Nông dược.xlsx" download><Icon name="download" className="text-white mr-1"/> <strong>Tải mẫu</strong></a>
                    <ButtonAdd text="Thêm nông dược" dataTarget={'add_or_edit_medicine'}
                               onClick={medicineStore.clearForm}/>
                </div>}
                {medicineStore.listMedicine && medicineStore.listMedicine.length > 0 ?
                    <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th className="text-center">STT</th>
                            <th>Tên nông dược</th>
                            <th>Nồng độ pha loãng</th>
                            <th className="text-center">Lượng sử dụng/Ha</th>
                            <th className="text-center">Dung tích/Đơn vị (Đóng gói)</th>
                            <th className="text-center">Giá thành</th>
                            {(checkPermission(PermissionConst.medicineUpdate) || checkPermission(PermissionConst.medicineDelete)) &&
                            <th className="text-center">Thao tác</th>}
                        </tr>
                        </thead>
                        <tbody>

                        {medicineStore.listMedicine.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">{sttPage(medicineStore.page, i)}</td>
                                    <td>{item.name}</td>
                                    <td>{item.concentration}</td>
                                    <td className="text-center">{item.useForOneHa} {item.unit?.code}</td>
                                    <td className="text-center">{item.capacity} {item.unit?.code}</td>
                                    <td className="text-center">{number_format(item.price)} đ</td>
                                    {(checkPermission(PermissionConst.medicineUpdate) || checkPermission(PermissionConst.medicineDelete)) &&
                                    <td className="text-center">
                                        <div className="action">
                                            {checkPermission(PermissionConst.medicineUpdate) &&
                                            <button className="bt_action" title="Sửa" data-toggle="modal"
                                                    data-target="#add_or_edit_medicine"
                                                    onClick={() => medicineStore.getDetail(item.id)}>
                                                <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                            </button>}
                                            {checkPermission(PermissionConst.medicineDelete) &&
                                            <button className="bt_action" title="Xóa"
                                                    onClick={() => medicineStore.id = item.id}
                                                    data-toggle="modal" data-target="#popup_confirm">
                                                <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                            </button>}
                                        </div>
                                    </td>}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table> : <NoContent/>}

                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Paginate currentPage={medicineStore.page} totalPage={medicineStore.totalPage}
                              changePage={changePage}/>
                </div>
                <AddOrEditMedicine/>
                <PopupConfirm isLoading={medicineStore.isLoadingBt} action={medicineStore.delete}/>
            </div>
        );
    }
}

export default observer(MedicineComponent)