import React from 'react';
import {observer} from "mobx-react";
import {questionStore} from "../QuestionStore";
import {urlImage} from "../../../common/utils/Utils";
import {Icon} from "../../../common/component/Icon";
import TextEditor from "../../../common/component/TextEditor";
import uploadFileHelper from "../../../common/helpers/UploadFileHelper";


const FormOther = () => {

    const uploadImg = (e: any) => {
        questionStore.formOther.attachFiles.push(e)
    }


    return (
        <div className="modal fade" id="add_or_edit_form_other_question" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Báo cáo trả lời khác</h5>
                        <button type="button" className="close" id="close_add_edit_form_other" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">

                        <div className="form-group h-auto">
                            <label>Mô tả <span className="text-danger">*</span></label>
                            <div className="input-group">
                                <TextEditor height={260} content={questionStore.formOther.solution} onChange={(e: any) => questionStore.formOther.solution = e}/>
                                {(questionStore.errors.other_solution && !questionStore.formOther.solution) && <p className="error">{questionStore.errors.other_solution}</p>}
                            </div>
                        </div>
                    </div>
                    <div className={`d-flex modal-footer mt-4 align-items-end justify-content-${questionStore.formOther.attachFiles && questionStore.formOther.attachFiles.length > 0 ? 'between' : 'end'}`}>
                        {questionStore.formOther.attachFiles && questionStore.formOther.attachFiles.length > 0 &&
                        <div className="list_image">
                            <div className="list_">
                                {questionStore.formOther.attachFiles.map((item: any, i: number) => {
                                    return (
                                        <div className="item" key={i}>
                                            <img className="img" src={urlImage(item)} alt=""/>
                                            <div className="close_item"
                                                 onClick={() => questionStore.formOther.attachFiles.splice(i, 1)}>
                                                <img src="/assets/ico/ico_close.svg" alt=""/>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                        <div className="d-flex pb-2 align-items-center">
                            <div className="upload" title="Tải ảnh">
                                <Icon name='image' type="outlined"/>
                                <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                       onChange={async (e: any) => await uploadFileHelper(e, uploadImg)}/>
                            </div>
                            <button type="button" className="btn bt-send-request"
                                    onClick={() => questionStore.sendOther()}
                                    disabled={questionStore.isLoadingBt}>Xác nhận <Icon name="send" type={"round"}
                                                                                        className="text-white"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(FormOther);