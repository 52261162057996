import {observable} from "mobx";
import {noticeService} from "./NoticeService";
import HttpStatusCode from "../../common/constants/HttpErrorCode";


class NoticeStore {
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable listNotice: any[] = []
    @observable countNotice: any = {
        read: 0,
        unRead: 0,
        total: 0
    }


    async getNotice() {
        this.isLoading = true
        const result = await noticeService.getNotice()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listNotice = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }


    async readMore() {
        const result = await noticeService.getNotice()
        if (result.status === HttpStatusCode.OK) {
            noticeStore.listNotice = noticeStore.listNotice.concat(result.body.data)
        }
    }

    async getCountNotice() {
        const result = await noticeService.getCountNotice()
        if (result.status === HttpStatusCode.OK) {
            this.countNotice = result.body
        }
    }


    async callNotice(){
        await noticeStore.getCountNotice()
        await noticeStore.getNotice()
    }


    async sendAllRead() {
        const result = await noticeService.readAllNotice()
        if (result.status === HttpStatusCode.OK) {
           await this.callNotice()
        }
    }

    async sendRead(id: any) {
        let ids: any[] = [id]
        const result = await noticeService.readNotice(ids)
        if (result.status === HttpStatusCode.OK) {
            this.listNotice.map((item) => {
                if(item.id == id){
                    item.read = true
                    noticeStore.countNotice.unRead -= 1
                }
            })
        }
    }
}

export const noticeStore = new NoticeStore()