import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {productStore} from "./ProductStore";
import ButtonAdd from "../../../common/component/ButtonAdd";
import Paginate from "../../../common/component/Paginate";
import NoContent from "../../../common/component/NoContent";
import PopupConfirm from "../../../common/component/PopupConfirm";
import AddOrEditProduct from "./components/AddOrEditProduct";
import Loading from "../../../common/component/Loading";
import {checkPermission, sttPage} from "../../../common/utils/Utils";
import {PermissionConst} from "../../../common/constants/Constants";
import ButtonImport from "../../../common/component/ButtonImport";
import {importData} from "../../../common/helpers/RequestHelper";
import Button from "../../../common/component/Button";
import {productService} from "./ProductService";
import {Icon} from "../../../common/component/Icon";
import {useTranslation} from "react-i18next";

const ProductComponent = () => {
    const { t } = useTranslation();
    const changePage = async (data: any) => {
        productStore.page = data.selected;
        await productStore.getProducts();
    }

    useEffect(() => {
        productStore.getProducts();
        productStore.getCateLv1()
        return () => {
            productStore.clearForm()
        }
    }, [])

    if (productStore.isLoading) {
        return <Loading/>
    } else return (
        <div className="product_content">
            {checkPermission(PermissionConst.productAdd) && <div className="bt_add">
                <ButtonImport onChange={(e: any) => importData(e, productStore.importData)} className="mr-2"/>
                <Button class="mr-2 button_add text-white" img={true} disabled={productStore.isLoadingBt} onClick={() => productService.exportData()} content={t('export')}/>
                <a style={{width: 108}} className="btn mr-2 button_add text-white d-flex align-items-center justify-content-center" href="/file/Sản phẩm.xlsx" download><Icon name="download" className="text-white mr-1"/> <strong>Tải mẫu</strong></a>
                <ButtonAdd text={'Thêm sản phẩm'} dataTarget="add_or_edit_product" onClick={productStore.clearForm}/>
            </div>}

            <div className='table-responsive'>
                {productStore.listProduct && productStore.listProduct.length > 0 ?
                    <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th rowSpan={2} className="text-center" style={{lineHeight: "81px"}}>STT</th>
                            <th rowSpan={2} style={{lineHeight: "81px"}}>Tên sản phẩm</th>
                            <th colSpan={3} className="text-center">Danh mục sản phẩm</th>
                            <th className="text-center" style={{lineHeight: "81px"}} rowSpan={2}>Ghi chú</th>
                            {(checkPermission(PermissionConst.productUpdate) || checkPermission(PermissionConst.productDelete)) &&
                            <th className="text-center" style={{lineHeight: "81px"}} rowSpan={2}>Thao tác</th>}
                        </tr>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th className="text-center">Nhóm</th>
                            <th className="text-center">Loại</th>
                            <th className="text-center">Giống</th>
                        </tr>
                        </thead>
                        <tbody>
                        {productStore.listProduct.map((item, i) => {

                            return (
                                <tr key={i}>
                                    <td className="text-center">{sttPage(productStore.page, i)}</td>
                                    <td>{item.name}</td>
                                    <td className="text-center">{item.group?.name}</td>
                                    <td className="text-center">{item.type?.name}</td>
                                    <td className="text-center">{item.treeLike?.name}</td>
                                    <td className="text-center">{item.note}</td>
                                    {(checkPermission(PermissionConst.productUpdate) || checkPermission(PermissionConst.productDelete)) &&
                                    <td className="text-center" width="18%">
                                        <div className="action">
                                            {checkPermission(PermissionConst.productUpdate) &&
                                            <button className="bt_action" title="Sửa" data-toggle="modal"
                                                    data-target="#add_or_edit_product"
                                                    onClick={() => productStore.getDetailProduct(item.id)}>
                                                <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                            </button>}
                                            {checkPermission(PermissionConst.productDelete) &&
                                            <button className="bt_action" title="Xóa"
                                                    onClick={() => productStore.id = item.id}
                                                    data-toggle="modal" data-target="#popup_confirm">
                                                <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                            </button>}
                                        </div>
                                    </td>}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table> : <NoContent/>}
            </div>
            <div className="d-flex align-items-center justify-content-center mt-4">
                <Paginate currentPage={productStore.page} totalPage={productStore.totalPage}
                          changePage={changePage}/>
            </div>

            <AddOrEditProduct/>
            <PopupConfirm isLoading={productStore.isLoadingBt} action={productStore.delete}/>
        </div>
    );
}

export default observer(ProductComponent)