import Constants from "../../../common/constants/Constants";
import {
    postRequest,
    IApiResponse,
    getRequest,
    deleteRequest,
    putRequest,
    exportRequest
} from "../../../common/helpers/RequestHelper";
import {productStore} from "./ProductStore";

class ProductService {
    public getProducts(size?:number): Promise<IApiResponse> {
        return getRequest(`/v1/product?page=${productStore.page}&size=${size ? size : 10}${productStore.categoryId ? `&categoryId=${productStore.categoryId}` : ''}`);
    }

    public getProductsAll(): Promise<IApiResponse> {
        return getRequest(`/v1/product?page=0&size=${Constants.MAX_SIZE}${productStore.categoryId ? `&categoryId=${productStore.categoryId}` : ''}`);
    }

    public detailProduct(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/product/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/product`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/product/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/product/${id}`, {id});
    }

    public role(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/product?farmId=${id}`);
    }

    public cateLv1(): Promise<IApiResponse> {
        return getRequest(`/v1/category?level=1&page=0&size=250`);
    }

    public cateLv2(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/category?parentId=${id}&page=0&size=250`);
    }

    public cateLv3(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/category?parentId=${id}&page=0&size=250`);
    }

    public importData(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/product/import`, data);
    }

    public exportData(): Promise<IApiResponse> {
        productStore.isLoadingBt = true
        const result = exportRequest(`/v1/product/export`, 'Sản phẩm');
        productStore.isLoadingBt = false
        return result
    }


}

export const productService = new ProductService();