import {observable} from "mobx";
import {laborService} from "./LaborService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery"
import {numberUtil} from "../../../common/utils/NumberUtil";

interface IErrors{
    role: string,
    name: string,
    phone: string,
}



class LaborStore {
    @observable step: any = 1
    @observable id: any = '';
    @observable type: any = '';
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable public errors: IErrors = {
        name: '',
        role: '',
        phone: "",
    }
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable dataRequest: any = {
        id: "",
        roleId: null,
        name: '',
        status: '',
        birthDay: '',
        wage: '',
        phone: ""
    }
    @observable listLabor: any[] = []
    @observable listRole: any[] = []

    clearError(){
        laborStore.errors = {
            role: '',
            name: '',
            phone: ""
        }
    }
    clearForm() {
        laborStore.clearError()
        laborStore.type = 'add'
        laborStore.dataRequest = {
            id: "",
            roleId: null,
            name: '',
            status: '',
            birthDay: '',
            wage: '',
            phone: "",
        }
    }

    async getLabors(size?: any) {
        laborStore.isLoading = true
        const result = await laborService.getLabors(size)
        laborStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            laborStore.listLabor = result.body.data
            laborStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getRole() {
        const result = await laborService.role()
        if (result.status === HttpStatusCode.OK) {
            laborStore.listRole = result.body.data
        }
    }

    async getDetailLabor(id: any) {
        laborStore.type = "edit"
        laborStore.clearError()
        const result = await laborService.detailLabors(id)
        if (result.status === HttpStatusCode.OK) {
            laborStore.dataRequest = result.body
        }else {
            toastUtil.error(result.body.message, 4)
        }
    }


    async add() {
        let {roleId, wage, phone} = laborStore.dataRequest

        let data = {
            roleId: parseInt(roleId),
            wage: wage,
            phone: phone
        }

        let regexPhone: RegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

        if (!phone) {
            laborStore.errors.phone = 'Số điện thoại không được để trống!'
            return
        }

        if (!regexPhone.test(phone)) {
            laborStore.errors.phone = "Số điện thoại chưa đúng định dạng!"
            return
        } else laborStore.errors.phone = ''


        if (!roleId) {
            laborStore.errors.role = 'Chức vụ không được để trống!'
            return
        }else laborStore.errors.role = ''

        laborStore.isLoadingBt = true
        const result = await laborService.create(data)
        laborStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thêm nhân công thành công', 1)
            $('#close_add_edit').trigger('click')
            laborStore.clearForm()
            await laborStore.getLabors()
        }else {
            if (typeof result.body.message === "string") {
                toastUtil.error(result.body.message, 3);
            } else if (typeof result.body.message === "object") {
                this.errors = result.body.message;
            }
        }
    }

    async update() {
        let {id, roleId, name, birthDay, wage, phone} = laborStore.dataRequest

        let data = {
            name: name,
            roleId: parseInt(roleId),
            birthDay: birthDay,
            wage: wage,
            phone: phone
        }

        if (!roleId) {
            laborStore.errors.role = 'Chức vụ không được để trống!'
            return
        }else laborStore.errors.role = ''

        if (!name) {
            laborStore.errors.name = 'Họ và tên không được để trống!'
            return
        }else laborStore.errors.name = ''


        laborStore.isLoadingBt = true
        const result = await laborService.update(id, data)
        laborStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật nhân công thành công', 2)
            $('#close_add_edit').trigger('click')
            laborStore.clearForm()
            await laborStore.getLabors()
        }else {
            toastUtil.error(result.body.message ?? 'Cập nhật nhân công thất bại', 5)
        }
    }

    async changeStatus() {
        let {id, status} = laborStore.dataRequest

        let data = {
            status: status == 'BLOCK' ? "ACTIVE" : 'BLOCK'
        }

        laborStore.isLoadingBt = true
        const result = await laborService.update(id, data)
        laborStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_confirm').trigger('click')
            laborStore.clearForm()
            toastUtil.success(status == 'BLOCK' ? 'Mở khóa tài khoản nhân công thành công' : 'Khóa tài khoản nhân công thành công', 3)
            await laborStore.getLabors()
        }else {
            toastUtil.error(result.body.message, 6)
        }
    }


}

export const laborStore = new LaborStore()