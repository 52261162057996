import React from 'react';
import {observer} from "mobx-react";
import {checkPermission, urlImage, urlImageSSO} from "../../../common/utils/Utils";
import {ITypeAnswer, questionStore} from "../QuestionStore";
import {dateUtils} from "../../../common/utils/DateUtils";
import {Icon} from "../../../common/component/Icon";
import {PermissionConst} from "../../../common/constants/Constants";



const ItemAnswer = () => {

    const returnClassName = (answerType: any) => {
        switch (answerType) {
            case ITypeAnswer.other:
                return 'form_other'
            case ITypeAnswer.disease:
                return 'form_disease'
            case ITypeAnswer.technique:
                return 'form_technique'
        }
    }

    if(questionStore.dataAnswer.id){
        let item: any = questionStore.dataAnswer
        return (
            <div className="item active">
                <div className="info_user">
                    <div className={`${item.user?.avatar ? '' : 'no_'}avatar`}>
                        {item.user?.avatar ? <img src={urlImageSSO(item.user?.avatar)} alt=""/> :
                            <Icon name={'person'} type="round"/>}
                    </div>
                    <div className="role">{item.user?.role?.name}</div>
                </div>
                <div className="content">
                    {item.answerType == ITypeAnswer.disease && <>
                        <p className="description">Tên
                            bệnh: <span>{item.answerForm?.disease?.diseaseName}</span>
                        </p>
                        <p className="description">Đặc điểm nhận
                            dạng: <span>{item.answerForm?.disease?.diseaseDetail}</span></p>
                        <p className="description">Bộ phận cây trồng nhiễm
                            bệnh: <span>{item.answerForm?.disease?.diseaseDamagedPart}</span></p>
                        <p className="description d-flex mb-0">Cách xử
                            lý: <span><div className="pl-1" dangerouslySetInnerHTML={{__html: item.answerForm?.disease?.diseaseSolution}}/></span></p>
                    </>}

                    {item.answerType == ITypeAnswer.technique && <>
                        <p className="description">Tên kỹ thuật / công
                            việc: <span>{item.answerForm.technique.techniqueName}</span></p>
                        <p className="description d-flex">Mô tả kỹ
                            thuật: <span><div className="pl-1" dangerouslySetInnerHTML={{__html: item.answerForm.technique.techniqueDetail}}/></span>
                        </p>
                        <p className="description">Thời gian thực
                            hiện: <span>{item.answerForm.technique.techniqueExecuteTime}</span>
                        </p>
                    </>}
                    {item.answerType == ITypeAnswer.other &&
                    <p className="description d-flex">Mô tả: <span><div className="pl-1" dangerouslySetInnerHTML={{__html: item.answerForm?.other?.solution}}/></span></p>}
                    {item.attachFiles && item.attachFiles.length > 0 && <>
                        <div className="list_attachFiles"
                             style={{width: `${(item.attachFiles.length * 132) - 12}px`}}>
                            {item.attachFiles.map((val: any, i: number) => {
                                return (
                                    <div className="item_img cursor_pointer" onClick={() => {
                                        questionStore.listImages = item.attachFiles
                                    }} data-toggle="modal" data-target="#popupZoom_1" key={i}>
                                        <img src={urlImage(val)} alt=""/>
                                    </div>
                                )
                            })}
                        </div>
                    </>}
                    <div className="d-flex  align-items-center justify-content-end">
                        <div className="d-flex">
                            <span className="time mr-4">{item.user?.name}</span>
                            <span
                                className="time mr-4">{item.createdAt == item.updatedAt ? '' : 'Đã chỉnh sửa'}</span>
                            <span
                                className="time">{dateUtils.formatDate(item.updatedAt, 'DD/MM/YYYY hh:mm A')}</span>
                        </div>
                    </div>
                </div>
                {checkPermission(PermissionConst.answerQuestion) &&
                <div className="action flex-wrap">
                    <div className="dropdown">
                        <div id={`actionComment${item.id}`} data-toggle="dropdown"
                             aria-haspopup="true"
                             aria-expanded="false">
                            <Icon name="more_horiz" type="outlined"/>
                        </div>
                        <div className="dropdown-menu" aria-labelledby={`actionComment${item.id}`}>
                            {checkPermission(PermissionConst.answerQuestion) &&
                            <a className="dropdown-item" href="#" data-toggle="modal" data-target={`#add_or_edit_${returnClassName(item.answerType)}_question`}><img
                                src="/assets/ico/action/ico_edit.svg" alt=""/> Sửa</a>}
                            {/*<button className="dropdown-item text-danger"*/}
                            {/*        onClick={() => questionStore.commentId = item.id}*/}
                            {/*        data-toggle="modal" data-target="#popup_confirm_comment">*/}
                            {/*    <img src="/assets/ico/action/ico_trash.svg" alt=""/> Xóa*/}
                            {/*</button>*/}
                        </div>
                    </div>
                    <Icon name="star" type={"round"} className="color-green"/>
                </div>}
            </div>
        );
    }else return null
}

export default observer(ItemAnswer);