import React from 'react';
import {observer} from "mobx-react";
import {diaryStore, ITabDiary} from "../DiaryStore";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";



const TabHeader = () => {

    const { t } = useTranslation();

    return (
        <div className="tab_header d-flex align-items-center">
            <Link to={`/diary?type=${ITabDiary.jobDiary}`}
                  className={`tab ${diaryStore.tabActive == ITabDiary.jobDiary && 'active'}`}
                  onClick={() => diaryStore.tabActive = ITabDiary.jobDiary}>
                <span>{t('productionLog')}</span>
            </Link>
            <Link to={`/diary?type=${ITabDiary.schedule}`}
                  className={`tab ${diaryStore.tabActive == ITabDiary.schedule && 'active'}`}
                  onClick={() => diaryStore.tabActive = ITabDiary.schedule}>
                <span>{t('productionSchedule')}</span>
            </Link>
            <Link to={`/diary?type=${ITabDiary.progress}`}
                  className={`tab ${diaryStore.tabActive == ITabDiary.progress && 'active'}`}
                  onClick={() => diaryStore.tabActive = ITabDiary.progress}>
                <span>{t('progress')}</span>
            </Link>
        </div>
    );
}

export default observer(TabHeader);