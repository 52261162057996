import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react";
import {trackingDetailStore} from "../TrackingDetailStore";
import {urlImage} from "../../../../common/utils/Utils";
import {numberUtil} from "../../../../common/utils/NumberUtil";
import uploadFileHelper from "../../../../common/helpers/UploadFileHelper";

const TemSample = () => {
    let {qrSetting, backgroundImage} = trackingDetailStore.dataRequest
    trackingDetailStore.setDefaultQrSetting()
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({x: qrSetting?.left ?? 0, y: qrSetting?.top ?? 0});
    const [initialMousePos, setInitialMousePos] = useState({x: qrSetting?.left ?? 0, y: qrSetting?.top ?? 0});
    const containerRef = useRef<any>(null);


    const handleMouseDown = (e: any) => {
        e.preventDefault();
        setIsDragging(true);
        setInitialMousePos({x: e.clientX, y: e.clientY});
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };


    const handleMouseMove = (e: any) => {
        if (isDragging) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const imageRect = e.target.getBoundingClientRect();

            const deltaX = (e.clientX - initialMousePos.x) / containerRect.width * 100;
            const deltaY = (e.clientY - initialMousePos.y) / containerRect.height * 100;

            const newX = position.x + deltaX;
            const newY = position.y + deltaY;


            const maxRight = 100 - (imageRect.width / containerRect.width * 100);
            const maxBottom = 100 - (imageRect.height / containerRect.height * 100);

            if (newX >= 0 && newX <= maxRight && newY >= 0 && newY <= maxBottom) {
                setPosition({x: newX, y: newY});
                setInitialMousePos({x: e.clientX, y: e.clientY});
                trackingDetailStore.dataRequest.qrSetting.top = newY
                trackingDetailStore.dataRequest.qrSetting.left = newX
            }
        }
    };


    const uploadImg = (e: any) => {
        trackingDetailStore.dataRequest.backgroundImage = e
    }

    useEffect(() => {
        setPosition({x: qrSetting?.left, y: qrSetting?.top})
        setInitialMousePos({x: qrSetting?.left, y: qrSetting?.top})
    }, [qrSetting])


    let width = qrSetting.stampWith ?? 35
    let height = qrSetting.stampHeight ?? 22

    let widthQr = qrSetting.qrSize ?? 9

    return (
        <div className="modal fade" id="qr_tem_demo" role="dialog" aria-hidden="true">
            <div className="modal-dialog" style={{maxWidth: 950}} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Tem Mẫu</h5>
                        <button type="button" className="close" id="close_tem_demo" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body p-0">
                        <div className="pt-4 pb-4 d-flex justify-content-center" style={{background: '#F8FDFF'}}>
                            <div className="d-flex justify-content-between" style={{width: '90%'}}>
                                <div style={{width: '10.5cm'}}>
                                    <div className="form-group h-auto mt-2">
                                        <label className="mb-0">Kích thước tem mẫu ( tối đa 105 x 105 mm ) <span
                                            className="text-danger">*</span></label>
                                        <div className="d-flex align-items-center">
                                            <input value={trackingDetailStore.dataRequest.qrSetting.stampWith}
                                                   className={`mr-2 form-control ${(!qrSetting.stampWith || qrSetting.stampWith > 105 || qrSetting.stampWith < 10) && 'border_error' }`}
                                                   onChange={(e: any) => {
                                                       if(e.currentTarget.value.length < 4){
                                                           trackingDetailStore.dataRequest.qrSetting.stampWith = numberUtil.regexNumber(e)
                                                       }
                                                   }}
                                                   placeholder='Chiều rộng'/>

                                            <input value={trackingDetailStore.dataRequest.qrSetting.stampHeight}
                                                   className={`mr-2 form-control ${(!qrSetting.stampHeight || qrSetting.stampHeight > 105 || qrSetting.stampHeight < 10) && 'border_error' }`}
                                                   onChange={(e: any) => {
                                                       if(e.currentTarget.value.length < 4){
                                                           trackingDetailStore.dataRequest.qrSetting.stampHeight = numberUtil.regexNumber(e)
                                                       }
                                                   }}
                                                   placeholder='Chiều dài'/>
                                        </div>
                                    </div>
                                    <div className="form-group h-auto mt-3">
                                        <label className="mb-0">Kích thước mã QR ( tối đa 27mm ) <span
                                            className="text-danger">*</span></label>
                                        <input value={trackingDetailStore.dataRequest.qrSetting.qrSize}
                                               className={`mr-2 form-control ${(!qrSetting.qrSize || qrSetting.qrSize > 27 || qrSetting.qrSize < 3) && 'border_error' }`}
                                               onChange={(e: any) => {
                                                   if(e.currentTarget.value.length < 3){
                                                       trackingDetailStore.dataRequest.qrSetting.qrSize = numberUtil.regexNumber(e)
                                                   }
                                               }}
                                               placeholder='Nhập chiều rộng'/>
                                    </div>
                                    <div className="form-group h-auto mt-3">
                                        <div className="form-check d-flex align-items-center">
                                            <input className="form-check-input mt-0 ml-0" type="checkbox" checked={trackingDetailStore.dataRequest.qrSetting.label && true}
                                                   onChange={(e: any) => {
                                                       trackingDetailStore.dataRequest.qrSetting.label = !trackingDetailStore.dataRequest.qrSetting.label;
                                                   }} id="active_code"/>
                                            <label className="form-check-label label_check mb-0 ml-4" htmlFor="active_code">Có mã Code</label>
                                        </div>
                                    </div>
                                </div>
                                {backgroundImage ?
                                    <div className="tem_sample mt-4" style={{
                                        width: `${width > 105 ? 105 : width}mm`,
                                        height: `${height > 105 ? 105 : height}mm`,
                                    }} onMouseOut={() => setIsDragging(false)}
                                         ref={containerRef}>
                                        <button type="button" className="close_img"
                                                onClick={() => trackingDetailStore.dataRequest.backgroundImage = ''}>
                                            <i className="fas fa-times"/>
                                        </button>
                                        <img src={urlImage(backgroundImage)} className="tem_background" alt=""/>
                                        <img id="img_qrCode" src={`/assets/images/tracking/qr_${trackingDetailStore.dataRequest.qrSetting.label ? 'demo' : 'default'}.png`} onMouseDown={handleMouseDown}
                                             onMouseUp={handleMouseUp}
                                             onMouseMove={handleMouseMove}
                                             style={{
                                                 top: `${position.y}%`,
                                                 left: `${position.x}%`,
                                                 width: `${widthQr > 27 ? 27 : widthQr}mm`,
                                                 height: `auto`,
                                                 cursor: isDragging ? 'grabbing' : 'grab'
                                             }} alt="">
                                        </img>

                                    </div> :
                                    <div className="upload_tem mt-4" style={{
                                        width: `105mm`,
                                        height: `66mm`,
                                    }} title="Upload tem mẫu">
                                        <div className="flex-wrap d-flex justify-content-center">
                                            <img src="/assets/ico/ico_cloud_upload.svg" alt=""/>
                                            <div className="w-100 mt-2 text-center">Tải tem mẫu kích thước mặc định 35 x 22 mm
                                            </div>
                                        </div>
                                        <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                               onChange={async (e: any) => await uploadFileHelper(e, uploadImg)}/>
                                    </div>}
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" style={{width: 180}} className="btn send-request"
                                onClick={() => trackingDetailStore.addEditTemSample()}
                                disabled={trackingDetailStore.isLoadingBt || !backgroundImage}>{trackingDetailStore.isLoadingBt ? <i className="fa fa-spinner text-white fa-spin"/> : 'Lưu'}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(TemSample);