import {observable} from "mobx";
import {blogService} from "./BlogService";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";
import $ from 'jquery'
import {profileStore} from "../profile/ProfileStore";
import {getPathName} from "../../common/utils/Utils";

class BlogStore {
    @observable id: any = ''
    @observable redirect: boolean = false
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable metadata: any = {
        page: 0, totalPage: 0
    }
    @observable dataRequest: any = {
        title: "",
        content: "",
        thumbnail: "",
        shortContent: ""
    }
    @observable listBlog: any[] = []

    clearForm() {

        blogStore.dataRequest = {
            id: '',
            title: "",
            content: "",
            thumbnail: "",
            shortContent: ""
        }
    }

    async getBlog() {
        this.isLoading = true
        const result = await blogService.getBlog()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listBlog = result.body.data
            this.metadata = result.body.metadata
        }
    }

    async getDetailBlog(id: number) {
        const result = await blogService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            this.dataRequest = result.body
        }
    }

    async addEdit() {
        let {id, title, content, thumbnail, shortContent} = this.dataRequest

        let data = {
            title: title,
            content: content,
            thumbnail: thumbnail,
            shortContent: shortContent
        }

        if(!title || !content || !thumbnail || !shortContent){
            toastUtil.warning('Vui lòng nhập đầy đủ thông tin!', 1)
            return
        }

        this.isLoadingBt = true
        const result = await getPathName() == 'add-blog' ? await blogService.addBlog(data) : await blogService.editBlog(id, data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${getPathName() == 'add-blog' ? 'Thêm' : 'Cập nhật'} bài viết thành công!`)
            this.redirect = true
            this.clearForm()
        } else toastUtil.error(result.body.message)
    }

    async delete() {
        blogStore.isLoadingBt = true
        const result = await blogService.delete(blogStore.id)
        blogStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Xóa bài viết thành công!')
            $('#close_delete').trigger('click')
            blogStore.id = ''
            await blogStore.getBlog()
        } else toastUtil.error(result.body.message)
    }
}

export const blogStore = new BlogStore()