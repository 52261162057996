import React from 'react'
import {observer} from 'mobx-react-lite'
import {numberUtil} from "../../../../common/utils/NumberUtil";
import InputAnt from "../../../../common/component/form/InputAnt";
import {qrCodePackageStore} from "../QrCodePackageStore";
import {Switch} from "antd";

const AddEditPackageQrCode = () => {


    return (
        <div className='modal fade' id='add_edit_qrcode_package' role="dialog" aria-hidden="true">
            <div className='modal-dialog mw-1000px' role="document">
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className="modal-title color-005CF7">Thiết lập gói QR Code</h5>
                        <button type="button" className="close" id="close_qr_package" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className='modal-body py-16px px-lg-10'>
                        <div className="form-group">
                            <label className='form-label mb-2 light-height-22px'>Tên gói <span
                                className="text-danger">*</span></label>
                            <InputAnt value={qrCodePackageStore.dataRequest.name}
                                      isError={qrCodePackageStore.errors.name} showCount
                                      onChange={(e: any) => qrCodePackageStore.dataRequest.name = e.currentTarget.value}
                                      placeholder='Nhập tên gói'/>
                        </div>
                        <div className="d-flex form-group gap-16px">
                            <div className="w-50">
                                <label className='form-label mb-2 light-height-22px'>Số lượng mã <span
                                    className="text-danger">*</span></label>
                                <InputAnt
                                    value={qrCodePackageStore.dataRequest.qrCodeLimit ? numberUtil.number_format(qrCodePackageStore.dataRequest.qrCodeLimit) : ''}
                                    isError={qrCodePackageStore.errors.qrCodeLimit} maxLength={11}
                                    onChange={(e: any) => qrCodePackageStore.dataRequest.qrCodeLimit = numberUtil.regexNumber(e)}
                                    placeholder='Nhập số lượng mã'/>
                            </div>
                            <div className="w-50">
                                <label className='form-label mb-2 light-height-22px'>Giá bán <span
                                    className="text-danger">*</span></label>
                                <InputAnt
                                    value={qrCodePackageStore.dataRequest.price ? numberUtil.number_format(qrCodePackageStore.dataRequest.price) : ''}
                                    isError={qrCodePackageStore.errors.price} maxLength={14}
                                    onChange={(e: any) => qrCodePackageStore.dataRequest.price = numberUtil.regexNumber(e)}
                                    placeholder='Nhập giá bán'/>
                            </div>

                        </div>
                        <div className="d-flex w-50 align-items-center gap-8px">
                            <div className="fs-14px fw-500">Trạng thái</div>
                            <Switch checked={qrCodePackageStore.dataRequest.isActive}
                                    onChange={() => qrCodePackageStore.dataRequest.isActive = !qrCodePackageStore.dataRequest.isActive}/>
                        </div>
                    </div>

                    <div className="modal-footer d-flex">
                        <button type="button" className="btn w-246px rounded-4px bt-back-outline"
                                data-dismiss="modal">Hủy
                        </button>
                        <button type="button" className="btn rounded-4px send-request"
                                onClick={() => qrCodePackageStore.addEdit()}
                                disabled={qrCodePackageStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default observer(AddEditPackageQrCode);
