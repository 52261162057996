import {observable} from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";
import $ from "jquery";
import {Moment} from "../../common/utils/Moment";
import {trackingStore} from "../tracking/TrackingStore";
import {trackingLivestockService} from "./TrackingLivestockService";

interface IDataRequest {
    id: '',
    barcode: any,
    trackingImage: '',
    images: any[],
    productQuality: {
        discontinuationPeriod: any,
        healthCondition: '',
        certification: ''
    },
    productInformation: {
        name: "",
        productType: '',
        area: '',
        productionBatch: ''
    },
    details: {
        harvestDate: any,
        netWeight: '',
        userManual: '',
        storageInstructions: '',
        description: any
    },
    unitInformation: {
        representativeName: "",
        email: "",
        phone: string | number,
        address: "",
        mainProduct: "",
        website: "",
        totalRevenue: '',
        mainMarket: '',
        totalLabor: '',
        businessDescription: "",
        certificates: any[]
    }
}

interface IError{
    name: any,
    productType: any,
    area: any,
    productionBatch: any,
    harvestDate: any,
    expirationDate: any,
    netWeight: any,
    userManual: any,
    storageInstructions: any,
    representativeName: any,
    phone: any,
    address: any,
    mainProduct: any,
    businessDescription: any,
}

class TrackingLivestockStore {
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable isLoadingDetail: boolean = false
    @observable warningError: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable type: any = ''
    @observable keyword: any = '';
    @observable searchYear: any = '';
    @observable pageHelper: any = '';
    @observable listTracking: any[] = []
    @observable dataFilter: any[] = []
    @observable minEndDate: Date = Moment.getToDay();
    @observable errors: IError = {
        name: "",
        productType: '',
        area: '',
        productionBatch: '',
        harvestDate: '',
        expirationDate: '',
        netWeight: '',
        userManual: '',
        storageInstructions: '',
        representativeName: "",
        phone: "",
        address: "",
        mainProduct: "",
        businessDescription: "",
    }
    @observable dataRequest: IDataRequest = {
        id: '',
        barcode: '',
        trackingImage: '',
        images: [],
        productQuality: {
            discontinuationPeriod: '',
            healthCondition: '',
            certification: ''
        },
        productInformation: {
            name: "",
            productType: '',
            area: '',
            productionBatch: ''
        },
        details: {
            harvestDate: '',
            netWeight: '',
            userManual: '',
            storageInstructions: '',
            description: ""
        },
        unitInformation: {
            representativeName: "",
            email: "",
            phone: "",
            address: "",
            mainProduct: "",
            website: "",
            totalRevenue: '',
            mainMarket: '',
            totalLabor: '',
            businessDescription: "",
            certificates: []
        }
    }

    clearError() {
        trackingLivestockStore.errors = {
            name: "",
            productType: '',
            area: '',
            productionBatch: '',
            harvestDate: '',
            expirationDate: '',
            netWeight: '',
            userManual: '',
            storageInstructions: '',
            representativeName: "",
            phone: "",
            address: "",
            mainProduct: "",
            businessDescription: "",
        }
    }

    clearForm() {
        trackingLivestockStore.type = 'add'
        trackingLivestockStore.dataRequest = {
            id: '',
            barcode: '',
            trackingImage: '',
            images: [],
            productQuality: {
                discontinuationPeriod: '',
                healthCondition: '',
                certification: ''
            },
            productInformation: {
                name: "",
                area: '',
                productType: '',
                productionBatch: ''
            },
            details: {
                harvestDate: '',
                netWeight: '',
                userManual: '',
                storageInstructions: '',
                description: ''
            },
            unitInformation: {
                representativeName: "",
                email: "",
                phone: "",
                address: "",
                mainProduct: "",
                website: "",
                totalRevenue: '',
                mainMarket: '',
                totalLabor: '',
                businessDescription: "",
                certificates: []
            }
        }
    }


    async getTracking() {
        this.clearError()
        trackingLivestockStore.isLoading = true
        const result = await trackingLivestockService.getTracking()
        trackingLivestockStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            trackingLivestockStore.listTracking = result.body.data
            trackingLivestockStore.totalPage = result.body.metadata.totalPage
        }
    }


    async getDetail(id: any) {
        this.clearError()
        trackingLivestockStore.type = 'edit'
        trackingLivestockStore.isLoadingDetail = true
        const result = await trackingLivestockService.getDetail(id)
        trackingLivestockStore.isLoadingDetail = false
        if (result.status === HttpStatusCode.OK) {
            trackingLivestockStore.dataRequest = result.body.data
            trackingLivestockStore.dataRequest.id = result.body.id
            if(!trackingLivestockStore.dataRequest?.details?.description){
                trackingLivestockStore.dataRequest.details.description = ''
            }
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }


    async addOrUpdateTracking() {
        this.clearError()
        let {
            id,
            productInformation,
            images,
            details, productQuality,
            trackingImage,
            barcode,
            unitInformation
        } = trackingLivestockStore.dataRequest
        let data = {
            barcode: barcode,
            trackingImage: trackingImage,
            images: images,
            productQuality: productQuality,
            productInformation: productInformation,
            details: details,
            unitInformation: unitInformation
        }
        trackingLivestockStore.warningError = false

        if (!productInformation.name) {
            trackingLivestockStore.errors.name = "Tên vật nuôi không được để trống!"
            trackingLivestockStore.warningError = true
        }

        if (!productInformation.productType) {
            trackingLivestockStore.errors.productType = "Tên giống vật nuôi không được để trống!"
            trackingLivestockStore.warningError = true
        }
        if (!productInformation.area) {
            trackingLivestockStore.errors.area = "Diện tích trang trại/chuồng trại không được để trống!"
            trackingLivestockStore.warningError = true
        }

        if (!productInformation.productionBatch) {
            trackingLivestockStore.errors.productionBatch = "Mã số lô không được để trống!"
            trackingLivestockStore.warningError = true
        }

        if (!details.harvestDate) {
            trackingLivestockStore.errors.harvestDate = "Ngày xuất chuồng không được để trống!"
            trackingLivestockStore.warningError = true
        }

        if (!details.netWeight) {
            trackingLivestockStore.errors.netWeight = "Tổng khối lượng không được để trống!"
            trackingLivestockStore.warningError = true
        }

        if (!details.userManual) {
            trackingLivestockStore.errors.userManual = "Hướng dẫn xuất bán không được để trống!"
            trackingLivestockStore.warningError = true
        }

        if (!details.storageInstructions) {
            trackingLivestockStore.errors.storageInstructions = "Hướng dẫn vận chuyển không được để trống!"
            trackingLivestockStore.warningError = true
        }

        if (!unitInformation.representativeName) {
            trackingLivestockStore.errors.representativeName = "Tên đơn vị không được để trống!"
            trackingLivestockStore.warningError = true
        }

        if (!unitInformation.phone) {
            trackingLivestockStore.errors.phone = "Số điện thoại không được để trống!"
            trackingLivestockStore.warningError = true
        }

        if (!unitInformation.address) {
            trackingLivestockStore.errors.address = "Địa chỉ không được để trống!"
            trackingLivestockStore.warningError = true
        }
        if (!unitInformation.businessDescription) {
            trackingLivestockStore.errors.businessDescription = "Loại hình không được để trống!"
            trackingLivestockStore.warningError = true
        }
        if (!unitInformation.mainProduct) {
            trackingLivestockStore.errors.mainProduct = "Sản phẩm chính không được để trống!"
            trackingLivestockStore.warningError = true
        }

        if (trackingLivestockStore.warningError) {
            return
        }

        trackingLivestockStore.isLoadingBt = true
        var result
        if (trackingLivestockStore.type === 'add') {
            result = await trackingLivestockService.add(data)
        } else result = await trackingLivestockService.update(id, data)

        trackingLivestockStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${trackingLivestockStore.type === 'add' ? 'Thêm' : 'Cập nhật'} truy xuất chăn nuôi thành công!`, 1)
            this.dataRequest.id = result.body.id
            await trackingLivestockStore.getTracking()
        } else {
            if (typeof result.body.message === "object") {
                this.errors = result.body.message
            } else {
                toastUtil.error(result.body.message, 2)
            }
        }
    }

    async uploadImages() {
        let {
            id,
            productInformation,
            images,
            details, productQuality,
            trackingImage,
            barcode,
            unitInformation
        } = trackingLivestockStore.dataRequest
        let data = {
            barcode: barcode,
            trackingImage: trackingImage,
            images: images,
            productQuality: productQuality,
            productInformation: productInformation,
            details: details,
            unitInformation: unitInformation
        }

        trackingStore.isLoadingBt = true
        const result = await trackingLivestockService.update(id, data)
        trackingStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật hình ảnh chăn nuôi thành công!', 1)
            $('#close_upload_images_livestock').trigger('click');
        } else {
            toastUtil.error(result.body.message, 3)
        }
    }


}

export const trackingLivestockStore = new TrackingLivestockStore()
