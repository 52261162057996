import {getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";

class RoleService {
    public getRoles(): Promise<IApiResponse> {
        return getRequest(`/v1/role`);
    }

    public getRoleDefault(): Promise<IApiResponse> {
        return getRequest(`/v1/config?key=REGISTER_FARM_DEFAULT_ROLE`);
    }

    public updateDefault(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/config?key=REGISTER_FARM_DEFAULT_ROLE`, data);
    }

    public getListPermissions(): Promise<IApiResponse> {
        return getRequest(`/v1/role/permission`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/role/${id}`);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/role/${id}`, data);
    }
}

export const roleService = new RoleService()