import React from 'react';
import {observer} from "mobx-react";
import {infoStore, TypeInfo} from "./InfoStore";
import {Link} from "react-router-dom";
import Input from "../../common/component/form/Input";
import {numberUtil} from "../../common/utils/NumberUtil";
import {urlImage} from "../../common/utils/Utils";
import {profileStore} from "../profile/ProfileStore";
import uploadFileHelper from "../../common/helpers/UploadFileHelper";


const EditInfo = () => {

    const uploadImg = (e: any) => {
        if (!infoStore.dataInfo.certificates) {
            infoStore.dataInfo.certificates = []
        }
        infoStore.dataInfo.certificates.push(e)
    }


    return (
        <div className="info_main">
            <div className="d-flex mb-3 justify-content-between align-items-center">
                <Link to={`/farm`} onClick={() => infoStore.type = TypeInfo.info}
                      className="d-flex edit-info mb-4 align-items-center">
                    <i className="far fa-arrow-left"/>
                    <span>Quay lại</span>
                </Link>
                {profileStore.dataFarm.farmOwner &&
                <button className="btn send-request" style={{width: 180}} onClick={() => infoStore.updateInfo()}
                        disabled={infoStore.isLoadingBt}>Lưu thiết lập</button>}
            </div>
            <h2>Thông tin HTX</h2>
            <div className="d-flex">
                <div className="w-50 pr-2">
                    <div className="d-flex align-items-center form-info">
                        <label>Tên đơn vị <span className="text-danger">*</span></label>
                        <Input value={infoStore.dataInfo.name} isError={infoStore.errors.name}
                               onChange={(e: any) => infoStore.dataInfo.name = e.currentTarget.value}
                               placeholder="Nhập tên đơn vị"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Tên đại diện</label>
                        <Input value={infoStore.dataInfo.representativeName}
                               onChange={(e: any) => infoStore.dataInfo.representativeName = e.currentTarget.value}
                               placeholder="Nhập tên đại diện"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Số điện thoại</label>
                        <Input value={infoStore.dataInfo.phone}
                               onChange={(e: any) => infoStore.dataInfo.phone = numberUtil.regexNumber(e)}
                               placeholder="Nhập số điện thoại"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Email</label>
                        <Input value={infoStore.dataInfo.email}
                               onChange={(e: any) => infoStore.dataInfo.email = e.currentTarget.value}
                               placeholder="Nhập email"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Địa chỉ</label>
                        <Input value={infoStore.dataInfo.address}
                               onChange={(e: any) => infoStore.dataInfo.address = e.currentTarget.value}
                               placeholder="Nhập địa chỉ"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Website</label>
                        <Input value={infoStore.dataInfo.website}
                               onChange={(e: any) => infoStore.dataInfo.website = e.currentTarget.value}
                               placeholder="Nhập link website"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Chứng chỉ</label>
                        <Input value={infoStore.dataInfo.certificate}
                               onChange={(e: any) => infoStore.dataInfo.certificate = e.currentTarget.value}
                               placeholder="Nhập chứng chỉ"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Số đăng ký kinh doanh <span className="text-danger">*</span></label>
                        <Input value={infoStore.dataInfo.businessCode} isError={infoStore.errors.businessCode}
                               onChange={(e: any) => infoStore.dataInfo.businessCode = e.currentTarget.value}
                               placeholder="Nhập số đăng ký kinh doanh"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Ký hiệu kinh doanh <span className="text-danger">*</span></label>
                        <Input value={infoStore.dataInfo.businessShortName} isError={infoStore.errors.businessShortName}
                               onChange={(e: any) => {
                                   if(e.currentTarget.value.length <= 5){
                                       infoStore.dataInfo.businessShortName = e.currentTarget.value
                                   }
                               }} placeholder="Nhập ký hiệu kinh doanh (Tối đa 5 ký tự)"/>
                </div>

            </div>
            <div className="w-50 pl-2">
                <div className="d-flex align-items-center form-info">
                    <label>Loại hình <span className="text-danger">*</span></label>
                    <Input value={infoStore.dataInfo.businessDescription} isError={infoStore.errors.businessDescription}
                           onChange={(e: any) => {
                               if(e.currentTarget.value.length <= 20){
                                   infoStore.dataInfo.businessDescription = e.currentTarget.value
                               }
                           }}
                           placeholder="Nhập loại hình doanh nghiệp (Tù 5-20 ký tự)"/>

                </div>
                <div className="d-flex align-items-center form-info">
                    <label>Sản phẩm chính</label>
                    <Input value={infoStore.dataInfo.mainProduct}
                           onChange={(e: any) => infoStore.dataInfo.mainProduct = e.currentTarget.value}
                           placeholder="Nhập sản phẩm chính"/>
                </div>
                <div className="d-flex align-items-center form-info">
                    <label>Mã số vùng trồng <span className="text-danger">*</span></label>
                    <Input value={infoStore.dataInfo.plantingAreaCode}
                           onChange={(e: any) => infoStore.dataInfo.plantingAreaCode = e.currentTarget.value}
                           placeholder="Nhập mã số vùng trồng"/>
                </div>
                <div className="d-flex align-items-center form-info">
                    <label>Tổng doanh thu</label>
                    <Input value={infoStore.dataInfo.totalRevenue}
                           onChange={(e: any) => infoStore.dataInfo.totalRevenue = e.currentTarget.value}
                           placeholder="Nhập tổng doanh thu"/>

                </div>
                <div className="d-flex align-items-center form-info">
                    <label>Thị trường chính</label>
                    <Input value={infoStore.dataInfo.mainMarket}
                           onChange={(e: any) => infoStore.dataInfo.mainMarket = e.currentTarget.value}
                           placeholder="Nhập thị trường chính"/>

                </div>
                <div className="d-flex align-items-center form-info">
                    <label>Tổng nhân viên</label>
                    <Input value={infoStore.dataInfo.totalLabor}
                           onChange={(e: any) => infoStore.dataInfo.totalLabor = e.currentTarget.value}
                           placeholder="Nhập tổng nhân viên"/>
                </div>
                <div className="d-flex align-items-center form-info">
                    <label>Giấy chứng nhận</label>
                    <Input value={infoStore.dataInfo.testimonial}
                           onChange={(e: any) => infoStore.dataInfo.testimonial = e.currentTarget.value}
                           placeholder="Nhập tên giấy chứng nhận"/>
                </div>
                <div className="d-flex align-items-center form-info">
                    <label>Năm thành lập</label>
                    <Input value={infoStore.dataInfo.foundedYear}
                           onChange={(e: any) => infoStore.dataInfo.foundedYear = e.currentTarget.value}
                           placeholder="2022"/>
                </div>
            </div>
        </div>
    <>
        <h2>Giấy chứng nhận</h2>
        <div className="scroll_certificate">
            <div className="list_certificate">
                <div className="upload" title="Upload giấy chứng nhận">
                    <img src="/assets/ico/ico_cloud_upload.svg" alt=""/>
                           <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                           onChange={async (e: any) => await uploadFileHelper(e, uploadImg)}/>
                </div>
                {infoStore.dataInfo.certificates && infoStore.dataInfo.certificates.map((item: any, i: number) => {
                    return (
                        <div className="item" key={i}>
                            <img src={urlImage(item)} alt=""/>
                            <div className="close_item"
                                 onClick={() => infoStore.dataInfo.certificates.splice(i, 1)}>
                                <img src="/assets/ico/ico_close.svg" alt=""/>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    </>
</div>
)
    ;
}

export default observer(EditInfo);
