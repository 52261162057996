import {observable} from "mobx";


export enum TypeActive {
    labor = "labor",
    product = "product",
    land = "land",
    medicine = "medicine",
    fertilizer = "fertilizer",
    ingredient = "ingredient",
    equipment = "equipment",
    distributor = "distributor",
}


class SetupStore{
    @observable tabActive: any = ''

}

export const setupStore = new SetupStore()