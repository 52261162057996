import {
  deleteRequest,
  getRequest,
  IApiResponse, patchRequest,
  postRequest,
  putRequest
} from '../../../common/helpers/RequestHelper'
import {packageStore} from "./PackageStore";


class PackageService {

  public getPackage(): Promise<IApiResponse>{
    return getRequest(`/v1/packages?page=${packageStore.page}&size=10${packageStore.name?.trim() ? `&name=${packageStore.name?.trim()}` : ''}`)
  }

  public getDetail(id: any): Promise<IApiResponse>{
    return getRequest(`/v1/packages/${id}`)
  }

  public add(data: object): Promise<IApiResponse>{
    return postRequest(`/v1/packages`, data)
  }

  public edit(id: any, data: object): Promise<IApiResponse>{
    return putRequest(`/v1/packages/${id}`, data)
  }

  public changeState(id: any, isActive: boolean): Promise<IApiResponse>{
    return patchRequest(`/v1/packages/${id}/change-state?isActive=${isActive}`, {})
  }

  public changeRecommended(id: any, isRecommended: boolean): Promise<IApiResponse>{
    return patchRequest(`/v1/packages/${id}/change-recommended?isRecommended=${isRecommended}`, {})
  }

  public delete(id: any): Promise<IApiResponse>{
    return deleteRequest(`/v1/packages/${id}`, {})
  }


}

export const packageService = new PackageService()

