import React from 'react';
import {productStore} from "../ProductStore";
import {observer} from "mobx-react";
import Input from "../../../../common/component/form/Input";
import Select from "../../../../common/component/form/Select";


const AddOrEditProduct = () => {

    const removeAccents = (str: any) => {
        return str.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/đ/g, 'd').replace(/Đ/g, 'D');
    }

    return (
        <div className="modal fade" id="add_or_edit_product" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Thiết lập sản phẩm</h5>
                        <button type="button" className="close" id="close_add_edit_product" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">

                        <div className="d-flex align-items-center">
                            <div className="w-50 pr-2">
                                <div className="form-group">
                                    <label>Nhóm <span className="text-danger">*</span></label>
                                    <Select value={productStore.dataRequest.cateLv1}
                                            isError={productStore.errors.cateLv1}
                                            onChange={(e: any) => productStore.changeCateLv1(e)}
                                            options={productStore.listCateLv1} placeholder="Chọn nhóm"/>

                                </div>
                            </div>
                            <div className="w-50 pl-2">
                                <div className="form-group">
                                    <label>Loại <span className="text-danger">*</span></label>
                                    <Select value={productStore.dataRequest.cateLv2} disabled={!productStore.dataRequest.cateLv1}
                                            isError={productStore.errors.cateLv2}
                                            onChange={(e: any) => productStore.changeCateLv2(e)}
                                            options={productStore.listCateLv2} placeholder="Chọn loại"/>
                                </div>
                            </div>
                        </div>


                        <div className="d-flex align-items-center">
                            <div className="w-50 pr-2">
                                <div className="form-group">
                                    <label>Giống <span className="text-danger">*</span></label>
                                    <Select value={productStore.dataRequest.cateLv3} disabled={!productStore.dataRequest.cateLv2}
                                            isError={productStore.errors.cateLv3} onChange={(e: any) => {
                                        productStore.dataRequest.cateLv3 = e
                                        productStore.errors.cateLv3 = ''
                                    }} options={productStore.listCateLv3} placeholder="Chọn giống"/>
                                </div>
                            </div>
                            <div className="w-50 pl-2">
                                <div className="form-group">
                                    <label>Tên sản phẩm <span className="text-danger">*</span></label>
                                    <Input isError={productStore.errors.name} value={productStore.dataRequest.name} disabled={!productStore.dataRequest.cateLv3}
                                           onChange={(e: any) => productStore.dataRequest.name = e.currentTarget.value}
                                           placeholder="Nhập tên sản phẩm"/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Tên viết tắt sản phẩm (Tối đa 5 ký tự) <span
                                className="text-danger">*</span></label>
                            <Input value={productStore.dataRequest.shortName}
                                   isError={productStore.errors.shortName}
                                   onChange={(e: any) => {
                                       if (e.currentTarget.value.length <= 5) {
                                           productStore.dataRequest.shortName = removeAccents(e.currentTarget.value.trim().toUpperCase())
                                       }
                                   }}
                                   placeholder="Nhập tên viết tắt sản phẩm"/>
                        </div>
                        <div className="form-group">
                            <label>Ghi chú</label>
                            <div className="input-group">
                                    <textarea value={productStore.dataRequest.note}
                                              onChange={(e: any) => productStore.dataRequest.note = e.currentTarget.value}
                                              className="form-control form-control-lg" placeholder="Nhập ghi chú"/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => productStore.type == "add" ? productStore.add() : productStore.update()}
                                disabled={productStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditProduct);