import React, {useEffect} from "react";
import {observer} from "mobx-react";
import Loading from "../../../common/component/Loading";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import {goBack, sttPage} from "../../../common/utils/Utils";
import {stageStore} from "./StageStore";
import AddOrEditStage from "./components/AddOrEditStage";
import PopupConfirm from "../../../common/component/PopupConfirm";
import ButtonAdd from "../../../common/component/ButtonAdd";
import {Link} from "react-router-dom";


const StagePage = () => {

    const changePage = async (data: any) => {
        stageStore.page = data.selected;
        await stageStore.getStages();
    }

    useEffect(() => {
        stageStore.getStages();
    }, [])

    return (
        <div className='table-responsive'>
            <div className="d-flex header_back align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <Link to={`/procedure`}>Quy trình</Link>
                    <i className="fal fa-angle-right"/>
                    <span>Giai đoạn</span>
                </div>
                <div onClick={() => goBack()} className="back cursor_pointer">
                    <i className="far fa-arrow-left"/>
                    <span>Quay lại</span>
                </div>
            </div>
            <div className="bt_add">
                <ButtonAdd text="Thêm giai đoạn" dataTarget={'add_or_edit_stage'} onClick={stageStore.clearForm}/>
            </div>
            <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                    <th className="text-center">STT</th>
                    <th className="text-center">Giai đoạn</th>
                    <th className="text-center">Thao tác</th>
                </tr>
                </thead>
                <tbody>

                {stageStore.listStage && stageStore.listStage.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td className="text-center">{sttPage(stageStore.page, i)}</td>
                            <td className="text-center">{item.name}</td>
                            <td className="text-center">
                                <button className="bt-outline-action bt mr-2" data-toggle="modal" data-target="#add_or_edit_stage" onClick={() => stageStore.detailStage(item.id)}>Sửa</button>
                                <button className="bt-action-delete bt" onClick={() => stageStore.id = item.id} data-toggle="modal" data-target="#popup_confirm">Xóa</button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>

            {stageStore.isLoading ? <Loading/> : stageStore.listStage && stageStore.listStage.length < 1 && <NoContent/>}

            <div className="d-flex align-items-center justify-content-center mt-4">
                <Paginate currentPage={stageStore.page} totalPage={stageStore.totalPage} changePage={changePage}/>
            </div>

            <AddOrEditStage/>
            <PopupConfirm isLoading={stageStore.isLoadingBt} action={stageStore.delete}/>
        </div>
    );
}

export default observer(StagePage)