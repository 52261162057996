import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import Input from "../../../common/component/form/Input";
import {blogStore} from "../BlogStore";
import TextEditor from "../../../common/component/TextEditor";
import {getIDParameter, getPathName, urlImage} from "../../../common/utils/Utils";
import {Navigate} from "react-router-dom";
import uploadFileHelper from "../../../common/helpers/UploadFileHelper";



const AddEditBlog = () => {


    const changeImg = (value: any) => {
        blogStore.dataRequest.thumbnail = value
    }

    useEffect(() => {
        let id: any = getIDParameter()
        if(id){
            blogStore.getDetailBlog(id).then()
        }
        return () => {
            blogStore.redirect = false
        }
    }, [])

    if(blogStore.redirect){
        return <Navigate to={'/blog'} />
    }else return (
        <div className="add_edit_blog p-2" >
            <h4 className="mb-4">{getPathName() == 'add-blog' ? 'Tạo' : 'Cập nhật'} bài viết</h4>
            <div className="form pt-2 pb-0">
                <div className='mb-4'>
                  <label className='form-label w-100'>Ảnh <span className="text-danger">*</span></label>
                  <div className='upLoadImage d-flex w-100'>
                    {blogStore.dataRequest.thumbnail ?
                      <img src={urlImage(blogStore.dataRequest.thumbnail)} alt='' /> :
                      <div className='no_img d-flex align-items-center justify-content-center'><i
                        className='far fa-image' /></div>}
                    <button className='btn change-avatar'><span>Upload</span>
                      <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                             onChange={async (e: any) => await uploadFileHelper(e, changeImg)} />
                    </button>
                  </div>
                </div>
                <div className="form-group">
                    <label>Tiêu đề <span className="text-danger">*</span></label>
                    <Input value={blogStore.dataRequest.title}
                           onChange={(e: any) => blogStore.dataRequest.title = e.currentTarget.value}
                           placeholder="Nhập tiêu đề"/>
                </div>
                <div className="form-group">
                    <label>Mô tả ngắn <span className="text-danger">*</span></label>
                    <Input value={blogStore.dataRequest.shortContent}
                           onChange={(e: any) => blogStore.dataRequest.shortContent = e.currentTarget.value}
                           placeholder="Nhập mô tả ngắn"/>
                </div>
                <div className="form-group h-auto">
                    <label>Nội dung <span className="text-danger">*</span></label>
                    <TextEditor content={blogStore.dataRequest.content} uploadImg={true}
                                onChange={(e: any) => blogStore.dataRequest.content = e}/>
                </div>
            </div>
            <div className="mt-4">
                <button type="button" className="btn send-request" onClick={() => blogStore.addEdit()} disabled={blogStore.isLoadingBt}>Hoàn thành</button>
            </div>
        </div>
    );
}

export default observer(AddEditBlog);