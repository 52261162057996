import React from "react";
import {jobDiaryStore} from "../jobDiaryStore";
import {observer} from "mobx-react";
import {urlImage} from "../../../../common/utils/Utils";
import {landStore} from "../../../setup/land/LandStore";
import {diaryStore} from "../../DiaryStore";
import {dateUtils} from "../../../../common/utils/DateUtils";


const PopupDetail = () => {

    return (
        <div className="modal fade" id="popup_detail_job" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{maxWidth: 1025}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="m-0">{landStore.listLand.map((item: any) => {
                            if (item.id == diaryStore.landId) {
                                return item.name
                            }
                        })}</h5>
                        <button type="button" className="close" id="close_detail_job" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="table_job">
                            <div className="row_job">
                                <label>Công việc</label>
                                <span>{jobDiaryStore.dataRequest.name}</span>
                            </div>
                            <div className="row_job">
                                <label>Thời gian</label>
                                <span>{dateUtils.formatDate(jobDiaryStore.dataRequest.startDate)} - {dateUtils.formatDate(jobDiaryStore.dataRequest.endDate)}</span>
                            </div>
                            <div className="row m-0 p-0">
                                <label className="col-2">Vật tư</label>
                                <div className="col-10">
                                    <div className="d-flex flex-wrap w-100 mb-1">Nông dược: {jobDiaryStore.dataRequest.medicines && jobDiaryStore.dataRequest.medicines.map((item, i) => {
                                        return <div key={i} className="pl-1">
                                            <span className="font-weight-medium">{item.medicine?.name}</span> ({item.qty} {item.medicine?.unit?.name}),
                                        </div>
                                    })}</div>
                                    <div className="d-flex flex-wrap w-100 mb-1">Phân bón: {jobDiaryStore.dataRequest.fertilizers && jobDiaryStore.dataRequest.fertilizers.map((item, i) => {
                                            return <div key={i} className="pl-1">
                                                <span className="font-weight-medium">{item.fertilizer?.name}</span> ({item.qty} {item.fertilizer?.unit?.name}),
                                            </div>
                                        })}</div>
                                    <div className="d-flex flex-wrap w-100 mb-1">Nguyên vật liệu: {jobDiaryStore.dataRequest.ingredients && jobDiaryStore.dataRequest.ingredients.map((item, i) => {
                                            return <div key={i} className="pl-1">
                                                <span className="font-weight-medium">{item.ingredient?.name}</span> ({item.qty} {item.ingredient?.unit}),
                                            </div>
                                        })}</div>
                                    <div className="d-flex flex-wrap w-100 mb-1">Máy móc: {jobDiaryStore.dataRequest.equipments && jobDiaryStore.dataRequest.equipments.map((item, i) => {
                                            return <div key={i} className="pl-1"><span className="font-weight-medium">{item.equipment?.name}</span>,
                                            </div>
                                        })}</div>
                                </div>
                            </div>

                            <div className="row_job">
                                <label>Ghi chú</label>
                                <span>{jobDiaryStore.dataRequest.note ?? '-'}</span>
                            </div>
                        </div>
                        <div className="list_image_job">
                            {jobDiaryStore.dataRequest.images && jobDiaryStore.dataRequest.images.map((item: any, i: number) => {
                                return <img src={urlImage(item)} key={i} alt=""/>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(PopupDetail)