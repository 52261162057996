import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {number_format, slug, sttPage} from "../../../common/utils/Utils";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import Loading from "../../../common/component/Loading";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {fertilizerStore} from "../../setup/fertilizer/FertilizerStore";


const FertilizerPage = () => {

    const {t} = useTranslation();

    const changePage = async (data: any) => {
        fertilizerStore.page = data.selected;
        await fertilizerStore.getFertilizer();
    }

    useEffect(() => {
        fertilizerStore.getFertilizer().then()
        fertilizerStore.getFertilizerAll().then()
    }, [])

    if (fertilizerStore.isLoading) {
        return <Loading/>
    } else return (
        <div className='table-responsive'>
            {fertilizerStore.listFertilizer && fertilizerStore.listFertilizer.length > 0 ?
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">STT</th>
                        <th>Tên phân bón</th>
                        <th className="text-center">Mã phân bón</th>
                        <th className="text-center">Tồn kho</th>
                        <th className="text-center">ĐVT</th>
                    </tr>
                    </thead>
                    <tbody>

                    {fertilizerStore.listFertilizer.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{sttPage(fertilizerStore.page, i)}</td>
                                <td>{item.name}</td>
                                <td className="text-center">{item.shortName}</td>
                                <td className="text-center">{number_format(item.inventory)}</td>
                                <td className="text-center">{item.unit?.code}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table> : <NoContent/>}

            <div className="d-flex align-items-center justify-content-center mt-4">
                <Paginate currentPage={fertilizerStore.page} totalPage={fertilizerStore.totalPage}
                          changePage={changePage}/>
            </div>
        </div>
    );
}

export default observer(FertilizerPage)