import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import "./PlanningStyle.scss";
import Loading from "../../common/component/Loading";
import {checkPermission, number_format, slug} from "../../common/utils/Utils";
import NoContent from "../../common/component/NoContent";
import Paginate from "../../common/component/Paginate";
import PopupConfirm from "../../common/component/PopupConfirm";
import ButtonAdd from "../../common/component/ButtonAdd";
import {dateUtils} from "../../common/utils/DateUtils";
import AddOrEditPlanOne from "./components/AddOrEditPlanOne";
import AddOrEditPlanTwo from "./components/AddOrEditPlanTwo";
import {Link} from "react-router-dom";
import FilterComponent from "../../common/component/filter/FilterComponent";
import {PermissionConst} from "../../common/constants/Constants";
import {procedureStore} from "../procedure/ProcedureStore";
import {landStore} from "../setup/land/LandStore";
import {planStore} from "./PlanStore";
import {useTranslation} from "react-i18next";


const PlanComponent = () => {
    const { t } = useTranslation();

    const changePage = async (data: any) => {
        planStore.page = data.selected;
        await planStore.getPlanning();
    }

    useEffect(() => {
        planStore.getPlanning().then()
        procedureStore.getProcedure(100).then()
        landStore.getLands().then();
        planStore.dataFilter = [
            {
                label: t('year'),
                type: 'select',
                value: planStore.searchYear,
                listValue: planStore.listYear,
                callback: async (e: any) => {
                    planStore.searchYear = e
                    planStore.page = 0
                    await planStore.getPlanning()
                }
            },
            {
                label: t('planName'),
                type: 'search',
                placeholder: localStorage.getItem('language') == 'en' ? 'Enter keyword' : 'Nhập từ khóa tìm kiếm',
                value: planStore.searchName,
                onChange: (e: any) => {
                    planStore.page = 0
                    planStore.searchName = e
                },
                callback: async (e: any) => {
                    await planStore.getPlanning()
                }
            },
        ];

        return () => {
            planStore.searchYear = planStore.date.getFullYear()
            planStore.searchName = ''
            landStore.listLand = []
            planStore.listLandPlan = []
            planStore.id = ''
        }
    }, [])

    return (
        <div className="planning">
            {planStore.dataFilter && <FilterComponent permissionCode={PermissionConst.planAdd} buttonAdd={<ButtonAdd text={t('addPlan')} dataTarget={'add_or_edit_plan'} onClick={planStore.clearForm}/>} data={planStore.dataFilter}/>}

            {planStore.isLoading ? <Loading/> :
                <div className='table-responsive'>
                    {planStore.listPlanning && planStore.listPlanning.length > 0 ?
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th className="text-center">{t('year')}</th>
                                <th>{t('planName')}</th>
                                <th>{t('product')}</th>
                                <th className="text-center">{t('productionArea')} (Ha)</th>
                                <th className="text-center">{t('expectedOutput')} (Kg)</th>
                                <th className="text-center">{t('actualOutput')} (Kg)</th>
                                <th className="text-center">{t('intendTime')}</th>
                                <th className="text-center">{t('actions')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {planStore.listPlanning.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{item.year}</td>
                                        <td>{item.name}</td>
                                        <td>{item.procedure?.product?.name}</td>
                                        <td className="text-center">{item.totalArea ? parseFloat(item.totalArea.toFixed(1)) : ''}</td>
                                        <td className="text-center">{number_format(item.expectedOutput)}</td>
                                        <td className="text-center">{number_format(item.actualOutput)}</td>
                                        <td className="text-center">{dateUtils.formatDate(item.startDate)} - {dateUtils.formatDate(item.endDate)}</td>
                                        <td className="text-center">
                                            <div className="action">
                                                <button className="bt_action" title={t('view')}>
                                                    <Link to={`/planning-detail/${slug(item.name)}/${item.id}`}>
                                                        <img src="/assets/ico/action/ico_detail.svg" alt=""/>
                                                    </Link>
                                                </button>
                                                {checkPermission(PermissionConst.planUpdate) &&
                                                <button className="bt_action" title={t('edit')}
                                                        data-toggle="modal" data-target="#add_or_edit_plan"
                                                        onClick={() => planStore.getDetail(item.id)}>
                                                    <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                </button>}
                                                {checkPermission(PermissionConst.planDelete) &&
                                                <button className="bt_action" title={t('delete')}
                                                        onClick={() => planStore.id = item.id}
                                                        data-toggle="modal" data-target="#popup_confirm">
                                                    <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                                </button>}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}

                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Paginate currentPage={planStore.page} totalPage={planStore.totalPage} changePage={changePage}/>
                    </div>

                    <AddOrEditPlanOne/>
                    <AddOrEditPlanTwo/>
                    <button id="add_plan_two" data-toggle="modal" data-target="#add_edit_plan_two" style={{opacity: 0}}/>
                    <PopupConfirm isLoading={planStore.isLoadingBt} action={planStore.delete}/>
                </div>}
        </div>
    )
}


export default observer(PlanComponent);