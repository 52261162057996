import React from 'react';
import {observer} from "mobx-react";
import {urlImage, urlImageSSO} from "../../../common/utils/Utils";
import {profileStore} from "../../profile/ProfileStore";
import {questionStore} from "../QuestionStore";
import {Icon} from "../../../common/component/Icon";
import TextEditor from "../../../common/component/TextEditor";
import uploadFileHelper from "../../../common/helpers/UploadFileHelper";


const SendComment = () => {

    const uploadImg = (e: any) => {
        questionStore.dataComment.attachFiles.push(e)
    }

    return (
        <div className="send_comment" id="send_comment">
            <div className="info_user">
                <div className={`${profileStore.dataFarm?.avatar ? '' : 'no_'}avatar`}>
                    {profileStore.dataFarm?.avatar ? <img src={urlImageSSO(profileStore.dataFarm?.avatar)} alt=""/> : <Icon name={'person'} type="round"/>}
                </div>
                <div className="role">{ profileStore.dataFarm.roleName}</div>
            </div>

            <div className="content">
                <TextEditor height={180} content={questionStore.dataComment.content} onChange={(e: any) => questionStore.dataComment.content = e}/>

                <div
                    className={`d-flex bt_comment align-items-end justify-content-${questionStore.dataComment.attachFiles && questionStore.dataComment.attachFiles.length > 0 ? 'between' : 'end'}`}>
                    {questionStore.dataComment.attachFiles && questionStore.dataComment.attachFiles.length > 0 &&
                    <div className="list_image_">
                        <div className="list_"
                             style={{width: `${(questionStore.dataComment.attachFiles.length * 132) - 12}px`}}>
                            {questionStore.dataComment.attachFiles.map((item: any, i: number) => {
                                return (
                                    <div className="item" key={i}>
                                        <img className="img" src={urlImage(item)} alt=""/>
                                        <div className="close_item"
                                             onClick={() => questionStore.dataComment.attachFiles.splice(i, 1)}>
                                            <img src="/assets/ico/ico_close.svg" alt=""/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>}
                    <div className="d-flex align-items-center">
                        <div className="upload" title="Tải ảnh">
                            <Icon name='image' type="outlined"/>
                            <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                   onChange={async (e: any) => await uploadFileHelper(e, uploadImg)}/>
                        </div>
                        <button className="bt_send d-flex align-items-center justify-content-center"
                                onClick={() => questionStore.sendComment()}
                                disabled={questionStore.isLoadingBt || !questionStore.dataComment.content.trim()}>Trả
                            lời <Icon name="reply" type="outlined" className="text-white ml-1"/>
                        </button>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(SendComment);