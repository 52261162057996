import {observable} from "mobx";
import {IFilter} from "../../../common/component/filter/FilterComponent";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {accountManagerService} from "./AccountManagerService";
import {accountService} from "../../auth/AccountService";
import $ from "jquery";



class AccountManagerStore {
    @observable id: any = '';
    @observable showPass: any = false;
    @observable type: any = '';
    @observable keyword: any = '';
    @observable page: number = 0;
    @observable totalPage: number = 5;
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable dataFilter: IFilter[] = []
    @observable public listProvince: any[] = []
    @observable public listDistrict: any[] = []
    @observable listAccount: any[] = [1,2,3,4,5,6]
    @observable dataAccountById: any = {}
    @observable public errors: any = {
        name: "",
        username: "",
        password: "",
        confirmPass: "",
        level: "",
        provinceId: "",
        districtId: "",
        state: ""
    }
    @observable dataRequest: any = {
        name: "",
        username: "",
        password: "",
        confirmPass: "",
        level: "center_manage",
        provinceId: null,
        districtId: null,
        state: "ACTIVE"
    }
    clearForm() {
        this.type = 'add'
        this.dataRequest = {
            name: "",
            username: "",
            password: "",
            confirmPass: "",
            level: "center_manage",
            provinceId: null,
            districtId: null,
            state: "ACTIVE"
        }
        this.errors = {
            name: "",
            username: "",
            password: "",
            confirmPass: "",
            level: "",
            provinceId: "",
            districtId: "",
            state: ""
        }
    }

    async changeProvince(provinceId: any) {
        accountManagerStore.dataRequest.provinceId = provinceId
        accountManagerStore.dataRequest.districtId = null
        await accountManagerStore.getDistricts(provinceId)
    }

    async changeDistrict(districtId: any) {
        accountManagerStore.dataRequest.districtId = districtId
    }


    async getProvinces() {
        const result = await accountService.getProvinces()
        if (result.status === HttpStatusCode.OK) {
            this.listProvince = result.body
        }
    }

    async getDistricts(provinceId: any) {
        const result = await accountService.getDistricts(provinceId)
        if (result.status === HttpStatusCode.OK) {
            this.listDistrict = result.body
        }
    }

    async getListAccount() {
        this.isLoading = true
        const result = await accountManagerService.getAccountManager()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listAccount = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }


    async getDetailAccountById(id: any) {
        const result = await accountManagerService.getDetailAccountManager(id)
        if (result.status === HttpStatusCode.OK) {
            if (result.body) {
                this.dataRequest = {
                    name: result.body?.user?.name,
                    username: result.body?.user?.phone,
                    password: "",
                    confirmPass: "",
                    level:   result.body?.level,
                    provinceId:  result.body?.province?.id,
                    districtId:  result.body?.district?.id,
                    state: result.body?.user?.status,
                }
            }
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async add() {
        this.isLoadingBt = true
        const data = {
            username: this.dataRequest.username,
            level: this.dataRequest.level,
            provinceId: this.dataRequest.provinceId,
            districtId: this.dataRequest.districtId,
            state: "ACTIVE"
        }
        const result = await accountManagerService.create(data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Đăng ký tài khoản quản lý thành công', 1)
            accountManagerStore.getListAccount().then()
            $('#add_or_edit_account_manager').trigger('click')
        } else {
            toastUtil.error(result.body.message, 3)
        }
    }

    async update() {
        this.isLoadingBt = true
        const data = {
            username: this.dataRequest.username,
            level: this.dataRequest.level,
            provinceId: this.dataRequest.provinceId,
            districtId: this.dataRequest.districtId,
            state: this.dataRequest.state,
        }
        const result = await accountManagerService.update(this.id, data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật thông tin tài khoản thành công', 2)
            accountManagerStore.getListAccount().then()
            $('#add_or_edit_account_manager').trigger('click')
        } else {
            toastUtil.error(result.body.message, 3)
        }
    }
    async signUp() {
        if (!this.dataRequest.name) {
            this.errors.name = 'Tên tài khoản không được để trống!'
            return
        } else this.errors.name = ''
        if (!this.dataRequest.username) {
            this.errors.username = 'Số điện thoại không được để trống!'
            return
        } else this.errors.username = ''
        if (!this.dataRequest.password.trim() && this.type !== 'edit') {
            this.errors.password = 'Mật khẩu không được để trống!'
            return
        }
        if (this.dataRequest.password && (this.dataRequest.password.trim().length < 6 || this.dataRequest.password.trim().length > 24)) {
            this.errors.password = 'Mật khẩu tối thiểu từ 6 đến 24 ký tự!'
            return
        } else this.errors.password = ''
        if (!this.dataRequest.confirmPass.trim() && this.type !== 'edit') {
            this.errors.confirmPass = 'Mật khẩu xác nhận không được để trống!'
            return
        }else this.errors.confirmPass = ''
        if (this.dataRequest.password !== this.dataRequest.confirmPass) {
            toastUtil.success("Mật khẩu xác nhận không trùng khớp", 1)
            return
        }

        let {name, username, password} = this.dataRequest
        let data = {
            name: name.trim(),
            username: username,
            password: password.trim(),
        }
        const result = accountManagerStore.type === 'edit' ?  await accountManagerService.updateSSO(this.id, data) :  await accountManagerService.register(data)
        if (result.status === HttpStatusCode.OK || result.body.messageCode === "USER_EXISTS") {
            if(accountManagerStore.type === 'edit') {
                await this.update()
            }
            else await this.add()
        } else {
            toastUtil.error(result.body.message, 2)
        }
    }
    async delete() {
        const result = await accountManagerService.delete(accountManagerStore.id)
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa tài khoản thành công', 3)
            await accountManagerStore.getListAccount()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }
}

export const accountManagerStore = new AccountManagerStore()