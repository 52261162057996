import React from 'react'
import { observer } from 'mobx-react-lite'
import { approvedPackageStore } from "../ApprovedPackageStore";
import { dateUtils } from "../../../../common/utils/DateUtils";
import { convertPrice, convertToLocaleVN } from "../../../../common/utils/Utils";
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

export const convertTypeDate = (type: string) => {
    switch (type) {
        case "DAYS":
            return "Ngày"
        case "YEARS":
            return "Năm"
        case "MONTHS":
            return "Tháng"
    }
}

const DetailApprovePackage = () => {
    const { t } = useTranslation();

    return (
        <div className='modal fade' id='detail_approve_package' aria-hidden='true'>
            <div className='modal-dialog max-w-1380px'>
                <div className='modal-content rounded-4px overflow-hidden'>
                    <div className='modal-header py-14px'>
                        <h2 className="fs-24px color-00953D">Chi tiết</h2>
                        <button type="button" className="close" id="close_detail_package" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className='modal-body bg-F3F4F5 py-20px px-8'>
                        <Row gutter={[10, 8]} className='w-100'>
                            <Col xs={18} className='d-flex gap-10px flex-column'>
                                <div className='p-16px bg-white'>
                                    <p className='fs-16px color-00953D fw-500'>{t("selectProdPackage")}</p>
                                    <div className='mt-10px overflow-auto min-h-150px max-h-275px'>
                                        <table className='align-middle w-100 table-hover table-rounded border gy-7 gs-7 table-sticky-header'>
                                            <thead>
                                                <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                                    <th className="text-center">STT</th>
                                                    <th className="text-center min-w-200px">{t("products/Services")}</th>
                                                    <th className="text-center">{t("orderUnit")}</th>
                                                    <th className="text-center min-w-150px">{t("orderType")}</th>
                                                    <th className="text-center w-150px">{t("quantityOrder")}</th>
                                                    <th className="text-end">{t("unitPrice")}</th>
                                                    <th className="text-end">{t("totalAmount")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {approvedPackageStore?.listDataRequest?.map((item: any, i: number) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="text-center">{i + 1}</td>
                                                            <td>{item?.name}</td>
                                                            <td className="text-center">{t("package")}</td>
                                                            <td className='text-center min-w-150px'>{t(item?.type)}</td>
                                                            <td className="text-center">{item.quantity}</td>
                                                            <td className="text-end">{convertPrice(item?.price)}</td>
                                                            <td className="text-end">{convertPrice(item.quantity * item.price)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className='p-16px bg-white'>
                                    <p className='fs-16px color-00953D fw-500'>{t("paymentInformation")}</p>
                                    <div className='d-flex justify-content-between align-items-center mb-10px'>
                                        <span>{t("orderCode")}</span>
                                        <span>{approvedPackageStore?.dataDetail?.code}</span>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span>{t("createdAt")}</span>
                                        <span>{dateUtils.formatDate(approvedPackageStore?.dataDetail?.createdAt, 'DD/MM/YYYY')}</span>
                                    </div>
                                    <hr className='bg-7C7B7B' />
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span>{t("totalAmount")}</span>
                                        <span className='fw-600'>{convertToLocaleVN(approvedPackageStore.dataDetail?.totalPrice || 0)}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(DetailApprovePackage)