import {getRequest, postRequest, putRequest, deleteRequest, IApiResponse} from "../../common/helpers/RequestHelper";
import {blogStore} from "./BlogStore";

class BlogService {
    public getBlog(): Promise<IApiResponse>{
        return getRequest(`/v1/farm_blog?page=${blogStore.metadata.page}&size=10`)
    }
    public getDetail(id: number): Promise<IApiResponse>{
        return getRequest(`/v1/farm_blog/${id}`)
    }
    public addBlog(data: any): Promise<IApiResponse>{
        return postRequest(`/v1/farm_blog`, data)
    }
    public editBlog(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/v1/farm_blog/${id}`, data)
    }
    public delete(id: number): Promise<IApiResponse>{
        return deleteRequest(`/v1/farm_blog`, [id])
    }
}

export const blogService = new BlogService()