import React, { useEffect} from 'react';
import Loading from "../../../common/component/Loading";
import {number_format, slug, sttPage} from "../../../common/utils/Utils";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ingredientStore} from "../../setup/ingredient/IngredientStore";


const IngredientPage = () => {

    const { t } = useTranslation();
    const changePage = async (data: any) => {
        ingredientStore.page = data.selected;
        await ingredientStore.getIngredient();
    }

    useEffect(() => {
        ingredientStore.getIngredient().then();
        ingredientStore.getIngredientAll().then();
    }, [])
    
    if (ingredientStore.isLoading) {
        return <Loading/>
    } else return (
        <div className='table-responsive'>
            {ingredientStore.listIngredient && ingredientStore.listIngredient.length > 0 ?
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">STT</th>
                        <th>Tên nguyên vật liệu</th>
                        <th className="text-center">Mã nguyên vật liệu</th>
                        <th className="text-center">Tồn kho</th>
                        <th className="text-center">ĐVT</th>
                    </tr>
                    </thead>
                    <tbody>

                    {ingredientStore.listIngredient.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{sttPage(ingredientStore.page, i)}</td>
                                <td>{item.name}</td>
                                <td className="text-center">{item.shortName}</td>
                                <td className="text-center">{number_format(item.inventory)}</td>
                                <td className="text-center">{item.unit}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table> : <NoContent/>}

            <div className="d-flex align-items-center justify-content-center mt-4">
                <Paginate currentPage={ingredientStore.page} totalPage={ingredientStore.totalPage}
                          changePage={changePage}/>
            </div>
        </div>
    );
}

export default observer(IngredientPage);
