import {
    getRequest,
    putRequest,
    deleteRequest,
    postRequest,
    IApiResponse,
    exportRequest
} from "../../../common/helpers/RequestHelper";
import {ingredientStore} from "./IngredientStore";
import {fertilizerStore} from "../fertilizer/FertilizerStore";
import Constants from "../../../common/constants/Constants";

class IngredientService{

    public getIngredient(size?: any): Promise<IApiResponse> {
        return getRequest(`/v1/ingredient?page=${ingredientStore.page}&size=${size ?? 10}`);
    }
    
    public getIngredientAll(): Promise<IApiResponse> {
        return getRequest(`/v1/ingredient?page=0&size=${Constants.MAX_SIZE}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/ingredient/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/ingredient`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/ingredient/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/ingredient/${id}`, {id});
    }

    public importData(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/ingredient/import`, data);
    }


    public exportData(): Promise<IApiResponse> {
        fertilizerStore.isLoadingBt = true
        const result = exportRequest(`/v1/ingredient/export`, 'Nguyên vật liệu');
        fertilizerStore.isLoadingBt = false
        return result
    }

}

export const ingredientService = new IngredientService()