import React from 'react';
import {observer} from "mobx-react";
import QRCode from 'qrcode.react';
import {trackingStore} from "../../TrackingStore";
import {trackingDetailStore} from "../TrackingDetailStore";
import DatePickerSingle from "../../../../common/component/DatePickerSingle";
import {urlImage} from "../../../../common/utils/Utils";
import {distributorStore} from "../../../setup/distributor/DistributorStore";
import uploadFileHelper from "../../../../common/helpers/UploadFileHelper";

const AddOrEditTrackingQR = observer(() => {

    const handleStartDate = async (startDate: Date) => {
        trackingDetailStore.dataRequest.info.sentDate = startDate.getTime()
        trackingDetailStore.dataRequest.info.expireDate = ''
    }

    const handleExpireDate = async (date: Date) => {
        trackingDetailStore.dataRequest.info.expireDate = date.getTime()
    }

    const changeImg = (e: any) => {
        trackingDetailStore.dataRequest.logo = e
    }

    let {info, batchCode, qrSetting} = trackingDetailStore.dataRequest

    let {
        consumerUnit,
        consumerUnitCode,
        deliveryLocation,
        netWeight,
        distributor,
        sentDate,
        expireDate,
        shippingPartner,
        shippingPartnerCode
    } = trackingDetailStore.errors



    return (
        <div className="modal fade" id="add_edit_tracking_qr" role="dialog" aria-hidden="true">
            <div className="modal-dialog" style={{maxWidth: 920}} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{trackingStore.dataRequest.plan?.name} - {trackingDetailStore.type == 'add' ? 'Thêm' : 'Cập nhật'} mã</h5>
                        <button type="button" className="close" id="close_qr_code" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-0">
                        <div className="content_access pb-0">
                            <div className="d-flex">
                                <div className="left">
                                    <div className="QR_code position-relative">
                                        <QRCode id="download_qrCode" level="L" value={trackingDetailStore.pageHelper}
                                                size={188}/>
                                        {trackingDetailStore.dataRequest.logo && <div
                                            className="logo_farm position-absolute d-flex align-items-center justify-content-center">
                                            <img width={36} height={36}
                                                 src={urlImage(trackingDetailStore.dataRequest.logo)}
                                                 alt=""/>
                                        </div>}
                                    </div>
                                    {trackingDetailStore.type !== 'add' && <div className="state_product mt-2">
                                        <label className="w-100">Cập nhật tình trạng</label>
                                        {trackingDetailStore.dataRequest.state !== "EXPIRED" ? <select
                                            onChange={(e: any) => trackingDetailStore.dataRequest.state = e.currentTarget.value}>
                                            <option value="USING"
                                                    selected={trackingDetailStore.dataRequest.state == "USING" && true}>Đang
                                                sử dụng
                                            </option>
                                            <option value="EVICTION"
                                                    selected={trackingDetailStore.dataRequest.state == "EVICTION" && true}>Bị
                                                thu hồi
                                            </option>
                                        </select> : <input type="text" value="Đã hết hạn" disabled={true}/>}
                                    </div>}

                                    <div className="tem_demo">
                                        {trackingDetailStore.dataRequest.backgroundImage &&
                                        <div className="d-flex justify-content-center">
                                            <div className="tem_background position-relative" style={{
                                                height: `${qrSetting.stampHeight}mm`,
                                                width:  `${qrSetting.stampWith}mm`,
                                            }}>
                                                <img src={urlImage(trackingDetailStore.dataRequest.backgroundImage)} style={{
                                                    height: `${qrSetting.stampHeight}mm`,
                                                    width:  `${qrSetting.stampWith}mm`,
                                                }} className="tem_img" alt=""/>
                                                <img id="img_qrCode" src={`/assets/images/tracking/qr_${qrSetting.label ? 'demo' : 'default'}.png`} style={{
                                                    top: `${qrSetting.top}%`,
                                                    left: `${qrSetting.left}%`,
                                                    height: `auto`,
                                                    width:  `${qrSetting.qrSize}mm`,
                                                }} alt=""/>
                                            </div>
                                        </div>}
                                        <div className="d-flex align-items-center mt-3 justify-content-between">
                                            <button className="add_" data-toggle="modal" data-target="#qr_tem_demo"
                                                    data-dismiss="modal">Tải tem
                                            </button>
                                            <button className="remove_"
                                                    disabled={!trackingDetailStore.dataRequest.backgroundImage}
                                                    onClick={() => trackingDetailStore.dataRequest.backgroundImage = ''}>Xóa
                                                tem
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form_access">
                                    <div className="list_info_product">
                                        <div className="info">
                                            {trackingDetailStore.type !== 'add' && <div>
                                                <div className="title_top">
                                                    <span>Thông tin sản phẩm</span>
                                                </div>
                                                <div className="list_content">
                                                    <div className="item">
                                                        <label className="title">Mã lô</label>
                                                        <input type="text" defaultValue={batchCode} title={batchCode}
                                                               disabled={true}/>
                                                    </div>
                                                </div>
                                            </div>}
                                            <div className="title_top">
                                                <span>Thông tin chi tiết</span>
                                                <i className='fas fa-plus'
                                                   onClick={() => info.more.push({key: '', value: ''})}/>
                                            </div>
                                            <div className="list_content">
                                                <div className="item">
                                                    <label className="title">Nhà phân phối <span
                                                        className="text-danger">*</span></label>
                                                    <select value={info.distributor}
                                                            className={`${distributor && !info.distributor ? 'border_error' : ''}`}
                                                            onChange={(e: any) => info.distributor = e.currentTarget.value}>
                                                        <option value="">Chọn</option>
                                                        {distributorStore.listDistributor && distributorStore.listDistributor.map((val: any, i: number) => {
                                                            return <option value={val.name} key={i}
                                                                           selected={val.name == info.distributor}>{val.name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Khối lượng tịnh <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info.netWeight}
                                                           className={`${netWeight && !info.netWeight ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.netWeight = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Ngày gửi hàng <span
                                                        className="text-danger">*</span></label>
                                                    <DatePickerSingle selected={info.sentDate} isError={sentDate}
                                                                      onChange={handleStartDate}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Ngày hết hạn <span
                                                        className="text-danger">*</span></label>
                                                    <DatePickerSingle selected={info.expireDate}
                                                                      minDate={trackingDetailStore.dataRequest.info.sentDate}
                                                                      isError={expireDate}
                                                                      onChange={handleExpireDate}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Địa điểm gửi hàng <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info.deliveryLocation}
                                                           className={`${deliveryLocation && !info.deliveryLocation ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.deliveryLocation = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Đơn vị tiêu thụ <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info.consumerUnit}
                                                           className={`${consumerUnit && !info.consumerUnit ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.consumerUnit = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Mã đơn vị tiêu thụ <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info.consumerUnitCode}
                                                           className={`${consumerUnitCode && !info.consumerUnitCode ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.consumerUnitCode = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Đơn vị vận chuyển <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info.shippingPartner}
                                                           className={`${shippingPartner && !info.shippingPartner ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.shippingPartner = e.currentTarget.value}/>
                                                </div>

                                                <div className="item">
                                                    <label className="title">Mã đơn vị vận chuyển <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info.shippingPartnerCode}
                                                           className={`${shippingPartnerCode && !info.shippingPartnerCode ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.shippingPartnerCode = e.currentTarget.value}/>
                                                </div>

                                                {info.more && info.more.map((item: any, i: number) => {
                                                    return <div className="item" key={i}>
                                                        <input className="title" type="text" value={item.key}
                                                               onChange={(e: any) => item.key = e.currentTarget.value}/>
                                                        <input type="text" value={item.value}
                                                               onChange={(e: any) => item.value = e.currentTarget.value}/>
                                                        <i className="fas fa-times"
                                                           onClick={() => info.more.splice(i, 1)}/>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="save_qr_code justify-content-center">
                                        <button className="mr-3 action_barcode position-relative">
                                            Chèn logo
                                            <input className="up_img"
                                                   accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                                   title='Logo kích thước 48x48'
                                                   onChange={async (e: any) => await uploadFileHelper(e, changeImg)}/>
                                        </button>
                                        <button className="mr-3 action_barcode bt_remove_outline"
                                                disabled={!trackingDetailStore.dataRequest.logo}
                                                onClick={() => trackingDetailStore.dataRequest.logo = ''}>Xóa logo
                                        </button>

                                        <button className="action_barcode mr-2"
                                                disabled={!trackingDetailStore.dataRequest.id}>{trackingDetailStore.dataRequest.id ?
                                            <a href={trackingDetailStore.pageHelper} target="_blank">Xem kết
                                                quả</a> : 'Xem kết quả'}</button>

                                        <button className="action_barcode"
                                                disabled={!trackingDetailStore.dataRequest.id} data-dismiss="modal"
                                                data-toggle="modal" data-target="#qr_code_tracking">Tải mã
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn send-request"
                                                style={{width: 276}}
                                                onClick={() => trackingDetailStore.addEditTracking(true)}
                                                disabled={trackingDetailStore.isLoadingBt || (trackingDetailStore.type == 'add' && trackingDetailStore.dataRequest.id)}>Lưu
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

})

export default AddOrEditTrackingQR;