import {observable} from "mobx";
import {procedureService} from "./ProcedureService";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";
import $ from "jquery";
import {productStore} from "../setup/product/ProductStore";
import {IFilter} from "../../common/component/filter/FilterComponent";
import {planStore} from "../planning/PlanStore";

class ProcedureStore {

    @observable id: any = '';
    @observable type: any = '';
    @observable date: any = new Date();
    @observable searchYear: any = this.date.getFullYear();
    @observable searchProduct: any = '';
    @observable page: number = 0;
    @observable isChanged: boolean = false
    @observable totalPage: number = 0;
    @observable isLoading: boolean = false
    @observable public errors: any = {
        name: "",
        cateLv2: '',
        cateLv1: '',
        cateLv3: '',
        productId: '',
    }
    @observable isLoadingBt: boolean = false
    @observable dataRequest: any = {
        id: "",
        name: "",
        cateLv2: '',
        cateLv1: '',
        cateLv3: '',
        productId: '',
        note: ""
    }
    @observable dataRequestJob: any = {
        id: "",
        name: "",
        type: '',
    }
    @observable listProcedure: any[] = []
    @observable listProduct: any[] = []
    @observable dataFilter: IFilter[] = []

    clearError() {
        procedureStore.errors = {
            name: "",
            cateLv2: '',
            cateLv1: '',
            cateLv3: '',
            productId: '',
        }
    }

    clearForm() {
        productStore.categoryId = ''
        procedureStore.clearError()
        procedureStore.type = 'add'
        procedureStore.dataRequest = {
            id: "",
            name: "",
            cateLv2: '',
            cateLv1: '',
            cateLv3: '',
            productId: '',
            note: ""
        }
    }


    async changeCateLv1(id: any) {
        procedureStore.dataRequest.cateLv1 = id
        productStore.listProduct = []
        procedureStore.dataRequest.cateLv2 = ''
        procedureStore.dataRequest.cateLv3 = ''
        procedureStore.dataRequest.productId = ''
        if (id) {
            procedureStore.errors.cateLv1 = ""
        }
        await productStore.getCateLv2(id)
    }

    async changeCateLv2(id: any) {
        procedureStore.dataRequest.cateLv2 = id
        procedureStore.dataRequest.cateLv3 = ''
        procedureStore.dataRequest.productId = ''
        productStore.listProduct = []
        if (id) {
            procedureStore.errors.cateLv2 = ""
        }
        await productStore.getCateLv3(id)
    }

    async changeCateLv3(id: any) {
        procedureStore.dataRequest.cateLv3 = id
        procedureStore.dataRequest.productId = ''
        if (id) {
            procedureStore.errors.cateLv3 = ""
        }
        productStore.categoryId = id
        await productStore.getProducts()
    }


    reorder = (startIndex: number, endIndex: number) => {
        const results: any[] = Array.from(procedureStore.listProcedure);
        const [removed] = results.splice(startIndex, 1);
        results.splice(endIndex, 0, removed);
        //resort
        results.forEach(function (item, index) {
            item.sort = index
        });
        procedureStore.listProcedure = results;
    };


    async getProcedure(size?: any) {
        procedureStore.isLoading = true
        const result = await procedureService.getProcedure(size)
        procedureStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            procedureStore.listProcedure = result.body.data
            procedureStore.totalPage = result.body.metadata.totalPage
        }
    }


    async getProducts() {
        const result = await procedureService.getProducts()
        if (result.status === HttpStatusCode.OK) {
            procedureStore.listProduct = result.body.data;
            procedureStore.dataFilter = [
                {
                    label: localStorage.getItem('language') == "en" ? "Year" : "Năm",
                    type: 'select',
                    value: procedureStore.searchYear,
                    listValue: [
                        {
                            id: '2022',
                            name: '2022',
                        },
                        {
                            id: '2023',
                            name: '2023',
                        },
                        {
                            id: '2024',
                            name: '2024',
                        },
                        {
                            id: '2025',
                            name: '2025',
                        },
                        {
                            id: '2026',
                            name: '2026',
                        },
                        {
                            id: '2027',
                            name: '2027',
                        },
                        {
                            id: '2028',
                            name: '2028',
                        },
                        {
                            id: '2029',
                            name: '2029',
                        },
                        {
                            id: '2030',
                            name: '2030',
                        }
                    ],
                    callback: async (e: any) => {
                        procedureStore.searchYear = e
                        planStore.page = 0
                        await procedureStore.getProcedure()
                    }
                },
                {
                    label: localStorage.getItem('language') == "en" ? "Product" : "Sản phẩm",
                    type: 'select',
                    value: procedureStore.searchProduct,
                    listValue: procedureStore.listProduct,
                    callback: async (e: any) => {
                        planStore.page = 0
                        procedureStore.searchProduct = e
                        await procedureStore.getProcedure()
                    }
                }
            ]
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async getDetail(id: any) {
        procedureStore.clearError()
        procedureStore.type = "edit"
        const result = await procedureService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            procedureStore.dataRequest = result.body
            if (result.body.product?.group) {
                procedureStore.dataRequest.cateLv1 = result.body.product.group.id
                await productStore.getCateLv2(result.body.product.group.id)
            }
            if (result.body.product?.type) {
                procedureStore.dataRequest.cateLv2 = result.body.product.type.id
                await productStore.getCateLv3(result.body.product.type.id)
            }
            if (result.body.product?.treeLike) {
                procedureStore.dataRequest.cateLv3 = result.body.product.treeLike.id
                productStore.categoryId = result.body.product.treeLike.id
                await productStore.getProducts()
            }
        }
    }

    async add() {
        this.clearError()
        let {name, cateLv1, note, cateLv2, cateLv3, productId} = procedureStore.dataRequest

        let data = {
            name: name,
            productId: parseInt(productId),
            note: note
        }
        if (!name) {
            procedureStore.errors.name = 'Tên quy trình không được để trống!'
        }
        if (!cateLv1) {
            procedureStore.errors.cateLv1 = 'Vui lòng chọn nhóm sản phẩm!'
        }
        if (!cateLv2) {
            procedureStore.errors.cateLv2 = 'Vui lòng chọn loại sản phẩm!'
        }
        if (!cateLv3) {
            procedureStore.errors.cateLv3 = 'Vui lòng chọn giống sản phẩm!'
        }
        if (!productId) {
            procedureStore.errors.productId = 'Vui lòng chọn sản phẩm!'
        }

        if (!name || !cateLv1 || !cateLv2 || !cateLv3 || !productId) {
            return
        }

        procedureStore.isLoadingBt = true
        const result = await procedureService.create(data)
        procedureStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thêm quy trình thành công', 1)
            $('#close_add_edit').trigger('click')
            procedureStore.clearForm()
            await procedureStore.getProcedure()
        } else {
            if (typeof result.body.message === "string") {
                toastUtil.error(result.body.message, 3);
            } else if (typeof result.body.message === "object") {
                this.errors = result.body.message;
            }
        }

    }

    async update() {
        this.clearError()
        let {id, name, cateLv1, note, cateLv2, cateLv3, productId} = procedureStore.dataRequest

        let data = {
            name: name,
            productId: parseInt(productId),
            note: note
        }

        if (!name) {
            procedureStore.errors.name = 'Tên quy trình không được để trống!'
        }
        if (!cateLv1) {
            procedureStore.errors.cateLv1 = 'Vui lòng chọn nhóm sản phẩm!'
        }
        if (!cateLv2) {
            procedureStore.errors.cateLv2 = 'Vui lòng chọn loại sản phẩm!'
        }
        if (!cateLv3) {
            procedureStore.errors.cateLv3 = 'Vui lòng chọn giống sản phẩm!'
        }
        if (!productId) {
            procedureStore.errors.productId = 'Vui lòng chọn sản phẩm!'
        }
        if (!name || !cateLv1 || !cateLv2 || !cateLv3 || !productId) {
            return
        }

        procedureStore.isLoadingBt = true
        const result = await procedureService.update(id, data)
        procedureStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật quy trình thành công', 3)
            $('#close_add_edit').trigger('click')
            procedureStore.clearForm()
            await procedureStore.getProcedure()
        } else {
            toastUtil.error("Cập nhật quy trình thất bại", 4)
        }
    }

    async delete() {
        procedureStore.isLoadingBt = true
        const result = await procedureService.delete(procedureStore.id)
        procedureStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa quy trình thành công', 5)
            await procedureStore.getProcedure()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }
}

export const procedureStore = new ProcedureStore()
