import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {scheduleStore} from "./ScheduleStore";
import {planStore} from "../../planning/PlanStore";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {EventClickArg} from "@fullcalendar/core";
import {diaryStore} from "../DiaryStore";
import {jobDiaryStore} from "../jobDiary/jobDiaryStore";
import Loading from "../../../common/component/Loading";


const Schedule = () => {

    const filterStarDate = async (e: any) => {
        planStore.id = '';
        diaryStore.landId = ''
        planStore.searchYear = e.currentTarget.value;
        await callApi()
    }

    const callApi = async () => {
        await planStore.getPlanning(100)
        planStore.id = planStore.listPlanning && planStore.listPlanning.length > 0 && planStore.listPlanning[0].id;
        if (planStore.id) {
            await planStore.getLands(planStore.id)
            if (planStore.listLandPlan && planStore.listLandPlan.length > 0) {
                diaryStore.landId = planStore.listLandPlan[0].id
                await jobDiaryStore.getDiary(true)
            } else diaryStore.landId = ''
        } else {
            scheduleStore.events = []
        }
    }

    function handleEventClick(clickInfo: EventClickArg) {
        console.log(clickInfo.event)
    }

    useEffect(() => {
        callApi().then()
        return () => {
            planStore.id = ''
            diaryStore.landId = ''
            planStore.listLandPlan = []
        }
    }, [])


    return (
        <div className="diary">
            <div className="d-flex align-items-center justify-content-between filter_header">
                <div className="d-flex align-items-center">
                    <img src="/assets/ico/filter.svg" alt=""/>
                    <div className="d-flex form_search align-items-center">
                        <span>Năm bắt đầu:</span>
                        <div className="filter_select">
                            <select onChange={(e: any) => filterStarDate(e)} value={planStore.searchYear}>
                                <option value="" disabled={true}>Chọn</option>
                                {planStore.listYear && planStore.listYear.map((item, i) => {
                                    return <option value={item.id} key={i}>{item.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="d-flex form_search align-items-center">
                        <span>Kế hoạch sản xuất:</span>
                        <div className="filter_select">
                            <select onChange={async (e: any) => {
                                planStore.id = e.currentTarget.value;
                                await planStore.getLands(planStore.id)
                                if (planStore.listLandPlan && planStore.listLandPlan.length > 0) {
                                    diaryStore.landId = planStore.listLandPlan[0].id
                                    await jobDiaryStore.getDiary(true)
                                } else diaryStore.landId = ''
                            }}
                                    value={planStore.id}>
                                <option value="" disabled>Chọn</option>
                                {planStore.listPlanning && planStore.listPlanning.map((item, i) => {
                                    return <option value={item.id} key={i}>{item.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="d-flex form_search align-items-center">
                        <span>Thửa:</span>
                        <div className="filter_select">
                            <select onChange={async (e: any) => {
                                diaryStore.landId = e.currentTarget.value;
                                await jobDiaryStore.getDiary(true)
                            }}
                                    value={diaryStore.landId}>
                                <option value="" disabled>Chọn</option>
                                {planStore.listLandPlan && planStore.listLandPlan.map((item, i) => {
                                    return <option value={item.id} key={i}>{item.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {jobDiaryStore.isLoading ? <Loading/> :
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    locale="vi"
                    headerToolbar={{
                        left: '',
                        center: "title",
                        right: "prev,next,today",
                    }}
                    buttonText={{
                        prev: 'Tháng trước',
                        next: 'Tháng tới',
                        today: "Hiện tại"
                    }}
                    dayMaxEvents={true}
                    events={scheduleStore.events.slice()}
                    eventClick={handleEventClick}
                />}
        </div>
    );
}

export default observer(Schedule);