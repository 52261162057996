import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import Loading from "../../../common/component/Loading";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import PopupConfirm from "../../../common/component/PopupConfirm";
import ButtonAdd from "../../../common/component/ButtonAdd";
import {equipmentStore} from "./EquipmentStore";
import AddOrEditEquipment from "./components/AddOrEditEquipment";
import {checkPermission, sttPage, urlImage} from "../../../common/utils/Utils";
import {PermissionConst} from "../../../common/constants/Constants";
import {Icon} from "../../../common/component/Icon";
import ButtonImport from "../../../common/component/ButtonImport";
import {importData} from "../../../common/helpers/RequestHelper";
import Button from "../../../common/component/Button";
import {equipmentService} from "./EquipmentService";
import {Link} from "react-router-dom";

export const returnIconFile = (item: any) => {
    const parts = item?.split(".");
    const extension = parts[parts.length - 1];
    switch (extension.toLocaleLowerCase()) {
        case "pdf":
            return <img className='object-fit-cover' style={{borderRadius: 10}} src="/assets/ico/file/icon_pdf.svg" alt=""/>;
        case "jpg":
        case "jpeg":
        case "png":
            return <img width={36} height={36} className='object-fit-cover' style={{objectFit: 'cover', borderRadius: 10}} src={urlImage(item)} alt=""/>;
        case "xlsx":
        case "xls":
            return <img className='object-fit-cover' style={{borderRadius: 10}} src="/assets/ico/file/icon_excel.svg" alt=""/>;
        case "doc":
        case "docx":
            return <img className='object-fit-cover' style={{borderRadius: 10}} src="/assets/ico/file/icon_doc.svg" alt=""/>;
        case "zip":
        case "rar":
            return <img className='object-fit-cover' style={{borderRadius: 10}} src="/assets/ico/file/icon_zip.svg" alt=""/>;
        default:
            return <img className='object-fit-cover' style={{borderRadius: 10}} src="/assets/ico/file/file_default.svg" alt=""/>
    }
}

const EquipmentComponent = () => {

    const changePage = async(data: any) => {
        equipmentStore.page = data.selected;
        await equipmentStore.getEquipments();
    }

    useEffect(() => {
        equipmentStore.getEquipments()
    }, [])

    if(equipmentStore.isLoading){
        return <Loading/>
    }else {
        return (
            <div className='table-responsive'>
                {checkPermission(PermissionConst.equipmentAdd) && <div className="bt_add">
                    <ButtonImport onChange={(e: any) => importData(e, equipmentStore.importData)} className="mr-2"/>
                    <Button class="mr-2 button_add text-white" img={true} disabled={equipmentStore.isLoadingBt} onClick={() => equipmentService.exportData()} content="Xuất"/>
                    <a style={{width: 108}} className="btn mr-2 button_add text-white" href="/file/Thiết bị máy móc.xlsx" download><Icon name="download" className="text-white mr-1"/> <strong>Tải mẫu</strong></a>
                    <ButtonAdd text="Thêm thiết bị" dataTarget={'add_or_edit_equipment'} onClick={equipmentStore.clearForm}/>
                </div>}
                {equipmentStore.listEquipment && equipmentStore.listEquipment.length > 0 ?
                    <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th className="text-center">STT</th>
                            <th>Tên thiết bị</th>
                            <th className="text-center">HDSD</th>
                            <th className="text-center">Ghi chú</th>
                            {(checkPermission(PermissionConst.equipmentUpdate) || checkPermission(PermissionConst.equipmentUpdate)) && <th className="text-center">Thao tác</th>}
                        </tr>
                        </thead>
                        <tbody>

                        {equipmentStore.listEquipment.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">{sttPage(equipmentStore.page, i)}</td>
                                    <td>{item.name}</td>
                                    <td className="text-center">
                                        {item.images && item.images[0] && <Link title="Hướng dẫn sử dụng" target="_blank" to={urlImage(item.images[0])}>
                                            {returnIconFile(item.images[0])}
                                        </Link>}
                                    </td>
                                    <td className="text-center"><p className="des" title={item.note}>{item.note}</p></td>
                                    {(checkPermission(PermissionConst.equipmentUpdate) || checkPermission(PermissionConst.equipmentUpdate)) && <td className="text-center" width="20%">
                                        <div className="action">
                                            {checkPermission(PermissionConst.equipmentUpdate) && <button className="bt_action" title={"Sửa"} data-toggle="modal"
                                                                                                         data-target="#add_or_edit_equipment"
                                                                                                         onClick={() => equipmentStore.getDetail(item.id)}>
                                                <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                            </button>}
                                            {checkPermission(PermissionConst.equipmentDelete) && <button className="bt_action" title={"Xóa"} onClick={() => equipmentStore.id = item.id}
                                                                                                         data-toggle="modal" data-target="#popup_confirm">
                                                <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                            </button>}
                                        </div>
                                    </td>}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table> : <NoContent/>}

                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Paginate currentPage={equipmentStore.page} totalPage={equipmentStore.totalPage}
                              changePage={changePage}/>
                </div>
                <AddOrEditEquipment/>
                <PopupConfirm isLoading={equipmentStore.isLoadingBt} action={equipmentStore.delete}/>

            </div>
        );
    }
}

export default observer(EquipmentComponent);