import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import "../PlanningStyle.scss"
import "./PlanDetailStyle.scss"
import Loading from "../../../common/component/Loading";
import {getIDParameter, number_format} from "../../../common/utils/Utils";
import {dateUtils} from "../../../common/utils/DateUtils";
import NoContent from "../../../common/component/NoContent";
import {Link} from "react-router-dom";
import {profileStore} from "../../profile/ProfileStore";
import {planStore} from "../PlanStore";
import {landStore} from "../../setup/land/LandStore";
import AddOrEditPlanOne from "../components/AddOrEditPlanOne";
import AddOrEditPlanTwo from "../components/AddOrEditPlanTwo";


const PlanDetail = () => {


    useEffect(() => {
        profileStore.link = `/planning`
        planStore.id = getIDParameter();
        planStore.getDetail(planStore.id).then()
        landStore.getLands().then();
        planStore.getLands(planStore.id).then();
    }, [])


    let items = planStore.dataRequest
    return (
        <div className="plan_job planning">
            <div className="header_back h-auto">
                <div className="d-flex justify-content-between">
                    <div className="header_left">
                        <div className="d-flex align-items-center">
                            <Link to={`/planning`}>Lập kế hoạch</Link>
                            <i className="fal fa-angle-right"/>
                            <span>{items.name}</span>
                        </div>
                    </div>
                    <div className="d-flex" style={{height: 25}}>
                        <div className="mr-4 edit_plan color-green" style={{fontSize: 14, cursor: "pointer"}}
                             data-toggle="modal"
                             data-target="#add_or_edit_plan"
                             onClick={() => planStore.getDetail(planStore.id)}>
                            <i className="fas fa-pen"/>
                            Sửa kế hoạch
                        </div>
                        <Link to={'/planning'} className="back">
                            <i className="far fa-arrow-left"/>
                            Quay lại
                        </Link>
                    </div>
                </div>
                <div className="header_plan_job">
                    <div className="d-flex flex-wrap info_plan align-items-center">
                        <div className="info">
                            <label>Năm: </label>
                            <span>{items.year}</span>
                        </div>
                        <div className="info">
                            <label>Sản phẩm: </label>
                            <span>{items.procedure?.product?.name}</span>
                        </div>
                        <div className="info">
                            <label>Thời gian (Dự kiến): </label>
                            <span>{dateUtils.formatDate(items.startDate)} - {dateUtils.formatDate(items.endDate)}</span>
                        </div>
                        <div className="info">
                            <label>Diện tích sản xuất: </label>
                            <span>{items.totalArea} Ha</span>
                        </div>
                        <div className="info">
                            <label>Sản lượng (Dự kiến): </label>
                            <span>{number_format(items.expectedOutput)} Kg</span>
                        </div>
                        <div className="info">
                            <label>Sản lượng thực tế: </label>
                            <span>{number_format(items.actualOutput)} Kg</span>
                        </div>

                    </div>
                </div>
            </div>
            {planStore.isLoading ? <Loading/> :
                <div className='table-responsive'>
                    {planStore.listLandPlan  && planStore.listLandPlan.length > 0 ?
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th>Tên thửa</th>
                                <th className="text-center">Tài khoản</th>
                                <th className="text-center">Diện tích (Ha)</th>
                                <th className="text-center">Sản lượng (Kg)</th>
                                <th className="text-center">Ngày nhập</th>
                            </tr>
                            </thead>
                            <tbody>
                            {planStore.listLandPlan.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{i + 1}</td>
                                        <td>{item.name}</td>
                                        <td className="text-center">{item.farmHousehold}</td>
                                        <td className="text-center">{item.area}</td>
                                        <td className="text-center">{number_format(item.actualOutput)}</td>
                                        <td className="text-center">{dateUtils.formatDate(item.createdAt)}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                </div>}
            <AddOrEditPlanOne/>
            <AddOrEditPlanTwo/>
            <button id="add_plan_two" data-toggle="modal" data-target="#add_edit_plan_two" style={{opacity: 0}}/>
        </div>
    );

}

export default observer(PlanDetail);