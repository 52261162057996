import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {progressStore} from "./ProgressStore";
import {planStore} from "../../planning/PlanStore";
import Loading from "../../../common/component/Loading";
import NoContent from "../../../common/component/NoContent";


const Progress = () => {

    const switchProgress = (progress: any) => {
        if (progress <= 25) {
            return "PREPARE"
        } else if (progress > 25 && progress <= 50) {
            return "PLANT"
        } else if (progress > 50 && progress <= 75) {
            return "PLANT"
        } else if (progress > 75 && progress <= 100) {
            return "PLANT"
        }
    }
    const callApi = async () => {
        await planStore.getPlanning()
        progressStore.planId = planStore.listPlanning && planStore.listPlanning.length > 0 && planStore.listPlanning[0].id;
        if(progressStore.planId){
            progressStore.getProgress().then()
        }
    }

    useEffect(() => {
        planStore.searchYear = planStore.date.getFullYear()
        callApi().then()
        return () => {
            planStore.searchYear = planStore.date.getFullYear()
        }
    }, [])

    return (
        <div className="progress_diary">
            <div className="d-flex align-items-center justify-content-between filter_header">
                <div className="d-flex align-items-center">
                    <img src="/assets/ico/filter.svg" alt=""/>
                    <div className="d-flex form_search align-items-center">
                        <span>Năm:</span>
                        <div className="filter_select">
                            <select onChange={async (e: any) => {
                                planStore.searchYear = e.currentTarget.value
                                progressStore.planId = ''
                                planStore.listPlanning = []
                                progressStore.listProgress = []
                                await planStore.getPlanning()
                                if(planStore.listPlanning && planStore.listPlanning.length > 0){
                                    progressStore.planId = planStore.listPlanning && planStore.listPlanning.length > 0 && planStore.listPlanning[0].id;
                                    await progressStore.getProgress()
                                }
                            }} value={planStore.searchYear}>
                                <option value="" disabled={true}>Chọn</option>
                                {planStore.listYear && planStore.listYear.map((item, i) => {
                                    return <option value={item.id} key={i}>{item.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="d-flex form_search align-items-center">
                        <span>Kế hoạch:</span>
                        <div className="filter_select">
                            <select onChange={async (e: any) => {
                                progressStore.planId = e.currentTarget.value;
                                await progressStore.getProgress()
                            }}
                                    value={progressStore.planId}>
                                <option value="" disabled>Chọn</option>
                                {planStore.listPlanning && planStore.listPlanning.map((item, i) => {
                                    return <option value={item.id} key={i}>{item.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {progressStore.isLoading ? <Loading/> : <div className='table-responsive'>
                {progressStore.listProgress && progressStore.listProgress.length > 0 ?
                    <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th className="text-center">STT</th>
                            <th className="text-center">Tên đất, cơ sở</th>
                            <th className="text-center">Nông hộ</th>
                            <th className="text-center">Địa điểm</th>
                            <th className="text-center">Tiến độ</th>
                        </tr>
                        </thead>
                        <tbody>
                        {progressStore.listProgress.map((item, i) => {
                            let width: any = (item.totalJobFinished / item.totalJob) * 100
                            return (
                                <tr key={i}>
                                    <td className="text-center">{i + 1}</td>
                                    <td width="30%">{item.land.name}</td>
                                    <td className="text-center">{item.land.farmHousehold}</td>
                                    <td width="25%">{item.land.ward.name}, {item.land.district.name}, {item.land.province.name}</td>
                                    <td className="text-center">
                                        <div className="d-inline-block">
                                            <div className="progress">
                                                <div className={`progress-bar ${switchProgress(width)}`}
                                                     role="progressbar" style={{width: `${width}%`}}
                                                     aria-valuemax={100}/>
                                            </div>
                                        </div>
                                    </td>
                                    {/*<td className="text-center">{item.stage?.name}</td>*/}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table> : <NoContent/>}
            </div>}
        </div>
    );
}

export default observer(Progress);