import {observable} from "mobx";
import {getRequest, postRequest} from "../../common/helpers/RequestHelper";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";
import {trackingStore} from "../tracking/TrackingStore";


class ReviewStore {
    @observable isLoadingBt: boolean = false
    @observable dataRequest: any = {
        star: '',
        name: '',
        comment: ''
    }
    @observable listReview: any[] = []
    @observable statistic: any = {
        aggregate: 0,
        fiveStar: 0,
        fourStar: 0,
        oneStar: 0,
        sumStar: 0,
        threeStar: 0,
        twoStar: 0
    }
    @observable metadata: { page: number, total: number, totalPage: number} = {
        page: 0, total: 0, totalPage: 0
    }

    clearForm(){
        this.dataRequest = {
            star: '',
            name: '',
            comment: ''
        }
    }

    async getStatistic(){
        const result = await getRequest(`/v1/reviews/statistic?batchId=${trackingStore.dataRequest.trackingQrBatch.id}`)
        if(result.status === HttpStatusCode.OK){
            this.statistic = result.body
        }
    }

    async getReview(){
        const result = await getRequest(`/v1/reviews?page=${this.metadata.page}&size=10&batchId=${trackingStore.dataRequest.trackingQrBatch.id}`)
        if(result.status === HttpStatusCode.OK){
            this.listReview = result.body.data
            this.metadata = result.body.metadata
        }
    }

    async readMore(){
        this.metadata.page ++
        const result = await getRequest(`/v1/reviews?page=${this.metadata.page}&size=10&batchId=${trackingStore.dataRequest.trackingQrBatch.id}`)
        if(result.status === HttpStatusCode.OK){
            this.listReview = this.listReview.concat(result.body.data)
            this.metadata = result.body.metadata
        }
    }

    async sendReview(){
        let {star, comment, name} = this.dataRequest

        let data = {
            name: name,
            batchId: trackingStore.dataRequest.trackingQrBatch.id,
            star: star,
            comment: comment,
            images: [],
            videos: [],
        }

        if(!name || !comment || !star){
            toastUtil.warning('Vui lòng nhập tên, chọn đánh giá và nhập nội dung để đánh giá!', 1)
            return
        }

        this.isLoadingBt = true
        const result = await postRequest(`/v1/reviews`, data)
        this.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            this.clearForm()
            await this.getStatistic()
            await this.getReview()
        }else {
            toastUtil.error(result.body.message)
        }

    }
}

export const reviewStore = new ReviewStore()