import React from 'react';
import { observer } from "mobx-react";
import { Spin } from "antd";
import { historyOrderFarmStore } from '../HistoryOrderFarmStore';

const PopupCancelPackage = () => {
    return (
        <div className="modal fade" id="popup_cancel" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{ maxWidth: 650 }}>
                <div className="modal-content pt-5 border-0">
                    <div className="text-center pt-6 mb-4">
                        <img src={"/assets/ico/ico_cancel.svg"} alt="" />
                        <h5 className="modal-title mt-2">Hủy gói</h5>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="action_footer d-flex justify-content-between gap-16px">
                            <button className="button btn-cancel flex-1" data-dismiss="modal">Hủy</button>
                            <button className="button btn-confirm flex-1 background-E60A32"
                                onClick={() => historyOrderFarmStore.changeStatus(historyOrderFarmStore.id, 'CANCEL')}
                                disabled={historyOrderFarmStore.isLoadingBt}>{historyOrderFarmStore.isLoadingBt ? <Spin className="text-white" /> : 'Xác nhận'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(PopupCancelPackage);