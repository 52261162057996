import React from 'react'
import {urlImage} from "../../../common/utils/Utils";
import PopupZoom from "../../../common/component/PopupZoom";
import {observer} from "mobx-react";
import {trackingSeafoodStore} from "../../trackingSeaFood/TrackingSeafoodStore";
import {checkFileExtension} from "../../../common/helpers/UploadFileHelper";

const Content2 = () => {

    let {images, productQuality, unitInformation} = trackingSeafoodStore.dataRequest;

   try{
       return (
           <div>
               <h2>Chất lượng sản phẩm</h2>
               <div className="info_unit">
                   <div className="info_text">
                       <label>Màu sắc</label>
                       <span>{productQuality?.color}</span>
                   </div>
                   <div className="info_text">
                       <label>Độ tươi</label>
                       <span>{productQuality?.freshness}</span>
                   </div>
                   <div className="info_text">
                       <label>Chứng nhận</label>
                       <span>{productQuality?.certification}</span>
                   </div>
               </div>

               {images && images.length > 0 && <>
                   <h2>Nông trường & Sản phẩm</h2>
                   <div className="scroll_images">
                       <div className="list_images" style={{width: `${(images.length * 278) - 24}px`}}>
                           {images.map((item: any, i: number) => {
                               return (
                                   <div className="item cursor_pointer d-flex align-items-center" data-toggle="modal" data-target="#popupZoom_1" key={i}>
                                       <div className="item cursor_pointer d-flex align-items-center" data-toggle="modal" data-target="#popupZoom_1" key={i}>
                                           {checkFileExtension(item, 'mp4') ? <video controls className="w-100" src={urlImage(item)}/> : <img src={urlImage(item)} alt=""/>}
                                       </div>
                                   </div>
                               )
                           })}
                       </div>
                   </div>
               </>}


              <div className="mt-5">
                  {unitInformation.certificates && unitInformation.certificates.length > 0 && <>
                      <h2>Giấy chứng nhận</h2>
                      <div className="scroll_certificate">
                          <div className="list_certificate"
                               style={{width: `${(unitInformation.certificates.length * 278) - 24}px`}}>
                              {unitInformation.certificates.map((item: any, i: number) => {
                                  return (
                                      <div className="item cursor_pointer" data-toggle="modal"
                                           data-target="#popupZoom" key={i}>
                                          <img src={urlImage(item)} alt=""/>
                                      </div>
                                  )
                              })}
                          </div>
                      </div>
                  </>}
              </div>

               <PopupZoom listImg={images} id="popupZoom_1" idCarousel="carouselZoomImg2"/>
               <PopupZoom listImg={unitInformation.certificates}/>
           </div>
       )
   }catch (e) {
       return <div/>
   }

}

export default observer(Content2)
