import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery";
import {ingredientService} from "./IngredientService";
import {warehouseStore} from "../../warehouse/WarehouseStore";
import Constants from "../../../common/constants/Constants";

class IngredientStore{

    @observable id: any = '';
    @observable type: any = '';
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable dataRequest: any = {
        id: '',
        name: "",
        unit: "",
        shortName: '',
        price: '',
        note: ""
    }
    @observable listIngredient: any[] = []
    @observable public errors: any = {
        name: '',
        price: '',
        unit: "",
    }


    clearError() {
        ingredientStore.errors = {
            name: '',
            price: '',
            unit: "",
        }
    }

    clearForm() {
        ingredientStore.clearError()
        ingredientStore.type = 'add'
        ingredientStore.dataRequest = {
            id: '',
            name: "",
            shortName: '',
            unit: "",
            price: '',
            note: ""
        }
    }

    async getIngredient(size?: any) {
        ingredientStore.isLoading = true
        const result = await ingredientService.getIngredient(size)
        ingredientStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            ingredientStore.listIngredient = result.body.data
            warehouseStore.listSupplies = result.body.data
            ingredientStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getIngredientAll() {
        ingredientStore.isLoading = true
        const result = await ingredientService.getIngredientAll()
        ingredientStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            warehouseStore.listSuppliesAll = result.body.data
        }
    }

    async importData(file: any) {
        let data = {
            fileImport: file
        }
        const result = await ingredientService.importData(data);
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Import nguyên vật liệu thành công!');
            await ingredientStore.getIngredient();
        } else {
            toastUtil.error(result.body.message);
        }
    }


    async getDetail(id: any) {
        ingredientStore.type = "edit"
        ingredientStore.clearError()
        const result = await ingredientService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            ingredientStore.dataRequest = result.body
        }else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async add() {
        let { name, price, shortName, note, unit} = ingredientStore.dataRequest

        let data = {
            name: name,
            shortName: shortName,
            price: Number(price),
            unit: unit,
            note: note ?? '',
        }

        if (!name) {
            ingredientStore.errors.name = "Tên nguyên vật liệu không được để trống!"
            return
        }

        if (!price) {
            ingredientStore.errors.price = "Giá thành không được để trống!"
            return
        }

        if (!unit) {
            ingredientStore.errors.unit = "Đơn vị không được để trống!"
            return
        }

        ingredientStore.isLoadingBt = true
        const result = await ingredientService.create(data)
        ingredientStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thêm nguyên vật liệu thành công', 1)
            $('#close_add_edit').trigger('click')
            ingredientStore.clearForm()
            await ingredientStore.getIngredient()
        }else {
            if (typeof result.body.message === "string") {
                toastUtil.error(result.body.message, 3);
            } else if (typeof result.body.message === "object") {
                this.errors = result.body.message;
            }
        }

    }

    async update() {
        let { id, name, price, shortName, note, unit} = ingredientStore.dataRequest

        let data = {
            name: name,
            shortName: shortName,
            price: Number(price),
            unit: unit,
            note: note ?? '',
        }

        if (!name) {
            ingredientStore.errors.name = "Tên nguyên vật liệu không được để trống!"
            return
        }

        if (!price) {
            ingredientStore.errors.price = "Giá thành không được để trống!"
            return
        }

        if (!unit) {
            ingredientStore.errors.unit = "Đơn vị không được để trống!"
            return
        }

        ingredientStore.isLoadingBt = true
        const result = await ingredientService.update(id, data)
        ingredientStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật nguyên vật liệu thành công', 2)
            $('#close_add_edit').trigger('click')
            ingredientStore.clearForm()
            await ingredientStore.getIngredient()
        }else {
            toastUtil.error('Cập nhật nguyên vật liệu thất bại', 5)
        }
    }

    async delete() {
        ingredientStore.isLoadingBt = true
        const result = await ingredientService.delete(ingredientStore.id)
        ingredientStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa nguyên vật liệu thành công', 3)
            await ingredientStore.getIngredient()
        }else {
            toastUtil.error(result.body.message, 6)
        }
    }

}

export const ingredientStore = new IngredientStore()