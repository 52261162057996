import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery"
import {landService} from "./LandService";
import {signUpStore} from "../../auth/signUp/SignUpStore";
import {planStore} from "../../planning/PlanStore";
import {locateHelper} from "../../../common/helpers/LocateHelper";
import {uploadExcel} from "../../../common/helpers/RequestHelper";
import {diaryStore} from "../../diary/DiaryStore";


interface IErrors {
    name: string,
    farmHousehold: string,
    province: string,
    district: string,
    ward: string,
    area: string,
    type: string
}


class LandStore {
    @observable id: any = ''
    @observable type: any = ''
    @observable zoom: number = 13;
    @observable listInfoLand: any[] = [];
    @observable isFullScreen: boolean = false
    @observable defaultCenter: any = {
        lat: 21.028298,
        lng: 105.834213
    }
    @observable dataProvince: any = {
        provinceId: '',
        districtId: '',
        ward: '',
    }
    @observable isLoading: boolean = false
    @observable public errors: IErrors = {
        name: '',
        farmHousehold: '',
        province: '',
        district: '',
        ward: '',
        area: '',
        type: '',
    }
    @observable isLoadingBt: boolean = false
    @observable dataRequest: any = {
        id: "",
        name: '',
        farmHousehold: '',
        provinceId: null,
        districtId: null,
        wardId: null,
        area: '',
        type: 'OWN',
        location: {
            coordinates: [],
            color: ''
        }
    }
    @observable listLand: any[] = []

    clearError() {
        planStore.dataRequest.landIds = []
        landStore.errors = {
            name: '',
            farmHousehold: '',
            province: '',
            district: '',
            ward: '',
            area: '',
            type: '',
        }
    }

    clearForm() {
        landStore.clearError()
        landStore.type = "add"
        signUpStore.listDistrict = []
        signUpStore.listWard = []
        landStore.dataRequest = {
            name: '',
            farmHousehold: '',
            provinceId: null,
            districtId: null,
            wardId: null,
            area: '',
            type: 'OWN',
            location: {
                coordinates: [],
                color: ''
            }
        }
    }

    zoomPlus = () => {
        if (landStore.zoom < 20) landStore.zoom++
    }

    zoomMinus = () => {
        if (landStore.zoom > 5) landStore.zoom--
    }


    async importData(file: any) {
        let data = {
            fileImport: file
        }
        const result = await landService.importData(data);
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Import đất cơ sở thành công!');
            await landStore.getLands();
        } else {
            toastUtil.error(result.body.message);
        }
    }

    async searchLocate(address: any, isTracking?: boolean) {
        const result = await locateHelper(address)
        if (result.status == 200) {
            landStore.zoom = isTracking ? 17 : 14
            landStore.defaultCenter = result.body.results[0].geometry.location;
        }
    }

    async getLands(isLoading?: boolean) {
        if (!isLoading) {
            landStore.isLoading = true
        }
        const result = await landService.getLands()
        landStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            landStore.listLand = result.body;
        }
    }

    getCenter(points: any[]) {
        var sumX = 0, sumY = 0;
        for (var i = 0; i < points.length; i++) {
            var point = points[i];
            var x = point[0];
            var y = point[1];
            sumX += x;
            sumY += y;
        }
        return {lat: sumX / points.length, lng: sumY / points.length};
    }

    async getDetailLand(id: any, isProvince?: boolean) {
        landStore.type = 'edit'
        landStore.clearError()
        const result = await landService.detailLand(id)
        if (result.status === HttpStatusCode.OK) {
            landStore.dataRequest = result.body
            if (result.body.location && result.body.location.coordinates && result.body.location.coordinates.length > 0) {
                landStore.defaultCenter = {
                    lat: result.body.location.coordinates[1].lat,
                    lng: result.body.location.coordinates[1].lng
                }

                var points: any = [];
                result.body.location.coordinates && result.body.location.coordinates.map((item: any) => {
                    points.push([item.lat, item.lng])
                })

                landStore.defaultCenter = this.getCenter(points);
            } else {
                landStore.dataRequest.location = {
                    coordinates: [],
                    color: ''
                }
            }
            if (result.body.province) {
                landStore.dataRequest.provinceId = result.body.province.id
                !isProvince && await signUpStore.getDistricts(result.body.province.id)
            }
            if (result.body.district) {
                landStore.dataRequest.districtId = result.body.district.id
                !isProvince && await signUpStore.getWards(result.body.district.id)
            }
            if (result.body.ward) {
                landStore.dataRequest.wardId = result.body.ward.id
            }

        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async changeProvince(provinceId: any) {
        landStore.dataRequest.provinceId = provinceId
        landStore.dataRequest.districtId = null
        landStore.dataRequest.wardId = null
        await signUpStore.getDistricts(provinceId)
    }

    async changeDistrict(districtId: any) {
        landStore.dataRequest.districtId = districtId
        landStore.dataRequest.wardId = null
        await signUpStore.getWards(districtId)
    }


    async add() {
        let {name, farmHousehold, provinceId, area, type, districtId, wardId} = landStore.dataRequest

        let data = {
            name: name,
            farmHousehold: farmHousehold,
            provinceId: provinceId,
            districtId: districtId,
            wardId: wardId,
            area: area,
            type: type,
        }
        if (!name) {
            landStore.errors.name = 'Tên đất cơ sở không được để trống!'
            return
        } else landStore.errors.name = ''

        if (!farmHousehold) {
            landStore.errors.farmHousehold = 'Nông hộ thành viên không được để trống!'
            return
        } else landStore.errors.farmHousehold = ''

        if (!provinceId) {
            landStore.errors.province = 'Vui lòng chọn tỉnh thành phố!'
            return
        } else landStore.errors.province = ''

        if (!districtId) {
            landStore.errors.district = 'Vui lòng chọn quận huyện!'
            return
        } else landStore.errors.district = ''

        if (!wardId) {
            landStore.errors.ward = 'Vui lòng chọn phường xã!'
            return
        } else landStore.errors.ward = ''

        if (!area) {
            landStore.errors.area = 'Diện tích (ha) không được để trống!'
            return
        } else landStore.errors.area = ''

        landStore.isLoadingBt = true
        const result = await landService.create(data)
        landStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thêm đất cơ ở thành công', 1)
            $('#close_add_edit').trigger('click')
            landStore.clearForm()
            await landStore.getLands()
        } else {
            if (typeof result.body.message === "string") {
                toastUtil.error(result.body.message, 3);
            } else if (typeof result.body.message === "object") {
                this.errors = result.body.message;
            }
        }

    }

    async update() {
        let {id, name, farmHousehold, provinceId, area, type, districtId, wardId, location} = landStore.dataRequest

        let data = {
            name: name,
            farmHousehold: farmHousehold,
            provinceId: provinceId,
            districtId: districtId,
            wardId: wardId,
            area: area,
            type: type,
            location: location
        }

        if (!name) {
            landStore.errors.name = 'Tên đất cơ sở không được để trống!'
            return
        } else landStore.errors.name = ''

        if (!farmHousehold) {
            landStore.errors.farmHousehold = 'Nông hộ thành viên không được để trống!'
            return
        } else landStore.errors.farmHousehold = ''

        if (!provinceId) {
            landStore.errors.province = 'Vui lòng chọn tỉnh thành phố!'
            return
        } else landStore.errors.province = ''

        if (!districtId) {
            landStore.errors.district = 'Vui lòng chọn quận huyện!'
            return
        } else landStore.errors.district = ''

        if (!wardId) {
            landStore.errors.ward = 'Vui lòng chọn phường xã!'
            return
        } else landStore.errors.ward = ''

        if (!area) {
            landStore.errors.area = 'Diện tích (ha) không được để trống!'
            return
        } else landStore.errors.area = ''

        landStore.isLoadingBt = true
        const result = await landService.update(id, data)
        landStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật đất cơ sở thành công', 2)
            $('#close_add_edit').trigger('click')
            landStore.clearForm()
            await landStore.getLands()
        } else {
            toastUtil.error(result.body.message ?? 'Cập nhật đất cơ thất bại', 5)
        }
    }

    async delete() {
        landStore.isLoadingBt = true
        const result = await landService.delete(landStore.id)
        landStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Xóa đất cơ sở thành công', 3)
            $('#close_delete').trigger('click')
            await landStore.getLands()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }


}

export const landStore = new LandStore()