import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {getIDParameter} from "../../common/utils/Utils";
import "./InfoTracking.scss"
import Loading from "../../common/component/Loading";
import {trackingStore} from "../tracking/TrackingStore";
import Header from "./components/Header";
import Content2 from "./components/Content2";
import Content1 from "./components/Content1";
import {infoStore} from "../info/InfoStore";
import {Link} from "react-router-dom";


const PreviewTracking = () => {

    useEffect(() => {
        window.scroll(0, 0)
        let idTracking: any = getIDParameter();
        trackingStore.getDetail(idTracking, true).then()
    }, [])

    let {packageInfo} = trackingStore.dataRequest;

    return (
        <div className="info_access">
            <Header/>
            <div>
                <div className="container">
                    <div className="logo d-flex justify-content-center align-items-center">
                        <Link to={'/'}><img src="/assets/images/logo_login.svg" alt=""/></Link>
                    </div>
                    {trackingStore.isLoadingDetail ? <Loading/> :
                        <>
                            <Content1/>
                            <h2>Thông tin chi tiết</h2>
                            <div className="w-100 info_ d-flex">
                                <div className="info_unit w-50 pr-4">
                                    <div className="info_text">
                                        <label>Ngày thu hoạch</label>
                                        <span>{packageInfo?.harvestDate}</span>
                                    </div>
                                    <div className="info_text">
                                        <label>Hướng dẫn sử dụng</label>
                                        <span>{packageInfo?.userManual}</span>
                                    </div>
                                    <div className="info_text">
                                        <label>Hướng dẫn bảo quản</label>
                                        <span>{packageInfo?.storageInstructions}</span>
                                    </div>
                                    <div className="info_text">
                                        <label>Ngày gửi hàng</label>
                                        <span>{packageInfo?.sentDate}</span>
                                    </div>
                                    <div className="info_text">
                                        <label>Sản xuất từ</label>
                                        <span>{packageInfo?.address}</span>
                                    </div>
                                    <div className="info_text">
                                        <label>Mã HTX</label>
                                        <span>{infoStore.dataInfo.businessShortName}</span>
                                    </div>
                                </div>
                                <div className="info_unit w-50 pl-4">
                                    {packageInfo && packageInfo?.more.map((item: any, i: number) => {
                                        return (
                                            <div className="info_text" key={i}>
                                                <label>{item.key}</label>
                                                <span>{item.value}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <Content2/>
                        </>}
                </div>
            </div>
        </div>
    );
}

export default observer(PreviewTracking);