import React from "react";

interface IProps {
  colSpan: number;
  message?: string;
  height?: number;
  isContent?: boolean;
}

const NoContentTable = ({
  message,
  colSpan,
  height,
  isContent = true,
}: IProps) => {
  return (
      <tr>
        <td style={{ height: height ? `${height}px` : `calc(100vh - 350px)`, padding: 0, border: "none" }} colSpan={colSpan} className="text-center">
          <div className="bg-white h-100 text-center d-flex flex-column justify-content-center align-items-center fs-15px py-32px">
            <div className="d-flex w-100 justify-content-center align-items-center">
              <img style={{height: height && height < 200 ? 150 : 228}} src="/assets/images/no_content.svg" alt=""/>
            </div>
            {isContent && (<span style={{ color: "#ccc", fontSize: "14px" }}>{message ?? ""}</span>)}
          </div>
        </td>
      </tr>
  );
};

export default NoContentTable;
