import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import "./styles/sidebar.scss";
import {profileStore} from '../profile/ProfileStore';
import {checkExpirationTime, checkPermission, getPathName, slug} from "../../common/utils/Utils";
import {PermissionConst} from "../../common/constants/Constants";
import {landStore} from "../setup/land/LandStore";
import {planStore} from "../planning/PlanStore";
import { useTranslation } from 'react-i18next';
import {managerInformationStore} from "../manager/information/ManagerInformationStore";
import {sidebarStore} from "./SidebarStore";
import StorageService from "../../common/service/StorageService";

enum IRole {
    admin = 'admin',
    user = 'user',
    manager = 'manager'
}


const SideBar = () => {

    const { t } = useTranslation();

    const data: any = [
        {
            id: 0,
            role: IRole.admin,
            menu: [
                {
                    id: 1,
                    name: "Chờ xét duyệt",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/ico_smile_plus",
                    link: '/cms/users'
                },
                {
                    id: 1,
                    name: "Đang hoạt động",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/ico_people",
                    link: '/cms/users/active'
                },
                {
                    id: 1,
                    name: "Đang tạm khóa",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/ico_lock",
                    link: '/cms/users/lock'
                },
                {
                    id: 1,
                    name: "Tài khoản quản lý",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/ico_people",
                    link: '/cms/account-manager'
                },
                {
                    id: 1,
                    name: "Quản lý quyền",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/ico_permission",
                    link: '/cms/permission'
                },
                {
                    id: 1,
                    name: "Quyền mặc định",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/ico_permission_default",
                    link: '/cms/permission-default'
                },
                {
                    id: 1,
                    name: "Thiết lập giá bán",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/setup_package",
                    submenu: [
                        {
                            id: 0,
                            name: 'Omfarm',
                            isAccess: true,
                            link: '/cms/setup_package/omfarm'
                        },
                        {
                            id: 0,
                            name: 'QR Code',
                            isAccess: true,
                            link: '/cms/setup_package/qrcode'
                        },
                    ]
                },
                {
                    id: 1,
                    name: "Duyệt gói",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/package",
                    link: '/cms/approve-package'
                },
            ]
        },
        {
            id: 0,
            role: IRole.manager,
            menu: [
                {
                    id: 1,
                    name: t("infomation"),
                    isAccess: true,
                    icon: "/assets/ico/sidebar/manager_information",
                    link: '/manager/information'
                },
                {
                    id: 1,
                    name: t("product"),
                    isAccess: true,
                    icon: "/assets/ico/sidebar/manager_product",
                    link: '/manager/product'
                },
                {
                    id: 1,
                    name: t("access"),
                    isAccess: true,
                    icon: "/assets/ico/sidebar/manager_tracking",
                    link:  '/manager/truy-xuat'
                },
                {
                    id: 1,
                    name: t("distributors"),
                    isAccess: true,
                    icon: "/assets/ico/sidebar/manager_distribution",
                    link: '/manager/nha-phan-phoi'
                }  ,
                {
                    id: 1,
                    name: t("report"),
                    isAccess: true,
                    icon: "/assets/ico/sidebar/manager_doc",
                    link:  '/manager/bao-cao'
                }
            ]
        },
        {
            id: 1,
            role: IRole.user,
            menu: [
                {
                    id: 0,
                    name: t('overview'),
                    isAccess: true,
                    icon: "/assets/ico/sidebar/info",
                    link: '/'
                },
                {
                    id: 2,
                    name: t('setup'),
                    isAccess: (checkPermission(PermissionConst.distributorView) || checkPermission(PermissionConst.landView) || checkPermission(PermissionConst.laborView) || checkPermission(PermissionConst.productView) || checkPermission(PermissionConst.medicineView) || checkPermission(PermissionConst.fertilizerView) || checkPermission(PermissionConst.ingredientView) || checkPermission(PermissionConst.equipmentView)),
                    icon: "/assets/ico/sidebar/setup",
                    link: '/setup'
                },
                {
                    id: 2,
                    name: t('warehouse'),
                    isAccess: (checkPermission(PermissionConst.productView) || checkPermission(PermissionConst.medicineView) || checkPermission(PermissionConst.fertilizerView) || checkPermission(PermissionConst.ingredientView) || checkPermission(PermissionConst.equipmentView)),
                    icon: "/assets/ico/sidebar/warehouse",
                    link: '/warehouse'
                },
                {
                    id: 3,
                    name: t('procedure'),
                    isAccess: checkPermission(PermissionConst.procedureView),
                    icon: "/assets/ico/sidebar/procedure",
                    link: '/procedure'
                },
                {
                    id: 4,
                    name: t('planning'),
                    isAccess: checkPermission(PermissionConst.planView),
                    icon: "/assets/ico/sidebar/planning",
                    link: '/planning'
                },
                {
                    id: 5,
                    name: t('diary'),
                    isAccess: checkPermission(PermissionConst.planView),
                    icon: "/assets/ico/sidebar/uil_diary",
                    link: '/diary'
                },
                {
                    id: 6,
                    name: t('access'),
                    isAccess: true,
                    icon: "/assets/ico/sidebar/access",
                    link: '/tracking'
                },
                {
                    id: 6,
                    name: t('seafoodTracing'),
                    isAccess: true,
                    icon: "/assets/ico/sidebar/access",
                    link: '/tracking-seafood'
                },
                {
                    id: 6,
                    name: t('trackingBreed'),
                    isAccess: true,
                    icon: "/assets/ico/sidebar/access",
                    link: '/tracking-livestock'
                },
                {
                    id: 7,
                    name: t('report'),
                    isAccess: checkPermission(PermissionConst.reportView),
                    icon: "/assets/ico/sidebar/dashboard",
                    link: '/report'
                },
                {
                    id: 7,
                    name: t('question'),
                    isAccess: checkPermission(PermissionConst.farmView),
                    icon: "/assets/ico/sidebar/question",
                    link: '/question'
                },
                {
                    id: 7,
                    name: "Bài viết",
                    isAccess: checkPermission(PermissionConst.farmBlogView),
                    icon: "/assets/ico/sidebar/blog",
                    link: '/blog'
                }
            ]
        }
    ];

    const checkPath = () => {
        profileStore.path = getPathName();
        const names = window.location.pathname.split("/");
        profileStore.link = profileStore.roleUser === (IRole.admin || IRole.manager) ? window.location.pathname : `/${names[1]}`;
    }

    const handleBackButton = (event: any) => {
        checkPath()
        event.preventDefault();
    };

    useEffect(() => {
        checkPath()
    }, [window.location.pathname]);

    useEffect(() => {
        window.addEventListener('popstate', handleBackButton);
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const filterLandPlan = async (id: any) => {
        planStore.id = id;
        if(id){
            await planStore.getLands(id);
        }else {
            await landStore.getLands()
        }
    };

    let listLand = planStore.id ? planStore.listLandPlan : landStore.listLand

    const isToken = profileStore.roleUser || StorageService.getToken()
    const itemByRole = data.find((val: any) => val.role === profileStore.roleUser);
    let pathCms = window.location.pathname.split('/')[2]?.trim();
    const pathManager = window.location.pathname.split('/')[2]?.trim();
    const url = profileStore.roleUser === IRole.admin ? "/cms/users" : profileStore.roleUser === IRole.manager ? "/manager/information" : "/";

    if (isToken) {
        return (
            <div id="sidebar" className={`${checkExpirationTime().isShowNotification ? "calc-h-vh-48px" : ""}`}>
                <nav className={`${checkExpirationTime().isShowNotification ? "top-48px" : ""} sidebar-main`}>
                    <Link to={url} onClick={() => {
                        profileStore.link = '/';
                        profileStore.path = '/'
                    }} className="logo">
                        <img src="/assets/images/logo_v1.svg" alt=""/>
                    </Link>

                    {(profileStore.path == "map" || profileStore.path == "maps") ? <div className="filter_search_map">
                        <div className="filter_land_plan">
                            <label>{t('plan')}</label>
                            <select className="form-control"
                                    onChange={(e: any) => filterLandPlan(e.currentTarget.value)}>
                                <option value="">Tất cả</option>
                                {planStore.listPlanning && planStore.listPlanning.map((item: any, i: number) => {
                                    return (
                                        <option value={item.id} key={i}>{item.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="search_map_land">
                            <div className="list_land">
                                <ul className="land nav">
                                    {listLand && listLand.map((item, i) => {
                                        return <li key={i} className={`${item.id == landStore.id ? 'active' : ''}`}>
                                            <Link className="p-0" to={`/map/${slug(item.name)}/${item.id}`}
                                                  onClick={() => {
                                                      profileStore.path = "map"
                                                      landStore.id = item.id
                                                      landStore.isLoading = true
                                                      setTimeout(() => {
                                                          landStore.isLoading = false
                                                      }, 1)
                                                  }}>
                                                {item.name}
                                            </Link>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div> : <>

                        <ul className="nav">
                            {itemByRole && itemByRole.menu.map((item: any, key: any) => {
                                if (item.isAccess) {
                                    let navItem = "";
                                    let classActive: any = null
                                    if (item.link == profileStore.link) navItem = "nav-item active"
                                    else navItem = "nav-item"
                                    if ((item.link == '/' && profileStore.path === "dashboard") || (pathCms == "permission" && item.link == '/cms/permission') || (profileStore.path == "warehouse" && item.link == "/warehouse") || (profileStore.path === "planning-detail" && item.link == "/planning") || (profileStore.path == "procedure-detail" && item.link == "/procedure")) {
                                        navItem = "nav-item active"
                                    }
                                    if ((pathManager === 'information' && item.link === '/manager/information') || (pathManager === "product" && item.link === "/manager/product") || (pathManager === "truy-xuat" && item.link === "/manager/truy-xuat") || (pathManager === "nha-phan-phoi" && item.link === "/manager/nha-phan-phoi") || (pathManager === "bao-cao" && item.link === "/manager/bao-cao")) {
                                        navItem = "nav-item active"
                                    }
                                    if (item.submenu) {
                                        item.submenu.map((kt: any) => {
                                            if (kt.link == sidebarStore.pathSubmenu || kt.link == window.location.pathname) {
                                                classActive = 'active'
                                            }
                                        })
                                        return (
                                            <li className={`nav-item border-0`} key={key}>
                                                <div className={`position-relative sub_menu ${classActive}`}>
                                                    <i className={`material-icons-round position-absolute`}
                                                       data-toggle="collapse" data-target={`#submenu_${key}`}>
                                                        keyboard_arrow_down
                                                    </i>
                                                    <a className="nav-link" onClick={() => {
                                                        item.isActive = !item.isActive
                                                    }} data-toggle="collapse" href={`#submenu_${key}`}>
                                                        <div className="icon">
                                                            <img
                                                                src={`${item.icon}${classActive ? '_active' : ''}.svg`}
                                                                alt=""/>
                                                        </div>
                                                        <span className="menu-title">{item.name}</span>
                                                    </a>
                                                    <div className={`collapse list_item ${classActive && 'show in'}`} id={`submenu_${key}`}>
                                                        {item.submenu.map((val: any, i: number) => {
                                                            if (val.isAccess) {
                                                                return (
                                                                    <Link to={val.link} key={i}
                                                                          onClick={() => sidebarStore.pathSubmenu = val.link}
                                                                          className={`nav-link ${(sidebarStore.pathSubmenu == val.link || val.link == window.location.pathname) && 'active'}`}>
                                                                        <span className="ml">{val.name}</span>
                                                                    </Link>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    } else return (
                                        <li className={`${navItem}`} key={key}>
                                            <div className="line"/>
                                            <Link to={item.link} className="nav-link"
                                                  onClick={() => {
                                                      profileStore.link = item.link;
                                                      sidebarStore.pathSubmenu = ''
                                                  }}>
                                                <div className="icon">
                                                    <img
                                                        src={`${item.icon}${((item.link == profileStore.link) || navItem == "nav-item active") ? '_active' : ''}.svg`}
                                                        alt=""/>
                                                </div>
                                                <span className="menu-title">{item.name}</span>
                                            </Link>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </>}
                </nav>
            </div>
        )

    } else return <nav className="sidebar-main" id="sidebar"/>

}

export default observer(SideBar);


