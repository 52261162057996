import React from 'react';
import {observer} from "mobx-react";
import {accountManagerStore} from "../../AccountManagerStore";
import Input from "../../../../../common/component/form/Input";
import Select from "../../../../../common/component/form/Select";


const AddOrEditAccount = () => {

    return (
        <div className="modal fade" id="add_or_edit_account_manager" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{`${accountManagerStore.type === 'edit' ? 'Cập nhật' : 'Thêm'} tài khoản quản lý`}</h5>
                        <button type="button" className="close" id="close_add_edit" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">

                        <div className="w-100 d-flex align-items-center">
                            <div className="w-50 pr-3">
                                <div className="form-group">
                                    <label>Tên tài khoản<span className="text-danger">*</span></label>
                                    <Input value={accountManagerStore.dataRequest.name}
                                           isError={accountManagerStore.errors.name}
                                           onChange={(e: any) => accountManagerStore.dataRequest.name = e.currentTarget.value}
                                           placeholder="Nhập tên tài khoản..."/>
                                </div>
                            </div>
                            <div className="w-50 pl-3">
                                <div className="form-group">
                                    <label>Số điện thoại đăng nhập <span className="text-danger">*</span></label>
                                    <Input value={accountManagerStore.dataRequest.username}
                                           isError={accountManagerStore.errors.username}
                                           onChange={(e: any) => accountManagerStore.dataRequest.username = e.currentTarget.value}
                                           placeholder="Nhập số điện thoại..."/>
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex align-items-center">
                            <div className="w-50 pr-3">
                                <div className="form-group">
                                    <label>Mật khẩu<span className="text-danger">*</span></label>
                                    <Input value={accountManagerStore.dataRequest.password}
                                           isError={accountManagerStore.errors.password}
                                           type={accountManagerStore.showPass ? "text" : "password"}
                                           onChange={(e: any) => accountManagerStore.dataRequest.password = e.currentTarget.value}
                                           placeholder="Nhập mật khẩu..."/>
                                </div>
                            </div>
                            <div className="w-50 pl-3">
                                <div className="form-group">
                                    <label>Nhập lại mật khẩu <span className="text-danger">*</span></label>
                                    <Input value={accountManagerStore.dataRequest.confirmPass}
                                           isError={accountManagerStore.errors.confirmPass}
                                           type={accountManagerStore.showPass ? "text" : "password"}
                                           onChange={(e: any) => accountManagerStore.dataRequest.confirmPass = e.currentTarget.value}
                                           placeholder="Nhập lại mật khẩu..."/>
                                </div>
                            </div>
                        </div>
                        <div className="w-100 mb-4 d-flex justify-content-start align-items-center">
                            <input
                                type="checkbox"
                                id="checkbox"
                                className={`cursor-pointer 
                        w-4 h-4 border-3 rounded-sm`}
                                style={{ marginRight: "6px", cursor: "pointer" }}
                                checked={accountManagerStore.showPass}
                                onChange={(e) => {
                                    accountManagerStore.showPass = !accountManagerStore.showPass;
                                }}
                            />
                            <label
                                htmlFor="checkbox"
                                style={{ cursor: "pointer", marginBottom: '0px', fontSize: '12px', color: '#3f3c3c' }}
                            >
                                Hiển thị mật khẩu
                            </label>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="w-100">
                                <div className="form-group">
                                    <label>Phân loại <span className="text-danger">*</span></label>
                                    <div className="d-flex align-items-center">
                                        <div className="form-check">
                                            <input className="form-check-input ml-0" type="radio" name="radio_classify" id="center_manage" value="center_manage"  onChange={(e: any) => accountManagerStore.dataRequest.level = e.currentTarget.value} checked={accountManagerStore.dataRequest.level == "center_manage" && true}/>
                                            <label className="form-check-label" htmlFor="center_manage">Cấp TW</label>
                                        </div>
                                        <div className="form-check ml-5">
                                            <input className="form-check-input ml-0" type="radio" name="radio_classify" id="province_manage" value="province_manage" onChange={(e: any) => accountManagerStore.dataRequest.level = e.currentTarget.value} checked={accountManagerStore.dataRequest.level == "province_manage" && true}/>
                                            <label className="form-check-label" htmlFor="province_manage">Cấp Tỉnh/ Thành phố</label>
                                        </div>
                                        <div className="form-check ml-5">
                                            <input className="form-check-input ml-0" type="radio" name="radio_classify" id="district_manage" value="district_manage" onChange={(e: any) => accountManagerStore.dataRequest.level = e.currentTarget.value} checked={accountManagerStore.dataRequest.level == "district_manage" && true}/>
                                            <label className="form-check-label" htmlFor="district_manage">Cấp Huyện</label>
                                        </div>
                                    </div>
                                    <p className="error">{accountManagerStore.errors && accountManagerStore.errors.type}</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            {(accountManagerStore.dataRequest.level === "province_manage" || accountManagerStore.dataRequest.level === "district_manage")
                                && <div className="w-50 pr-3">
                                <div className="form-group">
                                    <label>Tỉnh / Thành phố <span className="text-danger">*</span></label>
                                    <Select  value={accountManagerStore.dataRequest.provinceId}
                                             onChange={(e: any) => accountManagerStore.changeProvince(e)}
                                             isError={accountManagerStore.errors.province} options={accountManagerStore.listProvince}
                                             placeholder="Chọn tỉnh thành phố"/>
                                </div>
                            </div>}
                            {(accountManagerStore.dataRequest.level === "district_manage")
                                && <div className="w-50 pl-3">
                                <div className="form-group">
                                    <label>Quận / Huyện <span className="text-danger">*</span></label>
                                    <Select value={accountManagerStore.dataRequest.districtId}
                                            onChange={(e: any) => accountManagerStore.changeDistrict(e)}
                                            isError={accountManagerStore.errors.district} options={accountManagerStore.listDistrict}
                                            placeholder="Chọn quận huyện"/>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => accountManagerStore.signUp()}
                                disabled={accountManagerStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditAccount);