import Constants from "../../common/constants/Constants";
import { getRequest, IApiResponse, patchRequest } from "../../common/helpers/RequestHelper";
import { stringParams } from "../../common/utils/Utils";


class HistoryOrderFarmService {

    public getHistoryOrderFarm(size?: any): Promise<IApiResponse> {
        let searchObject = {
            size: Constants.MAX_SIZE,
            farmId: localStorage.getItem('farmId'),
        }
        return getRequest(`/v1/package_order_transaction${stringParams(searchObject)}`)
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/package_order_transaction/${id}`)
    }

    public changeStatus(id: number | string, status: string): Promise<IApiResponse>{
        return patchRequest(`/v1/package_order_transaction/${id}/change-status-order`, {status: status, note: ''})
      }
}

export const historyOrderFarmService = new HistoryOrderFarmService();