import React from "react";
import { Select } from "antd";

export function convertDataSelectAnt(arr: any[]) {
    let arrNew: any = []
    arr && arr.map((val) => {
        arrNew.push({label: val.name ?? val.code, value: val.id})
    });

    return arrNew
}

interface IProps {
    value?: any;
    width?: any;
    onChange?: any;
    isError?: any;
    options: any[];
    className?: any;
    disabled?: boolean;
    placeholder?: string;
    multiple?: boolean;
    noTextErr?: boolean;
    isShowClear?: boolean;
    callback?: any;
    isCallback?: any;
    isLoadingReadMore?: any;
    convertDataSelectAnt?: any
}

const SelectAnt = (item: IProps) => {

    return (
        <>
            <div className="input-group" style={{ width: item.width ?? "100%", minHeight: 40 }}>
                {item.multiple ? (
                    <Select
                        mode="multiple"
                        allowClear={item.isShowClear}
                        value={item.value}
                        style={{ borderRadius: 4 }}
                        disabled={item.disabled}
                        className={`${
                            (item.isError && !item.value) ||
                            (item.isError && item.value && item.value.length < 1)
                                ? "border_error"
                                : ""
                        } ${item.value.length > 0 ? "" : "no_select__multi"} ${
                            item.className
                        }`}
                        filterOption={(input: string, option: any) =>
                            (option.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(e: any) => item.onChange(e)}
                        showSearch
                        placeholder={item.placeholder ?? "Chọn"}
                        options={item?.convertDataSelectAnt ? item?.convertDataSelectAnt(item.options) : convertDataSelectAnt(item.options)}
                    />
                ) : (
                    <Select
                        value={item.value}
                        allowClear={item.isShowClear}
                        style={{ borderRadius: 4 }}
                        disabled={item.disabled}
                        className={`${item.isError && !item.value ? "border_error" : ""} ${item.className}`}
                        onChange={(e: any) => item.onChange(e)}
                        showSearch
                        filterOption={(input: string, option: any) =>
                            (option.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        placeholder={item.placeholder ?? "Chọn"}
                        options={item?.convertDataSelectAnt ? item?.convertDataSelectAnt(item.options) : convertDataSelectAnt(item.options)}
                    />
                )}
            </div>

            {!item.noTextErr && item.isError && !item.value && (<p className="error">{item.isError}</p>)}
            {!item.noTextErr && item.isError && item.value && item.value.length < 1 && <p className="error mt-1">{item.isError}</p>}
        </>
    );
};

export default SelectAnt;
