import React from 'react';
import {observer} from "mobx-react";
import QRCode from "qrcode.react";
import {toastUtil} from "../../../../common/utils/ToastUtil";
import {number_format, urlImage} from "../../../../common/utils/Utils";
import {numberUtil} from "../../../../common/utils/NumberUtil";
import {dateUtils} from "../../../../common/utils/DateUtils";
import {trackingLivestockDetailStore} from "../TrackingLivestockDetailStore";


const QRCodeTracking = () => {

    const copyLink = () => {
        var copyText: any = document.getElementById(`path_link`);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        toastUtil.success('Bạn đã sao chép thành công!')
    }


    return (
        <div className="modal fade" id="qr_code_tracking" role="dialog" aria-hidden="true">
            <div className="modal-dialog" style={{maxWidth: trackingLivestockDetailStore.step == 1 ? 700 : 880}} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{trackingLivestockDetailStore.step == 1 ? 'Tạo link tải mã QR' : 'Mã QR mới'}</h5>
                        <button type="button" className="close" id="close_qr_code" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body d-flex justify-content-center pt-2 mb-3">
                        <div className="form d-flex justify-content-center">
                            {trackingLivestockDetailStore.step == 1 && <div className="step_1">
                                <div className="d-flex align-items-center">
                                    <div className='text-center position-relative bg-white' style={{padding: 25, marginRight: 20}}>
                                        <QRCode id="download_qrCode" level="L" value={trackingLivestockDetailStore.pageHelper} size={190}/>
                                        {trackingLivestockDetailStore.dataRequest.logo && <div
                                            className="logo_farm position-absolute d-flex align-items-center justify-content-center w-100"
                                            style={{left: 0, top: '100px'}}>
                                            <img width={36} height={36}
                                                 src={urlImage(trackingLivestockDetailStore.dataRequest.logo)} alt=""/>
                                        </div>}
                                        <p className="mt-1 mb-0"
                                           style={{fontSize: 12}}>{trackingLivestockDetailStore.dataRequest.bathCode}</p>
                                    </div>

                                    <div style={{width: 320}}>
                                        <p className="text-center mb-2" style={{fontSize: 14}}>Bạn đang có <span
                                            className="color-green font-weight-medium">{number_format(trackingLivestockDetailStore.dataRequest.countQrExported)}</span> lượt tải về
                                            ở mã này!</p>
                                        <div className="mb-2">
                                            <label style={{fontSize: 12, color: '#181818'}}>Số QR tải về <span className="text-danger">*</span></label>
                                            <input type="text"
                                                   value={trackingLivestockDetailStore.qty ? number_format(trackingLivestockDetailStore.qty) : ''}
                                                   onChange={(e: any) => trackingLivestockDetailStore.qty = numberUtil.regexNumber(e)}
                                                   className={`w-100 ${((!trackingLivestockDetailStore.qty && trackingLivestockDetailStore.errors.qty) || trackingLivestockDetailStore.qty > 10000) ? 'border_error' : ''}`}
                                                   placeholder="Tối đa 10.000 mã qr"
                                                   style={{height: 40, border: `1px solid #181818`, fontSize: 12,  padding: `0 12px`}}
                                            />
                                        </div>
                                        <div className="d-flex mt-4 justify-content-center">
                                            <button className="btn send-request w-100" disabled={trackingLivestockDetailStore.isLoadingBt}
                                                    onClick={() => trackingLivestockDetailStore.addLinkDownloadQr()}
                                                    style={{height: 40, fontSize: 14}}>{trackingLivestockDetailStore.isLoadingBt ? <i className="fa fa-spinner text-white fa-spin" /> : 'Tạo link tải mã'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {trackingLivestockDetailStore.step === 2 && <div className="step_2">
                                <p className="text-center mb-1" style={{fontSize: 16}}>Bạn đang xuất <span
                                    className="color-green font-weight-medium">{number_format(trackingLivestockDetailStore.dataRequest.countQrExported)}</span> lượt
                                    ở mã này!</p>
                                <div className="link_download_qr">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span
                                            style={{fontSize: 12}}>{dateUtils.formatDate(trackingLivestockDetailStore.dataRequest.createdAt, 'DD/MM/YYYY HH:mm')}</span>
                                        <div className="color-green"><span
                                            className="font-weight-medium">{number_format(trackingLivestockDetailStore.qty)}</span> Mã
                                            QR
                                        </div>
                                    </div>
                                    <div className="d-flex action_ mt-2 align-items-center justify-content-center">
                                        <input type="text" id="path_link" onChange={() => {}}
                                               value={`${process.env.REACT_APP_API_DOMAIN}/farm/v1/data/tracking_batch_data/${trackingLivestockDetailStore.dataRequest.id}${trackingLivestockDetailStore.dataRequest.qrFile}`}/>
                                        <button title="Sao chép" onClick={() => copyLink()}>
                                            <img src="/assets/ico/ico_copy.png" alt=""/>
                                        </button>
                                        <button title="Tải mã" disabled={trackingLivestockDetailStore.isLoadingBt} onClick={async () => await trackingLivestockDetailStore.downloadQR(trackingLivestockDetailStore.dataRequest.id, trackingLivestockDetailStore.dataRequest.qrFile)}>
                                            <img src="/assets/ico/ico_download.png" alt=""/>
                                        </button>
                                        <button title={'In mã qr'} data-dismiss="modal" data-toggle="modal" data-target="#qr_print_code">
                                            <img src="/assets/ico/ico_printer.png" alt=""/>
                                        </button>
                                    </div>
                                </div>

                                <span className="mt-2 mb-4 font-italic" style={{
                                    color: 'red',
                                    fontSize: 14
                                }}>Chú ý*: Đường link tải về tồn tại trong 24h</span>
                                <div className="d-flex mt-3 justify-content-end">
                                    <button className="btn send-request-outline mr-4" style={{width: 189, height: 42}}
                                            onClick={() => trackingLivestockDetailStore.clearDownloadQR()}>Tạo mã mới
                                    </button>
                                    <button className="btn send-request"
                                            onClick={async () => await trackingLivestockDetailStore.getHistoryQr(trackingLivestockDetailStore.dataRequest.id)}
                                            data-dismiss="modal" data-toggle="modal" data-target="#history_qr"
                                            style={{width: 189, height: 42}}>Lịch sử
                                    </button>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(QRCodeTracking);