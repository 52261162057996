import React, { useEffect, useState } from 'react'
import "../UpgradePackage.scss";
import { convertPrice } from '../../../common/utils/Utils';
import { TIMES, TYPE_PACKAGE } from '../UpgradePackageConstant';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IPackagePrice } from '../InterfaceUpgradePackage';
import TextOverflowEllipsis from '../../../common/component/TextOverflowEllipsis';
import { upgradePackageStore } from '../UpgradePackageStore';

function ItemPackage({ item, key }: any) {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [selectTypePackage, setSelectTypePackage] = useState<IPackagePrice | null>(null);
    let { exportQRProduct, exportQrBatchUnlimited } = upgradePackageStore.farmPackageBuyInfo.packageFarm?.packageData || {}

    useEffect(() => {
        if (item?.packagePrices?.length > 0) {
            setSelectTypePackage(item?.packagePrices.find((element: IPackagePrice) => element?.isSelect))
        }
    }, [item?.packagePrices])

    return (
        <>
            <div key={key} className={`${item?.isUsing && !item?.isRecommended ? "item-recommended item-using" : item?.isRecommended ? "item-recommended" : ""} item-package`}>
                <div className='px-16px py-12px'>
                    <div>
                        <div className='d-flex align-items-center pb-8px'>
                            <TextOverflowEllipsis className='fs-24px m-0 pr-6px fw-600' value={item?.name} />
                            {
                                item?.isUsing
                                    ? <p className='recommended background-00953D color-ffffff'>{t("using")}</p>
                                    : item?.isRecommended && <p className='recommended'>{t("recommended")}</p>
                            }
                        </div>
                        <p className='d-flex d-flex align-item-end m-0 text-overflow-ellipsis'>
                            {
                                item?.type === TYPE_PACKAGE.TRIAL
                                    ? <div className='h-26px'>
                                        <h2 className="fs-24px m-0 color-FF3030">Miễn Phí</h2>
                                        <span className='fs-18px pl-8px mt-2px fw-600'>{!selectTypePackage ? "7 ngày" : `${selectTypePackage?.validityPeriod} ${TIMES.find(time => time?.type === selectTypePackage?.timeUnit)?.name || ""}`}</span>
                                    </div>
                                    : <div className='d-flex align-items-center h-26px'>
                                        <TextOverflowEllipsis className='m-0 fs-24px d-inline-block fw-600 max-w-200px' value={convertPrice(selectTypePackage?.price) || 0} />
                                        <span className='fs-18px pl-8px mt-2px fw-600'>VNĐ</span>
                                    </div>
                            }
                        </p>
                    </div>

                    <div className='type-package d-flex gap-3px mt-8px mb-7px min-h-23px overflow-x'>
                        {
                            item?.packagePrices?.map((packagePrice: IPackagePrice, index: number) => {
                                return item?.type !== TYPE_PACKAGE.TRIAL && index < 4 ? (
                                    <div
                                        className={`
                                            fs-11px px-12px py-4px bg-D7D7D7 rounded-3px fw-500 cursor_pointer min-w-50px flex-1 color-000000 d-flex justify-content-center align-items-center max-w-61px
                                            ${packagePrice?.isSelect ? "bg-F97414 color-ffffff" : ""}
                                        `}
                                        onClick={() => {
                                            item?.packagePrices.forEach((element: IPackagePrice) => {
                                                element.isSelect = element?.id === packagePrice?.id ? true : false
                                                element?.isSelect && setSelectTypePackage(element);
                                            });
                                        }}
                                    >
                                        {`
                                        ${packagePrice?.validityPeriod} 
                                        ${TIMES.find(item => item.type === packagePrice.timeUnit)?.name}
                                    `}
                                    </div>
                                ) : ""
                            })
                        }
                    </div>
                    <hr className='my-12px bg-061020' />
                    <div className={`${upgradePackageStore.isOpenHistory && !(!exportQRProduct && exportQrBatchUnlimited) ? "min-h-140px calc-max-h-vh-670px overflow-y" : ""} mb-12px gap-8px d-flex flex-column`}>
                        <div className='d-flex align-items-center'>
                            <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                            <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.feature")}</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            {(item?.unlimitedUser || item?.limitUser)
                                ? <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                : <img src={`/assets/ico/ico_close_cricle.svg`} alt="" width={16} className='pt-1px' />}
                            {
                                item?.unlimitedUser
                                    ? <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.unlimitedUser")}</span>
                                    : <span className='pl-8px fs-14px text-overflow-ellipsis'>{`${item?.limitUser || 0} ${t("packageDescriptions.limitUser")}`}</span>
                            }
                        </div>
                        <div className='d-flex align-items-center'>
                            {item?.exportQRProduct
                                ? <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                : <img src={`/assets/ico/ico_close_cricle.svg`} alt="" width={16} className='pt-1px' />}
                            <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.exportQRProduct")}</span>
                        </div>
                        {
                            item?.exportQRProduct &&
                            <div className='d-flex align-items-center'>
                                <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                <span className='pl-8px fs-14px text-overflow-ellipsis'>{`Tặng kèm ${item?.giftQRCodeExportProduct?.qrCodeLimit || 0} mã QR theo sản phẩm`}</span>
                            </div>
                        }
                        <div className='d-flex align-items-center'>
                            {item?.exportQrBatch
                                ? <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                : <img src={`/assets/ico/ico_close_cricle.svg`} alt="" width={16} className='pt-1px' />}
                            <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.exportQrBatch")}</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            {item?.exportQrBatchUnlimited || (!item?.exportQrBatchUnlimited && item?.giftQRCodeExportBatch?.qrCodeLimit)
                                ? <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                : <img src={`/assets/ico/ico_close_cricle.svg`} alt="" width={16} className='pt-1px' />}
                            {!item?.exportQrBatchUnlimited && item?.giftQRCodeExportBatch?.qrCodeLimit
                                ? <span className='pl-8px fs-14px text-overflow-ellipsis'>{`Tặng kèm ${item?.giftQRCodeExportBatch?.qrCodeLimit || 0} mã QR theo lô`}</span>
                                : <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.exportQrBatchUnlimited")}</span>}
                        </div>
                        <div className='d-flex align-items-center'>
                            {item?.exportExcel
                                ? <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                : <img src={`/assets/ico/ico_close_cricle.svg`} alt="" width={16} className='pt-1px' />}
                            <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.exportExcel")}</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            {item?.fraudAlert
                                ? <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                : <img src={`/assets/ico/ico_close_cricle.svg`} alt="" width={16} className='pt-1px' />}
                            <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.fraudAlert")}</span>
                        </div>
                    </div>

                    <div className='pb-2px pt-2px fs-18px'>
                        {
                            item?.type !== TYPE_PACKAGE.TRIAL
                                ? <button
                                    className={`${item?.isUsing ? "bg-47bd79 hover-bg-047934" : ""} button btn-confirm py-16px w-100 fs-18px fw-500`}
                                    data-dismiss="modal"
                                    onClick={() => navigate(`/buy-package/${item?.id}`, {
                                        state: { selectTypePackage: JSON.stringify(selectTypePackage || null) }
                                    })}
                                >
                                    {item?.isUsing ? "Gia hạn ngay" : t("buyNow")}
                                </button>
                                : <button
                                    className='button btn-cancel py-16px w-100 fs-18px fw-500 text-white bg-D7D7D7'
                                    disabled={true}
                                >
                                    {t("trial")}
                                </button>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}

export default observer(ItemPackage)