import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {IStatus, userStore} from "./UserStore";
import Loading from "../../../common/component/Loading";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import PopupConfirmCms from "./components/PopupConfirmCms";
import {dateUtils} from "../../../common/utils/DateUtils";
import FilterComponent from "../../../common/component/filter/FilterComponent";


const UserActiveComponent = () => {

    useEffect(() => {
        userStore.searchStatus = IStatus.ACTIVE
        userStore.getUsers()
        return () => {
            userStore.keyword = ''
        }
    }, [])

    const changePage = async (data: any) => {
        userStore.page = data.selected;
        await userStore.getUsers();
    }

    return (
        <div>
            <FilterComponent data={userStore.dataFilter}/>
            {userStore.isLoading ? <Loading/> : <div className='table-responsive'>
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">STT</th>
                        <th>Họ và tên</th>
                        <th className="text-center">ID đăng nhập</th>
                        <th>Tên đơn vị</th>
                        <th className="text-center">Loại hình</th>
                        <th>Địa chỉ</th>
                        <th className="text-center">Ngày tạo</th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>

                    {userStore.listUser && userStore.listUser.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td>{item.representativeName}</td>
                                <td className="text-center">{item.phone}</td>
                                <td >{item.name}</td>
                                <td className="text-center">{item.businessDescription}</td>
                                <td>{item.address}</td>
                                <td className="text-center">{dateUtils.formatDate(item.createdAt)}</td>
                                <td className="text-center">
                                    <button className="bt-outline-action mr-2" data-toggle="modal"
                                            data-target="#popup_confirm"
                                            onClick={() => {
                                                userStore.userId = item.id;
                                                userStore.status = IStatus.RESET
                                            }}>Reset
                                    </button>

                                    <button className="bt-action-delete" data-toggle="modal"
                                            data-target="#popup_confirm"
                                            onClick={() => {
                                                userStore.userId = item.id;
                                                userStore.status = IStatus.BLOCK
                                            }}>Khóa
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Paginate currentPage={userStore.page} totalPage={userStore.totalPage}
                              changePage={changePage}/>
                </div>
            </div>}
            {userStore.listUser && userStore.listUser.length < 1 && <NoContent/>}

            <PopupConfirmCms/>
        </div>
    );
}

export default observer(UserActiveComponent);