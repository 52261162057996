import {IApiResponse, IBodyError} from "./RequestHelper";
import StorageService from "../service/StorageService";
import HttpStatusCode from "../constants/HttpErrorCode";
import {toastUtil} from "../utils/ToastUtil";
import axios from "axios";
import humps from "humps";


export async function locateHelper(_params: any): Promise<IApiResponse> {
    var newHeaders: any = {
        'Content-Type': 'application/json',
    };

    return await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${_params}i&key=AIzaSyCn8eV4OJCGnktvuqI5DmfXqb-g1xn6LVk&components=country:VN`, {headers: newHeaders})
        .then(
            (response) => {
                const apiResponse: IApiResponse = {
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                };
                return apiResponse;
            },
            (error) => {
                if (error.response && error.response.status === HttpStatusCode.UNAUTHORIZED) {
                    toastUtil.error('Phiên đăng nhập hết hạn', 2);
                    StorageService.removeToken();
                }else if(error.response &&  error.response.status === HttpStatusCode.FORBIDDEN){
                    toastUtil.error('Không có quyền truy cập', 2);
                }

                let bodyError: IBodyError;
                try {
                    bodyError = {
                        errorCode: error.response.errorCode,
                        message: error.response.message
                    }
                } catch (e) {
                    bodyError = {
                        errorCode: HttpStatusCode.UNKNOW_ERROR,
                        message: "Unknow error, please try again later"
                    }
                }

                const apiResponse: IApiResponse = {
                    status: error.response.status,
                    body: bodyError
                };
                return apiResponse;
            },
        )
}
