import React from 'react';
import {laborStore} from "../LaborStore";
import {observer} from "mobx-react";

const ModalConfirm = () => {

    return (
        <div className="modal fade" id={'modal_confirm'} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" id="close_confirm" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center icon">
                        {laborStore.dataRequest.status == "BLOCK" ? <img src="/assets/images/unlock.svg" alt=""/> : <img src="/assets/images/lock.svg" alt=""/>}
                    </div>
                    <h2 className="text-center"> {laborStore.dataRequest.status == "BLOCK" ? 'Mở khóa tài khoản' : 'Khóa tài khoản'}</h2>
                    <p className="text-center">Chủ tài khoản sẽ {laborStore.dataRequest.status == "BLOCK" ? 'trở lại hoạt động bình thường' : 'không đăng nhập vào được'}<br/> farm bạn đang quản lý.</p>
                    <div className="d-flex action align-items-center justify-content-center">
                        <button className="btn btn-cancel" data-dismiss="modal">Hủy</button>
                        <button className={`btn ${laborStore.dataRequest.status == "BLOCK" ? 'btn-confirm' : 'bt_remove'}`} onClick={() => laborStore.changeStatus()} disabled={laborStore.isLoadingBt}>Xác nhận</button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default observer(ModalConfirm);