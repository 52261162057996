import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import AddOrEditQuestion from "./components/AddOrEditQuestion";
import ButtonAdd from "../../common/component/ButtonAdd";
import {ITypeAnswer, questionStore} from "./QuestionStore";
import {Link} from "react-router-dom";
import {Icon} from "../../common/component/Icon";
import "./QuestionStyle.scss"
import {checkPermission, getIDParameter, number_format, urlImage, urlImageSSO} from "../../common/utils/Utils";
import {dateUtils} from "../../common/utils/DateUtils";
import {profileStore} from "../profile/ProfileStore";
import FormDisease from "./components/FormDisease";
import FormOther from "./components/FormOther";
import FormTechnique from "./components/FormTechnique";
import PopupConfirm from "../../common/component/PopupConfirm";
import SendComment from "./components/SendComment";
import {medicineStore} from "../setup/medicine/MedicineStore";
import {fertilizerStore} from "../setup/fertilizer/FertilizerStore";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import PopupZoom from "../../common/component/PopupZoom";
import ListComment from "./components/ListComment";
import {PermissionConst} from "../../common/constants/Constants";


const QuestionDetail = () => {

    const callApi = async () => {
        await questionStore.getDetailQuestion(questionStore.id)
        if (questionStore.dataDetail.isAnswered) {
            await questionStore.getAnswer()
        }
        await questionStore.getComments()
        await questionStore.getTopic()
        await  medicineStore.getMedicine(100, true)
        await  fertilizerStore.getFertilizer(100, true)
    }

    useEffect(() => {
        questionStore.id = getIDParameter()
        callApi()
        return () => {
            questionStore.clearForm()
            questionStore.clearAnswer()
        }
    }, [])

    const returnClassName = (answerType?: any) => {
        switch (answerType ?? questionStore.dataDetail.postTopic.answerType) {
            case ITypeAnswer.other:
                return 'form_other'
            case ITypeAnswer.disease:
                return 'form_disease'
            case ITypeAnswer.technique:
                return 'form_technique'
        }
    }

    let {postTopic, user, name, content, attachFiles, createdAt, updatedAt, countComment} = questionStore.dataDetail


    return (
        <div className="detail_question">
            <div className="header_top d-flex align-items-center justify-content-between">
                <Link to={'/question'} className="back">
                    <Icon name="arrow_back" type={"outlined"}/>
                    Quay lại
                </Link>
                <div className="bt_add">
                    <ButtonAdd text="Thêm câu hỏi" dataTarget={'add_or_edit_question'}
                               onClick={questionStore.clearForm}/>
                </div>
            </div>
            <>
                <div className="breadcrumb">
                    <img src="/assets/ico/lightbulb.svg" alt=""/>
                    <Link to={'/question'}>Câu hỏi</Link>
                    <Icon name="chevron_right" type="outlined"/>
                    <Link to={'#'}>{postTopic?.name}</Link>
                </div>

                {questionStore.dataErrors.status === HttpStatusCode.NOTFOUND ?
                    <div className="no_content text-center">
                        <img src="/assets/ico/404.svg" alt=""/>
                        <p>{questionStore.dataErrors.message}</p>
                    </div> : <>
                        <div className="question d-flex position-relative">
                            <div className="info_user">
                                <div className={`${user?.avatar ? '' : 'no_'}avatar`}>
                                    {user?.avatar ? <img src={urlImageSSO(user?.avatar)} alt=""/> :
                                        <Icon name={'person'} type="round"/>}
                                </div>
                                <div className="role">{user?.role?.name}</div>
                            </div>
                            <div className="content">
                                <h4 className="title">{name}</h4>
                                {attachFiles && attachFiles.length > 0 && <div className="images d-flex">
                                    <div className="image cursor_pointer" onClick={() => {
                                        questionStore.listImages = attachFiles
                                    }} data-toggle="modal" data-target="#popupZoom">
                                        <img src={urlImage(attachFiles[0])} alt=""/>
                                    </div>
                                    <div className="list_img">
                                        {attachFiles && attachFiles.map((val: any, i: number) => {
                                            if (i > 0 && i < 5) {
                                                return <div className="img_ cursor_pointer"
                                                            onClick={() => {
                                                                questionStore.listImages = attachFiles
                                                            }}
                                                            data-toggle="modal" data-target="#popupZoom">
                                                    <img src={urlImage(val)} alt=""/>
                                                </div>
                                            }
                                        })}
                                    </div>
                                </div>}
                                <p className="description">{content}</p>
                                <div className="d-flex align-items-center justify-content-between">
                            <span className="count_comment">
                                {number_format(countComment)} Trả lời
                            </span>
                                    <div className="d-flex">
                                        <span className="time mr-4">{user?.name}</span>
                                        {createdAt != updatedAt && <span className="time mr-4">Đã chỉnh sửa</span>}
                                        <span
                                            className="time">{dateUtils.formatDate(updatedAt, 'DD/MM/YYYY hh:mm A')}</span>
                                    </div>
                                </div>
                            </div>
                            {(checkPermission(PermissionConst.answerQuestion) || (profileStore.dataFarm?.userId == user?.id)) &&
                            <div className="action">
                                <div className="dropdown">
                                    <div id="actionQuestion" data-toggle="dropdown" aria-haspopup="true"
                                         aria-expanded="false">
                                        <Icon name="more_horiz" type="outlined"/>
                                    </div>
                                    <div className="dropdown-menu" aria-labelledby="actionQuestion">
                                        {profileStore.dataFarm?.userId == user?.id &&
                                        <a className="dropdown-item" href="#" data-toggle="modal"
                                           data-target="#add_or_edit_question"
                                           onClick={() => questionStore.getDetailQuestion(questionStore.id)}><img
                                            src="/assets/ico/action/ico_edit.svg" alt=""/> Sửa</a>}
                                        {checkPermission(PermissionConst.answerQuestion) &&
                                        <a className="dropdown-item" href="#" data-toggle="modal"
                                           data-target={`#add_or_edit_${returnClassName()}_question`}>
                                            <img src="/assets/ico/action/ico_report.svg" alt=""/> Giải đáp</a>}
                                        {/*<button className="dropdown-item text-danger" data-toggle="modal"*/}
                                        {/*        data-target="#popup_confirm"><img*/}
                                        {/*    src="/assets/ico/action/ico_trash.svg"*/}
                                        {/*    alt=""/> Xóa*/}
                                        {/*</button>*/}
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="comments">
                            {checkPermission(PermissionConst.answerQuestion) && <SendComment/>}
                            <ListComment/>
                        </div>
                        <AddOrEditQuestion/>
                        {(questionStore.listMedicine && questionStore.listMedicine.length > 0 && questionStore.listFertilizer && questionStore.listFertilizer.length > 0) &&
                        <FormDisease/>}
                        <FormOther/>
                        <FormTechnique/>
                        <PopupConfirm id="popup_confirm_comment" isLoading={questionStore.isLoadingBt}
                                      action={questionStore.deleteComment}/>
                        <PopupConfirm isLoading={questionStore.isLoadingBt} action={questionStore.deleteQuestion}/>
                    </>}
            </>


            <PopupZoom listImg={questionStore.listImages} id="popupZoom_1" idCarousel="carouselZoomImg2"/>

            <PopupZoom listImg={attachFiles}/>

        </div>
    )

}

export default observer(QuestionDetail);