import React from 'react'
import QRCode from "qrcode.react";
import {profileStore} from "../../profile/ProfileStore";
import {Link} from "react-router-dom";


const Header = () => {

    return(
        <div className="container position-relative d-flex justify-content-between">
            <p style={{fontStyle: 'italic'}}>Bộ tiêu chí truy xuất nguồn gốc dựa theo Tiêu chuẩn truy xuất nguồn gốc
                TCVN 12850:2019, TCVN 12827-2019 <br/> và Tiêu chuẩn VietGAP (TCVN 11892-1:2017). <br/>Được thực
                hiện bởi <Link to={'/'}>omfarm.com.vn</Link></p>
            <div className="position-relative mr-2 mr-lg-5">
                <button className="btn btn_print mb-2 mb-lg-4" onClick={window.print}>Print</button>
                {profileStore.path !== 'xem-ket-qua-truy-xuat' && <div className="position-absolute d-none d-lg-block" style={{right: 0}}>
                    <QRCode id="download_qrCode" level="L" value={window.location.href} size={150}/>
                </div>}
            </div>
        </div>
    )
}

export default Header