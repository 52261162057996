import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {roleStore} from "./RoleStore";
import {Checkbox} from "antd";

const RoleDefault = () => {

    useEffect(() => {
        roleStore.getListPermission().then(() => {
            roleStore.getRoleDefault().then()
        })
    }, [])


    return (
        <div className="update_role pl-5 pr-5 pt-4">
            <div className="title mb-5">
                <h4>Quyền mặc định</h4>
            </div>
            <div className="pl-4 pr-4">
                <div className="form-group h-auto mb-4">
                    <p>Phân quyền cho chức vụ <span className="text-danger">*</span></p>
                    <div className="d-flex mt-4 align-items-center flex-wrap">
                        {roleStore.listPermission && roleStore.listPermission.map((item, i) => {
                            return <div key={i} className="w-25 d-flex align-items-center mb-3 pl-4 pr-4">
                                <Checkbox checked={item.isActive}
                                          onChange={(e: any) => {
                                              item.isActive = e.target.checked
                                              if(e.target.checked){
                                                  roleStore.dataRequest.value && roleStore.dataRequest.value.push(item.code)
                                              }else {
                                                  roleStore.dataRequest.value && roleStore.dataRequest.value.map((value: any, i: number) => {
                                                      if (value == item.code) {
                                                          roleStore.dataRequest.value.splice(i, 1)
                                                      }
                                                  })
                                              }
                                          }}>{item.name}</Checkbox>
                            </div>

                        })}
                    </div>
                </div>
                <button className="send-request mt-5" onClick={() => roleStore.updateRoleDefault()}
                        disabled={roleStore.isLoadingBt}>Cập nhật
                </button>
            </div>
        </div>
    )
}

export default observer(RoleDefault);