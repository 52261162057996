import React from 'react';
import Select from "react-select";
import {observer} from "mobx-react";

interface IProps {
    value?: any,
    options: {
        label: any,
        value: any
    }[],
    disabled?: boolean,
    noOptionsMessage?: string,
    placeholder?: string,
    callback: any,
}


const SelectMulti = ({value, options, disabled, noOptionsMessage, placeholder, callback}: IProps) => {

    const changeSelect = (data: any) => {
       callback && callback(data)
    }

    return (
        <Select
            value={value} onChange={(data: any) => changeSelect(data)}
            isMulti
            isClearable
            isSearchable
            isDisabled={disabled}
            options={options}
            className="basic-multi-select"
            menuPortalTarget={document.body}
            placeholder={placeholder}
            styles={{ menuPortal: (base: any, props: any) => ({ ...base, zIndex: 9999,  accentColor: props.data?.accentColor,}) }}
            noOptionsMessage={() => noOptionsMessage ?? "Không có dữ liệu"}
        />
    );
}

export default observer(SelectMulti);