import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {signUpStore} from "./SignUpStore"
import "./SignUpStyle.scss"
import {Navigate} from "react-router-dom";
import {profileStore} from "../../profile/ProfileStore";
import StorageService from "../../../common/service/StorageService";
import Input from "../../../common/component/form/Input";
import Select from "../../../common/component/form/Select";
import {Icon} from "../../../common/component/Icon";
import {useTranslation} from "react-i18next";


const SignUpComponent = observer(() => {
    const {t} = useTranslation();

    useEffect(() => {
        if (signUpStore.step == 2) {
            signUpStore.getProvinces().then()
        }
        return () => {
            signUpStore.clearForm()
        };
    }, [signUpStore.step])


    if (StorageService.getToken() && profileStore.dataFarm.farmId && profileStore.dataFarm.status === 'ACTIVE') {
        return <Navigate to={'/dashboard'} replace/>
    } else return (
        <div className="signUp_main">
            <div className="row flex-grow">
                <div className="col-6">
                    <img src="/assets/images/logo.webp" className="w-100 vh-100" style={{objectFit: 'cover'}}
                         alt=""/>
                </div>
                <div className="col-6">
                    <div className="d-flex pr-4 pt-3 pb-4 w-100 justify-content-end">
                        <span>{t('text1')} <span
                            className='color-green font-weight-medium'>{profileStore.dataFarm.name}</span>!</span>
                        <a className="ml-4 d-flex align-items-center"
                           href={`${process.env.REACT_APP_DOMAIN_SSO}/login?redirectTo=${window.location.origin}&service=farm${localStorage.getItem('language') ? `&lng=${localStorage.getItem('language')}` : ''}${profileStore.path === "cms" ? `&cms=true` : ''}`}
                           onClick={() => {
                               localStorage.clear()
                               StorageService.removeToken();
                               StorageService.removeRefreshToken()
                           }}>
                            <img className="mr-2" src="/assets/ico/ico_logout.svg" alt=""/>
                            {t('logout')}
                        </a>
                    </div>
                    {profileStore.dataFarm.status == 'BLOCK' ?
                        <div className="d-flex justify-content-center h-75 align-items-center">
                            <div className="form_signUp pl-5 ml-5 bg-white">
                                <div className="logo_login"
                                     style={{marginBottom: 29}}>
                                    <img src="/assets/images/logo_login.svg" alt=""/>
                                </div>
                                <h1 style={{color: '#E60A32'}}>{t('text2')}</h1>
                                <span className="text_des">{t('text3')}</span>
                                <div className="footer_action d-flex align-items-center">
                                    <button type="button" className="bt-send-request"
                                            onClick={() => signUpStore.sendToken()} disabled={signUpStore.isLoading}>
                                        {signUpStore.isLoading ? <i className="fa fa-spinner fa-spin"/> : t('loadPage')}
                                    </button>
                                </div>
                            </div>
                        </div> :
                        <>
                            {signUpStore.step == 1 &&
                            <div className="d-flex justify-content-center h-75 align-items-center">
                                <div className="form_signUp pl-5 bg-white">
                                    <div className="logo_login"
                                         style={{marginBottom: 29}}>
                                        <img src="/assets/images/logo_login.svg" alt=""/>
                                    </div>
                                    <h1>{t('text4')}</h1>
                                    <span className="text_des">{t('text5')}</span>
                                    <div className="footer_action d-flex align-items-center">
                                        <button type="button" style={{width: 220, height: 46}}
                                                className="send-request-outline mr-3"
                                                onClick={() => signUpStore.step = 2}>
                                            {localStorage.getItem('language') == "en" ? "SignUp Farm New" : "Đăng Ký Farm Mới"}
                                        </button>
                                        <button type="button" className="bt-send-request ml-3"
                                                onClick={() => signUpStore.sendToken()}
                                                disabled={signUpStore.isLoading}>
                                            {signUpStore.isLoading ?
                                                <i className="fa fa-spinner fa-spin"/> : t('loadPage')}
                                        </button>
                                    </div>
                                </div>
                            </div>}
                            {signUpStore.step == 2 && <div className="d-flex justify-content-center align-items-center">
                                <div className="form_signUp bg-white">
                                    <div className="logo_login d-flex justify-content-center"
                                         style={{marginBottom: 35}}>
                                        <img src="/assets/images/logo_login.svg" alt=""/>
                                    </div>
                                    <form className="step_2">
                                        <div className="_back mb-3 w-auto d-flex justify-content-start cursor_pointer"
                                             onClick={() => signUpStore.step = 1}>
                                            <Icon name="arrow_back" className="mr-2 font-weight-medium color-green"
                                                  type="outlined"/>
                                            <span className="font-weight-medium"> {t('back')}</span>
                                        </div>
                                        <div className="d-flex mt-2 align-items-center">
                                            <div className="form-group w-50 pr-3">
                                                <label>{t('unitName')} <span
                                                    className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <input type="text" value={signUpStore.dataRequest.name}
                                                           onChange={(e: any) => {
                                                               if (e.currentTarget.value.length > 0) {
                                                                   signUpStore.errors.farm_name = ""
                                                               } else {
                                                                   signUpStore.errors.farm_name = "Tên đơn vị không được để trống!"
                                                               }
                                                               signUpStore.dataRequest.name = e.currentTarget.value
                                                           }}
                                                           className={`form-control form-control-lg ${signUpStore.errors.farm_name ? 'border_error' : ''}`}
                                                           placeholder="Nhập tên đơn vị"/>
                                                </div>
                                                <p className="error">{signUpStore.errors && signUpStore.errors.farm_name}</p>
                                            </div>
                                            <div className="form-group w-50 pl-3">
                                                <label>{t('businessSymbol')} (Tối đa 5 ký tự) <span
                                                    className="text-danger">*</span></label>
                                                <Input value={signUpStore.dataRequest.businessShortName}
                                                       onChange={(e: any) => {
                                                           if (e.currentTarget.value.length <= 5) {
                                                               signUpStore.dataRequest.businessShortName = e.currentTarget.value
                                                           }
                                                       }} isError={signUpStore.errors.businessShortName}
                                                       placeholder="Nhập ký hiệu doanh nghiệp"/>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mt-2">
                                            <div className="form-group w-50 pr-3">
                                                <label>{t('businessCode')} (Từ 5 đến 20 ký tự) <span
                                                    className="text-danger">*</span></label>
                                                <Input value={signUpStore.dataRequest.businessCode}
                                                       className={signUpStore.dataRequest.businessCode && signUpStore.dataRequest.businessCode.length < 5 ? 'border_error' : ''}
                                                       onChange={(e: any) => {
                                                           if (e.currentTarget.value.length <= 20) {
                                                               signUpStore.dataRequest.businessCode = e.currentTarget.value
                                                           }
                                                       }}
                                                       isError={signUpStore.errors.businessCode}
                                                       placeholder="Nhập số đăng ký kinh doanh"/>
                                                {signUpStore.dataRequest.businessCode && signUpStore.dataRequest.businessCode.length < 5 &&
                                                <p className="error">Tối thiểu từ 5 đến 20 ký tự</p>}
                                            </div>
                                            <div className="form-group w-50 pl-3">
                                                <label>{t('type')} <span className="text-danger">*</span></label>
                                                <Select value={signUpStore.dataRequest.type}
                                                        onChange={(e: any) => signUpStore.dataRequest.type = e}
                                                        isError={signUpStore.errors.type} options={signUpStore.listType}
                                                        placeholder="Chọn loại hình"/>
                                            </div>
                                        </div>
                                        <div className="h-auto">
                                            <label>{t('address')} <span className="text-danger">*</span></label>
                                            <div className="d-flex align-items-center">
                                                <div className="w-50 pr-3">
                                                    <div className="form-group h-auto">
                                                        <Select isShowClear={true} isError={signUpStore.errors.province} value={signUpStore.dataRequest.provinceId} placeholder="Tỉnh / Thành Phố" options={signUpStore.listProvince}
                                                            onChange={(e: any) => signUpStore.changeProvince(e)}/>
                                                    </div>
                                                </div>
                                                <div className="w-50 pl-3">
                                                    <div className="form-group h-auto">
                                                        <Select isShowClear={true} isError={signUpStore.errors.district}
                                                            value={signUpStore.dataRequest.districtId} placeholder="Quận / Huyện" options={signUpStore.listDistrict}
                                                            onChange={(e: any) => signUpStore.changeDistrict(e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group h-auto">
                                                <Select isError={signUpStore.errors.ward} isShowClear={true}
                                                        value={signUpStore.dataRequest.wardId} placeholder="Phường / Xã" options={signUpStore.listWard}
                                                        onChange={(e: any) => signUpStore.dataRequest.wardId = e}/>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>{t('specificAddress')}</label>
                                            <div className="input-group">
                                                <input type="text" value={signUpStore.dataRequest.address}
                                                       onChange={(e: any) => signUpStore.dataRequest.address = e.currentTarget.value}
                                                       className="form-control form-control-lg"
                                                       placeholder="Nhập số nhà cụ thể"/>
                                            </div>
                                        </div>

                                        <div
                                            className="footer_action mt-0 d-flex align-items-center justify-content-end">
                                            <button type="button" className="btn bt-send-request"
                                                    onClick={() => signUpStore.addFarm()}
                                                    disabled={signUpStore.isLoading}
                                                    style={{marginTop: 16}}>
                                                {signUpStore.isLoading ?
                                                    <i className="fa fa-spinner fa-spin"/> : t('continue')}
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>}
                        </>}
                </div>
            </div>
        </div>
    );
})

export default SignUpComponent;