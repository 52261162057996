import {observable} from "mobx";

import {IFilter} from "../../../common/component/filter/FilterComponent";
import {managerInformationService} from "./ManagerInformationService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {accountService} from "../../auth/AccountService";



class ManagerInformationStore {
    @observable id: any = '';
    @observable idAccountManager: any = '';
    @observable roleManager: any = '';
    @observable searchName: any = '';
    @observable provinceId: any = '';
    @observable districtId: any = '';
    @observable page: number = 0;
    @observable totalPage: number = 5;
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable dataFilter: IFilter[] = []
    @observable public listProvince: any[] = []
    @observable public listDistrict: any[] = []
    @observable listFarm: any[] = []
    @observable dataFarmById: any = {}
    @observable dataAccountManagerById: any = {}
    @observable listProduct: any[] = []
    @observable listTracking: any[] = []
    @observable listProductTracking: any[] = []
    @observable listDetailTrackingById: any[] = []
    @observable listDistribution: any[] = []
    @observable detailPlanTracking: any = {};

    async getInformation() {
        this.isLoading = true
        const result = await managerInformationService.getFarm()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listFarm = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }


    async getDetailInformationById(id: any) {
        const result = await managerInformationService.getDetailFarm(id)
        if (result.status === HttpStatusCode.OK) {
                this.dataFarmById = result.body
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
    async getDetailAccountManager(id: any) {
        const result = await managerInformationService.getDetailAccountManager(id)
        if (result.status === HttpStatusCode.OK) {
                this.provinceId = result.body?.province?.id
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }


    async changeDistrict(districtId: any) {
        this.districtId = districtId
    }


    async getProvinces() {
        const result = await accountService.getProvinces()
        if (result.status === HttpStatusCode.OK) {
            this.listProvince = result.body
        }
    }

    async getDistricts(provinceId: any) {
        const result = await accountService.getDistricts(provinceId)
        if (result.status === HttpStatusCode.OK) {
            this.listDistrict = result.body
        }
    }
    async getProduct() {
        this.isLoading = true
        const result = await managerInformationService.getProducts()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listProduct = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }
    async getTracking() {
        this.isLoading = true
        const result = await managerInformationService.getTracking()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listTracking = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetailTrackingById(id: any) {
        const result = await managerInformationService.getDetailTracking(id)
        if (result.status === HttpStatusCode.OK) {
            this.listDetailTrackingById = result.body.data
            this.totalPage = result.body.metadata.totalPage
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
    async getDetailPlanTrackingById(id: any) {
        const result = await managerInformationService.getDetailPlanTracking(id)
        if (result.status === HttpStatusCode.OK) {
            this.detailPlanTracking = result.body
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
    async getDistribution() {
        this.isLoading = true
        const result = await managerInformationService.getDistribution()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listDistribution = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }
}

export const managerInformationStore = new ManagerInformationStore()