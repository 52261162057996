import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import $ from "jquery";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {productService} from "./ProductService";
import {warehouseStore} from "../../warehouse/WarehouseStore";
import Constants from "../../../common/constants/Constants";


interface IErrors {
    name: string,
    shortName: string,
    cateLv1: string,
    cateLv2: string,
    cateLv3: string
}


class ProductStore{
    @observable id: any = ''
    @observable type: any = ''
    @observable categoryId: any = ''
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable errors: IErrors = {
        name: "",
        shortName: '',
        cateLv1: '',
        cateLv2: '',
        cateLv3: '',
    }
    @observable dataRequest: any = {
        id: "",
        name: "",
        shortName: "",
        cateLv1: null,
        cateLv2: null,
        cateLv3: null,
        note: "",
        treeLike: {
            id: ''
        }
    }
    @observable listProduct: any[] = []
    @observable listCateLv1: any[] = []
    @observable listCateLv2: any[] = []
    @observable listCateLv3: any[] = []

    clearError(){
        productStore.errors = {
            name: "",
            shortName: '',
            cateLv1: '',
            cateLv2: '',
            cateLv3: '',
        }
    }

    clearForm() {
        productStore.clearError()
        productStore.type = 'add'
        productStore.listCateLv2 = []
        productStore.listCateLv3 = []
        productStore.dataRequest = {
            id: "",
            name: "",
            shortName: '',
            cateLv1: null,
            cateLv2: null,
            cateLv3: null,
            note: ""
        }
    }




    async getCateLv1() {
        const result = await productService.cateLv1()
        if (result.status === HttpStatusCode.OK) {
            productStore.listCateLv1 = result.body.data
        }
    }

    async changeCateLv1(id: any){
        productStore.dataRequest.cateLv1 = id
        productStore.listCateLv3 = []
        productStore.dataRequest.cateLv2 = null
        productStore.dataRequest.cateLv3 = null

        if(id){
            productStore.errors.cateLv1 = ""
        }
        await this.getCateLv2(id)
    }

    async changeCateLv2(id: any){
        productStore.dataRequest.cateLv2 = id
        productStore.dataRequest.cateLv3 = null
        if(id){
            productStore.errors.cateLv2 = ""
        }
        await this.getCateLv3(id)
    }


    async getCateLv2(id: any) {
        const result = await productService.cateLv2(id)
        if (result.status === HttpStatusCode.OK) {
            productStore.listCateLv2 = result.body.data
        }
    }

    async getCateLv3(id: any) {
        const result = await productService.cateLv3(id)
        if (result.status === HttpStatusCode.OK) {
            productStore.listCateLv3 = result.body.data
        }
    }


    async getProducts() {
        productStore.isLoading = true
        const result = await productService.getProducts()
        productStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            productStore.listProduct = result.body.data
            warehouseStore.listSupplies = result.body.data
            productStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getProductsAll() {
        productStore.isLoading = true
        const result = await productService.getProductsAll()
        productStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            warehouseStore.listSuppliesAll = result.body.data
        }
    }

    async importData(file: any) {
        let data = {
            fileImport: file
        }
        const result = await productService.importData(data);
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Import sản phẩm thành công!');
            await productStore.getProducts();
        } else {
            toastUtil.error(result.body.message);
        }
    }

    async getDetailProduct(id: any) {
        productStore.clearError()
        productStore.type = 'edit'
        const result = await productService.detailProduct(id)
        if (result.status === HttpStatusCode.OK) {
            productStore.dataRequest = result.body
            if(result.body.group){
                productStore.dataRequest.cateLv1 = result.body.group.id
                await productStore.getCateLv2(result.body.group.id)
            }
            if(result.body.type){
                productStore.dataRequest.cateLv2 = result.body.type.id
                await productStore.getCateLv3(result.body.type.id)
            }
            if(result.body.treeLike){
                productStore.dataRequest.cateLv3 = result.body.treeLike.id
            }
        }else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async add() {
        let {name, shortName, cateLv1, cateLv2, cateLv3, note} = productStore.dataRequest

        let data = {
            name: name,
            shortName: shortName,
            categoryId: parseInt(cateLv3),
            note: note
        }
        productStore.clearError()
        if (!cateLv1) {
            productStore.errors.cateLv1 = 'Vui lòng chọn nhóm sản phẩm!'
        }
        if (!cateLv2) {
            productStore.errors.cateLv2 = 'Vui lòng chọn loại sản phẩm!'
        }
        if (!cateLv3) {
            productStore.errors.cateLv3 = 'Vui lòng chọn giống sản phẩm!'
        }
        if (!name) {
            productStore.errors.name = 'Tên sản phẩm không được để trống!'
        }
        if (!shortName) {
            productStore.errors.shortName = 'Tên viết tắt sản phẩm không được để trống!'
        }
        if (shortName.length > 5) {
            productStore.errors.shortName = 'Tên viết tắt sản phẩm tối đa 5 ký tự!'
        }

        if(!cateLv1 || !cateLv2 || !cateLv3 || !name || !shortName || shortName.length > 5){
            return
        }

        productStore.isLoadingBt = true
        const result = await productService.create(data)
        productStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thiết lập sản phẩm thành công', 3)
            $('#add_or_edit_product').trigger('click')
            productStore.clearForm()
            await productStore.getProducts()
        }else {
            if (typeof result.body.message === "string") {
                toastUtil.error(result.body.message, 3);
            } else if (typeof result.body.message === "object") {
                this.errors = result.body.message;
            }
        }

    }

    async update() {
        let {id, shortName, name, cateLv1, cateLv2, cateLv3, note} = productStore.dataRequest

        let data = {
            name: name,
            shortName: shortName,
            categoryId: parseInt(cateLv3),
            note: note
        }
        productStore.clearError()

        if (!cateLv1) {
            productStore.errors.cateLv1 = 'Vui lòng chọn nhóm sản phẩm!'
        }
        if (!cateLv2) {
            productStore.errors.cateLv2 = 'Vui lòng chọn loại sản phẩm!'
        }
        if (!cateLv3) {
            productStore.errors.cateLv3 = 'Vui lòng chọn giống sản phẩm!'
        }
        if (!name) {
            productStore.errors.name = 'Tên sản phẩm không được để trống!'
        }
        if (!shortName) {
            productStore.errors.shortName = 'Tên viết tắt sản phẩm không được để trống!'
        }
        if (shortName.length > 5) {
            productStore.errors.shortName = 'Tên viết tắt sản phẩm tối đa 5 ký tự!'
        }

        if(!cateLv1 || !cateLv2 || !cateLv3 || !name || !shortName || shortName.length > 5){
            return
        }
        productStore.isLoadingBt = true
        const result = await productService.update(id, data)
        productStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật sản phẩm thành công', 3)
            $('#add_or_edit_product').trigger('click')
            productStore.clearForm()
            await productStore.getProducts()
        }else {
            toastUtil.error(result.body.message, 4)
        }
    }


    async delete() {
        productStore.isLoadingBt = true
        const result = await productService.delete(productStore.id)
        productStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa sản phẩm thành công', 3)
            await productStore.getProducts()
        }else {
            toastUtil.error(result.body.message, 4)
        }
    }
}

export const productStore = new ProductStore()