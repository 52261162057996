import {observable} from "mobx";
import {IFilter} from "../../../common/component/filter/FilterComponent";
import {diaryService} from "../DiaryService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery"
import {planStore} from "../../planning/PlanStore";
import {Moment} from "../../../common/utils/Moment";
import {medicineStore} from "../../setup/medicine/MedicineStore";
import {fertilizerStore} from "../../setup/fertilizer/FertilizerStore";
import {equipmentStore} from "../../setup/equipment/EquipmentStore";
import {ingredientStore} from "../../setup/ingredient/IngredientStore";
import {scheduleStore} from "../schedule/ScheduleStore";
import {diaryStore} from "../DiaryStore";
import {getLocalDateTime, plusDays} from "../../../common/utils/Utils";


interface IDataRequest {
    id: any,
    name: string,
    startDate: any,
    endDate: any,
    note: string,
    planId: any,
    images: string[]
    equipmentIds: any[],
    fertilizerIds: any[],
    ingredientIds: any[],
    medicines: any[],
    fertilizers: any[],
    ingredients: any[],
    equipments: any[],
    agriculturalMedicineIds: any[]
}

class JobDiaryStore {
    @observable typeModal: any = ''
    @observable keyword: string = ''
    @observable jobId: string = ''
    @observable error: string = ''
    @observable use_material: any = null
    @observable materialId: any = ''
    @observable listData: any[] = []
    @observable listSupplies: any[] = []
    @observable isLoadingBt: boolean = false
    @observable isLoadingAction: boolean = false
    @observable isLoading: boolean = false
    @observable countFinish: number = 0
    @observable listDiary: any[] = []
    @observable dataFilter: IFilter[] = []
    @observable filterLand: IFilter[] = []
    @observable dataRequest: IDataRequest = {
        id: "",
        name: "",
        startDate: "",
        endDate: "",
        note: "",
        planId: "",
        images: [],
        medicines: [],
        fertilizers: [],
        equipments: [],
        ingredients: [],
        equipmentIds: [],
        fertilizerIds: [],
        ingredientIds: [],
        agriculturalMedicineIds: []
    }
    @observable minEndDate: any = ""
    @observable errors: any = {
        name: "",
        startDate: "",
        endDate: "",
        equipments: '',
        fertilizers: '',
        ingredients: '',
        agriculturalMedicines: ''
    }

    clearForm() {
        jobDiaryStore.materialId = ''
        jobDiaryStore.listData = []
        jobDiaryStore.jobId = '';
        jobDiaryStore.typeModal = 'add';
        jobDiaryStore.dataRequest = {
            id: "",
            name: "",
            startDate: "",
            endDate: "",
            note: "",
            planId: "",
            images: [],
            ingredients: [],
            medicines: [],
            fertilizers: [],
            equipments: [],
            equipmentIds: [],
            fertilizerIds: [],
            ingredientIds: [],
            agriculturalMedicineIds: []
        }
    }

    async finishPlan() {
        let {actualOutput} = planStore.dataRequest

        let data = {
            actualOutput: actualOutput
        }

        if (!actualOutput) {
            jobDiaryStore.error = "Sản lượng thực tế không được để trống!"
            return
        } else jobDiaryStore.error = ""


        jobDiaryStore.isLoading = true
        const result = await diaryService.finishPlan(planStore.id, data)
        jobDiaryStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_finish_plan_').trigger('click')
            toastUtil.success('Kết thúc công việc thành công', 1);
        } else {
            toastUtil.error('Kết thúc công việc thất bại', 2);
        }
    }


    async getActualOutputLand() {
        const result = await diaryService.getActualOutputLand(planStore.id)
        if (result.status === HttpStatusCode.OK) {
            planStore.dataRequest.actualOutput = result.body.actualOutput
        }
    }


    async getDiary(isSchedule?: boolean) {
        this.isLoading = true
        const result = await diaryService.getJobDiary()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listDiary = result.body;
            if (isSchedule) {
                scheduleStore.events = []
                this.listDiary && this.listDiary.length > 0 && this.listDiary.map((item: any) => {
                    scheduleStore.events.push({
                        id: item.id,
                        title: item.name,
                        start: getLocalDateTime(item.startDate, 'yyyy-mm-dd'),
                        end: plusDays(item.endDate, 1),
                        allDay: true,
                    })
                })
            }
        }
    }


    async getDetail(id: any) {
        jobDiaryStore.typeModal = "edit"
        const result = await diaryService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            jobDiaryStore.dataRequest = result.body;
            jobDiaryStore.minEndDate = jobDiaryStore.dataRequest.startDate
            jobDiaryStore.listData = []
            jobDiaryStore.dataRequest.equipmentIds = []
            jobDiaryStore.dataRequest.fertilizerIds = []
            jobDiaryStore.dataRequest.ingredientIds = []
            jobDiaryStore.dataRequest.agriculturalMedicineIds = []
            if((result.body.images && result.body.images.length < 1) || !result.body.images){
                jobDiaryStore.dataRequest.images = []
            }
            result.body.equipments && result.body.equipments.map((item: any) => {
                let data = {
                    type: 'Máy móc',
                    data: item.equipment,
                    qty: item.qty,
                }
                jobDiaryStore.listData.push(data)

            })
            result.body.fertilizers && result.body.fertilizers.map((item: any) => {
                let data = {
                    type: 'Phân bón',
                    data: item.fertilizer,
                    qty: item.qty,
                }
                jobDiaryStore.listData.push(data)
            })
            result.body.ingredients && result.body.ingredients.map((item: any) => {
                let data = {
                    type: 'Nguyên vật liệu',
                    data: item.ingredient,
                    qty: item.qty,
                }
                jobDiaryStore.listData.push(data)
            })
            result.body.medicines && result.body.medicines.map((item: any) => {
                let data = {
                    type: 'Nông dược',
                    data: item.medicine,
                    qty: item.qty,
                }
                jobDiaryStore.listData.push(data)
            })
        } else {
            toastUtil.error(result.body.message, 2)
        }
    }


    unique_arr(arr: any) {
        return Array.from(new Set(arr))
    }

    async addOrEdit() {
        let {
            id,
            name,
            startDate,
            endDate,
            note, images,
            equipmentIds,
            fertilizerIds,
            ingredientIds,
            agriculturalMedicineIds
        } = this.dataRequest

        equipmentIds = []
        fertilizerIds = []
        ingredientIds = []
        agriculturalMedicineIds = []

        this.listData && this.listData.map((item) => {
            if (item.type == "Nông dược") {
                agriculturalMedicineIds.push({
                    id: item.data.id,
                    qty: Number(item.qty)
                })
            }
            if (item.type == "Phân bón") {
                fertilizerIds.push({
                    id: item.data.id,
                    qty: Number(item.qty)
                })
            }
            if (item.type == "Máy móc") {
                equipmentIds.push({
                    id: item.data.id,
                    qty: Number(item.qty)
                })
            }
            if (item.type == "Nguyên vật liệu") {
                ingredientIds.push({
                    id: item.data.id,
                    qty: Number(item.qty)
                })
            }
        })

        if (!name) {
            jobDiaryStore.errors.name = 'Tên công việc không được để trống!'
            return;
        } else if(name?.length <= 2 || name?.length >= 1000){
            jobDiaryStore.errors.name = 'Tên phải từ 3-1000 kí tự';
            return;
        }else jobDiaryStore.errors.name = ''

        if (!startDate) {
            jobDiaryStore.errors.startDate = 'Ngày bắt đầu không được để trống!'
            return;
        } else jobDiaryStore.errors.startDate = ''

        if (!endDate) {
            jobDiaryStore.errors.endDate = 'Ngày kết thúc không được để trống!'
            return;
        } else jobDiaryStore.errors.endDate = ''


        if (fertilizerIds.length < 1 && equipmentIds.length < 1 && ingredientIds.length < 1 && agriculturalMedicineIds.length < 1) {
            toastUtil.warning('Vui lòng thêm vật tư!', 2)
            return
        }

        let data = {
            name: name,
            startDate: startDate,
            endDate: endDate,
            note: note,
            landId: Number(diaryStore.landId),
            planId: Number(planStore.id),
            images: images,
            equipments: equipmentIds ? this.unique_arr(equipmentIds) : [],
            fertilizers: fertilizerIds ? this.unique_arr(fertilizerIds) : [],
            ingredients: ingredientIds ? this.unique_arr(ingredientIds) : [],
            agriculturalMedicines: agriculturalMedicineIds ? this.unique_arr(agriculturalMedicineIds) : [],
        }

        jobDiaryStore.isLoadingBt = true
        const result = this.typeModal == 'add' ? await diaryService.addJob(data) : await diaryService.editJob(id, data)
        jobDiaryStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${this.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} công việc thành công`, 3);
            $('#close_add_edit_job').trigger('click')
            this.clearForm()
            await this.getDiary()
        } else {
            toastUtil.error(result.body.message, 4);
            if (typeof result.body.message === "object") {
                jobDiaryStore.errors = result.body.message;
            }
        }

    }

    pushData() {
        this.isLoadingAction = true
        setTimeout(() => {
            this.isLoadingAction = false
        }, 1500)
        let value = {
            id: '',
            name: '',
            useForOneHa: ''
        }
        let isError: boolean = false
        this.listSupplies && this.listSupplies.map((item) => {
            this.listData && this.listData.map((val) => {
                if (val.data.id == this.materialId) {
                    isError = true
                }
            })
            if (item.id == this.materialId) {
                value.name = item.name
                value.id = item.id
                value.useForOneHa = item.useForOneHa ? `${item.useForOneHa} ${item.unit?.code}/Ha` : ''

            }
        })
        if (isError) {
            toastUtil.warning('Vật tư đã tồn tại!', 1)
            return
        }
        let data = {
            type: this.use_material,
            data: value,
            qty: 1
        }
        this.listData.unshift(data)
        jobDiaryStore.listSupplies = []
        this.use_material = null
        this.materialId = ''
    }

    async changeUseMaterial(value: any) {
        let data: any[] = []
        this.use_material = value
        if (value == "Nông dược") {
            await medicineStore.getMedicine(100)
            data = medicineStore.listMedicine
        } else if (value == "Phân bón") {
            await fertilizerStore.getFertilizer(100)
            data = fertilizerStore.listFertilizer
        } else if (value == "Máy móc") {
            await equipmentStore.getEquipments(100)
            data = equipmentStore.listEquipment
        } else if (value == "Nguyên vật liệu") {
            await ingredientStore.getIngredient(100)
            data = ingredientStore.listIngredient
        }

        this.listSupplies = data
    }

    async remove(index: number, id: any) {

        this.dataRequest.ingredientIds && this.dataRequest.ingredientIds.map((item: any, i: any) => {
            if (item == id) {
                jobDiaryStore.dataRequest.ingredientIds.splice(i, 1)
            }
        })
        this.dataRequest.equipments && this.dataRequest.equipments.map((item: any, i: any) => {
            if (item == id) {
                jobDiaryStore.dataRequest.fertilizerIds.splice(i, 1)
            }
        })
        this.dataRequest.agriculturalMedicineIds && this.dataRequest.agriculturalMedicineIds.map((item: any, i: any) => {
            if (item == id) {
                this.dataRequest.fertilizerIds.splice(i, 1)
            }
        })
        this.dataRequest.fertilizerIds && this.dataRequest.fertilizerIds.map((item: any, i: any) => {
            if (item == id) {
                this.dataRequest.fertilizerIds.splice(i, 1)
            }
        })

        this.listData.splice(index, 1)
    }


    async delete() {
        jobDiaryStore.isLoadingBt = true
        const result = await diaryService.deleteJob(jobDiaryStore.jobId)
        jobDiaryStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa công việc thành công!', 5)
            await jobDiaryStore.getDiary()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }
}

export const jobDiaryStore = new JobDiaryStore()