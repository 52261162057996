import React from 'react';
import {observer} from "mobx-react";
import {fertilizerStore} from "../FertilizerStore";
import {numberUtil} from "../../../../common/utils/NumberUtil";
import Input from "../../../../common/component/form/Input";
import Select from "../../../../common/component/form/Select";
import {medicineStore} from "../../medicine/MedicineStore";


const AddOrEditFertilizer = () => {


    const checkError = (value: any) => {
        if(!value && fertilizerStore.errors.element){
            return "border_error"
        }
    }

    return (
        <div className="modal fade" id="add_or_edit_fertilizer" role="dialog" aria-hidden="true">
            <div className="modal-dialog mt-5" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Thiết lập phân bón</h5>
                        <button type="button" className="close" id="close_add_edit" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="w-100 d-flex align-items-center">
                            <div className="w-50 pr-2">
                                <div className="form-group">
                                    <label>Tên phân bón <span className="text-danger">*</span></label>
                                    <Input
                                        onChange={(e: any) => fertilizerStore.dataRequest.name = e.currentTarget.value}
                                        value={fertilizerStore.dataRequest.name}
                                        isError={fertilizerStore.errors.name} placeholder={"Nhập tên phân bón"}/>
                                </div>
                            </div>
                            <div className="w-50 pl-2">
                                <div className="form-group">
                                    <label>Mã phân bón</label>
                                    <Input
                                        onChange={(e: any) => fertilizerStore.dataRequest.shortName = e.currentTarget.value}
                                        value={fertilizerStore.dataRequest.shortName} placeholder={"Nhập mã phân bón"}/>
                                </div>
                            </div>
                        </div>

                        <div className="w-100 d-flex align-items-center">
                            <div className="w-50 pr-2">
                                <div className="form-group">
                                    <label>Đơn vị <span className="text-danger">*</span></label>
                                    <Select
                                        onChange={(e: any) => fertilizerStore.dataRequest.unitId = e}
                                        value={fertilizerStore.dataRequest.unitId} options={medicineStore.listUnit}
                                        isError={fertilizerStore.errors.unit} placeholder={"Chọn đơn vị"}/>
                                </div>
                            </div>
                            <div className="w-50 pl-2">
                                <div className="form-group">
                                    <label>Giá thành</label>
                                    <Input
                                        onChange={(e: any) => fertilizerStore.dataRequest.price = numberUtil.regexNumber(e)}
                                        value={fertilizerStore.dataRequest.price} placeholder={"Nhập giá thành"}/>
                                </div>
                            </div>
                        </div>

                        <div className="w-100 d-flex align-items-center">
                            <div className="w-50 pr-2">
                                <div className="form-group">
                                    <label>Khối lượng / Đơn vị (Đóng gói) <span className="text-danger">*</span></label>
                                    <Input
                                        onChange={(e: any) => fertilizerStore.dataRequest.capacity = numberUtil.regexNumberDecimal(e)}
                                        value={fertilizerStore.dataRequest.capacity}
                                        isError={fertilizerStore.errors.capacity} placeholder={"Nhập khối lượng"}/>
                                </div>
                            </div>
                            <div className="w-50 pl-2">
                                <div className="form-group">
                                    <label>Lượng sử dụng/Ha <span className="text-danger">*</span></label>
                                    <Input
                                        onChange={(e: any) => fertilizerStore.dataRequest.useForOneHa = numberUtil.regexNumberDecimal(e)}
                                        value={fertilizerStore.dataRequest.useForOneHa}
                                        isError={fertilizerStore.errors.useForOneHa} placeholder={"Nhập lượng thuốc sử dụng"}/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Tỉ lệ <span className="text-danger">*</span></label>
                            <div className="d-flex justify-content-between">
                                <input type="text" value={fertilizerStore.dataRequest.element[0]}
                                       onChange={(e: any) => fertilizerStore.dataRequest.element[0] = numberUtil.regexNumberDecimal(e)}
                                       className={`form-control form-control-lg w-ratio ${checkError(fertilizerStore.dataRequest.element[0])}`}
                                       placeholder="%N"/>
                                <input type="text" value={fertilizerStore.dataRequest.element[1]}
                                       onChange={(e: any) => fertilizerStore.dataRequest.element[1] = numberUtil.regexNumberDecimal(e)}
                                       className={`form-control form-control-lg w-ratio ${checkError(fertilizerStore.dataRequest.element[1])}`}
                                       placeholder="%P"/>
                                <input type="text" value={fertilizerStore.dataRequest.element[2]}
                                       onChange={(e: any) => fertilizerStore.dataRequest.element[2] = numberUtil.regexNumberDecimal(e)}
                                       className={`form-control form-control-lg w-ratio ${checkError(fertilizerStore.dataRequest.element[2])}`}
                                       placeholder="%K"/>
                            </div>
                            {fertilizerStore.errors.element && <p className="error">{fertilizerStore.errors.element}</p>}
                        </div>
                        <div className="form-group">
                            <label>Nơi cung cấp</label>
                            <div className="input-group">
                                <input type="text" value={fertilizerStore.dataRequest.supplier}
                                       onChange={(e: any) => fertilizerStore.dataRequest.supplier = e.currentTarget.value}
                                       className="form-control form-control-lg" placeholder="Nhập nơi cung cấp"/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => fertilizerStore.type == "add" ? fertilizerStore.add() : fertilizerStore.update()}
                                disabled={fertilizerStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditFertilizer);