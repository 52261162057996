import React from 'react';
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {warehouseStore} from "../WarehouseStore";
import {TypeActive} from "../../setup/SetupStore";

const TabHeader = observer(() => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const changTab = (type: any) => {
        warehouseStore.tabActive = type;
        navigate({pathname: '/warehouse', search: `?tab=${type}`,});
    }

    return (
        <div className="tab_header d-flex align-items-center">
           <div className={`tab ${warehouseStore.tabActive == TypeActive.product ? 'active' : ''}`}
                 onClick={() => changTab(TypeActive.product)}>
                <img src={`assets/ico/setup/ico_product${warehouseStore.tabActive == TypeActive.product ? '_active' : ''}.svg`} alt=""/>
                <span>{t('product')}</span>
            </div>

           <div className={`tab ${warehouseStore.tabActive == TypeActive.medicine ? 'active' : ''}`}
                 onClick={() => changTab(TypeActive.medicine)}>
                <img src={`assets/ico/setup/ico_medicine${warehouseStore.tabActive == TypeActive.medicine ? '_active' : ''}.svg`} alt=""/>
                <span>{t('medicine')}</span>
            </div>

            <div className={`tab ${warehouseStore.tabActive == TypeActive.fertilizer ? 'active' : ''}`}
                 onClick={() => changTab(TypeActive.fertilizer)}>
                <img src={`assets/ico/setup/ico_fertilizer${warehouseStore.tabActive == TypeActive.fertilizer ? '_active' : ''}.svg`} alt=""/>
                <span>{t('fertilizer')}</span>
            </div>

          <div className={`tab ${warehouseStore.tabActive == TypeActive.ingredient ? 'active' : ''}`}
                 onClick={() => changTab(TypeActive.ingredient)}>
                <img src={`assets/ico/setup/ico_ingredient${warehouseStore.tabActive == TypeActive.ingredient ? '_active' : ''}.svg`} alt=""/>
                <span>{t('ingredient')}</span>
            </div>
            <div className={`tab ${warehouseStore.tabActive == TypeActive.equipment ? 'active' : ''}`}
                 onClick={() => changTab(TypeActive.equipment)}>
                <img src={`assets/ico/setup/ico_equipment${warehouseStore.tabActive == TypeActive.equipment ? '_active' : ''}.svg`} alt=""/>
                <span>{t('equipment')}</span>
            </div>
        </div>
    );

})

export default TabHeader;