import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import "./DiaryStyle.scss"
import TabHeader from "./components/TabHeader";
import {diaryStore, ITabDiary} from "./DiaryStore";
import JobDiary from "./jobDiary/JobDiary";
import Schedule from "./schedule/Schedule";
import Progress from "./progress/Progress";
import {getUrlParameter} from "../../common/utils/Utils";


const DiaryComponent = () => {

    const switchData = () => {
        switch (diaryStore.tabActive) {
            case ITabDiary.jobDiary:
                return <JobDiary/>
            case ITabDiary.schedule:
                return <Schedule/>
            case ITabDiary.progress:
                return <Progress/>
        }
    }

    useEffect(() => {
        diaryStore.tabActive = getUrlParameter('type') ?? ITabDiary.jobDiary;
    }, [])



    return (
        <div className="diary">
            <TabHeader/>
            {switchData()}
        </div>
    )
}

export default observer(DiaryComponent);