import React, {useState} from 'react';
import {number} from "prop-types";
import {numberUtil} from "../../../common/utils/NumberUtil";

interface IProps{
    value: any,
    onChange: any
    isError?: any,
    type?: any,
    disabled?: any,
    placeholder?: string
}



const InputPhone = (item:IProps) => {
    const [error, setError] = useState<string>('')


    return (
        <>
            <div className="input-group">
                <input type={item.type ?? 'text'} value={item.value || ""}
                       onChange={(e: any) => {
                           if(e.currentTarget.value.length <= 10){
                               item.onChange(e)
                               if(e.currentTarget.value.length > 1 && numberUtil.checkPhone(e.currentTarget.value)){
                                   setError('Số điện thoại chưa đúng định dạng!')
                               }else setError('')
                           }
                       }} disabled={item.disabled}
                       className={`form-control ${((item.isError && !item.value) || error) ? 'border_error' : ''}`} placeholder={item.placeholder ?? ''}/>
            </div>
            {(item.isError && !item.value) && <p className="error">{item.isError}</p>}
            {(item.value && numberUtil.checkPhone(item.value)) && <p className="error">{error}</p>}
        </>
    );
}

export default InputPhone;