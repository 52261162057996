import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {infoStore, TypeInfo} from "./InfoStore";
import "./InfoStyle.scss"
import Loading from "../../common/component/Loading";
import {profileStore} from "../profile/ProfileStore";
import {checkPermission, getUrlParameter, urlImage} from "../../common/utils/Utils";
import {Link} from "react-router-dom";
import EditInfo from "./EditInfo";
import {PermissionConst} from "../../common/constants/Constants";
import PopupZoom from "../../common/component/PopupZoom";


const InfoComponent = () => {

    useEffect(() => {
        profileStore.link = '/farm'
        infoStore.type = getUrlParameter('type') ?? TypeInfo.info
    }, [])

    if (infoStore.isLoading) {
        return <Loading/>
    } else return (
        <div className="info_main">
            {infoStore.type == TypeInfo.info ?
                <>
                    <div className="d-flex mb-3 justify-content-end">
                        {checkPermission(PermissionConst.farmUpdate) &&
                        <Link to={`/farm?type=${TypeInfo.edit}`} onClick={() => {
                            infoStore.getInfo().then()
                            infoStore.type = TypeInfo.edit
                        }}
                              className="d-flex edit-info align-items-center">
                            <span>Chỉnh sửa</span>
                            <img src="/assets/ico/ico_edit_v1.svg" className="ml-2" alt=""/>
                        </Link>}
                    </div>
                    <h2>Thông tin HTX</h2>
                    <div className="d-flex">
                        <div className="info_unit w-50 pr-2">
                            <div className="info_text">
                                <label>Tên đơn vị</label>
                                <span>{infoStore.dataInfo.name}</span>
                            </div>
                            <div className="info_text">
                                <label>Tên đại diện</label>
                                <span>{infoStore.dataInfo.representativeName}</span>
                            </div>
                            <div className="info_text">
                                <label>Số điện thoại</label>
                                <span>{infoStore.dataInfo.phone}</span>
                            </div>
                            <div className="info_text">
                                <label>Email</label>
                                <span>{infoStore.dataInfo.email}</span>
                            </div>
                            <div className="info_text">
                                <label>Địa chỉ</label>
                                <span>{infoStore.dataInfo.address}</span>
                            </div>
                            <div className="info_text">
                                <label>Website</label>
                                <span>{infoStore.dataInfo.website}</span>
                            </div>
                            <div className="info_text">
                                <label>Chứng chỉ</label>
                                <span>{infoStore.dataInfo.certificate}</span>
                            </div>
                            <div className="info_text">
                                <label>Số đăng ký kinh doanh</label>
                                <span>{infoStore.dataInfo.businessCode}</span>
                            </div>
                            <div className="info_text">
                                <label>Ký hiệu kinh doanh</label>
                                <span>{infoStore.dataInfo.businessShortName}</span>
                            </div>
                        </div>
                        <div className="info_unit w-50 pl-2">
                            <div className="info_text">
                                <label>Loại hình</label>
                                <span>{infoStore.dataInfo.businessDescription}</span>
                            </div>
                            <div className="info_text">
                                <label>Sản phẩm chính</label>
                                <span>{infoStore.dataInfo.mainProduct}</span>
                            </div>
                            <div className="info_text">
                                <label>Mã số vùng trồng</label>
                                <span>{infoStore.dataInfo.plantingAreaCode}</span>
                            </div>
                            <div className="info_text">
                                <label>Tổng doanh thu</label>
                                <span>{infoStore.dataInfo.totalRevenue}</span>
                            </div>
                            <div className="info_text">
                                <label>Thị trường chính</label>
                                <span>{infoStore.dataInfo.mainMarket}</span>
                            </div>
                            <div className="info_text">
                                <label>Tổng nhân viên</label>
                                <span>{infoStore.dataInfo.totalLabor}</span>
                            </div>
                            <div className="info_text">
                                <label>Giấy chứng nhận</label>
                                <span>{infoStore.dataInfo.testimonial}</span>
                            </div>
                            <div className="info_text">
                                <label>Năm thành lập</label>
                                <span>{infoStore.dataInfo.foundedYear}</span>
                            </div>
                        </div>
                    </div>
                    {infoStore.dataInfo.certificates && infoStore.dataInfo.certificates.length > 0 && <>
                        <h2>Giấy chứng nhận</h2>
                        <div className="scroll_certificate">
                            <div className="list_certificate">
                                {infoStore.dataInfo.certificates.map((item: any, i: number) => {
                                    return (
                                        <div className="item cursor_pointer" data-toggle="modal"
                                             data-target="#popupZoom" key={i}>
                                            <img src={urlImage(item)} alt=""/>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </>}

                    <PopupZoom listImg={infoStore.dataInfo.certificates}/>
                </> :
                <>
                    <EditInfo/>
                </>
            }
        </div>
    )
}

export default observer(InfoComponent)