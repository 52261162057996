import {observable} from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";
import $ from "jquery";
import {signUpStore} from "../auth/signUp/SignUpStore";
import {planService} from "./PlanService";
import {IFilter} from "../../common/component/filter/FilterComponent";
import {landStore} from "../setup/land/LandStore";
import {procedureStore} from "../procedure/ProcedureStore";
import {profileStore} from "../profile/ProfileStore";
import {getPathName} from "../../common/utils/Utils";

export enum ITabPlan {
    show_plan = "show_plan",
    production_plan = "production_plan"
}


class PlanStore {
    @observable id: any = '';
    @observable type: any = '';
    @observable totalArea: number = 0
    @observable checkAll: boolean = false;
    @observable date: any = new Date();
    @observable minEndDate: any = ""
    @observable searchYear: any = planStore.date.getFullYear();
    @observable searchName: any = '';
    @observable tabActive: any = ITabPlan.production_plan;
    @observable page: number = 0;
    @observable totalPage: number = 0;
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable dataFilter: IFilter[] = []
    @observable listLandPlan: any[] = []
    @observable listLandDetailPlan: any[] = []
    @observable listYear: any[] = [
        {
            id: '2022',
            name: '2022',
        },
        {
            id: '2023',
            name: '2023',
        },
        {
            id: '2024',
            name: '2024',
        },
        {
            id: '2025',
            name: '2025',
        },
        {
            id: '2026',
            name: '2026',
        },
        {
            id: '2027',
            name: '2027',
        },
        {
            id: '2028',
            name: '2028',
        },
        {
            id: '2029',
            name: '2029',
        },
        {
            id: '2030',
            name: '2030',
        }
    ]
    @observable public errors: any = {
        name: "",
        procedure: '',
        year: "",
        expectedOutput: '',
        startDate: "",
        endDate: "",
        land: ''
    }
    @observable dataRequest: any = {
        id: "",
        name: "",
        provinceId: null,
        districtId: null,
        procedureId: null,
        year: null,
        lands: [],
        expectedOutput: '',
        startDate: "",
        endDate: "",
        landIds: [],
    }
    @observable listPlanning: any[] = []

    clearError() {
        planStore.totalArea = 0
        planStore.errors = {
            name: "",
            procedure: '',
            year: "",
            expectedOutput: '',
            startDate: "",
            endDate: "",
            land: ''
        }
    }

    clearForm() {
        planStore.clearError()
        planStore.type = 'add'
        planStore.dataRequest = {
            id: "",
            name: "",
            provinceId: null,
            districtId: null,
            procedureId: null,
            year: null,
            lands: [],
            expectedOutput: '',
            startDate: "",
            endDate: "",
            landIds: [],
        }
        landStore.listLand && landStore.listLand.map((item) => {
            item.isActive = false
        })
    }

    async getPlanning(size?:number) {
        planStore.isLoading = true
        const result = await planService.getPlanning(size)
        planStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            planStore.listPlanning = result.body.data
            planStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getLands(id: any) {
        const result = await planService.getLandPlan(id)
        if (result.status === HttpStatusCode.OK) {
            planStore.dataRequest.landIds = []
            planStore.listLandPlan = []
            result.body && result.body.map((item: any) => {
                planStore.listLandPlan.push({id: item.land?.id, name: item.land?.name, farmHousehold: item.land?.farmHousehold, area: item.land?.area, actualOutput: item.actualOutput, createdAt: item.land?.createdAt})
                planStore.dataRequest.landIds.push(item.land?.id)
            })
        }
    }


    async getDetail(id: any) {
        planStore.clearError()
        planStore.type = "edit"
        const result = await planService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            planStore.dataRequest = result.body
            planStore.minEndDate = planStore.dataRequest.startDate
            planStore.totalArea = result.body.totalArea
            if (result.body.procedure) {
                planStore.dataRequest.procedureId = result.body.procedure.id
            }
            await procedureStore.getProcedure(100)

        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async checkValidate() {

        planStore.isLoadingBt = true

        let {id, name, procedureId, year, startDate, endDate} = planStore.dataRequest

        if (!year) {
            planStore.errors.year = 'Năm không được để trống!'
        } else planStore.errors.year = ''

        if (!procedureId) {
            planStore.errors.procedure = 'Quy trình không được để trống!'
        } else planStore.errors.procedure = ''

        if (!name) {
            planStore.errors.name = 'Tên kế hoạch không được để trống!'
        } else planStore.errors.name = ''

        if (!startDate) {
            planStore.errors.startDate = 'Ngày bắt đầu không được để trống!'
        } else planStore.errors.startDate = ''

        if (!endDate) {
            planStore.errors.endDate = 'Ngày kết thúc không được để trống!'
        } else planStore.errors.endDate = ''

        planStore.isLoadingBt = false

        if(!year || !procedureId || !name || !startDate || !endDate){
            return
        }

        planStore.checkAll = false
        planStore.listLandPlan = []
        planStore.dataRequest.landIds = []

        if(planStore.type == "edit"){
            landStore.listLand.map((item) => item.isActive = false)
            await planStore.getLands(id);
        }

        await signUpStore.getProvinces()

        $('#close_add_edit_one').trigger('click')
        $('#add_plan_two').trigger('click')

    }

    async add() {
        let {name, procedureId, year, expectedOutput, startDate, endDate, landIds} = planStore.dataRequest

        if (landIds && landIds.length < 1) {
            planStore.errors.land = 'Đất cơ sở không được để trống!'
            return
        } else planStore.errors.land = ''


        if (!expectedOutput) {
            planStore.errors.expectedOutput = 'Sản lượng dự kiến không được để trống!'
            return
        } else planStore.errors.expectedOutput = ''


        let data = {
            name: name,
            procedureId: procedureId,
            year: year,
            expectedOutput: expectedOutput,
            startDate: startDate,
            endDate: endDate,
            landIds: landIds ?? []
        }

        planStore.isLoadingBt = true
        const result = await planService.create(data)
        planStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thiết lập kế hoạch thành công', 1)
            $('#close_add_edit').trigger('click')
             planStore.clearForm()
            await planStore.getPlanning()
        } else {
            if (typeof result.body.message === "string") {
                toastUtil.error(result.body.message, 3);
            } else if (typeof result.body.message === "object") {
                this.errors = result.body.message;
            }
        }

    }

    async update() {
        let {id, name, procedureId, year, expectedOutput, startDate, endDate, landIds} = planStore.dataRequest

        if (landIds && landIds.length < 1) {
            planStore.errors.land = 'Đất cơ sở không được để trống!'
            return
        } else planStore.errors.land = ''

        if (!expectedOutput) {
            planStore.errors.expectedOutput = 'Sản lượng dự kiến không được để trống!'
            return
        } else planStore.errors.expectedOutput = ''


        let data = {
            name: name,
            procedureId: procedureId,
            year: year,
            expectedOutput: expectedOutput,
            startDate: startDate,
            endDate: endDate,
            landIds: landIds ?? []
        }

        planStore.isLoadingBt = true
        const result = await planService.update(id, data)
        planStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật kế hoạch thành công', 2)
            $('#close_add_edit').trigger('click')
            planStore.checkAll = false
            planStore.totalArea = 0
            planStore.dataRequest.landIds = []
            if(getPathName() == 'planning-detail'){
                planStore.getLands(planStore.id).then();
            }else {
                planStore.clearForm()
                await planStore.getPlanning()
            }
        } else {
            toastUtil.error('Cập nhật kế hoạch thất bại', 5)
        }
    }

    async delete() {
        planStore.isLoadingBt = true
        const result = await planService.delete(planStore.id)
        planStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa kế hoạch thành công', 3)
            await planStore.getPlanning()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }
}

export const planStore = new PlanStore()