import { observable } from "mobx";
import {getRequest} from "../../common/helpers/RequestHelper";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {planStore} from "../planning/PlanStore";

class ReportStore {
    @observable isLoading: boolean = false
    @observable planId: any = ''
    @observable name: any = ''
    @observable totalPriceMedicine: any = 0
    @observable totalPriceFertilizer: any = 0
    @observable dataFilter: any[] = []
    @observable dataReport: any = {
        countLabor: '',
        medicines: [],
        fertilizers: [],
        ingredients: [],
        equipments: []
    }


    async getReport() {
        reportStore.isLoading = true
        const result = await getRequest(`/v1/plan/${reportStore.planId}/materials?year=${planStore.searchYear}`)
        reportStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            reportStore.dataReport = result.body;
            let totalFertilizer = 0
            let totalMedicine = 0
            result.body.medicines && result.body.medicines.map((item: any) => {
                totalMedicine += item.material.price * item.qty
            })
            result.body.fertilizers && result.body.fertilizers.map((item: any) => {
                totalFertilizer += item.material.price * item.qty
            })

            reportStore.totalPriceMedicine = totalMedicine
            reportStore.totalPriceFertilizer = totalFertilizer

        }
    }

}

export const reportStore = new ReportStore()