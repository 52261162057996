
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from "../src/translation/en/common.json";
import common_vn from "../src/translation/vn/common.json";


i18n.use(initReactI18next).init({
    resources: {
        en: { translation: common_en },
        vi: { translation: common_vn }
    },
    lng: localStorage.getItem('language') ?? 'vi',
    fallbackLng: 'vi',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
