import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery"
import {medicineService} from "./MedicineService";
import {questionStore} from "../../question/QuestionStore";
import {warehouseStore} from "../../warehouse/WarehouseStore";
import Constants from "../../../common/constants/Constants";

class MedicineStore {
    @observable id: any = '';
    @observable type: any = '';
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable dataRequest: any = {
        id: '',
        name: '',
        supplier: '',
        note: '',
        price: '',
        unitId: '',
        shortName: '',
        useForOneHa: '',
        concentration: ''
    }
    @observable listMedicine: any[] = []
    @observable listUnit: any[] = []
    @observable public errors: any = {
        name: '',
        unit: '',
        price: '',
        capacity: '',
        useForOneHa: '',
        concentration: ''
    }

    clearError() {
        medicineStore.errors = {
            name: '',
            unit: '',
            price: '',
            capacity: '',
            useForOneHa: '',
            concentration: ''
        }
    }

    clearForm() {
        medicineStore.clearError()
        medicineStore.type = 'add'
        medicineStore.dataRequest = {
            id: '',
            name: '',
            supplier: '',
            note: '',
            shortName: '',
            price: '',
            unitId: '',
            capacity: '',
            useForOneHa: '',
            concentration: ''
        }
    }

    async importData(file: any) {
        let data = {
            fileImport: file
        }
        const result = await medicineService.importData(data);
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Import nông dược thành công!');
            await medicineStore.getMedicine();
        } else {
            toastUtil.error(result.body.message);
        }
    }

    async getMedicine(size?: any, isQuestion?: boolean) {
        medicineStore.isLoading = true
        const result = await medicineService.getMedicines(size)
        medicineStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            if(isQuestion){
                result.body.data && result.body.data.map((item: any) => {
                    questionStore.listMedicine.push({value: item.id, label: item.name})
                })
            }
            medicineStore.listMedicine = result.body.data
            warehouseStore.listSupplies = result.body.data
            medicineStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getMedicineAll() {
        medicineStore.isLoading = true
        const result = await medicineService.getMedicinesAll()
        medicineStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            warehouseStore.listSuppliesAll = result.body.data
        }
    }

    async getDetail(id: any) {
        medicineStore.clearError()
        medicineStore.type = "edit"
        const result = await medicineService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            medicineStore.dataRequest = result.body
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async getUnit() {
        const result = await medicineService.getUnit()
        if (result.status === HttpStatusCode.OK) {
            medicineStore.listUnit = result.body
        }
    }

    async add() {
        let {name, price, shortName, capacity, supplier, concentration, unitId, useForOneHa} = medicineStore.dataRequest

        this.clearError()

        let data = {
            name: name,
            shortName: shortName,
            supplier: supplier,
            price: Number(price),
            capacity: capacity,
            unitId: Number(unitId),
            useForOneHa: Number(useForOneHa),
            concentration: concentration,
        }

        if (!name) {
            medicineStore.errors.name = "Tên nông dược không được để trống!"
        }
        if (!unitId) {
            medicineStore.errors.unit = "Vui lòng chọn đơn vị!"
        }
        if (!capacity) {
            medicineStore.errors.capacity = "Dung tích không được để trống!"
        }
        if (!useForOneHa) {
            medicineStore.errors.useForOneHa = "Lượng thuốc sử dụng không được để trống!"
        }
        if (!concentration) {
            medicineStore.errors.concentration = "Nồng độ pha loãng không được để trống!"
        }

        if(!name || !unitId || !capacity || !useForOneHa || !concentration){
            return
        }

        medicineStore.isLoadingBt = true
        const result = await medicineService.create(data)
        medicineStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thêm nông dược thành công', 3)
            $('#close_add_edit_medicine').trigger('click')
            medicineStore.clearForm()
            await medicineStore.getMedicine()
        } else {
            if (typeof result.body.message === "string") {
                toastUtil.error(result.body.message, 3);
            } else if (typeof result.body.message === "object") {
                this.errors = result.body.message;
            }
        }
    }

    async update() {
        let {
            id,
            name,
            price,
            capacity,
            supplier,
            shortName,
            concentration,
            unitId,
            useForOneHa
        } = medicineStore.dataRequest
        this.clearError()
        let data = {
            name: name,
            shortName: shortName,
            supplier: supplier,
            price: Number(price),
            capacity: capacity,
            unitId: Number(unitId),
            useForOneHa: Number(useForOneHa),
            concentration: concentration,
        }

        if (!name) {
            medicineStore.errors.name = "Tên nông dược không được để trống!"
        }
        if (!unitId) {
            medicineStore.errors.unit = "Vui lòng chọn đơn vị!"
        }
        if (!capacity) {
            medicineStore.errors.capacity = "Dung tích không được để trống!"
        }
        if (!useForOneHa) {
            medicineStore.errors.useForOneHa = "Lượng thuốc sử dụng không được để trống!"
        }
        if (!concentration) {
            medicineStore.errors.concentration = "Nồng độ pha loãng không được để trống!"
        }

        if(!name || !unitId || !capacity || !useForOneHa || !concentration){
            return
        }


        medicineStore.isLoadingBt = true
        const result = await medicineService.update(id, data)
        medicineStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật nông dược thành công', 3)
            $('#close_add_edit_medicine').trigger('click')
            medicineStore.clearForm()
            await medicineStore.getMedicine()
        } else {
            toastUtil.error('Cập nhật nông dược thất bại', 4)
        }
    }

    async delete() {
        medicineStore.isLoadingBt = true
        const result = await medicineService.delete(medicineStore.id)
        medicineStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa nông dược thành công', 3)
            await medicineStore.getMedicine()
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }


}

export const medicineStore = new MedicineStore()