import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {questionStore} from "./QuestionStore";



class QuestionService {

    public getQuestions(): Promise<IApiResponse>{
        return getRequest(`/v1/post?page=${questionStore.page}&size=10${questionStore.topicId ? `&topicId=${questionStore.topicId}` : ''}${questionStore.searchName ? `&keyword=${questionStore.searchName}` : ''}`)
    }

    public getTopic(): Promise<IApiResponse>{
        return getRequest(`/v1/post/topic`)
    }

    public getQuestionDetail(id: any): Promise<IApiResponse>{
        return getRequest(`/v1/post/${id}`)
    }

    public getComments(id: any): Promise<IApiResponse>{
        return getRequest(`/v1/post/${id}/comment?page=0&size=100`)
    }

    public getDetailComment(questionId: any, id: any): Promise<IApiResponse>{
        return getRequest(`/v1/post/${questionId}/comment/${id}`)
    }

    public addComment(questionId: any, data: any): Promise<IApiResponse>{
        return postRequest(`/v1/post/${questionId}/comment`, data)
    }

    public updateComment(commentId: any, data: any): Promise<IApiResponse>{
        return putRequest(`/v1/post/comment/${commentId}`, data)
    }

    public deleteComment(id: any): Promise<IApiResponse>{
        return deleteRequest(`/v1/post/comment/${id}`, {id})
    }

    public addQuestion(data: any): Promise<IApiResponse>{
        return postRequest(`/v1/post`, data)
    }

    public updateQuestion(id: any, data: any): Promise<IApiResponse>{
        return putRequest(`/v1/post/${id}`, data)
    }

    public deleteQuestion(id: any): Promise<IApiResponse>{
        return deleteRequest(`/v1/post/${id}`, {id})
    }

    public getAnswer(id: any): Promise<IApiResponse>{
        return getRequest(`/v1/post/${id}/answer`)
    }

    public addOrUpdateAnswer(questionId: any, data: any): Promise<IApiResponse>{
        return postRequest(`/v1/post/${questionId}/answer`, data)
    }

}

export const questionService = new QuestionService()