import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {getIDParameter} from "../../common/utils/Utils";
import "./InfoTracking.scss"
import Loading from "../../common/component/Loading";
import {dateUtils} from "../../common/utils/DateUtils";
import {trackingStore} from "../tracking/TrackingStore";
import {trackingDetailStore} from "../tracking/trackingDetail/TrackingDetailStore";
import Header from "./components/Header";
import Content1 from "./components/Content1";
import Content2 from "./components/Content2";
import {Link} from "react-router-dom";
import {trackingLivestockStore} from "../trachkingLivestock/TrackingLivestockStore";
import {trackingLivestockDetailStore} from "../trachkingLivestock/trackingDetail/TrackingLivestockDetailStore";


const InfoTrackingBatchCodeLivestock = () => {

    useEffect(() => {
        window.scroll(0, 0)
        let idTracking: any = getIDParameter();
        let idTrackingBatch: any = getIDParameter(4);
        trackingLivestockStore.getDetail(idTracking).then()
        trackingLivestockDetailStore.getDetailTracking(idTrackingBatch).then()

        return () => {
            trackingDetailStore.clearForm()
        }
    }, [])

    let {details} = trackingLivestockStore.dataRequest;
    let {info} = trackingLivestockDetailStore.dataRequest;

    return (
        <div className="info_tracking_breed">
           <Header/>
            <div className="container">
                <div className="logo d-flex justify-content-center align-items-center">
                    <Link to={'/'}><img src="/assets/images/logo_login.svg" alt=""/></Link>
                </div>
                {trackingStore.isLoadingDetail ? <Loading/> :
                    <>
                        <Content1/>
                        <h2>Thông tin chi tiết</h2>
                        <div className="w-100 info_ d-flex">
                            <div className="info_unit w-50 pr-4">
                                <div className="info_text">
                                    <label>Ngày xuất chuồng</label>
                                    <span>{dateUtils.formatDate(details?.harvestDate)}</span>
                                </div>
                                <div className="info_text">
                                    <label>Tổng khối lượng</label>
                                    <span>{details?.netWeight}</span>
                                </div>
                                <div className="info_text">
                                    <label>Hướng dẫn xuất bán</label>
                                    <span>{details?.userManual}</span>
                                </div>
                                <div className="info_text">
                                    <label>Hướng dẫn vận chuyển</label>
                                    <span>{details?.storageInstructions}</span>
                                </div>
                                <div className="info_text">
                                    <label>Ngày gửi hàng</label>
                                    <span>{dateUtils.formatDate(info?.sentDate)}</span>
                                </div>
                                <div className="info_text">
                                    <label>Hạn sử dụng</label>
                                    <span>{dateUtils.formatDate(info?.expireDate)}</span>
                                </div>
                                <div className="info_text">
                                    <label>Địa điểm gửi hàng</label>
                                    <span>{info?.deliveryLocation}</span>
                                </div>
                                <div className="info_text">
                                    <label>Đơn vị tiêu thụ</label>
                                    <span>{info?.consumerUnit}</span>
                                </div>
                                <div className="info_text">
                                    <label>Mã đơn vị tiêu thụ</label>
                                    <span>{info?.consumerUnitCode}</span>
                                </div>
                            </div>
                            <div className="info_unit w-50 pl-4">
                                <div className="info_text">
                                    <label>Địa chỉ</label>
                                    <span>{info?.address}</span>
                                </div>
                                <div className="info_text">
                                    <label>Đơn vị vận chuyển</label>
                                    <span>{info?.shippingPartner}</span>
                                </div>
                                <div className="info_text">
                                    <label>Mã đơn vị vận chuyển</label>
                                    <span>{info?.shippingPartnerCode}</span>
                                </div>
                                <div className="info_text">
                                    <label>Số lượng đã xuất</label>
                                    <span>{info?.quantityShipped}</span>
                                </div>
                                <div className="info_text">
                                    <label>Số lượng tồn kho</label>
                                    <span>{info?.quantityLeft}</span>
                                </div>
                                <div className="info_text">
                                    <label>Đại lý phân phối</label>
                                    <span>{info?.distributingAgent}</span>
                                </div>
                                <div className="info_text">
                                    <label>Mã đơn vị phân phối</label>
                                    <span>{info?.distributingUnitCode}</span>
                                </div>
                                {info && info?.detailMore.map((item: any, i: number) => {
                                    return (
                                        <div className="info_text" key={i}>
                                            <label>{item.key}</label>
                                            <span>{item.value}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {details?.description && <>
                            <h2>Mô tả quy trình chăn nuôi</h2>
                            <div className="w-100 info_">
                                <div dangerouslySetInnerHTML={{__html: details?.description}}/>
                            </div>
                        </>}
                        <Content2/>
                    </>}
            </div>
        </div>
    );
}

export default observer(InfoTrackingBatchCodeLivestock);