import {getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {trackingStore} from "./TrackingStore";
import {trackingDetailStore} from "./trackingDetail/TrackingDetailStore";


class TrackingService {
    public getTracking(): Promise<IApiResponse> {
        return getRequest(`/v1/tracking?page=${trackingStore.page}&size=10${trackingStore.searchYear ? `&year=${trackingStore.searchYear}` : ''}${trackingStore.keyword ? `&keyword=${trackingStore.keyword}` : ''}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/tracking/plan/${id}`);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/tracking/plan/${id}`, data);
    }

    public getTrackingQR(): Promise<IApiResponse>{
        return getRequest(`/v1/tracking/${trackingDetailStore.id}/qr_batch?page=${trackingDetailStore.page}&size=10`)
    }

    public detailTrackingBatch(id: any): Promise<IApiResponse>{
        return getRequest(`/v1/tracking_batch/${id}`)
    }

    public detailTrackingQRBatch(id: any): Promise<IApiResponse>{
        return getRequest(`/v1/tracking/${id}`)
    }

    public historyQr(id: any): Promise<IApiResponse>{
        return getRequest(`/v1/tracking_batch/${id}/qr_files?page=${trackingDetailStore.pageHistory}&size=5`)
    }

    public addTrackingQR(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/tracking/${trackingDetailStore.id}/qr_batch`, data);
    }

    public editTrackingQR(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/tracking_batch/${id}`, data);
    }

    public addLinkDownloadQr(id: any, qty: number): Promise<IApiResponse> {
        return getRequest(`/v1/tracking_batch/${id}/qr_export?qty=${qty}${trackingDetailStore.landId ? `&landId=${trackingDetailStore.landId}` : ''}`);
    }

    public printQr(id: any, path: string, itemOnRow: number, grayScale: boolean, backgroundPrint: boolean): Promise<IApiResponse> {
        return postRequest(`/v1/tracking_batch/${id}/pdf${path}`, {itemOnRow: itemOnRow, grayScale: grayScale, backgroundPrint: backgroundPrint});
    }
}

export const trackingService = new TrackingService()