import {postRequest, IApiResponse, getRequest, deleteRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {procedureJobStore} from "./ProcedureJobStore";


class ProcedureJobService {
    public getProcedureJob(): Promise<IApiResponse> {
        return getRequest(`/v1/procedure/${procedureJobStore.procedureId}/job?page=0&size=100`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/procedure/${procedureJobStore.procedureId}/job/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/procedure/${procedureJobStore.procedureId}/job`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/procedure/${procedureJobStore.procedureId}/job/${id}`, data);
    }

    public sortJob(ids: any): Promise<IApiResponse> {
        return putRequest(`/v1/procedure/${procedureJobStore.procedureId}/job/sort`, {jobIds: ids});
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/procedure/${procedureJobStore.procedureId}/job/${id}`, {id});
    }
}

export const procedureJobService = new ProcedureJobService();