import React from 'react';
import {observer} from "mobx-react";
import Input from "../../../../common/component/form/Input";
import {stageStore} from "../StageStore";

const AddOrEditStage = () => {

    return (
        <div className="modal fade" id="add_or_edit_stage" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Thêm giai đoạn</h5>
                        <button type="button" className="close" id="close_add_edit_stage" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="form-group">
                            <label>Giai đoạn <span className="text-danger">*</span></label>
                            <Input onChange={(e: any) => stageStore.dataRequest.name = e.currentTarget.value}
                                   value={stageStore.dataRequest.name} isError={stageStore.errors.name} placeholder={"Nhập tên giai đoạn"}/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => stageStore.type == "add" ? stageStore.add() : stageStore.update()}
                                disabled={stageStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(AddOrEditStage);