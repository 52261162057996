import React from 'react';
import './notfound.scss'
import {Link} from "react-router-dom";
import StorageService from "../../common/service/StorageService";

const NotFoundPage = () => {

    return (
        <div className="d-flex notfound align-items-center justify-content-center">
            <div className="content text-center">
                <img src="/assets/images/not_found.svg" alt=""/>
                <h2>Oops! Trang bạn tìm kiếm không tồn tại.</h2>
                <span>Website đang nâng cấp do đó có một số link có thể được thay đổi.<br/>
                Bạn vui lòng trở lại <span className='color-green'>trang chủ</span> hoặc tìm kiếm với từ khóa khác!</span>
                <div className="d-flex justify-content-center">
                    <Link className="send-request" to={StorageService.getToken() ? `/dashboard` : '/login'}>Trở về trang chủ</Link>
                </div>
            </div>
        </div>
    );

}

export default NotFoundPage;