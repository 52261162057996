import React from 'react';
import {observer} from "mobx-react";
import {urlImage, urlImageSSO} from "../../../common/utils/Utils";
import {dateUtils} from "../../../common/utils/DateUtils";
import {profileStore} from "../../profile/ProfileStore";
import {Icon} from "../../../common/component/Icon";
import {questionStore} from "../QuestionStore";

interface IProps {
    item: any
    key: any
}


const ItemComment = ({item, key}:IProps) => {

    return (
        <div className={`item`} key={key}>
            <div className="info_user">
                <div className={`${item.user.avatar ? '' : 'no_'}avatar`}>
                    {item.user.avatar ? <img src={urlImageSSO(item.user.avatar)} alt=""/> : <Icon name={'person'} type="round"/>}
                </div>
                <div className="role">{item.user?.role?.name}</div>
            </div>
            <div className="content">
                <div className="description" dangerouslySetInnerHTML={{__html: item.content}}/>
                {item.attachFiles && item.attachFiles.length > 0 && <>
                    <div className="list_attachFiles"
                         style={{width: `${(item.attachFiles.length * 132) - 12}px`}}>
                        {item.attachFiles.map((val: any, i: number) => {
                            return (
                                <div className="item_img cursor_pointer"  onClick={() => {questionStore.listImages = item.attachFiles}} data-toggle="modal" data-target="#popupZoom_1" key={i}>
                                    <img src={urlImage(val)} alt=""/>
                                </div>
                            )
                        })}
                    </div>
                </>}
                <div className="d-flex align-items-center justify-content-end">
                    <div className="d-flex">
                        <span className="time mr-4">{item.user.name}</span>
                        <span
                            className="time mr-4">{item.createdAt == item.updatedAt ? '' : 'Đã chỉnh sửa'}</span>
                        <span
                            className="time">{dateUtils.formatDate(item.updatedAt, 'DD/MM/YYYY hh:mm A')}</span>
                    </div>
                </div>
            </div>
            {profileStore.dataFarm.userId == item.user.id &&
            <div className="action">
                <div className="dropdown">
                    <div id={`actionComment${item.id}`} data-toggle="dropdown" aria-haspopup="true"
                         aria-expanded="false">
                        <Icon name="more_horiz" type="outlined"/>
                    </div>
                    <div className="dropdown-menu" aria-labelledby={`actionComment${item.id}`}>
                        <a className="dropdown-item" href="#send_comment" onClick={() => questionStore.getDetailComment(item.id)}><img
                            src="/assets/ico/action/ico_edit.svg" alt=""/> Sửa</a>
                        <button className="dropdown-item text-danger"
                                onClick={() => questionStore.commentId = item.id}
                                data-toggle="modal" data-target="#popup_confirm_comment"><img
                            src="/assets/ico/action/ico_trash.svg" alt=""/> Xóa
                        </button>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default observer(ItemComment);