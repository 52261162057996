import {observable} from "mobx";


export enum ITabDiary{
    jobDiary = "jobDiary",
    schedule = "schedule",
    progress = "progress"
}


class DiaryStore{
    @observable landId: string = ''
    @observable tabActive: any = ITabDiary.jobDiary
}

export const diaryStore = new DiaryStore()