import React from 'react';
import {observer} from "mobx-react";
import {profileStore} from "../ProfileStore";
import InputPassword from "../../../common/component/InputPassword";


const ChangePass = () => {

    return (
        <div className="change_pass d-flex justify-content-center">
            <div className="form">
                <div className="form-group">
                    <label>Mật khẩu hiện tại</label>
                    <InputPassword value={profileStore.userPassword.oldPassword}
                                   isError={profileStore.errors.oldPassword}
                                   onChange={(e: any) => profileStore.userPassword.oldPassword = e.currentTarget.value}
                                   placeholder="Nhập mật khẩu hiện tại"/>
                </div>
                <div className="form-group">
                    <label>Mật khẩu mới</label>
                    <InputPassword value={profileStore.userPassword.password}
                                   isError={profileStore.errors.password}
                                   onChange={(e: any) => profileStore.userPassword.password = e.currentTarget.value}
                                   placeholder="Nhập mật khẩu mới"/>
                </div>
                <div className="form-group">
                    <label>Xác nhận mật khẩu</label>
                    <InputPassword value={profileStore.userPassword.confirmPassword}
                                   isError={profileStore.errors.confirmPassword}
                                   onChange={(e: any) => profileStore.userPassword.confirmPassword = e.currentTarget.value}
                                   placeholder="Xác nhận mật khẩu mới"/>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn send-request" onClick={() => profileStore.changePassword()}
                            disabled={profileStore.isLoadingBt}>Lưu
                    </button>
                </div>
            </div>
        </div>
    );
}

export default observer(ChangePass);