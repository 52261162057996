import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import Loading from "../../../common/component/Loading";
import NoContent from "../../../common/component/NoContent";
import './AccountManagerPage.scss'
import {accountManagerStore} from "./AccountManagerStore";
import ButtonAdd from "../../../common/component/ButtonAdd";
import AddOrEditAccount from "./components/modalAddEditAccount/ModalAccount";
import PopupConfirm from "../../../common/component/PopupConfirm";
import {dateUtils} from "../../../common/utils/DateUtils";
import FilterComponent from "../../../common/component/filter/FilterComponent";
import {userStore} from "../users/UserStore";

const convertLevel = (value: string) => {
    switch (value) {
        case "center_manage":
            return "Cấp TW"
        case "province_manage":
            return "Cấp Tỉnh/ TP"
        case "district_manage":
            return "Cấp Huyện"
        default:
            return null
    }
}

const AccountManagerPage = () => {

    useEffect(() => {
        accountManagerStore.getListAccount().then()
        accountManagerStore.getProvinces().then()
    }, [])

    return (
        <>
        <div className="account_manager p-4">
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <h2>TÀI KHOẢN QUẢN LÝ</h2>

               <div className="d-flex align-items-center">
                   <div className="filter_search position-relative mr-2" style={{width: `190px`}}>
                       <input type="text" onChange={(e: any) => accountManagerStore.keyword = e.currentTarget.value} onKeyDown={async (e: any) => {
                           if (e.key === 'Enter') {
                               accountManagerStore.page = 0
                               await accountManagerStore.getListAccount()
                           }}} placeholder={"Nhập từ khóa"}/>
                       <i className="far fa-search color-green position-absolute" onClick={async () => {
                           accountManagerStore.page = 0
                           await accountManagerStore.getListAccount()
                       }}/>
                   </div>
                   <ButtonAdd text="Thêm tài khoản" className="text-white" dataTarget={'add_or_edit_account_manager'} onClick={() => {accountManagerStore.clearForm()}}/>
               </div>
            </div>
            {accountManagerStore.isLoading ? <Loading/> :
                <div className='table-responsive'>
                    {accountManagerStore.listAccount && accountManagerStore.listAccount.length > 0 ?
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th className="text-center">Tên tài khoản</th>
                                <th className="text-center">Số điện thoại</th>
                                <th className="text-center">Cấp quản lý</th>
                                <th className="text-center">Tỉnh</th>
                                <th className="text-center">Huyện</th>
                                <th className="text-center">Ngày tạo</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {accountManagerStore.listAccount.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{i + 1}</td>
                                        <td className="text-center">{item?.user?.name}</td>
                                        <td className="text-center">{item?.user?.phone}</td>
                                        <td className="text-center">{convertLevel(item?.level)}</td>
                                        <td className="text-center">{item?.province?.name ?? "-"}</td>
                                        <td className="text-center">{item?.district?.name ?? "-"}</td>
                                        <td className="text-center">{dateUtils.formatDate(item.createdAt)}</td>
                                        <td className="text-center">
                                            <div className="action">
                                                <button data-toggle="modal"
                                                        onClick={async () => {
                                                            accountManagerStore.type = 'edit'
                                                            accountManagerStore.id = item.id
                                                            await accountManagerStore.getDetailAccountById(item.id)
                                                        }}
                                                        data-target="#add_or_edit_account_manager"
                                                        className="bt_action" title="Chi tiết">
                                                        <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                </button>
                                                <button className="bt_action" title="Xóa"
                                                        onClick={() => {
                                                            accountManagerStore.id = item.id
                                                        }}
                                                        data-toggle="modal" data-target="#popup_confirm">
                                                    <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                </div>}
        </div>
            <AddOrEditAccount />
            <PopupConfirm isLoading={accountManagerStore.isLoadingBt} action={accountManagerStore.delete}/>
        </>

    )
}

export default observer(AccountManagerPage);