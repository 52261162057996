import React from 'react';
import {observer} from "mobx-react"
import {profileStore} from "../ProfileStore";
import DatePickerSingle from "../../../common/component/DatePickerSingle";
import {urlImage} from "../../../common/utils/Utils";
import uploadFileHelper from "../../../common/helpers/UploadFileHelper";


const ChangeAccount = () => {

    const handleFilterDate = async (startDate: Date) => {
        profileStore.dataRequest.birthDay = startDate.getTime();
    };

    const changeImg = (e: any) => {
        profileStore.dataRequest.avatar = e
    }


    return (
        <div className="change_account d-flex justify-content-center">
            <div>
                <div className="avatar_user" style={{border: '1px solid #009444'}}>
                    <img className="avatar" src={profileStore.dataRequest.avatar ? urlImage(profileStore.dataRequest.avatar) : '/assets/ico/user_default.svg'} alt='' />
                    <div className="upload" title="Chọn ảnh">
                        <img src="/assets/ico/ico_camera.svg" alt=""/>
                        <input accept='image/jpeg, image/jpg, image/png' type='file' onChange={async (e: any) => await uploadFileHelper(e, changeImg)} />
                    </div>
                </div>
                <div className="d-flex h-auto avatar_user align-items-center justify-content-center mt-2">
                    <span>Avatar</span>
                </div>
            </div>

            <div className="form">
                <div className="form-group h-auto mb-4 d-flex align-items-center">
                    <p>Họ và tên</p>
                    <input type="text" className="form-control" value={profileStore.dataRequest.name} onChange={(e: any) => profileStore.dataRequest.name = e.currentTarget.value} placeholder="Tên của bạn"/>
                </div>
                <div className="form-group h-auto mb-4 d-flex align-items-center">
                    <p>Số điện thoại</p>
                    <input type="text" className="form-control" value={profileStore.dataRequest.phone} disabled={true}/>
                </div>

                <div className="form-group h-auto mb-4 d-flex align-items-center">
                    <p>Địa chỉ</p>
                    <input type="text" className="form-control" value={profileStore.dataRequest.address} onChange={(e: any) => profileStore.dataRequest.address = e.currentTarget.value} placeholder="Nhập địa chỉ của bạn"/>
                </div>

                <div className="form-group h-auto mb-4 d-flex align-items-center">
                    <p>Email</p>
                    <input type="text" className="form-control" value={profileStore.dataRequest.email} onChange={(e: any) => profileStore.dataRequest.email = e.currentTarget.value} placeholder="Email của bạn"/>
                </div>

                <div className="form-group h-auto mb-4 d-flex align-items-center">
                    <p>Ngày sinh</p>
                    <DatePickerSingle selected={profileStore.dataRequest.birthDay} onChange={handleFilterDate} placeholder="Chọn ngày sinh"/>
                </div>

                <div className="form-group h-auto mb-4 d-flex align-items-center">
                    <p>Giới tính</p>
                    <div className="d-flex align-items-center">
                        <div className="form-check">
                            <input className="form-check-input ml-0" type="radio" name="radio_gender" id="male" value={0}  onClick={(e: any) => profileStore.dataRequest.gender = e.currentTarget.value} checked={profileStore.dataRequest.gender == 0 && true}/>
                            <label className="form-check-label" htmlFor="male">Nam</label>
                        </div>
                        <div className="form-check ml-5">
                            <input className="form-check-input ml-0" type="radio" name="radio_gender" id="female" value={1} onClick={(e: any) => profileStore.dataRequest.gender = e.currentTarget.value} checked={profileStore.dataRequest.gender == 1 && true}/>
                            <label className="form-check-label" htmlFor="female">Nữ</label>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn send-request" onClick={() => profileStore.updated()}
                            disabled={profileStore.isLoadingBt}>Lưu
                    </button>
                </div>
            </div>
        </div>
    );
}

export default observer(ChangeAccount);