import {observable} from "mobx";
import {IFilter} from "../../../common/component/filter/FilterComponent";
import {diaryService} from "../DiaryService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";

class ProgressStore {
    @observable isLoading: boolean = false
    @observable planId: any = ''
    @observable listProgress: any[] = []
    @observable dataFilter: IFilter[] = []

    async getProgress(){
        progressStore.isLoading = true
        const result = await diaryService.getProgress(progressStore.planId)
        progressStore.isLoading = false
        if(result.status === HttpStatusCode.OK){
            progressStore.listProgress = result.body
        }
    }


}

export const progressStore = new ProgressStore()