import {postRequest, IApiResponse, getRequest, deleteRequest} from "../../common/helpers/RequestHelper";
import StorageService from "../../common/service/StorageService";

class AccountService {

    public logOut(): Promise<IApiResponse> {
        return deleteRequest('/sso/v1/auth/logout', {token: StorageService.getToken()});
    }

    public getProfile(): Promise<IApiResponse> {
        return getRequest(`/v1/users/me`)
    }

    public updateUser(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/users`, data)
    }

    public changePass(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/auth/change-password`, data)
    }

    public getProvinces(): Promise<IApiResponse> {
        return getRequest(`/v1/provinces`)
    }

    public getDistricts(provinceId: any): Promise<IApiResponse> {
        return getRequest(`/v1/districts?provinceId=${provinceId}`)
    }

    public getWards(districtId: any): Promise<IApiResponse> {
        return getRequest(`/v1/wards?districtId=${districtId}`)
    }

    public getPackageInfo(): Promise<IApiResponse> {
        return getRequest(`/v1/farm_packages/package_info`)
    }
}

export const accountService = new AccountService();
