import React from 'react';
import {observer} from "mobx-react";
import {signUpStore} from "../../auth/signUp/SignUpStore";
import Loading from "../../../common/component/Loading";
import NoContent from "../../../common/component/NoContent";
import {landStore} from "../../setup/land/LandStore";
import {planStore} from "../PlanStore";
import Select from "../../../common/component/form/Select";
import {numberUtil} from "../../../common/utils/NumberUtil";


const AddPlanStepTwo = observer(() => {

    const changProvince = async (id: any) => {
        planStore.dataRequest.provinceId = id;
        planStore.dataRequest.districtId = null;
        signUpStore.listDistrict = []
        if (id) {
            await signUpStore.getDistricts(id)
        }
        await landStore.getLands();
    }

    const changDistrict = async (id: any) => {
        planStore.dataRequest.districtId = id;
        await landStore.getLands();
    }

    const checkAll = () => {
        planStore.totalArea = 0
        planStore.dataRequest.landIds = []
        planStore.checkAll = !planStore.checkAll
        landStore.listLand.map((item: any) => {
            if (planStore.checkAll) {
                item.isActive = true
                planStore.totalArea += item.area
                planStore.dataRequest.landIds.push(item.id)
            } else {
                item.isActive = false
                planStore.dataRequest.landIds = []
            }
        })
    }

    const onCheck = (id: any) => {
        let checkAll = true
        if(!planStore.dataRequest.landIds){
            planStore.dataRequest.landIds = []
        }
        landStore.listLand.map((item: any) => {
            if (item.id == id) {
                item.isActive = !item.isActive
                if (item.isActive) {
                    planStore.dataRequest.landIds.push(item.id)
                    planStore.totalArea += item.area
                } else {
                    planStore.dataRequest.landIds.map((value: any, i: number) => {
                        if (value == item.id) {
                            planStore.dataRequest.landIds.splice(i, 1)
                        }
                    })
                    planStore.totalArea -= item.area
                }
            }
            if (!item.isActive) {
                checkAll = false
            }
        })

        planStore.checkAll = checkAll
    }

    let count = 0

    return (
        <div className="modal fade" id="add_edit_plan_two" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header pb-3">
                        <h5 className="modal-title">{planStore.type == 'add' ? 'Tạo' : 'Sửa'} kế hoạch sản
                            xuất</h5>
                        <button type="button" className="close" id="close_add_edit" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-0 pb-0">
                        <div className="tab_filter">
                            <div className="d-flex align-items-center" style={{marginBottom: 12}}>
                                <img src="/assets/ico/filter.svg" alt=""/>
                                <span>Lọc theo địa chỉ</span>
                            </div>
                            <div className="w-100 d-flex align-items-center">
                                <div className="w-50 mr-4">
                                    <Select value={planStore.dataRequest.provinceId} placeholder="Tỉnh / Thành Phố"
                                            onChange={(e: any) => changProvince(e)} options={signUpStore.listProvince}/>
                                </div>
                                <div className="w-50 ml-4">
                                    <Select value={planStore.dataRequest.districtId} placeholder="Quận / Huyện"
                                            onChange={(e: any) => changDistrict(e)} options={signUpStore.listDistrict}/>
                                </div>
                            </div>
                        </div>

                        {landStore.isLoading ? <Loading height={260}/> :
                            <div className='table-responsive table_land'>
                                {landStore.listLand && landStore.listLand.length > 0 ?
                                    <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                                        <thead>
                                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                            <th className="text-center"><input type="checkbox" checked={planStore.checkAll} onClick={() => checkAll()}  onChange={() =>{}} className="check_all"/></th>
                                            <th className="text-center">STT</th>
                                            <th className="text-center">Tên đất cơ sở</th>
                                            <th className="text-center">Diện tích (ha)</th>
                                            <th className="text-center">Nông hộ</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {landStore.listLand.map((item, i) => {
                                            planStore.dataRequest.landIds && planStore.dataRequest.landIds.map((value: any) => {
                                                if (item.id == value) {
                                                    item.isActive = true
                                                    count ++
                                                }
                                            })

                                            if(count == landStore.listLand.length){
                                                planStore.checkAll = true
                                            }

                                            return (
                                                <tr key={i}>
                                                    <td className="text-center"><input type="checkbox" checked={item.isActive} onClick={() => onCheck(item.id)} onChange={() =>{}} className="check_item"/></td>
                                                    <td className="text-center">{i+1}</td>
                                                    <td className="text-center">{item.name}</td>
                                                    <td className="text-center">{item.area}</td>
                                                    <td className="text-center">{item.farmHousehold}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table> : <NoContent height={260}/>}
                            </div>}
                        <div className="d-flex pr-4 w-100 justify-content-end">
                            <p className="error w-auto">{planStore.dataRequest.landIds && planStore.dataRequest.landIds.length < 1 && planStore.errors.land}</p>
                        </div>
                        <div className="expectedOutput d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <span>Tổng</span> <span
                                className="m-0">{parseFloat(planStore.totalArea.toFixed(1))} Ha</span>
                            </div>
                            <div className="d-flex align-items-center">
                                <span>Sản lượng dự kiến <span className="text-danger">*</span></span> <input type="text" className={planStore.errors.expectedOutput && !planStore.dataRequest.expectedOutput ? 'border_error' : ''}
                                                                      value={planStore.dataRequest.expectedOutput}
                                                                      onChange={(e: any) => planStore.dataRequest.expectedOutput = numberUtil.regexNumber(e)}
                                                                      placeholder="Nhập sản lượng dự kiến"/> <span
                                className="ml-3 mr-0">Kg</span>
                            </div>
                        </div>
                        <div className="d-flex pr-4 w-100 justify-content-end">
                            <p className="error w-auto">{!planStore.dataRequest.expectedOutput && planStore.errors.expectedOutput && planStore.errors.expectedOutput}</p>
                        </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                        <button type="button" className="btn bt-back-outline" data-toggle="modal"
                                data-target="#add_or_edit_plan" data-dismiss="modal"
                                disabled={planStore.isLoadingBt}>Quay lại
                        </button>
                        <button type="button" className="btn send-request"
                                onClick={() => planStore.type == 'add' ? planStore.add() : planStore.update()}
                                disabled={planStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
})

export default AddPlanStepTwo;