import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {planStore} from "./PlanStore";

class PlanService {
    public getPlanning(size?: number): Promise<IApiResponse> {
        return getRequest(`/v1/plan?page=${planStore.page}&size=${size ?? 10}${planStore.searchYear ? `&year=${planStore.searchYear}` : ''}${planStore.searchName ? `&name=${planStore.searchName.trim()}` : ''}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/plan/${id}`);
    }

    public getLandPlan(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/plan/${id}/land`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/plan`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/plan/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/plan/${id}`, {id});
    }
}

export const planService = new PlanService()