import React, { useEffect} from 'react';
import {observer} from "mobx-react";
import Loading from "../../../common/component/Loading";
import NoContent from "../../../common/component/NoContent";
import {roleStore} from "./RoleStore";
import {Link} from "react-router-dom";
import {slug} from "../../../common/utils/Utils";


const RoleComponent = () => {

   useEffect(() => {
       roleStore.getRoles()
   }, [])

    if (roleStore.isLoading) {
        return <Loading/>
    } else return (
        <div className='table-responsive'>
            <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                    <th className="text-center">STT</th>
                    <th>Chức vụ</th>
                    <th className="text-center">Thao tác</th>
                </tr>
                </thead>
                <tbody>

                {roleStore.listRole && roleStore.listRole.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td width="10%" className="text-center">{i + 1}</td>
                            <td>{item.name}</td>
                            <td className="text-center" width="20%">
                                <button className="bt-outline-action">
                                    <Link to={`/cms/permission/${slug(item.name)}/${item.id}`}>Cập nhật</Link>
                                </button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>

            {roleStore.listRole && roleStore.listRole.length < 1 && <NoContent/>}

        </div>
    );
}

export default observer(RoleComponent);