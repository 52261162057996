import React from 'react';

interface IProps {
    text: string,
    dataTarget: string
    onClick?: any,
    className?: any,
    icon?: string,
}


const ButtonAdd = (item: IProps) => {

    return (
        <button className={`btn button_add ${item.className}`} onClick={() => item.onClick()} data-toggle="modal" data-target={`#${item.dataTarget}`}>
            {item?.icon
                ? <img src={item?.icon} alt="" className="icon_action mr-8px" />
                : <i className="fal fa-plus" />
            }
            <span>{item.text}</span>
        </button>
    );
}

export default ButtonAdd;