import React from 'react'
import { useNavigate } from 'react-router-dom'
import { historyOrderFarmStore } from '../../historyOrderFarm/HistoryOrderFarmStore';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

function SentSuccessfullyModal() {
    let navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="modal fade" id="sent-success-modal" role="dialog" aria-hidden="true" data-backdrop="static">
            <div className="modal-dialog" role="document" style={{ maxWidth: 760 }}>
                <div className="modal-content border-0 px-15px py-68px">
                    <button type="button" id="close_sent-success-modal" className="close d-none" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="modal-body p-0 d-flex flex-column align-items-center">
                        <img src="/assets/ico/empty-wallet-tick.svg" width={100} alt="" />
                        <span className='mt-16px fs-24px fw-700 mb-16px'>{t("requestSuccessfully")}</span>
                        <span className='fw-600'>{t("notificationSenSuccess.notifi_1")}</span>
                        <span className='fw-600'>Hotline: <span className='color-00953D'>0865 309 299</span> {t("notificationSenSuccess.notifi_2")}</span>
                        <button
                            className='btn button_add text-white fs-16px mt-16px rounded-4px'
                            data-dismiss="modal"
                            onClick={() => {
                                historyOrderFarmStore.getHistoryOrderFarm()
                                navigate("/history-order-farm")
                            }}>
                            Đến lịch sử mua
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(SentSuccessfullyModal)