import {observable} from "mobx";
import {stageService} from "./StageService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {userService} from "../../admin/users/UserService";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery";
import {IStatus, userStore} from "../../admin/users/UserStore";
import {questionService} from "../../question/QuestionService";
import {questionStore} from "../../question/QuestionStore";


class StageStore {
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable id: any = ''
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable type: string = ''
    @observable dataRequest: any = {
        name: ''
    }
    @observable errors: any = {
        name: ''
    }
    @observable listStage: any[] = []

    clearForm() {
        stageStore.type = 'add'
        stageStore.id = ''
        stageStore.dataRequest = {
            name: ''
        }
        stageStore.errors = {
            name: ''
        }
    }

    async getStages() {
        stageStore.isLoading = true
        const result = await stageService.getStages()
        stageStore.isLoading = false
        if(result.status === HttpStatusCode.OK){
            stageStore.listStage = result.body.data
            stageStore.totalPage = result.body.totalPage
        }
    }

    async detailStage(id: number) {
        stageStore.type = 'edit'
        const result = await stageService.getDetail(id)
        if (result.status == HttpStatusCode.OK) {
            stageStore.dataRequest = result.body
        }
    }

    async add() {
        let data = {
            name: stageStore.dataRequest.name
        }

        if(!data.name){
            stageStore.errors.name = "Tên giai đoạn không được để trống"
            return false
        }else stageStore.errors.name = ""

        stageStore.isLoadingBt = true
        const result = await stageService.add(data)
        stageStore.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            toastUtil.success('Thêm giai đoạn thành công!')
            $('#close_add_edit_stage').trigger('click')
            stageStore.clearForm()
            await stageStore.getStages()
        }else {
            toastUtil.error(result.body.message, 1)
        }
    }

    async update() {
        let data = {
            name: stageStore.dataRequest.name
        }

        if(!data.name){
            stageStore.errors.name = "Tên giai đoạn không được để trống"
            return false
        }else stageStore.errors.name = ""

        stageStore.isLoadingBt = true
        const result = await stageService.update(stageStore.dataRequest.id, data)
        stageStore.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            toastUtil.success('Cập nhật giai đoạn thành công!')
            $('#close_add_edit_stage').trigger('click')
            stageStore.clearForm()
            await stageStore.getStages()
        }else {
            toastUtil.error(result.body.message, 1)
        }
    }

    async delete() {
        stageStore.isLoadingBt = true;
        const result = await stageService.delete(stageStore.id)
        stageStore.isLoadingBt = false;
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Xóa câu hỏi thành công!', 3)
            $('#close_delete').trigger('click')
            await stageStore.getStages()
        }else {
            toastUtil.error(result.body.message , 4)
        }
    }
}

export const stageStore = new StageStore()