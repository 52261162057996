import React, { useEffect } from 'react';
import {observer} from "mobx-react";
import "./TrackingStyle.scss"
import FilterComponent from "../../common/component/filter/FilterComponent";
import Loading from "../../common/component/Loading";
import {checkPermission, slug, sttPage} from "../../common/utils/Utils";
import NoContent from "../../common/component/NoContent";
import {planStore} from "../planning/PlanStore";
import UploadImages from "./components/UploadImages";
import {PermissionConst} from "../../common/constants/Constants";
import Paginate from "../../common/component/Paginate";
import {trackingStore} from "./TrackingStore";
import UpdateTracking from "./components/UpdateTracking";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


const TrackingPage = () => {

    const { t } = useTranslation();

    const changePage = async (data: any) => {
        trackingStore.page = data.selected;
        await trackingStore.getTracking();
    }

    useEffect(() => {
        trackingStore.getTracking();
        trackingStore.dataFilter = [
            {
                label: t('year'),
                type: 'select',
                value: trackingStore.searchYear,
                listValue: planStore.listYear,
                callback: async (e: any) => {
                    trackingStore.searchYear = e
                    trackingStore.page = 0
                    await trackingStore.getTracking();
                }
            },
            {
                label: t('plan'),
                type: 'search',
                placeholder: localStorage.getItem('language') == 'en' ? 'Enter plan name' : 'Nhập tên kế hoạch',
                value: trackingStore.keyword,
                onChange: (e: any) => {
                    trackingStore.page = 0
                    trackingStore.keyword = e
                },
                callback: async () => {
                    await trackingStore.getTracking();
                }
            }
        ]
        
        return () => {
            trackingStore.searchYear = trackingStore.date.getFullYear()
            trackingStore.keyword = ''
        }
    }, [])


    return (
        <div className="tracking">
            {trackingStore.dataFilter &&
            <FilterComponent data={trackingStore.dataFilter}/>}
            {trackingStore.isLoading ? <Loading/> :
                <div className='table-responsive'>
                    {trackingStore.listTracking && trackingStore.listTracking.length > 0 ?
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center">{t('year')}</th>
                                    <th>{t('planName')}</th>
                                    <th>{t('product')}</th>
                                    <th className="text-center">{t('image')}</th>
                                    <th className="text-center">{t('barcode')}</th>
                                    <th className="text-center">{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>

                            {trackingStore.listTracking.map((item, i) => {
                                let pageHelper = `${window.location.origin}/truy-xuat/${slug(item.plan?.procedure?.product?.name)}/${item.id}`

                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(trackingStore.page, i)}</td>
                                        <td className="text-center">{item.plan?.year}</td>
                                        <td>{item.plan?.name}</td>
                                        <td>{item.plan?.procedure?.product?.name}</td>
                                        <td className="text-center">
                                            <img className="cursor_pointer" style={{width: 28, height: 28, borderRadius: 0}} data-toggle="modal" data-target="#upload_images_tracking" title="Hình ảnh nông trường sản phẩm"
                                                 onClick={async () => await trackingStore.getDetail(item.id)} src="/assets/ico/ico_images.svg" alt=""/></td>
                                        <td className="text-center">
                                            <img className="cursor_pointer" style={{width: 28, height: 28, borderRadius: 0}}  data-toggle="modal"
                                                 data-target="#update_tracking"
                                                 onClick={async () => {
                                                     trackingStore.pageHelper = pageHelper;
                                                     await trackingStore.getDetail(item.id)
                                                 }} title={t('barcode')}
                                                 src="/assets/ico/ico_barcode.svg" alt=""/></td>
                                        <td width="20%" className="text-center">
                                            <div className="action d-flex align-items-center justify-content-center">
                                                <Link className="text-center mr-3" to={`/tracking-detail/${slug(item.plan?.name)}/${item.id}`} title={t('view')}>
                                                    <img src="/assets/ico/action/ico_detail.svg" alt=""/></Link>
                                                {checkPermission(PermissionConst.trackingUpdate) &&
                                                <img className="cursor_pointer mr-3" data-toggle="modal" title={t('edit')}
                                                     data-target="#update_tracking"
                                                     onClick={async () => {
                                                         trackingStore.pageHelper = pageHelper;
                                                         await trackingStore.getDetail(item.id)
                                                     }} src="/assets/ico/action/ico_edit.svg" alt=""/>}
                                                {checkPermission(PermissionConst.trackingUpdate) &&
                                                <img className="cursor_pointer" src="/assets/ico/action/ico_image.svg" data-toggle="modal"
                                                     data-target="#upload_images_tracking"
                                                     title={t('productFarmImages')}
                                                     onClick={async () => await trackingStore.getDetail(item.id)}
                                                     alt=""/>}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Paginate currentPage={trackingStore.page} totalPage={trackingStore.totalPage} changePage={changePage}/>
                    </div>
                    <UpdateTracking/>
                    <UploadImages/>
                </div>}
        </div>
    )

}

export default observer(TrackingPage);