import React from 'react'

interface IProps {
    value?: string | null;
    className?: string;
    line?: number;
}

function TextOverflowEllipsis({ value, className, line }: IProps) {
    return (
        value ? <div
        className='line-height-0px'
            title={value}>
            <p
                className={`${className || ""} m-0 text-overflow-ellipsis`}
                style={{ WebkitLineClamp: line || 1 }}
            >
                {value || ""}
            </p>
        </div> : <span>-</span>
    )
}

export default TextOverflowEllipsis