import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from 'jquery';
import {distributorService} from "./DistributorService";

class DistributorStore {
    @observable id: any = ''
    @observable page: number = 0
    @observable typeModal: string = ""
    @observable keyword: string = ""
    @observable totalPage: number = 0
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable listDistributor: any[] = []
    @observable errors: any = {
        name: "",
        phone: "",
    }
    @observable dataRequest: any = {
        id: '',
        name: "",
        phone: "",
        address: "",
    }

    clearError() {
        distributorStore.errors = {
            name: "",
            phone: ""
        }
    }
    clearForm() {
        distributorStore.typeModal = 'add';

        distributorStore.dataRequest = {
            id: '',
            name: "",
            phone: "",
            address: "",
        }
    }

    async getDistributor(size?: number) {
        this.isLoading = true
        const result = await distributorService.getDistributor(size)
        this.isLoading = false
        if(result.status === HttpStatusCode.OK){
            this.listDistributor = result.body.data
            this.totalPage = result.body.metadata.totalPage

        }else this.listDistributor = []
    }

    async getDetail(id: number) {
        this.typeModal = 'edit'
        const result = await distributorService.getDetail(id)
        if(result.status === HttpStatusCode.OK){
            this.dataRequest = result.body
        }
    }

    async addEdit() {
        let {id, name, phone, address} = this.dataRequest
        this.clearError()
        let data = {
            name: name,
            phone: phone,
            address: address,
        }

        if(!name){
            this.errors.name = 'Nhà phân phối không được để trống!'
        }
        if(!phone){
            this.errors.phone = 'Số điện thoại không được để trống!'
        }

        if(!name || !phone){
            return
        }

        this.isLoadingBt = true
        const result =  this.typeModal == 'add' ? await distributorService.create(data) : await distributorService.update(id, data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${this.typeModal === 'add' ? 'Thêm' : 'Cập nhật'} nhà phân phối thành công!`, 1)
            $('#close_add_edit_distributor').trigger('click')
            this.clearForm()
            await this.getDistributor()
        } else {
            if (typeof result.body.message === "string") {
                toastUtil.error(result.body.message, 3);
            } else if (typeof result.body.message === "object") {
                this.errors = result.body.message;
            }
        }
    }

    async delete() {
        distributorStore.isLoadingBt = true
        const result = await distributorService.delete(distributorStore.id)
        distributorStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`Xóa nhà phân phối thành công!`, 1)
            $('#close_delete').trigger('click')
            await distributorStore.getDistributor()
        } else toastUtil.error(result.body.message, 2)
    }
}

export const distributorStore = new DistributorStore()