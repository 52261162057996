import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import Paginate from "../../../common/component/Paginate";
import NoContent from "../../../common/component/NoContent";
import Loading from "../../../common/component/Loading";
import {number_format, slug, sttPage} from "../../../common/utils/Utils";
import {useTranslation} from "react-i18next";
import {productStore} from "../../setup/product/ProductStore";

const ProductPage = () => {

    const {t} = useTranslation();
    const changePage = async (data: any) => {
        productStore.page = data.selected;
        await productStore.getProducts();
    }

    useEffect(() => {
        productStore.getProducts().then();
        productStore.getProductsAll().then();
        return () => {
            productStore.clearForm()
        }
    }, [])

    if (productStore.isLoading) {
        return <Loading/>
    } else return (
        <div className="product_content">
            <div className='table-responsive'>
                {productStore.listProduct && productStore.listProduct.length > 0 ?
                    <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th className="text-center">STT</th>
                            <th>{t('productName')}</th>
                            <th className="text-center">{t('productCode')}</th>
                            <th className="text-center">{t('inventory')}</th>
                            <th className="text-center">{t('unit')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {productStore.listProduct.map((item, i) => {

                            return (
                                <tr key={i}>
                                    <td className="text-center">{sttPage(productStore.page, i)}</td>
                                    <td>{item.name}</td>
                                    <td className="text-center">{item.shortName}</td>
                                    <td className="text-center">{number_format(item.inventory)}</td>
                                    <td className="text-center">KG</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table> : <NoContent/>}
            </div>
            <div className="d-flex align-items-center justify-content-center mt-4">
                <Paginate currentPage={productStore.page} totalPage={productStore.totalPage}
                          changePage={changePage}/>
            </div>
        </div>
    );
}

export default observer(ProductPage)