import React from 'react';
import { warehouseStore } from "../WarehouseStore";
import { numberUtil } from "../../../common/utils/NumberUtil";
import NoContent from "../../../common/component/NoContent";
import { observer } from "mobx-react";
import { number_format } from "../../../common/utils/Utils";
import SelectAnt from '../../../common/component/form/Select';

const ImportExportInventory = () => {


    return (
        <div className="modal fade" id="import_export_inventory" role="dialog" aria-hidden="true">
            <div className="modal-dialog mt-5" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{warehouseStore.type == 'MINUS' ? 'Xuất' : 'Nhập'} Kho</h5>
                        <button type="button" className="close" id="close_import_export_inventory" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="form-group">
                            <label>Vật tư <span className="text-danger">*</span></label>
                            <SelectAnt
                                value={warehouseStore.dataRequest.suppliesId}
                                options={warehouseStore.listSuppliesAll}
                                convertDataSelectAnt={warehouseStore.convertDataSelectAnt}
                                onChange={(e: any) => warehouseStore.dataRequest.suppliesId = e}
                            />
                        </div>
                        <div className="d-flex justify-content-end mb-4">
                            <button className="btn btn-sm btn-success" onClick={() => warehouseStore.pushData()} disabled={!warehouseStore.dataRequest.suppliesId}>Thêm
                            </button>
                        </div>
                        <div className={`table-responsive mb-4 table_job`}>
                            {warehouseStore.listData && warehouseStore.listData.length > 0 ?
                                <table
                                    className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                                    <thead>
                                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                            <th>Tên</th>
                                            <th className="text-center">Mã vật tư</th>
                                            <th className="text-center">Loại</th>
                                            <th className="text-center">ĐVT</th>
                                            <th className="text-center">Số lượng</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {warehouseStore.listData.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="text-center">{item.name}</td>
                                                    <td className="text-center">{item.code}</td>
                                                    <td className="text-center">{item.type}</td>
                                                    <td className="text-center">{item.unit}</td>
                                                    <td className="text-center">
                                                        <input className={`form-control input_table ${item.error && !item.qty && 'border_error'}`} type="text" value={item.qty} placeholder={warehouseStore.type == "PLUS" ? 'Nhập số lượng' : `Tối đa ${number_format(item.inventory)}`} onChange={(e: any) => item.qty = numberUtil.regexNumber(e)} />
                                                    </td>
                                                    <td className="text-center"><i
                                                        onClick={() => warehouseStore.listData.splice(i, 1)}
                                                        style={{ fontSize: 16 }} className="fas fa-trash text-danger" /></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table> : <NoContent height={220} />}
                        </div>

                        <div className="form-group">
                            <label>Ghi chú</label>
                            <input className="form-control" value={warehouseStore.dataRequest.note}
                                onChange={async (e: any) => warehouseStore.dataRequest.note = e.currentTarget.value}
                                placeholder="Ghi chú" />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className={`btn send-request ${warehouseStore.type == 'MINUS' && 'bg_export'}`} onClick={() => warehouseStore.importExport()}
                            disabled={warehouseStore.isLoadingBt}>{warehouseStore.type == 'MINUS' ? 'Xuất' : 'Nhập'} Kho
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(ImportExportInventory);