import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Radio, Switch } from "antd";
import { packageStore, TypeDate, TypeTime } from "./PackageStore";
import AddEditPackage from "./components/AddEditPackage";
import Loading from "../../../common/component/Loading";
import FilterComponent from "../../../common/component/filter/FilterComponent";
import { number_format, sttPage } from "../../../common/utils/Utils";
import { numberUtil } from "../../../common/utils/NumberUtil";
import NoContent from "../../../common/component/NoContent";
import PopupConfirm from "../../../common/component/PopupConfirm";
import Paginate from "../../../common/component/Paginate";
import ButtonAdd from "../../../common/component/ButtonAdd";


const PackagePage = () => {

    const handlePageClick = async (data: any) => {
        packageStore.page = data.selected
        await packageStore.getPackage()
    }

    useEffect(() => {
        packageStore.getPackage().then()
    }, [])

    const callApi = () => {
        packageStore.page = 0
        packageStore.getPackage().then()
    }

    let dataFilter: any[] = [
        {
            label: "Tìm kiếm",
            type: 'search',
            placeholder: 'Nhập từ khóa',
            value: packageStore.name,
            onChange: (e: any) => packageStore.name = e,
            callback: () => callApi()
        },
    ]

    return (
        <div className="enterprise_page">
            <div className="d-flex mb-4 align-items-center">
                <FilterComponent isAdmin={true} data={dataFilter}
                    buttonAdd={<ButtonAdd text="Thêm mới" className="text-white"
                        dataTarget={'add_edit_package'}
                        onClick={() => packageStore.clearForm()} />} />
            </div>
            <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4 no-footer'>
                {packageStore.isLoading ? <Loading /> : <div>
                    <div className='table-responsive calc-max-h-vh-270px overflow-auto'>
                        {packageStore.listPackage && packageStore.listPackage.length > 0 ?
                            <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7 table-sticky-header '>
                                <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <th className="text-center">STT</th>
                                        <th>Tên gói</th>
                                        <th className="text-end">Giá gói</th>
                                        <th className="text-center">Thời gian</th>
                                        <th className="text-center">Số lượng <br /> người dùng</th>
                                        <th className="text-center">Gói <br /> khuyên dùng</th>
                                        <th className="text-center">Trạng thái</th>
                                        <th className="text-center">Thao tác</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {packageStore.listPackage?.map((item: any, i: number) => {
                                        return (
                                            <tr key={i}>
                                                <td className="text-center">{sttPage(packageStore.page, i)}</td>
                                                <td>{item.name}</td>
                                                <td className="text-end">{item.type === TypeTime.TRIAL ? "Miễn phí" : item.packagePrices && item.packagePrices.length > 0 ? numberUtil.formatNumber(item.packagePrices[0]?.price) : ''}</td>
                                                <td className="text-center">{item.packagePrices && item.packagePrices.length > 0 ? `${item.packagePrices[0]?.validityPeriod} ${item.type === TypeTime.PAID ? 'Năm' : 'Ngày'}` : ''}</td>
                                                <td className="text-center">{item.unlimitedUser ? 'Không giới hạn' : number_format(item.limitUser)}</td>
                                                <td className="text-center"><Radio checked={item.isRecommended} disabled={item.type == TypeTime.TRIAL}
                                                    onClick={() => packageStore.changeRecommended(item.id, item.isRecommended)} />
                                                </td>
                                                <td className="text-center">
                                                    <Switch title={!item.isActive ? "Bật" : "Ngưng"} className="color-00953D"
                                                        checked={item.isActive}
                                                        onChange={() => packageStore.changeState(item.id, item.isActive)}
                                                    />
                                                </td>
                                                <td width='10%'>
                                                    <div className="action">
                                                        <button className="bt_action" title={"Chỉnh sửa"}
                                                            data-toggle="modal" data-target="#add_edit_package"
                                                            onClick={() => packageStore.getDetail(item.id)}>
                                                            <img src="/assets/ico/action/ico_edit.svg" alt="" />
                                                        </button>

                                                        <button className="bt_action" title={"Xóa"}
                                                            onClick={() => packageStore.id = item.id}
                                                            data-toggle="modal" data-target="#popup_confirm">
                                                            <img src="/assets/ico/action/ico_trash.svg" alt="" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table> : <NoContent />}
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Paginate changePage={handlePageClick} totalPage={packageStore.totalPage}
                            currentPage={packageStore.page} />
                    </div>
                    <AddEditPackage />
                    <PopupConfirm isLoading={packageStore.isLoading} action={() => packageStore.delete()} />
                </div>}
            </div>
        </div>
    )
}

export default observer(PackagePage)