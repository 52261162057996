/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { profileStore } from "../profile/ProfileStore";
import "./styles/header.scss"
import { infoStore } from "../info/InfoStore";
import { checkExpirationTime, checkPermission, getIDParameter, urlImageSSO } from "../../common/utils/Utils";
import NoticeComponent from "../notice/NoticeComponent";
import { noticeStore } from "../notice/NoticeStore";
import StorageService from "../../common/service/StorageService";
import { PermissionConst } from "../../common/constants/Constants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { managerInformationStore } from "../manager/information/ManagerInformationStore";
import { deleteRequest } from '../../common/helpers/RequestHelper';
import HttpStatusCode from '../../common/constants/HttpErrorCode';
import { toastUtil } from '../../common/utils/ToastUtil';
import SVG from "react-inlinesvg";
import PackageInUse from '../upgradePackage/components/PackageInUse';
import { upgradePackageStore } from '../upgradePackage/UpgradePackageStore';

const Nav = () => {

    const { t, i18n } = useTranslation();
    const [active, setActive] = useState<string>("")
    let isAdmin = localStorage.getItem("isAdmin");

    const changeLanguage = (lng: any) => {
        i18n.changeLanguage(lng).then();
        localStorage.setItem('language', lng)
    };
    const convertTypeManager = (type: any) => {
        switch (type) {
            case "center_manage":
                return t("centralManagement")
            case "province_manage":
                return t("provincialManagement")
            case "district_manage":
                return t("districtManagement")
        }
    }

    const clearLocalStorage = () => {
        StorageService.removeToken();
        StorageService.removeRefreshToken();

        localStorage.clear();

        const redirectTo = window.location.href
        window.location.href = `/login?redirectTo=${redirectTo}`;
    }

    const logOut = async () => {
        const result = await deleteRequest('/sso/v1/auth/logout', {}, true)
        if (result.status === HttpStatusCode.OK) {
            clearLocalStorage()
        } else {
            toastUtil.error(result.body.message, 1)
        }
    }

    useEffect(() => {
        setActive(getIDParameter(1))
    }, [getIDParameter(1)])

    return (
        <div className="header-top">
            <div className="content-header d-flex align-items-center justify-content-between">
                <div>
                    <h4>{infoStore.dataInfo.name}</h4>
                    {infoStore.dataInfo.status === "PENDING" &&
                        <div className="d-flex farm_pending align-items-center"><img className="mr-1" src="/assets/ico/ico_error.svg" alt="" /> {t('noti')}</div>}
                </div>
                <div className="d-flex align-items-center">
                    {checkPermission(PermissionConst.farmView) && !checkExpirationTime().isExpired && profileStore.roleUser != "admin" &&
                        <Link to="/farm" className="info_farm">
                            <div className="icon">
                                <img src={`/assets/ico/sidebar/unit.svg`} alt="" />
                            </div>
                            <span className="menu-title">{t('cooperativeInformation')}</span>
                        </Link>}

                    <div className="user_profile d-flex align-items-center justify-content-between position-relative">
                        <div className="d-flex align-items-center">
                            <div className="avatar">
                                <img
                                    src={profileStore.dataFarm.avatar !== 'null' ? urlImageSSO(profileStore.dataFarm.avatar) : '/assets/images/avatar_default.png'}
                                    alt="" />
                            </div>
                            <div className="info_name">
                                <p className="name">{profileStore.dataFarm.name ?? profileStore.dataFarm.username}</p>
                                <p className="manage">{profileStore.dataFarm.farmOwner ? 'Chủ đơn vị' : profileStore.roleUser === 'manager' ? convertTypeManager(managerInformationStore.roleManager) : profileStore.dataFarm.roleName}</p>
                            </div>
                        </div>
                        <img className="img_ico" src="/assets/ico/ico_ellipsis.svg" alt="" />
                        <div className="box_user_info">
                            <div className='box_user_info_top'>
                                <a
                                    href={`${process.env.REACT_APP_DOMAIN_SSO}/profile?redirectTo=${window.location.origin}&service=farm&username=${profileStore.dataFarm.username}${localStorage.getItem('language') ? `&lng=${localStorage.getItem('language')}` : ''}`}
                                    target={'_blank'}
                                    className='box_user_info-item'
                                >
                                    <SVG src="/assets/ico/profile-circle.svg" />
                                    Thông tin tài khoản
                                    {/* {t('account')} */}
                                </a>
                                {
                                    isAdmin != "true" && infoStore.dataInfo.status !== "PENDING" &&
                                    <>
                                        <div
                                            className={`box_user_info-item`}
                                            data-toggle="modal"
                                            data-target={`#package_in_use_modal`}
                                            onClick={() => upgradePackageStore.getFarmPackageBuyInfo()}
                                        >
                                            <SVG src="/assets/ico/document.svg" />
                                            Gói đang sử dụng
                                        </div>
                                        <Link
                                            to={"/history-order-farm"}
                                            className={`${active === "history-order-farm" ? "isAvtive" : ""} box_user_info-item`}
                                            onClick={() => setActive("history-order-farm")}
                                        >
                                            <SVG src="/assets/ico/receipt-item.svg" />
                                            Lịch sử mua
                                        </Link>
                                    </>
                                }
                                <div className="dropdown mr-2 ">
                                    <button className="box_user_info-item btn dropdown-toggle" type="button" id="changeLanguage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src={`/assets/ico/${localStorage.getItem('language') == 'en' ? "england" : 'vietnam'}.png`} alt="" />
                                        <span>{localStorage.getItem('language') == 'en' ? "Language: ENG" : 'Ngôn ngữ: VIE'}</span>
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="changeLanguage">
                                        <div className="dropdown-item cursor_pointer font-weight-medium" onClick={() => changeLanguage('vi')}><img src="/assets/ico/vietnam.png" alt="" /> Vietnam</div>
                                        <div className="dropdown-item cursor_pointer font-weight-medium" onClick={() => changeLanguage('en')}><img src="/assets/ico/england.png" alt="" /> English</div>
                                    </div>
                                </div>
                            </div>
                            <div className='logout'>
                                <a
                                    href={'#'}
                                    onClick={logOut}>
                                    <SVG src="/assets/ico/sidebar/logout.svg" width={24} height={24} />
                                    {t('logout')}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="notice dropdown ml-4">
                        <button id="dropdownNotice" type="button" onClick={() => noticeStore.getNotice()}
                            data-toggle="dropdown">
                            {/*<Icon name="notifications_none" type="round"/>*/}
                            <i className="far fa-bell" style={{ fontSize: 20 }} />
                            {noticeStore.countNotice.unRead > 0 &&
                                <span className="count_unRead">{noticeStore.countNotice.unRead}</span>}
                        </button>
                        <PackageInUse />
                        <NoticeComponent />
                    </div>
                </div>
            </div>
        </div >
    );

}

export default observer(Nav);
