import React from 'react';
import {observer} from "mobx-react";
import {IStatus, userStore} from "../UserStore";


const PopupConfirmCms = () => {

    const switchIcon = () => {
        switch (userStore.status) {
            case IStatus.ACTIVE:
                return "/assets/ico/ico_confirm.svg"
            case IStatus.CANCEL:
                return "/assets/ico/ico_cancel.svg"
            case IStatus.BLOCK:
                return "/assets/ico/ico_lock.svg"
            case IStatus.UNLOCK:
                return "/assets/ico/ico_unlock.svg"
            case IStatus.RESET:
                return "/assets/ico/ico_reset_pass.svg"
        }
    }

    const switchTitle = () => {
        switch (userStore.status) {
            case IStatus.CANCEL:
                return "TỪ CHỐI TÀI KHOẢN"
            case IStatus.ACTIVE:
                return "XÁC NHẬN TÀI KHOẢN"
            case IStatus.BLOCK:
                return "KHÓA TÀI KHOẢN"
            case IStatus.UNLOCK:
                return "MỞ KHÓA TÀI KHOẢN"
            case IStatus.RESET:
                return "RESET MẬT KHẨU"
        }
    }

    const switchText = () => {
        switch (userStore.status) {
            case IStatus.ACTIVE:
                return <span>Bạn có muốn thêm tài khoản ?</span>
            case IStatus.CANCEL:
                return <span>Bạn có muốn từ chối tài khoản ?</span>
            case IStatus.BLOCK:
                return <span>Bạn có muốn khóa tài khoản ?</span>
            case IStatus.UNLOCK:
                return <span>Bạn có muốn mở khóa tài khoản ?</span>
            case IStatus.RESET:
                return <span>Bạn muốn RESET tài khoản <br/>về mật khẩu mặc định omfarm123?</span>
        }
    }


    return (
        <div className="modal fade" id="popup_confirm" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" id="close_confirm" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center icon">
                        <img src={switchIcon()} alt=""/>
                    </div>
                    <h2 className="text-center">{switchTitle()}</h2>
                    <p className="text-center">{switchText()}</p>
                    <div className="d-flex action align-items-center justify-content-center">
                        <button className="btn btn-cancel" data-dismiss="modal">Hủy</button>
                        <button className="btn btn-confirm" onClick={() => userStore.updateStatus()} disabled={userStore.isLoadingBt}>Xác nhận</button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default observer(PopupConfirmCms);