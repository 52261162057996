import { Col, Row } from 'antd'
import React from 'react'
import { convertPrice, convertToLocaleVN } from '../../../common/utils/Utils'
import { observer } from 'mobx-react'
import { historyOrderFarmStore } from '../HistoryOrderFarmStore'
import { dateUtils } from '../../../common/utils/DateUtils'
import { useTranslation } from 'react-i18next'

function ViewHistoryOrderFarm() {
    const { t } = useTranslation();
    return (
        <div className="modal fade" id="view-history-order-farm" role="dialog" aria-hidden="true" data-backdrop="static">
            <div className="modal-dialog" role="document" style={{ maxWidth: 1350 }}>
                <div className="modal-content border-0 p-28px bg-F3F4F5">
                    <div className="modal-header border-0 p-0">
                        <span className='fs-32px fw-600 pb-18px'>{t("viewDetailOrder")}</span>
                        <button type="button" id="close_upgrade-farm-modal" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body p-0 gap-18px d-flex overflow-y flex-wrap calc-max-h-vh-250px fw-500">
                        <Row gutter={[10, 8]} className='w-100'>
                            <Col xs={18} className='d-flex gap-10px flex-column'>
                                <div className='p-16px bg-white'>
                                    <p className='fs-16px color-00953D fw-500'>{t("selectProdPackage")}</p>
                                    <div className='mt-10px overflow-auto min-h-150px max-h-275px'>
                                        <table className='align-middle w-100 table-hover table-rounded border gy-7 gs-7 table-sticky-header'>
                                            <thead>
                                                <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                                    <th className="text-center">STT</th>
                                                    <th className="text-center min-w-200px">{t("products/Services")}</th>
                                                    <th className="text-center">{t("orderUnit")}</th>
                                                    <th className="text-center min-w-150px">{t("orderType")}</th>
                                                    <th className="text-center w-150px">{t("quantityOrder")}</th>
                                                    <th className="text-end">{t("unitPrice")}</th>
                                                    <th className="text-end">{t("totalAmount")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {historyOrderFarmStore?.listDataRequest?.map((item: any, i: number) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="text-center">{i + 1}</td>
                                                            <td>{item?.name}</td>
                                                            <td className="text-center">{t("package")}</td>
                                                            <td className='text-center min-w-150px'>{t(item?.type)}</td>
                                                            <td className="text-center">{item.quantity}</td>
                                                            <td className="text-end">{convertPrice(item?.price)}</td>
                                                            <td className="text-end">{convertPrice(item.quantity * item.price)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className='p-16px bg-white'>
                                    <p className='fs-16px color-00953D fw-500'>{t("paymentInformation")}</p>
                                    <div className='d-flex justify-content-between align-items-center mb-10px'>
                                        <span>{t("orderCode")}</span>
                                        <span>{historyOrderFarmStore?.dataRequest?.code}</span>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span>{t("createdAt")}</span>
                                        <span>{dateUtils.formatDate(historyOrderFarmStore?.dataRequest?.createdAt, 'DD/MM/YYYY')}</span>
                                    </div>
                                    <hr className='bg-7C7B7B' />
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span>{t("totalAmount")}</span>
                                        <span className='fw-600'>{convertToLocaleVN(historyOrderFarmStore.dataRequest?.totalPrice || 0)}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(ViewHistoryOrderFarm)