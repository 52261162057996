import Constants from "../../common/constants/Constants";
import { getRequest, IApiResponse, patchRequest, postRequest } from "../../common/helpers/RequestHelper";
import { stringParams } from "../../common/utils/Utils";

class UpgradePackageService {

    public getFarmPackage(size?: any): Promise<IApiResponse> {
        let searchObject = {
            page: 0,
            size: size ? size : Constants.MAX_SIZE,
            isActive: true,
        }

        return getRequest(`/v1/packages${stringParams(searchObject)}`)
    }

    public getDetailPackage(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/packages/${id}`)
    }

    public getDetailPackagePrice(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/package_prices/${id}`)
    }

    public getDetailPackageOrder(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/package_order_transaction/${id}`)
    }

    public getQrCodePackage(type?: any): Promise<IApiResponse> {
        let searchObject = {
            page: 0,
            size: Constants.MAX_SIZE,
            type: type || null
        }

        return getRequest(`/v1/qr_code_packages${stringParams(searchObject)}`)
    }

    public add(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/package_order_transaction`, data)
    }

    public comfirmPayment(id: any): Promise<IApiResponse> {
        return patchRequest(`/v1/package_order_transaction/${id}/comfirm-payment`, {})
    }

    public getFarmPackageBuyInfo(): Promise<IApiResponse> {
        return getRequest('/v1/farm_packages/package_buy_info')
    }
}

export const upgradePackageService = new UpgradePackageService();