import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import "./InfoTracking.scss"
import Loading from "../../common/component/Loading";
import {dateUtils} from "../../common/utils/DateUtils";
import {trackingStore} from "../tracking/TrackingStore";
import Content2 from "./components/Content2";
import Header from "./components/Header";
import Content1 from "./components/Content1";
import {trackingDetailStore} from "../tracking/trackingDetail/TrackingDetailStore";
import {Link} from "react-router-dom";
import {landStore} from "../setup/land/LandStore";


const InfoTracking = () => {

    useEffect(() => {
        window.scroll(0, 0)
        const urlSegments = window.location.href.split('/');
        const idTracking: any = urlSegments[urlSegments.length - 1];
        landStore.zoom = 17
        trackingDetailStore.getDetailTrackingQr(idTracking).then()
        return () => {
            trackingDetailStore.clearForm()
            trackingDetailStore.listPlanJob = []
        }
    }, [])

    let {packageInfo} = trackingStore.dataRequest;
    let {trackingQrBatch} = trackingDetailStore.dataRequest;

    return (
        <div className="info_access">
            <Header/>
            <div className="container">
                <div className="logo d-flex justify-content-center align-items-center">
                    <Link to={'/'}><img src="/assets/images/logo_login.svg" alt=""/></Link>
                </div>
                {trackingStore.isLoadingDetail ? <Loading/> :
                    <>
                        <Content1/>
                        <h2>Thông tin chi tiết</h2>
                        <div className="w-100 info_ d-flex">
                            <div className="info_unit w-50 pr-4">
                                <div className="info_text">
                                    <label>Nhà phân phối</label>
                                    <span>{trackingQrBatch?.info.distributor}</span>
                                </div>
                                <div className="info_text">
                                    <label>Ngày thu hoạch</label>
                                    <span>{packageInfo?.harvestDate}</span>
                                </div>
                                <div className="info_text">
                                    <label>Ngày gửi hàng</label>
                                    <span>{dateUtils.formatDate(trackingQrBatch?.info?.sentDate)}</span>
                                </div>
                                <div className="info_text">
                                    <label>Hạn sử dụng</label>
                                    <span>{dateUtils.formatDate(trackingQrBatch?.info?.expireDate)}</span>
                                </div>
                                <div className="info_text">
                                    <label>Khối lượng tịnh</label>
                                    <span>{trackingQrBatch?.info?.netWeight}</span>
                                </div>
                                <div className="info_text">
                                    <label>Hướng dẫn sử dụng</label>
                                    <span>{packageInfo?.userManual}</span>
                                </div>
                                <div className="info_text">
                                    <label>Hướng dẫn bảo quản</label>
                                    <span>{packageInfo?.storageInstructions}</span>
                                </div>
                            </div>
                            <div className="info_unit w-50 pl-4">
                                <div className="info_text">
                                    <label>Địa điểm gửi hàng</label>
                                    <span>{trackingQrBatch?.info?.deliveryLocation}</span>
                                </div>
                                <div className="info_text">
                                    <label>Tên đơn vị tiêu thụ</label>
                                    <span>{trackingQrBatch?.info?.consumerUnit}</span>
                                </div>
                                <div className="info_text">
                                    <label>Mã đơn vị tiêu thụ</label>
                                    <span>{trackingQrBatch?.info?.consumerUnitCode}</span>
                                </div>
                                <div className="info_text">
                                    <label>Địa chỉ</label>
                                    <span>{packageInfo?.address}</span>
                                </div>
                                <div className="info_text">
                                    <label>Đơn vị vận chuyển</label>
                                    <span>{trackingQrBatch?.info?.shippingPartner}</span>
                                </div>
                                <div className="info_text">
                                    <label>Mã đơn vị vận chuyển</label>
                                    <span>{trackingQrBatch?.info?.shippingPartnerCode}</span>
                                </div>
                                {trackingQrBatch && trackingQrBatch?.info && trackingQrBatch?.info?.more.map((item: any, i: number) => {
                                    return (
                                        <div className="info_text" key={i}>
                                            <label>{item.key}</label>
                                            <span>{item.value}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <Content2/>
                    </>}
            </div>
        </div>
    );
}

export default observer(InfoTracking);