import {getRequest, IApiResponse} from "../../../common/helpers/RequestHelper";
import {managerInformationStore} from "./ManagerInformationStore";

class ManagerInformationService {
    public getFarm(): Promise<IApiResponse> {
        return getRequest(`/v1/agency_manage/farm?page=${managerInformationStore.page}&size=10${managerInformationStore.searchName ? `&keyword=${managerInformationStore.searchName}` : ""}${managerInformationStore.provinceId ? `&provinceId=${managerInformationStore.provinceId}` : ""}${managerInformationStore.districtId ? `&districtId=${managerInformationStore.districtId}` : ""}`);
    }
    public getDetailFarm(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/farm/${id}`);
    }
    public getDetailAccountManager(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/agency_manage/${id}`);
    }
    public getProducts(): Promise<IApiResponse> {
        return getRequest(`/v1/agency_manage/products?page=${managerInformationStore.page}&size=10${managerInformationStore.searchName ? `&keyword=${managerInformationStore.searchName}` : ""}${managerInformationStore.provinceId ? `&provinceId=${managerInformationStore.provinceId}` : ""}${managerInformationStore.districtId ? `&districtId=${managerInformationStore.districtId}` : ""}`);
    }
    public getTracking(): Promise<IApiResponse> {
        return getRequest(`/v1/agency_manage/tracking?page=${managerInformationStore.page}&size=10${managerInformationStore.searchName ? `&keyword=${managerInformationStore.searchName}` : ""}${managerInformationStore.provinceId ? `&provinceId=${managerInformationStore.provinceId}` : ""}${managerInformationStore.districtId ? `&districtId=${managerInformationStore.districtId}` : ""}`);
    }
    public getDetailTracking(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/agency_manage/tracking/${id}/qr_batch?page=${managerInformationStore.page}&size=10`);
    }
    public getDetailPlanTracking(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/tracking/plan/${id}`);
    }
    public getDistribution(): Promise<IApiResponse> {
        return getRequest(`/v1/agency_manage/distributor?page=${managerInformationStore.page}&size=10${managerInformationStore.searchName ? `&keyword=${managerInformationStore.searchName}` : ""}${managerInformationStore.provinceId ? `&provinceId=${managerInformationStore.provinceId}` : ""}${managerInformationStore.districtId ? `&districtId=${managerInformationStore.districtId}` : ""}`);
    }
}

export const managerInformationService = new ManagerInformationService()