import React from 'react';
import {observer} from "mobx-react";

interface IProps {
    id?: string,
    isLoading: any
    action: any
}


const PopupConfirm = ({id, isLoading, action}: IProps) =>{

    return (
        <div className="modal fade" id={id ?? 'popup_confirm'} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" id="close_delete" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center icon">
                        <img src="/assets/ico/icon-remove.svg" alt=""/>
                    </div>
                    <h2 className="text-center">Xác nhận xóa</h2>
                    <p className="text-center">Bạn có chắc chắn muốn xóa?</p>
                    <div className="d-flex action align-items-center justify-content-center">
                        <button className="btn btn-cancel" data-dismiss="modal">Hủy</button>
                        <button className="btn btn-confirm" onClick={() => action()} disabled={isLoading}>Xác nhận</button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default observer(PopupConfirm);