import {Navigate, Route, Routes} from "react-router-dom";
import LoginComponent from "../auth/login/LoginComponent";
import React from "react";
import {profileStore} from "../profile/ProfileStore";
import InfoTracking from "../infoTracking/InfoTracking";
import InfoTrackingBatchCode from "../infoTracking/InfoTrackingBatchCode";
import PreviewTracking from "../infoTracking/PreviewTracking";
import NotFoundPage from "../notFound/NotFound";
import {observer} from "mobx-react";
import PreviewTrackingSeafood from "../infoTrackingSeafood/PreviewTrackingSeafood";
import InfoTrackingBatchCodeSeafood from "../infoTrackingSeafood/InfoTrackingBatchCodeSeafood";
import InfoTrackingSeafood from "../infoTrackingSeafood/InfoTrackingSeafood";
import PreviewTrackingLivestock from "../infoTrackingLivestock/PreviewTrackingLivestock";
import InfoTrackingLivestock from "../infoTrackingLivestock/InfoTrackingLivestock";
import InfoTrackingBatchCodeLivestock from "../infoTrackingLivestock/InfoTrackingBatchCodeLivestock";
import {getPathName} from "../../common/utils/Utils";
import PrivatePolicy from "../auth/login/PrivatePolicy";
import ScrollToTop from "../../common/component/ScrollToTop";


const RedirectAuth = () => {

    let path: any = getPathName()

    if(path.startsWith('cms')){
        profileStore.roleUser = 'admin'
    }

    const isAdmin = profileStore.roleUser === 'admin';

    const adminRoutes = (
        <Routes>
            <Route path={`/cms/login`} element={<LoginComponent/>}/>
            <Route path="*" element={<Navigate to="/cms/login" replace/>} />
        </Routes>
    )

   const userRoutes = (
       <Routes>
           <Route path={`/login`} element={<LoginComponent/>}/>
           <Route path={`/cms`} element={<LoginComponent/>}/>
           <Route path={`/chinh-sach-bao-mat`} element={<PrivatePolicy/>}/>
           <Route path={`/notfound`} element={<NotFoundPage/>}/>
           <Route path={`/xem-truoc-truy-xuat/:slug/:id`} element={<PreviewTracking/>}/>
           <Route path={`/truy-xuat/:slug/:batchCode`} element={<InfoTracking/>}/>
           <Route path={`/truy-xuat-lo/:slug/:trackingId/:trackingBatchId`} element={<InfoTrackingBatchCode/>}/>

           <Route path="/xem-ket-qua-truy-xuat-hai-san/:slug/:id" element={<PreviewTrackingSeafood/>}/>
           <Route path="/truy-xuat-lo-hai-san/:slug/:trackingId/:trackingBatchId" element={<InfoTrackingBatchCodeSeafood/>}/>
           <Route path="/truy-xuat-hai-san/:slug/:batchCode" element={<InfoTrackingSeafood/>}/>

           <Route path="/xem-ket-qua-truy-xuat-chan-nuoi/:slug/:id" element={<PreviewTrackingLivestock/>}/>
           <Route path="/truy-xuat-lo-chan-nuoi/:slug/:trackingId/:trackingBatchId" element={<InfoTrackingBatchCodeLivestock/>}/>
           <Route path="/truy-xuat-chan-nuoi/:slug/:batchCode" element={<InfoTrackingLivestock/>}/>
           <Route path="*" element={<Navigate to="/login" replace/>} />
       </Routes>
   )

    return isAdmin ? adminRoutes : userRoutes;
}

export default observer(RedirectAuth)
