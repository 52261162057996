import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {managerInformationStore} from "./ManagerInformationStore";
import FilterComponent from "../../../common/component/filter/FilterComponent";
import Loading from "../../../common/component/Loading";
import {slug, sttPage} from "../../../common/utils/Utils";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import {profileStore} from "../../profile/ProfileStore";
import {useTranslation} from "react-i18next";


const ManagerInformationPage = () => {
    const { t } = useTranslation();
    const changePage = async (data: any) => {
        managerInformationStore.page = data.selected;
        await managerInformationStore.getInformation();
    }
    useEffect(() => {
        managerInformationStore.getInformation().then()
        if(managerInformationStore.roleManager === 'province_manage') {
            managerInformationStore.getDetailAccountManager(profileStore.dataFarm.userId).then()
        }
        managerInformationStore.getProvinces().then(() => {
            if(managerInformationStore.roleManager === 'center_manage')
            {
                managerInformationStore.dataFilter = [
                    {
                        label: t("farmName"),
                        type: 'search',
                        placeholder: t("searchFarmName"),
                        value: managerInformationStore.searchName,
                        onChange: (e: any) => {
                            managerInformationStore.page = 0
                            managerInformationStore.searchName = e
                        },
                        callback: async (e: any) => {
                            await managerInformationStore.getInformation();
                        }
                    },
                    {
                        label: t("province"),
                        type: 'select',
                        value: managerInformationStore.provinceId,
                        listValue: managerInformationStore.listProvince,
                        callback: async (e: any) => {
                            managerInformationStore.provinceId = e;
                            managerInformationStore.page = 0
                            await managerInformationStore.getInformation();
                        }
                    },
                    {
                        label: t("district"),
                        type: 'select',
                        value: managerInformationStore.districtId,
                        listValue: managerInformationStore.listDistrict,
                        callback: async (e: any) => {
                            managerInformationStore.districtId = e;
                            managerInformationStore.page = 0
                            await managerInformationStore.changeDistrict(e)
                            await managerInformationStore.getInformation();
                        }
                    }
                ];

            }
            else if(managerInformationStore.roleManager === 'province_manage') {
                managerInformationStore.dataFilter = [
                    {
                        label: t("farmName"),
                        type: 'search',
                        placeholder: t("searchFarmName"),
                        value: managerInformationStore.searchName,
                        onChange: (e: any) => {
                            managerInformationStore.page = 0
                            managerInformationStore.searchName = e
                        },
                        callback: async (e: any) => {
                            await managerInformationStore.getInformation();
                        }
                    },
                    {
                        label: t("district"),
                        type: 'select',
                        value: managerInformationStore.districtId,
                        listValue: managerInformationStore.listDistrict,
                        callback: async (e: any) => {
                            managerInformationStore.districtId = e;
                            managerInformationStore.page = 0
                            await managerInformationStore.getInformation();
                        }
                    }
                ];

            }
            else if(managerInformationStore.roleManager === 'district_manage') {
                managerInformationStore.dataFilter = [
                    {
                        label: t("farmName"),
                        type: 'search',
                        placeholder: t("searchFarmName"),
                        value: managerInformationStore.searchName,
                        onChange: (e: any) => {
                            managerInformationStore.page = 0
                            managerInformationStore.searchName = e
                        },
                        callback: async (e: any) => {
                            await managerInformationStore.getInformation();
                        }
                    },
                ];
            }
        })
    }, [])

    useEffect(() => {
        if(managerInformationStore.provinceId) {
             managerInformationStore.getDistricts(managerInformationStore.provinceId).then(() => {
                 managerInformationStore.dataFilter[managerInformationStore.dataFilter.length - 1].listValue = managerInformationStore.listDistrict
             })
        }
    }, [managerInformationStore.provinceId])
    return (
        <div className="planning">
            {managerInformationStore.dataFilter && <FilterComponent data={managerInformationStore.dataFilter}/>}

            {managerInformationStore.isLoading ? <Loading/> :
                <div className='table-responsive'>
                    {managerInformationStore.listFarm && managerInformationStore.listFarm.length > 0 ?
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th className="text-center">{t("numberOfOrders")}</th>
                                <th className="text-center">{t("farmName")}</th>
                                <th className="text-center">{t("province")}</th>
                                <th className="text-center">{t("district")}</th>
                                <th className="text-center">{t("product")}</th>
                                <th className="text-center">{t("representative")}</th>
                                <th className="text-center">{t("phoneNumber")}</th>
                                <th className="text-center">{t("actions")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {managerInformationStore.listFarm.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(managerInformationStore.page, i)}</td>
                                        <td className="text-start">{item?.name ?? "-"}</td>
                                        <td className="text-center">{item?.province?.name ?? "-"}</td>
                                        <td className="text-center">{item?.district?.name ?? "-"}</td>
                                        <td className="text-center">{item?.mainProduct ?? "-"}</td>
                                        <td className="text-center">{item?.representativeName ?? "-"}</td>
                                        <td className="text-center">{item?.phone ?? "-"}</td>
                                        <td className="text-center">
                                            <div className="action">
                                                <button onClick={() => {
                                                     managerInformationStore.id = item.id
                                                }} className="bt_action" title="Chi tiết">
                                                    <Link to={`/manager/information/${slug(item.name)}/${item.id}`}>
                                                        <img src="/assets/ico/action/ico_detail.svg" alt=""/>
                                                    </Link>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}

                    {managerInformationStore.listFarm?.length > 0 && <div className="d-flex align-items-center justify-content-center mt-4">
                        <Paginate currentPage={managerInformationStore.page} totalPage={managerInformationStore.totalPage} changePage={changePage}/>
                    </div>}
                </div>}
        </div>
    )
}

export default observer(ManagerInformationPage);