import {observable} from "mobx";
import {questionService} from "./QuestionService";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";
import $ from 'jquery'

export enum ITypeAnswer {
    answered = 'answered',
    notAnswered = 'not_answered',
    disease = 'DISEASE',
    technique = 'TECHNIQUE',
    other = 'OTHER'
}


class QuestionStore {
    @observable isAnswered: any = ''
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable type: any = 'add'
    @observable typeComment: any = 'add'
    @observable id: any = ''
    @observable commentId: any = ''
    @observable typeAnswer: any = ''
    @observable topicId: any = ''
    @observable searchName: any = ''
    @observable listImages: any[] = []
    @observable dataAnswer: any = {
        id: '',
        user: {
            name: '',
            role: {
                name: ''
            }
        },
        answerType: "",
        answerForm: {
            disease: {
                diseaseName: "",
                diseaseDetail: "",
                damagedPart: "",
                solution: ""
            },
            technique: {
                techniqueName: "",
                techniqueDetail: "",
                techniqueExecuteTime: ""
            },
            other: {
                solution:""
            }
        },
        answerResource: {
            medicineIds: [],
            fertilizerIds: []
        }
    }
    @observable listQuestion: any[] = []
    @observable listComment: any[] = []
    @observable listTopic: any[] = []
    @observable listMedicine: any[] = []
    @observable listFertilizer: any[] = []
    @observable dataComment: any = {
        content: '',
        attachFiles: []
    }
    @observable dataErrors: any = {
        status: 0,
        message: ''
    }
    @observable formOther: any = {
        id: '',
        solution: "",
        attachFiles: []
    }
    @observable formTechnique: any = {
        id: '',
        techniqueName: "",
        techniqueDetail: "",
        techniqueExecuteTime: "",
        attachFiles: []
    }
    @observable formDisease: any = {
        id: '',
        diseaseName: "",
        diseaseDetail: "",
        damagedPart: '',
        solution: '',
        attachFiles: [],
        medicines: [],
        fertilizers: []
    }
    @observable dataRequest: any = {
        id: '',
        postTopicId: null,
        postTopic: {
            id: '',
            answerType: '',
            name: ''
        },
        user: {
            id: '',
            avatar: '',
            name: ''
        },
        name: '',
        content: '',
        attachFiles: []
    }
    @observable dataDetail: any = {
        id: '',
        postTopicId: null,
        postTopic: {
            id: '',
            answerType: '',
            name: ''
        },
        user: {
            id: '',
            avatar: '',
            name: ''
        },
        name: '',
        content: '',
        attachFiles: []
    }
    @observable errors: any = {
        name: '',
        postTopic: '',
        diseaseName: "",
        diseaseDetail: "",
        damagedPart: '',
        other_solution: '',
        disease_solution: '',
        techniqueName: ''
    }

    clearAnswer() {
        questionStore.dataAnswer = {
            id: '',
            user: {
                name: '',
                role: {
                    name: ''
                }
            },
            answerType: "",
            answerForm: {
                disease: {
                    diseaseName: "",
                    diseaseDetail: "",
                    damagedPart: "",
                    solution: ""
                },
                technique: {
                    techniqueName: "",
                    techniqueDetail: "",
                    techniqueExecuteTime: ""
                },
                other: {
                    solution:""
                }
            },
            answerResource: {
                medicineIds: [],
                fertilizerIds: []
            }
        }
    }

    clearForm() {
        questionStore.type = 'add'
        questionStore.typeComment = 'add'
        questionStore.errors = {
            name: '',
            postTopic: '',
            diseaseName: "",
            diseaseDetail: "",
            damagedPart: '',
            other_solution: '',
            disease_solution: '',
            techniqueName: ''
        }
        questionStore.dataErrors = {
            status: 0,
            message: ''
        }
        questionStore.dataRequest = {
            id: '',
            postTopicId: null,
            name: '',
            postTopic: {
                id: '',
                name: ''
            },
            content: '',
            attachFiles: []
        }
        questionStore.dataComment = {
            content: '',
            attachFiles: []
        }
        questionStore.formOther = {
            id: '',
            solution: "",
            attachFiles: []
        }
        questionStore.formDisease = {
            id: '',
            diseaseName: "",
            diseaseDetail: "",
            damagedPart: '',
            solution: '',
            attachFiles: [],
            answerResource: {
                medicineIds: [],
                fertilizerIds: []
            }
        }
        questionStore.formTechnique = {
            id: '',
            techniqueName: "",
            techniqueDetail: "",
            techniqueExecuteTime: "",
            attachFiles: []
        }
    }

    async getQuestions() {
        questionStore.isLoading = true
        const result = await questionService.getQuestions()
        questionStore.isLoading = false
        if (result.status == HttpStatusCode.OK) {
            questionStore.listQuestion = result.body.data
            questionStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getComments() {
        const result = await questionService.getComments(questionStore.id)
        if (result.status == HttpStatusCode.OK) {
            questionStore.listComment = result.body.data
            questionStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetailComment(id: any) {
        questionStore.typeComment = 'edit'
        const result = await questionService.getDetailComment(questionStore.id, id)
        if (result.status == HttpStatusCode.OK) {
            questionStore.dataComment = result.body
        }
    }


    async getAnswer() {
        const result = await questionService.getAnswer(questionStore.id)
        if (result.status == HttpStatusCode.OK) {
            questionStore.dataAnswer = result.body
            console.log(questionStore.dataAnswer)
            if (questionStore.dataAnswer.answerType == ITypeAnswer.other) {
                questionStore.formOther = {
                    id: result.body.id,
                    solution: result.body.answerForm.other.solution ?? '',
                    attachFiles: result.body.attachFiles,
                }
            }

            if (questionStore.dataAnswer.answerType == ITypeAnswer.technique) {
                questionStore.formTechnique = {
                    id: result.body.id,
                    techniqueName: result.body.answerForm.technique.techniqueName ?? "",
                    techniqueDetail: result.body.answerForm.technique.techniqueDetail ?? "",
                    techniqueExecuteTime: result.body.answerForm.technique.techniqueExecuteTime ?? "",
                    attachFiles: result.body.attachFiles,
                }
            }

            if (questionStore.dataAnswer.answerType == ITypeAnswer.disease) {
                var medicines: any = []
                var fertilizers: any = []
                if(result.body.answerResource.medicines && result.body.answerResource.medicines.length > 0){
                    result.body.answerResource.medicines.map((item: any) => {
                        medicines.push({value: item.medicine.id, label: item.medicine.name})
                    })
                }
                if(result.body.answerResource.fertilizers && result.body.answerResource.fertilizers.length > 0){
                    result.body.answerResource.fertilizers.map((item: any) => {
                        fertilizers.push({value: item.fertilizer.id, label: item.fertilizer.name})
                    })
                }
                questionStore.formDisease = {
                    id: result.body.id,
                    diseaseName: result.body.answerForm.disease.diseaseName ?? "",
                    diseaseDetail: result.body.answerForm.disease.diseaseDetail ?? "",
                    solution: result.body.answerForm.disease.diseaseSolution ?? "",
                    damagedPart: result.body.answerForm.disease.diseaseDamagedPart ?? "",
                    attachFiles: result.body.attachFiles,
                    medicines: medicines,
                    fertilizers: fertilizers
                }
            }

        }
    }

    async getDetailQuestion(id: any) {
        questionStore.type = 'edit'
        const result = await questionService.getQuestionDetail(id)

        if (result.status == HttpStatusCode.OK) {
            questionStore.dataDetail = result.body
            questionStore.dataRequest = result.body
        }else {
            if(result.status == 404){
                questionStore.dataErrors = {
                    status: result.status,
                    message: result.body.message
                }
            }else {
                toastUtil.error(result.body.message, 0)
            }
        }
    }

    async getTopic() {
        questionStore.isLoading = true
        const result = await questionService.getTopic()
        questionStore.isLoading = false
        if (result.status == HttpStatusCode.OK) {
            questionStore.listTopic = result.body.data
        }
    }


    async sendQuestion() {
        let {id, name, postTopicId, content, attachFiles} = questionStore.dataRequest;

        let data = {
            postTopicId: postTopicId,
            name: name,
            content: content,
            attachFiles: attachFiles
        }

        if (!postTopicId) {
            questionStore.errors.postTopic = 'Vui lòng chọn thư mục!'
            return
        }
        if (!name) {
            questionStore.errors.name = 'Tiêu đề không được để trống!'
            return
        }

        var result

        questionStore.isLoadingBt = true
        if (questionStore.type == 'add') result = await questionService.addQuestion(data)
        else result = await questionService.updateQuestion(id, data)
        questionStore.isLoadingBt = false

        if (result.status === HttpStatusCode.OK) {
            if (questionStore.type == 'add') toastUtil.success('Thêm câu hỏi thành công', 7)
            else toastUtil.success('Cập nhật câu hỏi thành công', 8)
            $('#close_add_edit').trigger('click')
            if (questionStore.type == 'add') {
                await questionStore.getQuestions()
            } else {
               questionStore.dataDetail = questionStore.dataRequest
            }
            questionStore.clearForm()
        }else {
            toastUtil.error(result.body.message , 1)
        }
    }


    async sendComment() {
        let {id, content, attachFiles} = questionStore.dataComment;
        let data = {
            content: content.trim(),
            attachFiles: attachFiles
        }

        console.log(questionStore.typeComment)
        var result
        questionStore.isLoadingBt = true
        if (questionStore.typeComment == 'edit') {
            result = await questionService.updateComment(id, data)
        } else result = await questionService.addComment(questionStore.dataDetail.id, data)
        questionStore.isLoadingBt = false

        if (result.status === HttpStatusCode.OK) {
            if (questionStore.typeComment == 'edit') {
                toastUtil.success('Cập nhật câu trả lời thành công', 5)
            } else {
                toastUtil.success('Thêm câu trả lời thành công', 6)
                questionStore.dataDetail.countComment += 1
            }
            questionStore.typeComment = 'add'
            questionStore.dataComment = {
                content: '',
                attachFiles: []
            }
            await questionStore.getComments()
        }else {
            toastUtil.error(result.body.message, 2)
        }
    }

    async sendOther() {
        let {id, attachFiles, solution} = questionStore.formOther;

        let data = {
            answerType: questionStore.dataDetail.postTopic.answerType,
            attachFiles: attachFiles,
            answerForm: {
                other: {
                    solution: solution.trim()
                }
            }
        }

        if (!solution.trim()) {
            this.errors.other_solution = 'Mô tả không được để trống!'
            return
        }

        await this.sendAnswer(id, data, 'other')
    }

    async sendDisease() {
        let {
            id,
            attachFiles,
            solution,
            diseaseName,
            diseaseDetail,
            damagedPart,
            medicines,
            fertilizers
        } = questionStore.formDisease;
        var medicineIds: any = []
        var fertilizerIds: any = []


        if(medicines && medicines.length > 0){
            medicines.map((item: any) => {
                medicineIds.push(item.value)
            })
        }

        if(fertilizers && fertilizers.length > 0){
            fertilizers.map((item: any) => {
                fertilizerIds.push(item.value)
            })
        }

        var data = {
            answerType: questionStore.dataDetail.postTopic.answerType,
            attachFiles: attachFiles,
            answerForm: {
                disease: {
                    diseaseName: diseaseName.trim(),
                    diseaseDetail: diseaseDetail.trim(),
                    diseaseDamagedPart: damagedPart.trim(),
                    diseaseSolution: solution.trim(),
                }
            },
            answerResource: {
                medicineIds: medicineIds,
                fertilizerIds: fertilizerIds
            }
        }

        if (!diseaseName) {
            this.errors.diseaseName = 'Tên bệnh không được để trống!'
            return
        }

        if (!diseaseDetail) {
            this.errors.diseaseDetail = 'Mô tả không được để trống!'
            return
        }

        if (!damagedPart) {
            this.errors.damagedPart = 'Bộ phận nhiễm bệnh không được để trống!'
            return
        }

        if (!solution) {
            this.errors.disease_solution = 'Cách xử lý không được để trống!'
            return
        }

        await this.sendAnswer(id, data, 'disease')

    }

    async sendTechnique() {
        let {id, attachFiles, techniqueName, techniqueDetail, techniqueExecuteTime} = questionStore.formTechnique;

        let data = {
            answerType: questionStore.dataDetail.postTopic.answerType,
            attachFiles: attachFiles,
            answerForm: {
                technique: {
                    techniqueName: techniqueName.trim(),
                    techniqueDetail: techniqueDetail.trim(),
                    techniqueExecuteTime: techniqueExecuteTime.trim(),
                }
            }
        }

        if (!techniqueName) {
            this.errors.techniqueName = 'Tên kỹ thuật không được để trống!'
            return
        }

        if (!techniqueDetail) {
            this.errors.techniqueDetail = 'Mô tả không được để trống!'
            return
        }


        if (!techniqueExecuteTime) {
            this.errors.techniqueExecuteTime = 'Thời gian thực hiện không được để trống!'
            return
        }

        await this.sendAnswer(id, data, 'technique')
    }

    async sendAnswer(commentId: any, data: any, classId: any) {
        questionStore.isLoadingBt = true
        const result = await questionService.addOrUpdateAnswer(questionStore.id, data)
        questionStore.isLoadingBt = false

        if (result.status === HttpStatusCode.OK) {
            if (questionStore.typeComment == 'add') toastUtil.success('Thêm báo cáo thành công', 2)
            else toastUtil.success('Cập nhật báo cáo thành công', 1)
            $(`#close_add_edit_form_${classId}`).trigger('click')
            this.clearForm()
            await questionStore.getAnswer()
        }else {
            toastUtil.error(result.body.message , 3)
        }
    }

    async deleteQuestion() {
        questionStore.isLoadingBt = true;
        const result = await questionService.deleteQuestion(questionStore.id)
        questionStore.isLoadingBt = false;
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Xóa câu hỏi thành công!', 3)
            setTimeout(() => {
                window.location.href = '/question'
            }, 500)
        }else {
            toastUtil.error(result.body.message , 4)
        }
    }

    async deleteComment() {
        questionStore.isLoadingBt = true;
        const result = await questionService.deleteComment(questionStore.commentId)
        questionStore.isLoadingBt = false;
        if (result.status === HttpStatusCode.OK) {
            questionStore.dataDetail.countComment -= 1
            questionStore.listComment.map((item, i) => {
                if (item.id == questionStore.commentId) {
                    questionStore.listComment.splice(i, 1)
                }
            })
            questionStore.commentId = ''
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa câu trả lời thành công!', 4)
        }else {
            toastUtil.error(result.body.message , 5)
        }
    }

}

export const questionStore = new QuestionStore()