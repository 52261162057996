import React from 'react';
import {observer} from "mobx-react";
import {trackingDetailStore} from "../TrackingDetailStore";


const ModalPrintQR = () => {

    return (
        <div className="modal fade" id="qr_print_code" role="dialog" aria-hidden="true">
            <div className="modal-dialog" style={{maxWidth: 650}} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">In mã QR</h5>
                        <button type="button" className="close" id="close_print_code" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="form-group">
                            <label>Kiểu in <span className="text-danger">*</span></label>
                            <select
                                className={`form-control form-control-lg ${trackingDetailStore.errorBackgroundPrint ? 'border_error' : ''}`} onChange={(e: any) => {
                                trackingDetailStore.backgroundPrint = e.currentTarget.value
                                if(trackingDetailStore.backgroundPrint == 1){
                                    trackingDetailStore.errorBackgroundPrint = ''
                                }
                            }}>
                                <option value={1}>In mặc định</option>
                                <option value={2} selected={trackingDetailStore.backgroundPrint == 2}>In theo mẫu</option>
                            </select>
                            {trackingDetailStore.errorBackgroundPrint && <p className="error">{trackingDetailStore.errorBackgroundPrint}</p>}
                        </div>
                        <div className="form-group">
                            <label>Chọn mẫu tem <span className="text-danger">*</span></label>
                            <select className="form-control" onChange={(e: any) => trackingDetailStore.itemOnRow = e.currentTarget.value}>
                                <option value={1} selected={trackingDetailStore.itemOnRow == 1}>Mẫu 1 tem (3,5cm x 2,2cm)</option>
                                <option value={2} selected={trackingDetailStore.itemOnRow == 2}>Mẫu 2 tem (3,5cm x 2,2cm)</option>
                                <option value={3} selected={trackingDetailStore.itemOnRow == 3}>Mẫu 3 tem (3,5cm x 2,2cm)</option>
                                <option value={4} selected={trackingDetailStore.itemOnRow == 4}>Mẫu 4 tem (3,5cm x 2,2cm)</option>
                                <option value={5} selected={trackingDetailStore.itemOnRow == 5}>Mẫu 5 tem (3,5cm x 2,2cm)</option>
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request" disabled={trackingDetailStore.isLoadingBt} onClick={() => trackingDetailStore.printQr()}>{trackingDetailStore.isLoadingBt ? <i className="fa fa-spinner text-white fa-spin" /> : 'In mã'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(ModalPrintQR);