import {observable} from "mobx";
import $ from "jquery";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import axios from "axios";
import StorageService from "../../../common/service/StorageService";
import {slug} from "../../../common/utils/Utils";
import {trackingSeafoodStore} from "../TrackingSeafoodStore";
import {trackingSeafoodService} from "../TrackingSeafoodService";
import {trackingDetailStore} from "../../tracking/trackingDetail/TrackingDetailStore";

interface IDataRequest {
    id: any,
    logo: '',
    qrFile: "",
    createdAt: any,
    countQrExported: '',
    bathCode: '',
    info: {
        address: "",
        sentDate: any,
        expireDate: any
        deliveryLocation: "",
        consumerUnit: "",
        consumerUnitCode: '',
        shippingPartner: "",
        shippingPartnerCode: "",
        more: any[],
        quantityShipped: any,
        quantityLeft: any,
        distributingAgent: '',
        distributingUnitCode: '',
        detailMore: any[]
    }
}

class TrackingSeafoodDetailStore {
    @observable id: any = '';
    @observable idBatch: any = '';
    @observable pageHelper: any = '';
    @observable type: any = '';
    @observable itemOnRow: any = 2;
    @observable grayScale: any = 2;
    @observable qty: any = '';
    @observable step: number = 1;
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable pageHistory: number = 0
    @observable totalPageHistory: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable listTracking: any[] = []
    @observable listImage: any[] = []
    @observable listHistoryQr: any[] = []
    @observable errors = {
        qty: false,
        address: false,
        sentDate: false,
        deliveryLocation: false,
        consumerUnit: false,
        expireDate: false,
        consumerUnitCode: false,
        shippingPartner: false,
        shippingPartnerCode: false,
        distributingAgent: false,
    }
    @observable dataRequest: IDataRequest = {
        id: '',
        logo: '',
        qrFile: "",
        createdAt: '',
        countQrExported: '',
        bathCode: '',
        info: {
            address: "",
            sentDate: "",
            expireDate: '',
            deliveryLocation: "",
            consumerUnit: "",
            consumerUnitCode: '',
            shippingPartner: "",
            shippingPartnerCode: "",
            more: [],
            quantityShipped: '',
            quantityLeft: '',
            distributingAgent: '',
            distributingUnitCode: '',
            detailMore: []
        }
    }

    clearForm() {
        trackingSeafoodDetailStore.type = 'add'
        trackingDetailStore.itemOnRow = 2
        trackingDetailStore.grayScale = 2
        trackingSeafoodDetailStore.dataRequest = {
            id: '',
            logo: '',
            qrFile: "",
            createdAt: '',
            countQrExported: '',
            bathCode: '',
            info: {
                address: "",
                sentDate: "",
                expireDate: '',
                deliveryLocation: "",
                consumerUnit: "",
                consumerUnitCode: '',
                shippingPartner: "",
                shippingPartnerCode: "",
                more: [],
                quantityShipped: '',
                quantityLeft: '',
                distributingAgent: '',
                distributingUnitCode: '',
                detailMore: []
            }
        }
    }

    clearErrors() {
        trackingSeafoodDetailStore.errors = {
            qty: false,
            address: false,
            sentDate: false,
            expireDate: false,
            deliveryLocation: false,
            consumerUnit: false,
            consumerUnitCode: false,
            shippingPartner: false,
            shippingPartnerCode: false,
            distributingAgent: false,
        }
    }

    async getTracking() {
        trackingSeafoodDetailStore.isLoading = true
        const result = await trackingSeafoodService.getTrackingQR()
        trackingSeafoodDetailStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            trackingSeafoodDetailStore.listTracking = result.body.data
            trackingSeafoodDetailStore.totalPage = result.body.metadata.totalPage
        }
    }

    async printQr() {
        let grayScale = trackingSeafoodDetailStore.grayScale == 2
        trackingSeafoodDetailStore.isLoadingBt = true
        const result = await trackingSeafoodService.printQr(trackingSeafoodDetailStore.dataRequest.id, trackingSeafoodDetailStore.dataRequest.qrFile, trackingSeafoodDetailStore.itemOnRow, false);
        trackingSeafoodDetailStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            let url = process.env.REACT_APP_API_QR + '/farm/v1/pdf/' + result.body.filePath
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');
                })
                .catch(error => {
                    toastUtil.error(error);
                });
        }
    }

    async getDetailTracking(id: number) {
        trackingSeafoodDetailStore.type = 'edit'
        trackingSeafoodDetailStore.clearDownloadQR()
        trackingSeafoodDetailStore.clearErrors()
        const result = await trackingSeafoodService.detailTrackingBatch(id)
        if (result.status === HttpStatusCode.OK) {
            trackingSeafoodDetailStore.dataRequest = result.body
        }
    }

    async getDetailTrackingQr(id: number) {
        const result = await trackingSeafoodService.detailTrackingBatchQr(id)
        if (result.status === HttpStatusCode.OK) {
            await trackingSeafoodStore.getDetail(result.body.trackingQrBatchData.trackingId)
            await trackingSeafoodDetailStore.getDetailTracking(result.body.trackingQrBatchData.id)
        }
    }

    async getHistoryQr(id: number) {
        trackingSeafoodDetailStore.isLoadingBt = false
        trackingSeafoodDetailStore.pageHistory = 0
        trackingSeafoodDetailStore.listHistoryQr = []
        const result = await trackingSeafoodService.historyQr(id)
        if (result.status === HttpStatusCode.OK) {
            trackingSeafoodDetailStore.listHistoryQr = result.body.data
            trackingSeafoodDetailStore.totalPageHistory = result.body.metadata.totalPage
        }
    }

    async readMore() {
        const result = await trackingSeafoodService.historyQr(trackingSeafoodDetailStore.idBatch)
        if (result.status === HttpStatusCode.OK) {
            trackingSeafoodDetailStore.listHistoryQr = trackingSeafoodDetailStore.listHistoryQr.concat(result.body.data)
        }
    }

    async addEditTracking(isClose?: boolean) {
        trackingSeafoodDetailStore.clearErrors()
        let {id, info, logo} = trackingSeafoodDetailStore.dataRequest

        let data = {
            logo: logo,
            packageInfo: info
        }

        if (!info.address) {
            trackingSeafoodDetailStore.errors.address = true
        }

        if (!info.sentDate) {
            trackingSeafoodDetailStore.errors.sentDate = true
        }

        if (!info.expireDate) {
            trackingSeafoodDetailStore.errors.expireDate = true
        }
        if (!info.deliveryLocation) {
            trackingSeafoodDetailStore.errors.deliveryLocation = true
        }
        if (!info.consumerUnit) {
            trackingSeafoodDetailStore.errors.consumerUnit = true
        }
        if (!info.consumerUnitCode) {
            trackingSeafoodDetailStore.errors.consumerUnitCode = true
        }

        if (!info.shippingPartner) {
            trackingSeafoodDetailStore.errors.shippingPartner = true
        }

        if (!info.shippingPartnerCode) {
            trackingSeafoodDetailStore.errors.shippingPartnerCode = true
        }

        if (!info.distributingAgent) {
            trackingSeafoodDetailStore.errors.distributingAgent = true
        }


        if (trackingSeafoodDetailStore.errors.address || trackingSeafoodDetailStore.errors.distributingAgent || trackingSeafoodDetailStore.errors.consumerUnitCode || trackingSeafoodDetailStore.errors.sentDate || trackingSeafoodDetailStore.errors.expireDate || trackingSeafoodDetailStore.errors.deliveryLocation || trackingSeafoodDetailStore.errors.consumerUnit || trackingSeafoodDetailStore.errors.shippingPartner || trackingSeafoodDetailStore.errors.shippingPartnerCode) {
            toastUtil.warning('Vui lòng nhập đầy đủ thông tin', 2)
            return
        }

        trackingSeafoodDetailStore.isLoadingBt = true

        let result: any

        if (trackingSeafoodDetailStore.type === 'add') {
            result = await trackingSeafoodService.addTrackingQR(data)
        } else {
            result = await trackingSeafoodService.editTrackingQR(id, data)
        }
        trackingSeafoodDetailStore.isLoadingBt = false

        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${trackingSeafoodDetailStore.type === 'add' ? 'Thêm' : 'Cập nhật'} mã qr thành công!`, 1)
            if (!isClose) {
                $('#close_qr_code').trigger('click')
            } else {
                trackingSeafoodDetailStore.dataRequest = result.body
                trackingSeafoodDetailStore.clearDownloadQR()
                trackingSeafoodDetailStore.pageHelper = `${window.location.origin}/truy-xuat-lo-hai-san/${slug(trackingSeafoodStore.dataRequest.productInformation.name)}/${trackingSeafoodStore.dataRequest.id}/${result.body.id}`
            }
            await trackingSeafoodDetailStore.getTracking();
        } else {
            toastUtil.warning(result.body.message ?? `${trackingSeafoodDetailStore.type === 'add' ? 'Thêm' : 'Cập nhật'} mã qr xuất thất bại`, 3)
        }
    }


    clearDownloadQR() {
        trackingSeafoodDetailStore.isLoadingBt = false
        trackingSeafoodDetailStore.step = 1
        trackingSeafoodDetailStore.qty = ''
    }

    async addLinkDownloadQr() {
        trackingSeafoodDetailStore.errors.qty = false

        if (!trackingSeafoodDetailStore.qty) {
            trackingSeafoodDetailStore.errors.qty = true
            toastUtil.warning('Vui lòng nhập số QR tải về!', 1)
            return
        }

        if (Number(trackingSeafoodDetailStore.qty) > 10000) {
            trackingSeafoodDetailStore.errors.qty = true
            toastUtil.warning('Số QR tải về tối đa là 10.000!', 2)
            return
        }



        trackingSeafoodDetailStore.isLoadingBt = true
        const result = await trackingSeafoodService.addLinkDownloadQr(trackingSeafoodDetailStore.dataRequest.id, trackingSeafoodDetailStore.qty)
        trackingSeafoodDetailStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            trackingSeafoodDetailStore.dataRequest = result.body
            toastUtil.success('Tạo link mã qr thành công!', 1)
            trackingSeafoodDetailStore.listTracking.map((item) => {
                if (item.id === trackingSeafoodDetailStore.dataRequest.id) {
                    item.countQrExported = result.body.countQrExported
                }
            })
            trackingSeafoodDetailStore.step = 2
        } else {
            toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra!', 2)
        }
    }

    downloadQR = async (id: any, path: any) => {
        var newHeaders: any = {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            Authorization: 'Bearer ' + StorageService.getToken()
        }

        trackingSeafoodDetailStore.isLoadingBt = true

        return await axios
            .get(`${process.env.REACT_APP_API_DOMAIN}/farm/v1/data/tracking_batch_data/${id}${path}`, {
                headers: newHeaders,
                responseType: 'blob'
            })
            .then(({data: blob}) => {
                trackingSeafoodDetailStore.isLoadingBt = false
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = 'qrFile.zip';
                link.click();
            });
    };

}


export const trackingSeafoodDetailStore = new TrackingSeafoodDetailStore()