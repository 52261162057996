import React from 'react';
import "./FilterStyle.scss"
import {checkPermission} from "../../utils/Utils";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";

export interface IFilter {
    label: string,
    type: string,
    value: string,
    callback: any,
    width?: any,
    onChange?: any,
    placeholder?: any,
    listValue?: any[],
}

interface IProps {
    label?: string
    buttonAdd?: any
    componentIcon?: any
    permissionCode?: any,
    isAdmin?: boolean,
    data: IFilter[]
}

export enum IType {
    date = 'date',
    select = "select",
    search = 'search'
}

const FilterComponent = (item: IProps) => {

    const { t } = useTranslation();

    const onKeyDown = async (e: any, item: any) => {
        if (e.key === 'Enter') {
            await item.callback()
        }
    }


    const switchCase = (item: IFilter) => {
        switch (item.type) {
            case IType.date:
                return <div className="search_date">
                    <input type="date" value={item.value} onChange={(e: any) => { item.callback(e.currentTarget.value); item.value = e.currentTarget.value }} placeholder={item.placeholder ?? ''} />
                </div>
            case IType.select:
                return <div className="filter_select">
                    <select onChange={(e: any) => { item.callback(e.currentTarget.value); item.value = e.currentTarget.value }} value={item.value}>
                        <option value="">{t('choose')}</option>
                        {item.listValue && item.listValue.map((item, i) => {
                            return <option value={item.id} key={i}>{item.name}</option>
                        })}
                    </select>
                </div>
            case IType.search:
                return <div className="filter_search position-relative" style={{ width: `${item.width ?? '189px'}` }}>
                    <input type="text" onChange={(e: any) => { item.onChange(e.currentTarget.value); item.value = e.currentTarget.value }} onKeyDown={(e: any) => onKeyDown(e, item)} placeholder={item.placeholder ?? ''} />
                    <i className="far fa-search color-green position-absolute" onClick={() => item.callback()} />
                </div>
        }
    }

    return (
        <div className="d-flex align-items-center justify-content-between filter_header">
            {/*{item.label && <h3 className="m-0">{item.label}</h3>}*/}
            <div className="d-flex align-items-center">
                <div className='component_filter'>
                    {item.componentIcon
                        ? item.componentIcon
                        : <img src="/assets/ico/filter.svg" alt="" />
                    }
                </div>
                {item.data && item.data.map((item, i) => {
                    return <div className="d-flex form_search align-items-center" key={i}>
                        <span>{item.label}:</span>
                        {switchCase(item)}
                    </div>
                })}
            </div>
            {(item.isAdmin || (item.permissionCode && checkPermission(item.permissionCode))) && item.buttonAdd}
        </div>
    );
}

export default observer(FilterComponent);