import {observable} from "mobx";
import {getRequest, postRequest} from "../../common/helpers/RequestHelper";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";
import $ from 'jquery'
import {productStore} from "../setup/product/ProductStore";
import {medicineStore} from "../setup/medicine/MedicineStore";
import {fertilizerStore} from "../setup/fertilizer/FertilizerStore";
import {ingredientStore} from "../setup/ingredient/IngredientStore";
import {equipmentStore} from "../setup/equipment/EquipmentStore";


class WarehouseStore {
    @observable tabActive: any = ''
    @observable tabSupplies: any = ''
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable type: string = ''
    @observable metadata: any = {
        page: 0,
        totalPage: 0
    }
    @observable dataRequest: any = {
        suppliesId: '',
        suppliesQty: [],
        note: ''
    }
    @observable dataDetail: any = {
        code: '',
        inventoryType: '',
        suppliesType: '',
        createdAt: '',
        shortName: '',
        supplies: [],
        user: {
            name: ''
        },
        note: ''
    }
    @observable listData: any[] = []
    @observable listSupplies: any[] = []
    @observable listSuppliesAll: any[] = []
    @observable listHistory: any[] = []

    clearForm(){
        this.listData = []
        this.dataRequest = {
            suppliesId: '',
            note: ''
        }
    }

    convertDataSelectAnt(arr: any[]) {
        let arrNew: any = []
        arr && arr.map((val) => {
            arrNew.push({ label: `${val.name} ${val.inventory > 0 ? `(Tồn kho: ${val.inventory})` : '(Hết hàng)'}`, value: val.id })
        });

        return arrNew;
    }

    returnType(){
        switch (warehouseStore.tabActive) {
            case "product":
                return "Sản phẩm"
            case "medicine":
                return "Nông dược"
            case "fertilizer":
                return "Phân bón"
            case "ingredient":
                return "Nguyên vật liệu"
            case "equipment":
                return "Thiết bị"
        }
    }

    updateData(){
        switch (warehouseStore.tabActive) {
            case "product":
                return productStore.getProducts()
            case "medicine":
                return medicineStore.getMedicine()
            case "fertilizer":
                return fertilizerStore.getFertilizer()
            case "ingredient":
                return ingredientStore.getIngredient()
            case "equipment":
                return equipmentStore.getEquipments()
        }
    }

    pushData(){
        let isError: boolean = false
        this.listData && this.listData.map((val) => {
            if (val.id == this.dataRequest.suppliesId) {
                isError = true
            }
        })

        if (isError) {
            toastUtil.warning('Vật tư đã tồn tại!', 1)
            return
        }

        this.listSuppliesAll.map((item) => {
            if(item.id == this.dataRequest.suppliesId){
                this.listData.unshift({id: item.id, name: item.name, code: item.shortName, type: this.returnType(), unit: warehouseStore.tabActive == 'product' ? 'KG' : typeof item.unit == "object" ? item.unit?.code : item.unit , qty: '', inventory: item.inventory})
            }
        })

        this.dataRequest.suppliesId = ''
    }

    async getHistoryInventory(){
        this.isLoading = true
        const result = await getRequest(`/v1/inventory?page=${this.metadata.page}&size=10`)
        this.isLoading = false
        if(result.status === HttpStatusCode.OK){
            this.listHistory = result.body.data;
            this.metadata = result.body.metadata
        }
    }

    async getDetail(id: number){
        this.isLoading = true
        const result = await getRequest(`/v1/inventory/${id}`)
        this.isLoading = false
        if(result.status === HttpStatusCode.OK){
            this.dataDetail = result.body;
        }
    }

    async getDetailHistoryInventory(id: number){
        this.isLoading = true
        const result = await getRequest(`/v1/inventory/${id}/log`)
        this.isLoading = false
        if(result.status === HttpStatusCode.OK){
            this.listData = result.body;
        }
    }

    unique_arr(arr: any) {
        return Array.from(new Set(arr))
    }

    async importExport(){
        let {note} = this.dataRequest
        let suppliesQty: any = []
        let checkQty = false
        this.listData && this.listData.map((item) => {
            if(item.qty){
                suppliesQty.push({
                    suppliesId: item.id,
                    quantity: Number(item.qty)
                })
            }else {
                checkQty = true
                item.error = true
            }
        })
        if(checkQty){
            toastUtil.warning(`Vui lòng nhập số lượng vật tư ${this.type == "PLUS" ? 'nhập' : 'xuất'} kho`)
            return
        }

        if(suppliesQty && suppliesQty.length < 1){
            toastUtil.warning("Vui lòng thêm vật tư!")
            return
        }

        let data = {
            suppliesQty: this.unique_arr(suppliesQty),
            suppliesType: this.tabActive.toUpperCase(),
            inventoryType: this.type,
            note: note
        }
        this.isLoadingBt = true
        const result = await postRequest(`/v1/inventory`, data)
        this.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            toastUtil.success(`${this.type == "PLUS" ? 'Nhập' : 'Xuất'} kho thành công!`)
            $('#close_import_export_inventory').trigger('click')
            this.clearForm()
            await this.updateData()
        }else toastUtil.error(result.body.message)
    }

}

export const warehouseStore = new WarehouseStore()