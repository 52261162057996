import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import { questionStore} from "./QuestionStore";
import Loading from "../../common/component/Loading";
import ButtonAdd from "../../common/component/ButtonAdd";
import NoContent from "../../common/component/NoContent";
import "./QuestionStyle.scss"
import Paginate from "../../common/component/Paginate";
import ListQuestion from "./components/ListQuestion";
import AddOrEditQuestion from "./components/AddOrEditQuestion";


const QuestionComponent = () => {

    useEffect(() => {
         questionStore.getQuestions()
         questionStore.getTopic()
    }, [])


    const changePage = async (data: any) => {
        questionStore.page = data.selected;
        await questionStore.getQuestions();
    }

    const onKeyDown = async(e: any) => {
        if (e.key === 'Enter') {
            await callApi()
        }
    }

    const callApi = async () => {
        await questionStore.getQuestions();
    }

    const changeType = async (type: any) => {
        questionStore.searchName = ''
        questionStore.topicId = type
        await questionStore.getQuestions();
    }

    const changeTypeAnswer = async (type: any) => {
        questionStore.typeAnswer = type
        await questionStore.getQuestions();
    }


    return <div className='questions'>
        <div className="d-flex align-items-center justify-content-between filter_header">
            <div className="d-flex align-items-center">
                <img src="/assets/ico/filter.svg" alt=""/>
                <div className="d-flex form_search align-items-center">
                    <span>Thư mục:</span>
                    <div className="filter_select">
                        <select onChange={(e: any) => changeType(e.currentTarget.value)} value={questionStore.topicId}>
                            <option value="">Chọn</option>
                            {questionStore.listTopic && questionStore.listTopic.map((item, i) => {
                                return(
                                    <option value={item.id} key={i}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                {/*<div className="d-flex form_search align-items-center">*/}
                {/*    <span>Sắp xếp: </span>*/}
                {/*    <div className="filter_select">*/}
                {/*        <select onChange={(e: any) => changeTypeAnswer(e.currentTarget.value)}*/}
                {/*                value={questionStore.typeAnswer}>*/}
                {/*            <option value="">Chọn</option>*/}
                {/*            <option value={ITypeAnswer.answered}*/}
                {/*                    selected={questionStore.typeAnswer == ITypeAnswer.answered}>Đã trả lời*/}
                {/*            </option>*/}
                {/*            <option value={ITypeAnswer.notAnswered}*/}
                {/*                    selected={questionStore.typeAnswer == ITypeAnswer.notAnswered}>Chưa trả lời*/}
                {/*            </option>*/}
                {/*        </select>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="d-flex form_search align-items-center">
                    <div className="filter_search position-relative" style={{width: '189px'}}>
                        <input type="text" value={questionStore.searchName}
                               onChange={(e: any) => questionStore.searchName = e.currentTarget.value}
                               onKeyDown={(e: any) => onKeyDown(e)}
                               placeholder="Nhập câu hỏi tìm kiếm..."/>
                        <i className="far fa-search color-green position-absolute"
                           onClick={() => callApi()}/>
                    </div>
                </div>
            </div>
            <div className="bt_add">
                <ButtonAdd text="Thêm câu hỏi" dataTarget={'add_or_edit_question'} onClick={questionStore.clearForm}/>
            </div>
        </div>

        {questionStore.isLoading ? <Loading/> : <>
            {questionStore.listQuestion && questionStore.listQuestion.length > 0 ?
                <div className="list-question">
                    {questionStore.listQuestion.map((item, i) => {
                        return <ListQuestion item={item} key={i}/>
                    })}
                </div> : <NoContent/>
            }

            <div className="d-flex align-items-center justify-content-center mt-4">
                <Paginate currentPage={questionStore.page} totalPage={questionStore.totalPage}
                          changePage={changePage}/>
            </div>

            <AddOrEditQuestion/>
        </>}
    </div>
}

export default observer(QuestionComponent);