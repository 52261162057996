import React from 'react'
import ItemPackage from './ItemPackage'
import { upgradePackageStore } from '../UpgradePackageStore'
import { observer } from 'mobx-react'
import Loading from '../../../common/component/Loading'
import { useTranslation } from 'react-i18next'
import ItemResources from './ItemResources';
import "../UpgradePackage.scss"

function UpgradeFarmModal() {
    const { t } = useTranslation();
    let { exportQRProduct, exportQrBatchUnlimited, exportQrBatch } = upgradePackageStore.farmPackageBuyInfo.packageFarm?.packageData || {}
    let isHorizontal = exportQRProduct && !exportQrBatchUnlimited && exportQrBatch;

    return (
        <div className="modal fade" id="upgrade-farm-modal" role="dialog" aria-hidden="true" data-backdrop="static">
            <div className="modal-dialog my-20px" role="document" style={{ maxWidth: 1350 }}>
                <div className="modal-content border-0 p-20px px-20px">
                    <div className="modal-header border-0 p-0">
                        <span className='fs-32px fw-600 pb-18px'>{t("upgradeOmfarm")}</span>
                        <button type="button" id="close_upgrade-farm-modal" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className={`
                        modal-body p-0 gap-18px d-flex calc-max-h-vh-160px
                        ${upgradePackageStore.isOpenHistory && !(!exportQRProduct && exportQrBatchUnlimited) ? "overflow-x" : "flex-wrap overflow-y"}
                    `}>
                        {upgradePackageStore?.isLoading ? <Loading /> : upgradePackageStore.listFarmPackage?.map((item, i) =>
                            <ItemPackage
                                key={i}
                                item={item}
                            />
                        )}
                    </div>
                    {
                        upgradePackageStore.isOpenHistory && (
                            <>
                                {
                                    exportQRProduct &&
                                    <div className='pt-16px bg-white flex-1'>
                                        <p className='fs-16px color-00953D fw-500'>{t("qrByProduct")}</p>
                                        <div className={`${isHorizontal ? "overflow-x" : "flex-wrap overflow-y calc-max-h-vh-570px"} list-rq-code d-flex gap-10px`}>
                                            {
                                                upgradePackageStore?.isLoading ? <Loading /> : [...upgradePackageStore.listQrCodeProduct,...upgradePackageStore.listQrCodeProduct].map((resources, i) =>
                                                    <ItemResources key={i} item={resources} isHorizontal={isHorizontal} />
                                                )
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    !exportQrBatchUnlimited && exportQrBatch &&
                                    <div className='pt-16px bg-white flex-1'>
                                        <p className='fs-16px color-00953D fw-500'>{t("qrInBatches")}</p>
                                        <div className={`${isHorizontal ? "overflow-x" : "flex-wrap overflow-y calc-max-h-vh-570px"} list-rq-code d-flex gap-10px`}>
                                            {
                                                upgradePackageStore?.isLoading ? <Loading /> : [...upgradePackageStore.listQrCodeBatches,...upgradePackageStore.listQrCodeBatches].map((resources, i) =>
                                                    <ItemResources key={i} item={resources} isHorizontal={isHorizontal} />
                                                )
                                            }
                                        </div>
                                    </div>
                                }
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default observer(UpgradeFarmModal)