import React from 'react';
import {questionStore} from "../QuestionStore";
import ItemAnswer from "./ItemAnswer";
import ItemComment from "./ItemComment";
import {observer} from "mobx-react";

const ListComment = () => {

    return (
        <div className="list_comment">
            <ItemAnswer/>
            {questionStore.listComment && questionStore.listComment.map((item, i) => {
                return <ItemComment item={item} key={i}/>
            })}

        </div>
    );
}

export default observer(ListComment);