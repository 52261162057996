import React from 'react';
import {observer} from "mobx-react";
import {laborStore} from "../LaborStore";
import {numberUtil} from "../../../../common/utils/NumberUtil";
import Input from "../../../../common/component/form/Input";
import Select from "../../../../common/component/form/Select";
import DatePickerSingle from "../../../../common/component/DatePickerSingle";


const AddOrEditLabor = () => {

    const handleFilterDate = async (startDate: Date) => {
        laborStore.dataRequest.birthDay = startDate.getTime();
    };

    return (
        <div className="modal fade" id="add_or_edit_labor" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Thiết lập tài khoản</h5>
                        <button type="button" className="close" id="close_add_edit" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="w-100 d-flex">
                            <div className="form-group w-50 pr-2">
                                <label>Số điện thoại <span className="text-danger">*</span></label>
                                <Input className={(laborStore.dataRequest.phone && numberUtil.checkPhone(laborStore.dataRequest.phone)) ? 'border_error' : ''} value={laborStore.dataRequest.phone} disabled={laborStore.type == "edit" && true}
                                       onChange={(e: any) => {
                                           if(e.currentTarget.value.length <= 10){
                                               laborStore.dataRequest.phone = numberUtil.regexPhone(e)
                                           }
                                           if(e.currentTarget.value.length > 0){
                                               laborStore.errors.phone = ''
                                           }
                                           if(numberUtil.checkPhone(laborStore.dataRequest.phone)){
                                               laborStore.errors.phone = 'Số điện thoại chưa đúng định dạng!'
                                           }
                                       }}
                                       placeholder="Nhập số điện thoại"/>
                                {numberUtil.checkPhone(laborStore.dataRequest.phone) && <span style={{fontSize: 14}} className="text-danger">{laborStore.errors.phone}</span>}
                            </div>
                            <div className="form-group w-50 pl-2">
                                <label>Chức vụ <span className="text-danger">*</span></label>
                                <Select value={laborStore.dataRequest.roleId}
                                        onChange={(e: any) => laborStore.dataRequest.roleId = e}
                                        isError={laborStore.errors.role} options={laborStore.listRole}
                                        placeholder="Chọn chức vụ"/>
                            </div>
                        </div>
                        {laborStore.type == "edit" && <div className="w-100 d-flex ">
                            <div className="form-group w-50 pr-2">
                                <label>Họ và tên <span className="text-danger">*</span></label>
                                <Input isError={laborStore.errors.name} value={laborStore.dataRequest.name}
                                       onChange={(e: any) => laborStore.dataRequest.name = e.currentTarget.value}
                                       placeholder="Nhập họ và tên"/>
                            </div>
                            <div className="form-group w-50 pl-2">
                                <label>Ngày sinh</label>
                                <DatePickerSingle selected={laborStore.dataRequest.birthDay} maxDate={new Date()}
                                                  onChange={handleFilterDate} placeholder="Chọn ngày sinh"/>
                            </div>
                        </div>}
                        <div className="form-group">
                            <label>Lương theo giờ (VNĐ/h)</label>
                            <div className="input-group">
                                <input type="text" className={`form-control form-control-lg`}
                                       value={laborStore.dataRequest.wage}
                                       onChange={(e: any) => laborStore.dataRequest.wage = numberUtil.regexNumber(e)}
                                       placeholder="Nhập số tiền"/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => laborStore.type == "edit" ? laborStore.update() : laborStore.add()} disabled={laborStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditLabor);