import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import FilterComponent from "../../../common/component/filter/FilterComponent";
import Loading from "../../../common/component/Loading";
import {sttPage} from "../../../common/utils/Utils";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import {managerInformationStore} from "../information/ManagerInformationStore";
import {profileStore} from "../../profile/ProfileStore";
import {dateUtils} from "../../../common/utils/DateUtils";
import {useTranslation} from "react-i18next";


const ManagerDistributionPage = () => {
    const { t } = useTranslation();

    const changePage = async (data: any) => {
        managerInformationStore.page = data.selected;
        await managerInformationStore.getDistribution();
    }

    useEffect(() => {
        managerInformationStore.getDistribution().then()
        if(managerInformationStore.roleManager === 'province_manage') {
            managerInformationStore.getDetailAccountManager(profileStore.dataFarm.userId).then()
        }
        managerInformationStore.getProvinces().then(() => {
            if(managerInformationStore.roleManager === 'center_manage')
            {
                managerInformationStore.dataFilter = [
                    {
                        label: t("distributorName"),
                        type: 'search',
                        placeholder: t("search"),
                        value: managerInformationStore.searchName,
                        onChange: (e: any) => {
                            managerInformationStore.page = 0
                            managerInformationStore.searchName = e
                        },
                        callback: async (e: any) => {
                            await managerInformationStore.getDistribution();
                        }
                    },
                    {
                        label: t("province"),
                        type: 'select',
                        value: managerInformationStore.provinceId,
                        listValue: managerInformationStore.listProvince,
                        callback: async (e: any) => {
                            managerInformationStore.provinceId = e;
                            managerInformationStore.page = 0
                            await managerInformationStore.getDistribution();
                        }
                    },
                    {
                        label: t("district"),
                        type: 'select',
                        value: managerInformationStore.districtId,
                        listValue: managerInformationStore.listDistrict,
                        callback: async (e: any) => {
                            managerInformationStore.districtId = e;
                            managerInformationStore.page = 0
                            await managerInformationStore.changeDistrict(e)
                            await managerInformationStore.getDistribution();
                        }
                    }
                ];

            }
            else if(managerInformationStore.roleManager === 'province_manage') {
                managerInformationStore.dataFilter = [
                    {
                        label:  t("distributorName"),
                        type: 'search',
                        placeholder:  t("search"),
                        value: managerInformationStore.searchName,
                        onChange: (e: any) => {
                            managerInformationStore.page = 0
                            managerInformationStore.searchName = e
                        },
                        callback: async (e: any) => {
                            await managerInformationStore.getDistribution();
                        }
                    },
                    {
                        label: t("district"),
                        type: 'select',
                        value: managerInformationStore.districtId,
                        listValue: managerInformationStore.listDistrict,
                        callback: async (e: any) => {
                            managerInformationStore.districtId = e;
                            managerInformationStore.page = 0
                            await managerInformationStore.getDistribution();
                        }
                    }
                ];

            }
            else if(managerInformationStore.roleManager === 'district_manage') {
                managerInformationStore.dataFilter = [
                    {
                        label:  t("distributorName"),
                        type: 'search',
                        placeholder:  t("search"),
                        value: managerInformationStore.searchName,
                        onChange: (e: any) => {
                            managerInformationStore.page = 0
                            managerInformationStore.searchName = e
                        },
                        callback: async (e: any) => {
                            await managerInformationStore.getDistribution();
                        }
                    },
                ];
            }
        })
    }, [])

    useEffect(() => {
        if(managerInformationStore.provinceId) {
            managerInformationStore.getDistricts(managerInformationStore.provinceId).then(() => {
                managerInformationStore.dataFilter[managerInformationStore.dataFilter.length - 1].listValue = managerInformationStore.listDistrict
            })
        }
    }, [managerInformationStore.provinceId])
    return (
        <div className="planning">
            {managerInformationStore.dataFilter && <FilterComponent data={managerInformationStore.dataFilter}/>}

            {managerInformationStore.isLoading ? <Loading/> :
                <div className='table-responsive'>
                    {managerInformationStore.listDistribution && managerInformationStore.listDistribution.length > 0 ?
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th className="text-center">{t("numberOfOrders")}</th>
                                <th className="text-center">{t("distributorName")}</th>
                                <th className="text-center">{t("address")}</th>
                                <th className="text-center">{t("phoneNumber")}</th>
                                <th className="text-center">{t("createdAt")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {managerInformationStore.listDistribution.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(managerInformationStore.page, i)}</td>
                                        <td className="text-start">{item?.name ?? "-"}</td>
                                        <td className="text-start">{item?.address ?? "-"}</td>
                                        <td className="text-center">{item?.phone ?? "-"}</td>
                                        <td className="text-center">{dateUtils.formatDate(item?.createdAt)}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}

                    {managerInformationStore.listDistribution?.length > 0 && <div className="d-flex align-items-center justify-content-center mt-4">
                        <Paginate currentPage={managerInformationStore.page} totalPage={managerInformationStore.totalPage} changePage={changePage}/>
                    </div>}
                </div>}
        </div>
    )
}

export default observer(ManagerDistributionPage);