import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import QRCode from "react-qr-code";
import Paginate from "../../../common/component/Paginate";
import NoContent from "../../../common/component/NoContent";
import {trackingStore} from "../TrackingStore";
import Loading from "../../../common/component/Loading";
import {getIDParameter, number_format, slug, sttPage} from "../../../common/utils/Utils";
import {trackingDetailStore} from "./TrackingDetailStore";
import ButtonAdd from "../../../common/component/ButtonAdd";
import {Link} from "react-router-dom";
import {dateUtils} from "../../../common/utils/DateUtils";
import AddOrEditTrackingQR from "./components/AddOrEditTrackingQR";
import QRCodeTracking from "./components/QRCodeTracking";
import HistoryQr from "./components/HistoryQr";
import "./TrackingBatchStyle.scss"
import ModalPrintQR from "./components/ModalPrintQr";
import {distributorStore} from "../../setup/distributor/DistributorStore";
import {planStore} from "../../planning/PlanStore";
import TemSample from "./components/TemSample";

const TrackingDetailPage = () => {

    const addQr = () => {
        trackingDetailStore.pageHelper = `${window.location.origin}/xem-ket-qua-truy-xuat/${slug(trackingStore.dataRequest.plan?.procedure?.product?.name)}/${trackingStore.dataRequest.id}`
        trackingDetailStore.clearForm()
    }

    const changePage = async (data: any) => {
        trackingDetailStore.page = data.selected;
        await trackingDetailStore.getTracking();
    }

    const historyQr = async (item: any) => {
        trackingDetailStore.dataRequest = item;
        trackingDetailStore.setDefaultQrSetting()
        trackingDetailStore.idBatch = item.id;
        await trackingDetailStore.getHistoryQr(item.id)
    }

    useEffect(() => {
        trackingDetailStore.id = getIDParameter()
        trackingStore.getDetail(trackingDetailStore.id).then()
        trackingDetailStore.getTracking().then()
        distributorStore.getDistributor(100).then()
        planStore.getLands(trackingDetailStore.id).then()
    }, [])


    return (
        <div className="tracking-detail">
            <div className="header_back border-0 d-flex align-items-center justify-content-between">
                <div className="header_left">
                    <div className="d-flex align-items-center">
                        <Link to={`/tracking`}>Truy xuất</Link>
                        <i className="fal fa-angle-right"/>
                        <span>{trackingStore.dataRequest?.plan?.name}</span>
                    </div>
                </div>
                <ButtonAdd text="Thêm mã QR" className="text-white" dataTarget={'add_edit_tracking_qr'} onClick={addQr}/>
            </div>
            <div className='table-responsive'>
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr>
                        <th className="text-center">STT</th>
                        <th className="text-center">Mã lô</th>
                        <th className="text-center">Khối lượng tịnh</th>
                        <th className="text-center">Ngày gửi</th>
                        <th className="text-center">Lượt tải</th>
                        <th className="text-center">Mã QR</th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {trackingDetailStore.listTracking && trackingDetailStore.listTracking.map((item, i) => {
                        let pageHelper = `${window.location.origin}/truy-xuat-lo/${slug(trackingStore.dataRequest?.plan?.procedure?.product?.name)}/${trackingStore.dataRequest.id}/${item.id}`

                        return (
                            <tr key={i}>
                                <td className="text-center">{sttPage(trackingDetailStore.page, i)}</td>
                                <td className="text-center">{item.batchCode}</td>
                                <td className="text-center">{item.info?.netWeight}</td>
                                <td className="text-center">{dateUtils.formatDate(item.info?.sentDate)}</td>
                                <td className="text-center">{number_format(item.countQrExported)}</td>
                                <td className="text-center">
                                    <div title={"Mã QR truy xuất sản phẩm"}>
                                        <QRCode level="L" value={pageHelper} size={32}/>
                                    </div>
                                </td>
                                <td width="20%" className="text-center">
                                    <div className="action d-flex align-items-center justify-content-center">
                                        <img className="mr-3" data-toggle="modal" data-target="#add_edit_tracking_qr"
                                             onClick={async () => {trackingDetailStore.pageHelper = pageHelper; await trackingDetailStore.getDetailTracking(item.id)}} src="/assets/ico/action/ico_edit.svg" alt="" title="Sửa"/>
                                        <img className="mr-3" onClick={async () => {trackingDetailStore.pageHelper = pageHelper; await trackingDetailStore.getDetailTracking(item.id)}}
                                             data-toggle="modal" data-target="#qr_code_tracking" title="Tạo link tải mã qr" src="/assets/ico/action/ico_download.svg" alt=""/>
                                        <img onClick={async () => historyQr(item)}
                                             data-toggle="modal" data-target="#history_qr" title="Lịch sử xuất"
                                             src="/assets/ico/file_clock.svg" alt=""/>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {trackingDetailStore.isLoading && <Loading/>}

                {!trackingDetailStore.isLoading && trackingDetailStore.listTracking && trackingDetailStore.listTracking.length < 1 &&
                <NoContent/>}

                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Paginate currentPage={trackingDetailStore.page} totalPage={trackingDetailStore.totalPage}
                              changePage={changePage}/>
                </div>
                <ModalPrintQR/>
                <AddOrEditTrackingQR/>
                <QRCodeTracking/>
                <HistoryQr/>
                <TemSample/>
            </div>
        </div>
    )

}

export default observer(TrackingDetailPage);