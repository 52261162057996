import React, { useEffect } from 'react';
import {observer} from "mobx-react";
import {jobDiaryStore} from "../jobDiaryStore";
import NoContent from "../../../../common/component/NoContent";
import DatePickerSingle from "../../../../common/component/DatePickerSingle";
import Select from "../../../../common/component/form/Select";
import Input from "../../../../common/component/form/Input";
import {numberUtil} from "../../../../common/utils/NumberUtil";
import {urlImage} from "../../../../common/utils/Utils";
import uploadFileHelper from "../../../../common/helpers/UploadFileHelper";


const AddEditJob = observer(() => {

    const handleStartDate = (startDate: Date) => {
        jobDiaryStore.dataRequest.startDate = startDate.getTime()
        jobDiaryStore.minEndDate = startDate
        if(jobDiaryStore.dataRequest.startDate > jobDiaryStore.dataRequest.endDate){
            jobDiaryStore.dataRequest.endDate = ''
        }
    }

    const handleEndDate = (endDate: Date) => {
        jobDiaryStore.dataRequest.endDate = endDate.getTime()
    }

    const uploadImage = (e: any) => {
        jobDiaryStore.dataRequest.images.push(e)
    }

    useEffect(() => {
        if (jobDiaryStore.dataRequest.name && (jobDiaryStore.dataRequest.name?.length <= 2 || jobDiaryStore.dataRequest.name?.length >= 1000)) {
            jobDiaryStore.errors.name = 'Tên phải từ 3-1000 kí tự';
        } else {
            jobDiaryStore.errors.name = '';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobDiaryStore.dataRequest.name])


    return (
        <div className="modal fade bg-white" id="add_edit_job" role="dialog" aria-hidden="true">
            <div className="modal-dialog m-0 w-100 h-100" role="document" style={{maxWidth: `100%`, minHeight: '100vh'}}>
                <div className="modal-content border-0 h-100" style={{padding: `0 120px`}}>
                    <div className="modal-header">
                        <h5 className="modal-title">{jobDiaryStore.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} công việc</h5>
                        <button type="button" className="close" id="close_add_edit_job" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="form-group mb-4">
                            <label>Công việc <span className="text-danger">*</span></label>
                            <Input
                                isError={jobDiaryStore.errors.name}
                                value={jobDiaryStore.dataRequest.name}
                                isValid={jobDiaryStore.errors.name && jobDiaryStore.dataRequest.name}
                                onChange={(e: any) => jobDiaryStore.dataRequest.name = e.currentTarget.value}
                                placeholder="Nhập công việc" />
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <div className="form-group w-50 mr-4">
                                <label>Ngày bắt đầu <span className="text-danger">*</span></label>
                                <DatePickerSingle selected={jobDiaryStore.dataRequest.startDate}
                                                  isError={jobDiaryStore.errors.startDate} onChange={handleStartDate}
                                                  placeholder="Chọn ngày bắt đầu"/>
                            </div>
                            <div className="form-group w-50 ml-4">
                                <label>Ngày kết thúc <span className="text-danger">*</span></label>
                                <DatePickerSingle selected={jobDiaryStore.dataRequest.endDate} minDate={jobDiaryStore.minEndDate}
                                                  isError={jobDiaryStore.errors.endDate} onChange={handleEndDate}
                                                  placeholder="Chọn ngày kết thúc"/>
                            </div>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 pr-4">
                                <div className="form-group">
                                    <label>Sử dụng vật tư <span className="text-danger">*</span></label>
                                    <Select value={jobDiaryStore.use_material}
                                            options={[
                                                {
                                                    id: 'Nông dược',
                                                    name: 'Nông dược'
                                                },
                                                {
                                                    id: 'Phân bón',
                                                    name: 'Phân bón'
                                                },
                                                {
                                                    id: 'Máy móc',
                                                    name: 'Máy móc'
                                                },
                                                {
                                                    id: 'Nguyên vật liệu',
                                                    name: 'Nguyên vật liệu'
                                                }
                                            ]}
                                            onChange={(e: any) => jobDiaryStore.changeUseMaterial(e)}
                                            placeholder="Chọn sử dụng vật tư"/>
                                </div>
                            </div>
                            <div className="w-50 pl-4">
                                <div className="form-group">
                                    <label>Tên vật tư <span className="text-danger">*</span></label>
                                    <select className="form-control form-control-lg" value={jobDiaryStore.materialId} onChange={(e: any) => jobDiaryStore.materialId = e.currentTarget.value}>
                                        <option value="" disabled>Chọn tên vật tư</option>
                                        {jobDiaryStore.listSupplies && jobDiaryStore.listSupplies.map((item: any, i: number) => {
                                            return <option key={i} value={item.id}>{item.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mb-4">
                            <button className="btn btn-sm btn-success" onClick={() => jobDiaryStore.pushData()} disabled={jobDiaryStore.isLoadingAction || !jobDiaryStore.materialId}>Thêm</button>
                        </div>
                        <div className={`table-responsive mb-4 table_job`}>
                            {jobDiaryStore.listData && jobDiaryStore.listData.length > 0 ?
                                <table
                                    className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                                    <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <th className="text-center">Loại</th>
                                        <th className="text-center">Vật liệu</th>
                                        <th className="text-center">Số lượng sử dụng</th>
                                        <th className="text-center">Lượng sử dụng/<br/> đơn vị diện tích</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {jobDiaryStore.listData.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="text-center" title={item.type}>{item.type}</td>
                                                <td className="text-center">{item.data?.name}</td>
                                                <td className="text-center"><input type="text" value={item.qty} onChange={(e: any) => item.qty = numberUtil.regexNumber(e)}/></td>
                                                <td className="text-center">{item.data?.useForOneHa}</td>
                                                <td className="text-center"><i onClick={() => jobDiaryStore.remove(i, item.data?.id)} style={{fontSize: 16}} className="fas fa-trash text-danger"/></td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table> : <NoContent height={220}/>}
                        </div>

                        <div className="list_image_job mb-4">
                            <div className="list_">
                                <div className="upload" title="Thêm ảnh công việc">
                                    <img src="/assets/ico/ico_cloud_upload.svg" alt=""/>
                                    <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                           onChange={async (e: any) => await uploadFileHelper(e, uploadImage)}/>
                                </div>
                                {jobDiaryStore.dataRequest.images && jobDiaryStore.dataRequest.images.map((item: any, i: number) => {
                                    return (
                                        <div className="item" key={i}>
                                            <img className="img" src={urlImage(item)} alt=""/>
                                            <div className="close_item"
                                                 onClick={() => jobDiaryStore.dataRequest.images.splice(i, 1)}>
                                                <img src="/assets/ico/ico_close.svg" alt=""/>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="form-group mb-4">
                            <label>Ghi chú</label>
                            <Input value={jobDiaryStore.dataRequest.note}
                                   onChange={(e: any) => jobDiaryStore.dataRequest.note = e.currentTarget.value}
                                   placeholder="Nhập ghi chú"/>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn send-request" onClick={() => jobDiaryStore.addOrEdit()}
                                disabled={jobDiaryStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

})

export default AddEditJob;