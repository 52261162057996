import {observable} from "mobx";
import {getRequest, putRequest} from "../../common/helpers/RequestHelper"
import {profileStore} from "../profile/ProfileStore";
import {toastUtil} from "../../common/utils/ToastUtil";

export enum TypeInfo {
    info = 'info',
    edit = 'edit'
}

class InfoStore {
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable type: string = TypeInfo.info
    @observable errors: any = {
        name: "",
        businessDescription: "",
        businessCode: "",
        businessShortName: ""
    }
    @observable dataInfo: any = {
        name: "",
        businessDescription: "",
        foundedYear: '',
        email: "",
        phone: "",
        address: "",
        mainProduct: "",
        website: "",
        testimonial: '',
        totalRevenue: '',
        mainMarket: '',
        totalLabor: '',
        representativeName: "",
        certificate: '',
        certificates: [],
        plantingAreaCode: "",
        businessCode: "",
        businessShortName: ""
    }


    async getInfo(id?: any) {
        this.isLoading = true
        const result = await getRequest(`/v1/farm/${id ?? profileStore.dataFarm.farmId}`)
        this.isLoading = false
        if (result.status == 200) {
            this.dataInfo = result.body
        }
    }

    async updateInfo() {
        this.errors = {
            name: "",
            businessDescription: "",
            businessCode: "",
            businessShortName: ""
        }
        let {
            name,
            foundedYear,
            email,
            phone,
            address,
            plantingAreaCode,
            businessCode,
            businessShortName,
            mainProduct,
            website,
            testimonial,
            totalRevenue,
            businessDescription,
            mainMarket,
            totalLabor,
            representativeName,
            certificate,
            certificates
        } = infoStore.dataInfo
        let data = {
            name: name ?? null,
            foundedYear: Number(foundedYear) ?? null,
            email: email ?? null,
            phone: phone ?? null,
            address: address ?? null,
            mainProduct: mainProduct ?? null,
            website: website ?? null,
            testimonial: testimonial ?? null,
            totalRevenue: totalRevenue ?? null,
            mainMarket: mainMarket ?? null,
            totalLabor: totalLabor ?? null,
            businessDescription: businessDescription ?? null,
            representativeName: representativeName ?? null,
            certificate: certificate ?? null,
            certificates: certificates ?? null,
            plantingAreaCode: plantingAreaCode ?? null,
            businessCode: businessCode ?? null,
            businessShortName: businessShortName ?? null
        }

        if(!name){
            this.errors.name = 'Tên đơn vị không được để trống!'
        }

        if(!businessCode){
            this.errors.businessCode = 'Số đăng ký kinh doanh không được để trống!'
        }

        if(!businessShortName){
            this.errors.businessShortName = 'Ký hiệu kinh doanh không được để trống!'
        }

        if(!businessDescription){
            this.errors.businessDescription = 'Loại hình không được để trống!'
        }

        if(!name || !businessCode || !businessShortName || !businessDescription){
            return
        }

        this.isLoadingBt = true
        const result = await putRequest(`/v1/farm/${profileStore.dataFarm.farmId}`, data)
        this.isLoadingBt = false
        if (result.status == 200) {
            toastUtil.success('Cập nhật thông tin htx thành công', 2)
            await infoStore.getInfo()
        } else {
            if (typeof result.body.message === "string") {
                toastUtil.error(result.body.message, 3);
            } else if (typeof result.body.message === "object") {
                this.errors = result.body.message;
            }
        }
    }

}

export const infoStore = new InfoStore()
