import React from 'react';
import {observer} from "mobx-react";
import {landStore} from "../LandStore";
import {signUpStore} from "../../../auth/signUp/SignUpStore";
import Input from "../../../../common/component/form/Input";
import Select from "../../../../common/component/form/Select";
import {numberUtil} from "../../../../common/utils/NumberUtil";


const AddOrEditLand = () => {

    return (
        <div className="modal fade" id="add_or_edit_land" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Thiết lập đất - cơ sở</h5>
                        <button type="button" className="close" id="close_add_edit" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">

                        <div className="w-100 d-flex align-items-center">
                            <div className="w-50 pr-2">
                                <div className="form-group">
                                    <label>Tên đất - Cơ sở <span className="text-danger">*</span></label>
                                    <Input isError={landStore.errors.name} value={landStore.dataRequest.name}
                                           onChange={(e: any) => landStore.dataRequest.name = e.currentTarget.value}
                                           placeholder="Nhập tên đất cơ sở"/>
                                </div>
                            </div>
                            <div className="w-50 pl-2">
                                <div className="form-group">
                                    <label>Nông hộ - Thành viên <span className="text-danger">*</span></label>
                                    <Input isError={landStore.errors.farmHousehold} value={landStore.dataRequest.farmHousehold}
                                           onChange={(e: any) => landStore.dataRequest.farmHousehold = e.currentTarget.value}
                                           placeholder="Nhập nông hộ thành viên"/>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="w-50 pr-2">
                                <div className="form-group">
                                    <label>Tỉnh / Thành phố <span className="text-danger">*</span></label>
                                    <Select  value={landStore.dataRequest.provinceId}
                                             onChange={(e: any) => landStore.changeProvince(e)}
                                             isError={landStore.errors.province} options={signUpStore.listProvince}
                                             placeholder="Chọn tỉnh thành phố"/>
                                </div>
                            </div>
                            <div className="w-50 pl-2">
                                <div className="form-group">
                                    <label>Quận / Huyện <span className="text-danger">*</span></label>
                                    <Select value={landStore.dataRequest.districtId}
                                            onChange={(e: any) => landStore.changeDistrict(e)}
                                            isError={landStore.errors.district} options={signUpStore.listDistrict}
                                            placeholder="Chọn quận huyện"/>
                                </div>
                            </div>
                        </div>


                        <div className="d-flex align-items-center">
                            <div className="w-50 pr-2">
                                <div className="form-group">
                                    <label>Phường / Xã <span className="text-danger">*</span></label>
                                    <Select value={landStore.dataRequest.wardId}
                                            onChange={(e: any) => landStore.dataRequest.wardId = e}
                                            isError={landStore.errors.ward} options={signUpStore.listWard}
                                            placeholder="Chọn phường xã"/>
                                </div>
                            </div>
                            <div className="w-50 pl-2">
                                <div className="form-group">
                                    <label>Phân loại <span className="text-danger">*</span></label>
                                    <div className="d-flex align-items-center">
                                        <div className="form-check">
                                            <input className="form-check-input ml-0" type="radio" name="radio_classify" id="own" value="OWN"  onChange={(e: any) => landStore.dataRequest.type = e.currentTarget.value} checked={landStore.dataRequest.type == "OWN" && true}/>
                                            <label className="form-check-label" htmlFor="own">Sở hữu</label>
                                        </div>
                                        <div className="form-check ml-5">
                                            <input className="form-check-input ml-0" type="radio" name="radio_classify" id="leased_land" value="RENT" onChange={(e: any) => landStore.dataRequest.type = e.currentTarget.value} checked={landStore.dataRequest.type == "RENT" && true}/>
                                            <label className="form-check-label" htmlFor="leased_land">Đất thuê</label>
                                        </div>
                                    </div>
                                    <p className="error">{landStore.errors && landStore.errors.type}</p>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Diện tích (ha) <span className="text-danger">*</span></label>
                            <Input isError={landStore.errors.area} value={landStore.dataRequest.area}
                                   onChange={(e: any) => landStore.dataRequest.area = numberUtil.regexNumberDecimal(e)}
                                   placeholder="Nhập diện tích"/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => landStore.type == "add" ? landStore.add() : landStore.update()}
                                disabled={landStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditLand);