import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {getRequestOrg} from "../../../common/helpers/RequestHelper";


const Footer = () => {
    const [dataFooter, setDataFooter] = useState<any>()

    const getConfigHome = async () => {
        const result = await getRequestOrg('/omfarm/v1/config/PAGE_HOME')
        if (result.status === 200) {
            setDataFooter(result.body.content?.footer)
        }
    }

    useEffect(() => {
        getConfigHome().then()
    }, [])

    return (
        <div className="footer_om bg-white">
            <div className="footer_content">
                <div className="container d-flex justify-content-between flex-wrap">
                    <div className="content_left">
                        <div className="logo">
                            <img src="/assets/images/Logo_OMFARM.png" alt=""/>
                        </div>
                        <p className="d-flex align-items-center"><img className="mr-1" src="/assets/ico/ico_phone.png" alt=""/> Hotline: <a className="ml-1" href={`tel:+${dataFooter?.phone}`}>{dataFooter?.phone}</a></p>
                        <p className="d-flex align-items-center"><img className="mr-1" src="/assets/ico/ico_email.png" alt=""/> Email: <a  className="ml-1 email" href={`mailto:${dataFooter?.email}`}>{dataFooter?.email}</a></p>
                        <p>Địa chỉ: <span>Tầng 2 A40 lô 12, KĐT mới Định Công,<br/> P. Định Công, Q. Hoàng Mai, TP. Hà Nội, VN</span></p>

                    </div>
                    <div className="list_box">
                        {dataFooter?.linkInfo && dataFooter?.linkInfo.length > 0 && dataFooter?.linkInfo.map((val: any, i: number) => {
                            return (
                                <div className="box" key={i}>
                                    <h4>{val.title}</h4>
                                    <div className="content">
                                        {val.items && val.items.map((item: any, index: number) => {
                                            return (
                                                <Link to={item.target} key={index}>
                                                    {item.name}
                                                </Link>
                                            )
                                        })}
                                    </div>
                                    {i+1 == dataFooter.linkInfo.length && <div className="mt-4 d-none d-lg-block">
                                        <h4>{dataFooter?.title}</h4>
                                        <div className="d-flex list_ico align-items-center">
                                            <a href={dataFooter?.linkFacebook} target='_blank'>
                                                <img src="/assets/ico/ico_fb.svg" width={34} height={34} alt=""/>
                                            </a>
                                            <a href={dataFooter?.linkYoutube} target='_blank'>
                                                <img className='ml-3 mr-3' src="/assets/ico/ico_yt.svg" width={48} height={34} alt=""/>
                                            </a>
                                            <a href={dataFooter?.linkTiktok} target='_blank'>
                                                <img src="/assets/ico/ico_tiktok.svg" width={30} height={34} alt=""/>
                                            </a>
                                        </div>
                                    </div>}
                                </div>

                            )
                        })
                        }
                    </div>
                </div>
            </div>
            <div className="footer_right w-100 d-flex align-items-center justify-content-center">
                <span>© 2023 Ommani. All Rights Reserved</span>
            </div>
        </div>
    )

}

export default Footer

