import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery";
import {procedureJobService} from "./ProcedureJobService";


class ProcedureJobStore {
    @observable procedureId: any = '';
    @observable id: any = '';
    @observable type: any = '';
    @observable step_active: any = 1;
    @observable isLoading: boolean = false
    @observable isChanged: boolean = true
    @observable public errors: any = {
        name: "",
        type: ''
    }
    @observable isLoadingBt: boolean = false
    @observable dataRequest: any = {
        id: "",
        name: "",
        stage: {
            id: ""
        },
        sort: ''
    }
    @observable listProcedureJob: any[] = []

    clearError() {
        procedureJobStore.errors = {
            name: "",
            type: ''
        }
    }

    clearForm() {
        procedureJobStore.clearError()
        procedureJobStore.type = 'add'
        procedureJobStore.dataRequest = {
            id: "",
            name: "",
            stage: {
                id: ""
            },
            sort: ''
        }
    }

    reorder = async (startIndex: number, endIndex: number) => {
        const results: any[] = Array.from(procedureJobStore.listProcedureJob);
        const [removed] = results.splice(startIndex, 1);
        results.splice(endIndex, 0, removed);
        //resort
        results.forEach(function (item, index) {
            item.sort = index
        });
        procedureJobStore.listProcedureJob = results;

    };

    async sortJob() {
        const idS: number[] = procedureJobStore.listProcedureJob.map(item => item.id);
        const response = await procedureJobService.sortJob(idS);
        if (response.status === HttpStatusCode.OK) {
            procedureJobStore.isChanged = true
        } else {
            toastUtil.error(response.body.message ? response.body.message : 'Error.')
        }
    }

    async getProcedureJob() {
        procedureJobStore.isLoading = true
        const result = await procedureJobService.getProcedureJob()
        procedureJobStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            procedureJobStore.listProcedureJob = result.body.data
        }
    }


    async getDetail(id: any) {
        procedureJobStore.clearError()
        procedureJobStore.type = "edit"
        const result = await procedureJobService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            procedureJobStore.dataRequest = result.body
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async add() {
        let {name, stage, sort} = procedureJobStore.dataRequest


        let data = {
            name: name,
            stageId: stage.id,
            sort: sort ? Number(sort) : null,
            procedureId: procedureJobStore.procedureId
        }
        if(procedureJobStore.listProcedureJob.length < 1){
            data.sort = 0
        }else {
            if(!sort && sort !== 0){
                data.sort = procedureJobStore.listProcedureJob.pop().sort + 1
            }
        }

        if (!name) {
            procedureJobStore.errors.name = 'Tên công việc không được để trống!'
            return
        } else procedureJobStore.errors.name = ''


        if (!data.stageId) {
            procedureJobStore.errors.type = 'Phân loại không được để trống!'
            return
        } else procedureJobStore.errors.type = ''

        procedureJobStore.isLoadingBt = true
        const result = await procedureJobService.create(data)
        procedureJobStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thiết lập công việc thành công', 1)
            $('#close_add_edit').trigger('click')
            procedureJobStore.clearForm()
            await procedureJobStore.getProcedureJob()
        } else {
            toastUtil.error("Thiết lập công việc thất bại", 2)
        }

    }

    async update() {
        let {id, name, stage, sort} = procedureJobStore.dataRequest

        let data = {
            name: name,
            stageId: stage.id,
            sort: sort,
            procedureId: procedureJobStore.procedureId,
        }

        if(!sort && sort !== 0){
            data.sort = procedureJobStore.listProcedureJob.pop().sort + 1
        }

        if (!name) {
            procedureJobStore.errors.name = 'Tên công việc không được để trống!'
            return
        } else procedureJobStore.errors.name = ''


        if (!stage.id) {
            procedureJobStore.errors.type = 'Phân loại không được để trống!'
            return
        } else procedureJobStore.errors.type = ''


        procedureJobStore.isLoadingBt = true
        const result = await procedureJobService.update(id, data)
        procedureJobStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật công việc thành công', 3)
            $('#close_add_edit').trigger('click')
            procedureJobStore.clearForm()
            await procedureJobStore.getProcedureJob()
        } else {
            toastUtil.error("Cập nhật công việc thất bại", 4)
        }
    }

    async delete() {
        procedureJobStore.isLoadingBt = true
        const result = await procedureJobService.delete(procedureJobStore.id)
        procedureJobStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa công việc thành công', 5)
            await procedureJobStore.getProcedureJob()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }
}

export const procedureJobStore = new ProcedureJobStore()