import React from 'react';
import {observer} from "mobx-react";
import Input from "../../../common/component/form/Input";
import {questionStore} from "../QuestionStore";
import Select from "../../../common/component/form/Select";
import {urlImage} from "../../../common/utils/Utils";
import {Icon} from "../../../common/component/Icon";
import uploadFileHelper from "../../../common/helpers/UploadFileHelper";


const AddOrEditQuestion = () => {

    const uploadImg = (e: any) => {
        questionStore.dataRequest.attachFiles.push(e)
    }

    return (
        <div className="modal fade" id="add_or_edit_question" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{questionStore.type == 'add' ? 'Thêm' : 'Cập nhật'} câu hỏi</h5>
                        <button type="button" className="close" id="close_add_edit" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">

                        <div className="form-group">
                            <label>Thư mục <span className="text-danger">*</span></label>
                            <Select value={questionStore.dataRequest.postTopicId} disabled={questionStore.type == 'edit' && true}
                                    isError={questionStore.errors.postTopic} options={questionStore.listTopic}
                                    onChange={(e: any) => questionStore.dataRequest.postTopicId = e}
                                    placeholder="Chọn thư mục"/>
                        </div>

                        <div className="form-group">
                            <label>Tiêu đề <span className="text-danger">*</span></label>
                            <Input value={questionStore.dataRequest.name} isError={questionStore.errors.name}
                                   onChange={(e: any) => questionStore.dataRequest.name = e.currentTarget.value}
                                   placeholder="Nhập tiêu đề"/>
                        </div>

                        <div className="form-group">
                            <label>Nội dung</label>
                            <div className="input-group">
                                    <textarea value={questionStore.dataRequest.content}
                                              onChange={(e: any) => questionStore.dataRequest.content = e.currentTarget.value}
                                              className={`form-control form-control-lg`}
                                              placeholder="Nhập nội dung câu hỏi..."/>
                            </div>
                        </div>
                    </div>

                    <div className={`d-flex pt-0 modal-footer mt-4 align-items-end justify-content-${questionStore.dataRequest.attachFiles && questionStore.dataRequest.attachFiles.length > 0 ? 'between' : 'end'}`}>
                        {questionStore.dataRequest.attachFiles && questionStore.dataRequest.attachFiles.length > 0 &&
                        <div className="list_image">
                            <div className="list_" style={{width: `${(questionStore.dataRequest.attachFiles.length * 132) - 12}px`}}>
                                {questionStore.dataRequest.attachFiles.map((item: any, i: number) => {
                                    return (
                                        <div className="item" key={i}>
                                            <img className="img" src={urlImage(item)} alt=""/>
                                            <div className="close_item"
                                                 onClick={() => questionStore.dataRequest.attachFiles.splice(i, 1)}>
                                                <img src="/assets/ico/ico_close.svg" alt=""/>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                        <div className="d-flex pb-2 align-items-center">
                            <div className="upload" title="Tải ảnh">
                                <Icon name='image' type="outlined"/>
                                <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                       onChange={async (e: any) => await uploadFileHelper(e, uploadImg)}/>
                            </div>
                            <button type="button" className="btn bt-send-request"
                                    onClick={() => questionStore.sendQuestion()}
                                    disabled={questionStore.isLoadingBt}>Gửi câu hỏi <Icon name="send" type={"round"} className="text-white ml-1"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditQuestion);