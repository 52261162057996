import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {userService} from "./UserService";
import $ from "jquery"
import {toastUtil} from "../../../common/utils/ToastUtil";

export enum IStatus {
    ACTIVE = "ACTIVE",
    BLOCK = "BLOCK",
    UNLOCK = "UNLOCK",
    CANCEL = "CANCEL",
    RESET = "RESET",
    PENDING = "PENDING"
}


class UserStore {
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable userId: string = ''
    @observable status: string = ''
    @observable searchStatus: string = ''
    @observable keyword: string = ''
    @observable page: any = 0
    @observable totalPage: any = 0
    @observable listUser: any[] = []
    @observable dataFilter: any[] = [
        {
            label: "Tìm kiếm",
            type: 'search',
            placeholder: 'Nhập từ khóa',
            value: userStore.keyword,
            onChange: (e: any) => userStore.keyword = e,
            callback: async () => {
                userStore.page = 0
                await userStore.getUsers()
            }
        }
    ]

    async getUsers() {
        userStore.isLoading = true
        const result = await userService.getUsers()
        userStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            userStore.listUser = result.body.data
            userStore.totalPage = result.body.metadata.totalPage
        }
    }

    async updateStatus() {
        let message = 'Cập nhật trạng thái'
        userStore.isLoadingBt = true

        let status: any = userStore.status;

        if(userStore.status == IStatus.UNLOCK){
            status = IStatus.ACTIVE
        }

        let data: any = {
            status: status
        }

        if(userStore.status == IStatus.RESET){
            data['password'] = "123456"
        }

        const result = await userService.updateStatus(userStore.userId, data)
        userStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            userStore.listUser && userStore.listUser.map((item) => {
                if(item.id == userStore.userId){
                    item.status = userStore.status
                }
            })

            if(userStore.status == IStatus.CANCEL){
                message = "Từ chối tài khoản thành công"
            }else if(userStore.status == IStatus.ACTIVE){
                message = "Xác nhận tài khoản thành công"
            }else if(userStore.status == IStatus.BLOCK){
                message = "Khóa tài khoản thành công"
            }else if(userStore.status == IStatus.UNLOCK){
                message = "Mở khóa tài khoản"
            }else if(userStore.status == IStatus.RESET){
                message = "Reset mật khẩu thành công, mật khẩu mới là 123456"
            }

            toastUtil.success(message, 1)

            userStore.userId = ''
            userStore.status = ''
            $('#close_confirm').trigger('click')
            await userStore.getUsers()
        }else {
           toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra', 2)
        }
    }



}

export const userStore = new UserStore()