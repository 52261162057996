import {observable} from 'mobx'
import $ from 'jquery'
import {toastUtil} from "../../../common/utils/ToastUtil";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {qrCodePackageService} from "./QrCodePackageService";

interface IError{
    name: string,
    price: string,
    qrCodeLimit: string,
}
interface IDataRequest{
    id: string | number,
    name: string,
    type: string,
    price: string | number,
    qrCodeLimit: string | number
    isActive: boolean,
}

export enum TypeQrCode{
    PACKAGE_EXPORT_BATCH = "PACKAGE_EXPORT_BATCH",
    PACKAGE_EXPORT_PRODUCT = "PACKAGE_EXPORT_PRODUCT "
}

class QrCodePackageStore {
    @observable id: number | string = ''
    @observable page: number = 0
    @observable name: string = ''
    @observable totalPage: number = 0
    @observable listPackage: any[] = []
    @observable errors: IError = {
        name: '',
        price: '',
        qrCodeLimit: '',
    }
    @observable dataRequest: IDataRequest = {
        id: '',
        name: '',
        price: '',
        qrCodeLimit: '',
        type: TypeQrCode.PACKAGE_EXPORT_BATCH,
        isActive: true,
    }
    @observable isOpenDetail: boolean = false
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false


    clearError(){
        this.errors = {
            name: '',
            price: '',
            qrCodeLimit: '',
        }
    }

    clearForm(){
        this.clearError()
        this.dataRequest = {
            id: '',
            name: '',
            price: '',
            type: TypeQrCode.PACKAGE_EXPORT_BATCH,
            qrCodeLimit: '',
            isActive: true,
        }
    }

    async getQrCodePackage() {
        this.isLoading = true;
        const result = await qrCodePackageService.getQrCodePackage()
        this.isLoading = false;
        if (result.status === 200) {
            this.listPackage = result.body.data;
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: any) {
        this.clearError()
        const result = await qrCodePackageService.getDetail(id);
        if (result.status === 200) {
            this.dataRequest = result.body;
        }
    }


    async changeState(id: number, isActive: boolean) {
        this.isLoadingBt = true
        const result = await qrCodePackageService.changeState(id, !isActive)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${isActive ? 'Ngưng' : 'Bật'} hoạt động thiết lập gói QR Code thành công`,2)
            this.listPackage.map((item: any) => {
                if(item.id === id){
                    item.isActive = !isActive
                }
            })
        } else toastUtil.error(result.body.message)
    }


    async addEdit(){
        this.clearError()

        let {id, name, isActive, price, type, qrCodeLimit} = this.dataRequest

        let error = false
        if(!name?.trim()){
            error = true
            this.errors.name = 'Tên gói không được để trống!'
        }
        if(!price){
            error = true
            this.errors.price = 'Giá bán không được để trống!'
        }

        if(!qrCodeLimit){
            error = true
            this.errors.qrCodeLimit = 'Số lượng mã không được để trống!'
        }

        if(error){
            return
        }

        let data = {
            name: name,
            isActive: isActive,
            price: price,
            type: type,
            qrCodeLimit: qrCodeLimit,
        }

        this.isLoadingBt = true
        const result = id ? await qrCodePackageService.edit(id, data) : await qrCodePackageService.add(data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${id ? "Cập nhật" : 'Thiết lập'} thiết lập gói QR Code thành công`, 2)
            $('#close_qr_package').trigger('click')
            this.clearForm()
            this.getQrCodePackage().then()
        } else {
            toastUtil.error(result.body.message)
        }
    }


    async delete() {
        this.isLoadingBt = true
        const result = await qrCodePackageService.delete(this.id)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`Xóa thiết lập gói QR Code thành công`, 1)
            $('#close_delete').trigger('click')
            this.getQrCodePackage().then()
        } else toastUtil.error(result.body.message)

    }


}

export const qrCodePackageStore = new QrCodePackageStore();


