import React from 'react';
import {observer} from "mobx-react";
import {trackingSeafoodDetailStore} from "../TrackingSeafoodDetailStore";;


const ModalPrintQR = () => {

    return (
        <div className="modal fade" id="qr_print_code" role="dialog" aria-hidden="true">
            <div className="modal-dialog" style={{maxWidth: 650}} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">In mã QR</h5>
                        <button type="button" className="close" id="close_print_code" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        {/*<div className="form-group">*/}
                        {/*    <label>Chọn máy in <span className="text-danger">*</span></label>*/}
                        {/*    <select className="form-control" onChange={(e: any) => trackingSeafoodDetailStore.grayScale = e.currentTarget.value}>*/}
                        {/*        <option value={1}>Máy in màu</option>*/}
                        {/*        <option value={2} selected={trackingSeafoodDetailStore.grayScale == 2}>Máy in đen trắng</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        <div className="form-group">
                            <label>Chọn mẫu tem <span className="text-danger">*</span></label>
                            <select className="form-control" onChange={(e: any) => trackingSeafoodDetailStore.itemOnRow = e.currentTarget.value}>
                                <option value={2}>Mẫu 2 tem (3,5cm x 2,2cm)</option>
                                <option value={3}>Mẫu 3 tem (3,5cm x 2,2cm)</option>
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request" disabled={trackingSeafoodDetailStore.isLoadingBt} onClick={() => trackingSeafoodDetailStore.printQr()}>{trackingSeafoodDetailStore.isLoadingBt ? <i className="fa fa-spinner text-white fa-spin" /> : 'In mã'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(ModalPrintQR);