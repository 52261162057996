import React, { useRef } from 'react';
import {observer} from "mobx-react";
import {useReactToPrint} from 'react-to-print';
import {trackingStore} from "../TrackingStore";
import Barcode from "react-barcode";
import {slug} from "../../../common/utils/Utils";
import html2canvas from "html2canvas";
import {numberUtil} from "../../../common/utils/NumberUtil";
import "../TrackingStyle.scss"

const UpdateTracking = observer(() => {
    const componentRef: any = useRef();

    const downloadBarcode = async () => {
        const element = componentRef.current;
        const canvas = await html2canvas(element);
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'barcodeTracking.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    let {packageInfo} = trackingStore.dataRequest

    let {
        harvestDate,
        address,
        storageInstructions,
        userManual
    } = trackingStore.errors

    return (
        <div className="modal fade" id="update_tracking" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{trackingStore.dataRequest.plan?.name}</h5>
                        <button type="button" className="close" id="close_qr_code" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="content_access">
                            <div className="d-flex">
                                <div>
                                    <div className="barcode" ref={componentRef}>
                                        {trackingStore.dataRequest.barcode ? <Barcode value={`${trackingStore.dataRequest.barcode}`} format="EAN13"/> :
                                            <div className="no_barcode">
                                                <span>Bạn chưa có<br/>mã vạch</span>
                                            </div>}
                                    </div>
                                    <input type="text" className="input_barcode border-right-0 border-top-0 border-left-0" value={trackingStore.dataRequest.barcode ?? ''} onChange={(e: any) => {
                                        if(e.currentTarget.value.length <= 13){
                                            trackingStore.dataRequest.barcode = numberUtil.regexNumber(e)
                                        }
                                    }} placeholder="Nhập mã vạch ..."/>
                                </div>

                                <div className="form_access">
                                    <div className="list_info_product">
                                        <div className="info">
                                            <div className="title_top">
                                                <span>Thông tin đóng gói</span>
                                                <i className='fas fa-plus' onClick={() => packageInfo.more.push({key: '', value: ''})}/>
                                            </div>
                                            <div className="list_content">
                                                <div className="item">
                                                    <label>Ngày thu hoạch <span className="text-danger">*</span></label>
                                                    <input type="text" value={packageInfo.harvestDate}
                                                           className={`${harvestDate && !packageInfo.harvestDate ? 'border_error' : ''}`}
                                                           onChange={(e: any) => packageInfo.harvestDate = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label>Hướng dẫn sử dụng <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={packageInfo.userManual}
                                                           className={`${userManual && !packageInfo.userManual ? 'border_error' : ''}`}
                                                           onChange={(e: any) => packageInfo.userManual = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label>Hướng dẫn bảo quản <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={packageInfo.storageInstructions}
                                                           className={`${storageInstructions && !packageInfo.storageInstructions ? 'border_error' : ''}`}
                                                           onChange={(e: any) => packageInfo.storageInstructions = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label>Được sản xuất từ <span className="text-danger">*</span></label>
                                                    <input type="text" value={packageInfo.address}
                                                           className={`${address && !packageInfo.address ? 'border_error' : ''}`}
                                                           onChange={(e: any) => packageInfo.address = e.currentTarget.value}/>
                                                </div>

                                                {packageInfo.more && packageInfo.more.map((item: any, i: number) => {
                                                    return <div className="item" key={i}>
                                                        <input type="text" value={item.key}
                                                               onChange={(e: any) => item.key = e.currentTarget.value}/>
                                                        <input type="text" value={item.value}
                                                               onChange={(e: any) => item.value = e.currentTarget.value}/>
                                                        <i className="fas fa-times"
                                                           onClick={() => packageInfo.more.splice(i, 1)}/>
                                                    </div>
                                                })}
                                            </div>
                                        </div>

                                        <div className="info">
                                            <div className="title_top">
                                                <span>Chất lượng sản phẩm</span>
                                                <i className='fas fa-plus'
                                                   onClick={() => trackingStore.dataRequest.more.push({
                                                       key: '',
                                                       value: ''
                                                   })}/>
                                            </div>
                                            <div className="list_content">
                                                {trackingStore.dataRequest.more && trackingStore.dataRequest.more.map((item: any, i: number) => {
                                                    return <div className="item" key={i}>
                                                        {i + 1 > 3 ? <input type="text" value={item.key}
                                                                            onChange={(e: any) => item.key = e.currentTarget.value}/> :
                                                            <label>{item.key} <span
                                                                className="text-danger">*</span></label>}
                                                        <input type="text" value={item.value}
                                                               className={`${item.isError && !item.value ? 'border_error' : ''}`}
                                                               onChange={(e: any) => item.value = e.currentTarget.value}/>
                                                        {i + 1 > 3 && <i className="fas fa-times"
                                                                         onClick={() => trackingStore.dataRequest.more.splice(i, 1)}/>}
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                        <div className="info">
                                            <div className="title_top">
                                                <span>Ghi chú</span>
                                            </div>
                                            <div className="list_content">
                                                <textarea rows={3} value={trackingStore.dataRequest.note}
                                                          onChange={(e: any) => trackingStore.dataRequest.note = e.currentTarget.value}
                                                          placeholder="Nhập ghi chú"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="save_qr_code">
                                        <div className="d-flex align-items-center">
                                            {trackingStore.dataRequest.id ? <a href={`${window.location.origin}/xem-ket-qua-truy-xuat/${slug(trackingStore.dataRequest.plan?.procedure?.product?.name)}/${trackingStore.dataRequest.id}`} target="_blank">
                                                <button className="action_barcode mr-2">Xem kết quả</button>
                                            </a> : <button className="action_barcode mr-2">Xem kết quả</button>}
                                            <button className="action_barcode mr-2" disabled={!trackingStore.dataRequest.barcode} onClick={() => downloadBarcode()}>Tải Mã Vạch</button>
                                            <button className="action_barcode" disabled={!trackingStore.dataRequest.barcode} onClick={handlePrint}>In Mã Vạch</button>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn send-request"
                                                onClick={() => trackingStore.updateTracking(true)}
                                                disabled={trackingStore.isLoadingBt}>Lưu
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

})

export default UpdateTracking;