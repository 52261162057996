import React from 'react';
import {observer} from "mobx-react";
import {questionStore} from "../QuestionStore";
import Input from "../../../common/component/form/Input";
import {urlImage} from "../../../common/utils/Utils";
import {Icon} from "../../../common/component/Icon";
import SelectMulti from "../../../common/component/form/SelectMulti";
import TextEditor from "../../../common/component/TextEditor";
import uploadFileHelper from "../../../common/helpers/UploadFileHelper";


const FormDisease = observer(() => {

    const uploadImg = (e: any) => {
        questionStore.formDisease.attachFiles.push(e)
    }

    const changeMedicine = (value: any) => {
        questionStore.formDisease.medicines = value
    }

    const changeFertilizer = (value: any) => {
        questionStore.formDisease.fertilizers = value
    }


    return (
        <div className="modal fade form_answer" id="add_or_edit_form_disease_question" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Báo cáo trả lời sâu bệnh</h5>
                        <button type="button" className="close" id="close_add_edit_form_disease" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="d-flex">
                            <div className="w-50 mr-2">
                                <div className="form-group">
                                    <label>Tên bệnh <span className="text-danger">*</span></label>
                                    <Input value={questionStore.formDisease.diseaseName}
                                           isError={questionStore.errors.diseaseName}
                                           onChange={(e: any) => questionStore.formDisease.diseaseName = e.currentTarget.value}
                                           placeholder="Nhập tên bệnh"/>
                                </div>
                            </div>
                            <div className="w-50 ml-2">
                                <div className="form-group">
                                    <label>Bộ phận cây trồng nhiễm bệnh <span className="text-danger">*</span></label>
                                    <Input value={questionStore.formDisease.damagedPart}
                                           isError={questionStore.errors.damagedPart}
                                           onChange={(e: any) => questionStore.formDisease.damagedPart = e.currentTarget.value}
                                           placeholder="Nhập bộ phận cây trồng nhiễm bệnh"/>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Mô tả đặc điểm nhận diện <span className="text-danger">*</span></label>
                            <Input value={questionStore.formDisease.diseaseDetail}
                                   isError={questionStore.errors.diseaseDetail}
                                   onChange={(e: any) => questionStore.formDisease.diseaseDetail = e.currentTarget.value}
                                   placeholder="Nhập mô tả đặc điểm nhận diện"/>
                        </div>

                        <div className="form-group h-auto">
                            <label>Cách xử lý <span className="text-danger">*</span></label>
                            <div className="input-group">
                                <TextEditor height={160} content={questionStore.formDisease.solution}
                                            onChange={(e: any) => questionStore.formDisease.solution = e}/>
                                {(questionStore.errors.disease_solution && !questionStore.formDisease.solution) &&
                                <p className="error">{questionStore.errors.disease_solution}</p>}
                            </div>
                        </div>

                        <div className="d-flex mt-4">
                            <div className="w-50 mr-2">
                                <div className="form-group">
                                    <label>Nông dược sử dụng</label>
                                    <SelectMulti placeholder={"Chọn nông dược"} options={questionStore.listMedicine}
                                                 value={questionStore.formDisease.medicines}
                                                 callback={(e: any) => changeMedicine(e)}/>
                                </div>
                            </div>

                            <div className="w-50 ml-2">
                                <div className="form-group">
                                    <label>Phân bón sử dụng</label>
                                    <SelectMulti placeholder={"Chọn phân bón"} options={questionStore.listFertilizer}
                                                 value={questionStore.formDisease.fertilizers}
                                                 callback={(e: any) => changeFertilizer(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`d-flex modal-footer align-items-end justify-content-${questionStore.formDisease.attachFiles && questionStore.formDisease.attachFiles.length > 0 ? 'between' : 'end'}`}>
                        {questionStore.formDisease.attachFiles && questionStore.formDisease.attachFiles.length > 0 &&
                        <div className="list_image">
                            <div className="list_">
                                {questionStore.formDisease.attachFiles.map((item: any, i: number) => {
                                    return (
                                        <div className="item" key={i}>
                                            <img className="img" src={urlImage(item)} alt=""/>
                                            <div className="close_item"
                                                 onClick={() => questionStore.formDisease.attachFiles.splice(i, 1)}>
                                                <img src="/assets/ico/ico_close.svg" alt=""/>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                        <div className="d-flex pb-2 align-items-center">
                            <div className="upload" title="Tải ảnh">
                                <Icon name='image' type="outlined"/>
                                <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                       onChange={async (e: any) => await uploadFileHelper(e, uploadImg)}/>
                            </div>
                            <button type="button" className="btn bt-send-request"
                                    onClick={() => questionStore.sendDisease()} disabled={questionStore.isLoadingBt}>Xác
                                nhận <Icon name="send" type={"round"} className="text-white"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

})

export default FormDisease;