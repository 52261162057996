import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {accountService} from "../AccountService";
import {postRequest} from "../../../common/helpers/RequestHelper";
import {toastUtil} from "../../../common/utils/ToastUtil";
import StorageService from "../../../common/service/StorageService";
import {checkExpirationTime, parseJwt} from "../../../common/utils/Utils";
import {infoStore} from "../../info/InfoStore";
import {profileStore} from "../../profile/ProfileStore";
import {managerInformationStore} from "../../manager/information/ManagerInformationStore";

interface IErrors {
    farm_name: string,
    type: string,
    address: string,
    province: string,
    district: string,
    ward: string,
    businessCode: string,
    businessShortName: string
}


class SignUpStore {
    @observable public step: any = 1
    @observable public text_error: any = ''
    @observable public errors: IErrors = {
        farm_name: "",
        type: "",
        address: "",
        province: "",
        district: "",
        ward: '',
        businessCode: "",
        businessShortName: ""
    }
    @observable public listType: any = [
        {
            name: 'Hộ gia đình',
            id: "HOUSEHOLD"
        },
        {
            name: 'Hợp tác xã',
            id: "COOPERATIVE"
        },
        {
            name: 'Tổ hợp tác',
            id: "COOPERATIVE_GROUP"
        },
        {
            name: 'Trang trại',
            id: "FARM"
        },
        {
            name: 'Công ty',
            id: "COMPANY"
        },
        {
            name: 'Cơ quan nhà nước',
            id: "STATE_AGENCIES"
        }
    ]
    @observable public isLoading: boolean = false
    @observable public listProvince: any[] = []
    @observable public listDistrict: any[] = []
    @observable public listWard: any[] = []
    @observable public dataRequest: any = {
        name: "",
        type: null,
        address: "",
        provinceId: null,
        districtId: null,
        wardId: null,
        businessCode: "",
        businessShortName: ""
    }


    clearError() {
        signUpStore.errors = {
            farm_name: "",
            type: "",
            address: "",
            province: "",
            district: "",
            ward: '',
            businessCode: "",
            businessShortName: ""
        }
    }

    clearForm() {
        signUpStore.clearError()
        signUpStore.dataRequest = {
            name: "",
            type: null,
            address: "",
            provinceId: null,
            districtId: null,
            wardId: null,
            businessCode: "",
            businessShortName: ""
        }
    }


    async changeProvince(provinceId: any) {
        signUpStore.dataRequest.provinceId = provinceId
        signUpStore.dataRequest.districtId = null
        signUpStore.dataRequest.wardId = null
        await signUpStore.getDistricts(provinceId)
    }

    async changeDistrict(districtId: any) {
        signUpStore.dataRequest.districtId = districtId
        signUpStore.dataRequest.wardId = null
        await signUpStore.getWards(districtId)
    }


    async getProvinces() {
        const result = await accountService.getProvinces()
        if (result.status === HttpStatusCode.OK) {
            signUpStore.listProvince = result.body
            signUpStore.listDistrict = []
            signUpStore.listWard = []
        }
    }

    async getDistricts(provinceId: any) {
        const result = await accountService.getDistricts(provinceId)
        if (result.status === HttpStatusCode.OK) {
            signUpStore.listDistrict = result.body
            signUpStore.listWard = []
        }
    }

    async getWards(districtId: any) {
        const result = await accountService.getWards(districtId)
        if (result.status === HttpStatusCode.OK) {
            signUpStore.listWard = result.body
        }
    }

    async sendToken(isAddFarm?: boolean) {
        signUpStore.isLoading = true
        const result = await postRequest('/sso/v1/auth/gettoken?service=farm', {}, true)
        signUpStore.isLoading = false;
        if (result.status === HttpStatusCode.OK) {
            let claims: any = result.body.claims;
            let token: string = result.body.token;
            let refreshToken: string = result.body.refreshToken;
            localStorage.setItem('name', claims.name)
            localStorage.setItem('avatar', claims.avatar)
            localStorage.setItem('listRole', claims.roles)
            localStorage.setItem('expirationTime', claims.packageExp)
            localStorage.setItem('packageName', claims.packageName)
            localStorage.setItem('farmOwner', claims.farmOwner)
            localStorage.setItem('isAdmin', claims.isAdmin || false)
            if(checkExpirationTime(claims.packageExp).isExpired && !claims.isAdmin) window.location.href = "het-han-dung-thu";
            StorageService.setToken(token);
            StorageService.setRefreshToken(refreshToken);
            parseJwt(token)
            profileStore.dataFarm.name = claims.name
            profileStore.dataFarm.avatar = claims.avatar
            
            profileStore.listRole = claims.roles.split(',')
            if (profileStore.listRole.includes('center_manage')) {
                profileStore.roleUser = 'manager'
                managerInformationStore.roleManager = 'center_manage'
            } else if (profileStore.listRole.includes('province_manage')) {
                profileStore.roleUser = 'manager'
                managerInformationStore.roleManager = 'province_manage'
            } else if (profileStore.listRole.includes('district_manage')) {
                profileStore.roleUser = 'manager'
                managerInformationStore.roleManager = 'district_manage'
            }
            
            isAddFarm && await infoStore.getInfo();
        }
    }

    async addFarm() {
        this.clearError()
        let {
            name,
            type,
            businessCode,
            businessShortName,
            provinceId,
            districtId,
            wardId,
            address
        } = signUpStore.dataRequest
        let data = {
            name: name.trim(),
            type: type,
            businessCode: businessCode,
            businessShortName: businessShortName,
            address: address,
            provinceId: Number(provinceId),
            districtId: Number(districtId),
            wardId: Number(wardId)
        }

        if (!name) {
            signUpStore.errors.farm_name = 'Tên đơn vị không được để trống!';
        }
        if (!businessShortName) {
            signUpStore.errors.businessShortName = 'Ký hiệu doanh nghiệp không được để trống!';
        }
        if (businessShortName.length > 5) {
            signUpStore.errors.businessShortName = 'Ký hiệu doanh nghiệp tối đa là 5 ký tự!';
        }
        if (!businessCode) {
            signUpStore.errors.businessCode = 'Số đăng ký kinh doanh không được để trống!';
        }

        if (!type) {
            signUpStore.errors.type = 'Loại hình không được để trống!';
        }
        if (!provinceId) {
            signUpStore.errors.province = "Tỉnh/Thành phố không được để trống!"
        }
        if (!districtId) {
            signUpStore.errors.district = "Quận/Huyện không được để trống!"
        }
        if (!wardId) {
            signUpStore.errors.ward = "Phường/Xã không được để trống!"
        }

        if (!name || !type || !provinceId || !districtId || !wardId || signUpStore.errors.businessShortName || !businessCode) {
            return
        }

        if (businessCode.length < 5) {
            return
        }

        signUpStore.isLoading = true
        const result = await postRequest(`/v1/farm`, data)
        signUpStore.isLoading = false

        if (result.status == 200) {
            toastUtil.success('Tạo thông tin farm thành công', 2)
            this.clearForm()
            await this.sendToken(true)
        } else {
            if (typeof result.body.message === "object") {
                signUpStore.errors = result.body.message
            } else {
                toastUtil.error(result.body.message, 2)
            }
        }
    }
}

export const signUpStore = new SignUpStore()