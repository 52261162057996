import {observable} from "mobx";

export enum IRole {
    admin = 'admin',
    user = 'user'
}

class SidebarStore {
    @observable pathSubmenu: any = ''
}

export const sidebarStore = new SidebarStore()