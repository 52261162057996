import React from 'react';
import {observer} from "mobx-react";
import QRCode from 'qrcode.react';
import DatePickerSingle from "../../../../common/component/DatePickerSingle";
import {urlImage} from "../../../../common/utils/Utils";
import {trackingLivestockStore} from "../../TrackingLivestockStore";
import {numberUtil} from "../../../../common/utils/NumberUtil";
import {trackingLivestockDetailStore} from "../TrackingLivestockDetailStore";
import uploadFileHelper from "../../../../common/helpers/UploadFileHelper";

;

const AddOrEditTrackingQR = observer(() => {

    const handleStartDate = async (startDate: Date) => {
        trackingLivestockDetailStore.dataRequest.info.sentDate = startDate.getTime()
    }
    const handleExpireDate = async (date: Date) => {
        trackingLivestockDetailStore.dataRequest.info.expireDate = date.getTime()
    }


    const changeImg = (e: any) => {
        trackingLivestockDetailStore.dataRequest.logo = e
    }

    let {info} = trackingLivestockDetailStore.dataRequest

    let {
        consumerUnit,
        consumerUnitCode,
        deliveryLocation,
        address,
        sentDate,
        expireDate,
        shippingPartner,
        shippingPartnerCode,
        distributingAgent
    } = trackingLivestockDetailStore.errors

    return (
        <div className="modal fade" id="add_edit_tracking_qr" role="dialog" aria-hidden="true">
            <div className="modal-dialog" style={{maxWidth: 920}} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{trackingLivestockStore.dataRequest.productInformation.name} - {trackingLivestockDetailStore.type == 'add' ? 'Thêm' : 'Cập nhật'} mã</h5>
                        <button type="button" className="close" id="close_qr_code" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-0">
                        <div className="content_access pb-0">
                            <div className="d-flex">
                                <div className="QR_code position-relative">
                                    <QRCode id="download_qrCode" level="L" value={trackingLivestockDetailStore.pageHelper}
                                            size={188}/>
                                    {trackingLivestockDetailStore.dataRequest.logo && <div
                                        className="logo_farm position-absolute d-flex align-items-center justify-content-center">
                                        <img width={36} height={36}
                                             src={urlImage(trackingLivestockDetailStore.dataRequest.logo)}
                                             alt=""/>
                                    </div>}
                                </div>

                                <div className="form_access">
                                    <div className="list_info_product">
                                        <div className="info">
                                            <div className="title_top">
                                                <span>Thông tin chi tiết</span>
                                                <i className='fas fa-plus'
                                                   onClick={() => info.detailMore.push({key: '', value: ''})}/>
                                            </div>
                                            <div className="list_content">
                                                <div className="item">
                                                    <label className="title">Ngày gửi hàng <span
                                                        className="text-danger">*</span></label>
                                                    <DatePickerSingle selected={info.sentDate} isError={sentDate}
                                                                      onChange={handleStartDate}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Ngày hết hạn <span
                                                        className="text-danger">*</span></label>
                                                    <DatePickerSingle selected={info.expireDate} minDate={trackingLivestockDetailStore.dataRequest.info.sentDate} isError={expireDate}
                                                                      onChange={handleExpireDate}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Địa điểm gửi hàng <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info.deliveryLocation}
                                                           className={`${deliveryLocation && !info.deliveryLocation ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.deliveryLocation = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Đơn vị tiêu thụ <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info.consumerUnit}
                                                           className={`${consumerUnit && !info.consumerUnit ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.consumerUnit = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Mã đơn vị tiêu thụ <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info.consumerUnitCode}
                                                           className={`${consumerUnitCode && !info.consumerUnitCode ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.consumerUnitCode = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Địa chỉ <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info.address}
                                                           className={`${address && !info.address ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.address = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Đơn vị vận chuyển <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info.shippingPartner}
                                                           className={`${shippingPartner && !info.shippingPartner ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.shippingPartner = e.currentTarget.value}/>
                                                </div>

                                                <div className="item">
                                                    <label className="title">Mã đơn vị vận chuyển <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info.shippingPartnerCode}
                                                           className={`${shippingPartnerCode && !info.shippingPartnerCode ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.shippingPartnerCode = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Số lượng đã xuất</label>
                                                    <input type="text" value={info?.quantityShipped}
                                                           onChange={(e: any) => info.quantityShipped = numberUtil.regexNumber(e)}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Số lượng tồn kho</label>
                                                    <input type="text" value={info?.quantityLeft}
                                                           onChange={(e: any) => info.quantityLeft = numberUtil.regexNumber(e)}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Đại lý phân phối<span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info?.distributingAgent}
                                                           className={`${distributingAgent && !info?.distributingAgent ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.distributingAgent = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Mã đơn vị phân phối</label>
                                                    <input type="text" value={info?.distributingUnitCode}
                                                           onChange={(e: any) => info.distributingUnitCode = e.currentTarget.value}/>
                                                </div>
                                                {info?.detailMore && info?.detailMore.map((item: any, i: number) => {
                                                    return <div className="item" key={i}>
                                                        <input className="title" type="text" value={item.key}
                                                               onChange={(e: any) => item.key = e.currentTarget.value}/>
                                                        <input type="text" value={item.value}
                                                               onChange={(e: any) => item.value = e.currentTarget.value}/>
                                                        <i className="fas fa-times"
                                                           onClick={() => info.detailMore.splice(i, 1)}/>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="save_qr_code justify-content-center">
                                        <button className="mr-3 action_barcode position-relative">
                                            Chèn logo
                                            <input className="up_img"
                                                   accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                                   title='Logo kích thước 48x48'
                                                   onChange={async (e: any) => await uploadFileHelper(e, changeImg)}/>
                                        </button>
                                        <button className="mr-3 action_barcode bt_remove_outline"
                                                disabled={!trackingLivestockDetailStore.dataRequest.logo}
                                                onClick={() => trackingLivestockDetailStore.dataRequest.logo = ''}>Xóa
                                            logo
                                        </button>

                                        <button className="action_barcode mr-2"
                                                disabled={!trackingLivestockDetailStore.dataRequest.id}>{trackingLivestockDetailStore.dataRequest.id ?
                                            <a href={trackingLivestockDetailStore.pageHelper} target="_blank">Xem kết
                                                quả</a> : 'Xem kết quả'}</button>

                                        <button className="action_barcode"
                                                disabled={!trackingLivestockDetailStore.dataRequest.id}
                                                data-dismiss="modal"
                                                data-toggle="modal" data-target="#qr_code_tracking">Tải mã
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn send-request"
                                                style={{width: 276}}
                                                onClick={() => trackingLivestockDetailStore.addEditTracking(true)}
                                                disabled={trackingLivestockDetailStore.isLoadingBt || (trackingLivestockDetailStore.type == 'add' && trackingLivestockDetailStore.dataRequest.id)}>Lưu
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

})

export default AddOrEditTrackingQR;