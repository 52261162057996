import React from 'react';
import {observer} from "mobx-react";
import Input from "../../../../common/component/form/Input";
import {jobDiaryStore} from "../jobDiaryStore";
import {planStore} from "../../../planning/PlanStore";


const FinishPlan = () => {


    return (
        <div className="modal fade" id="finishPlan" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{planStore.dataRequest.actualOutput ? 'Chỉnh sửa kết quả' : 'Kết thúc kế hoạch'}</h5>
                        <button type="button" className="close" id="close_finish_plan_" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="form-group">
                            <label>Nhập sản lượng thực tế <span className="text-danger">*</span></label>
                            <Input isError={jobDiaryStore.error} value={planStore.dataRequest.actualOutput}
                                   onChange={(e: any) => planStore.dataRequest.actualOutput = e.currentTarget.value}
                                   placeholder="Nhập sản lượng thực tế"/>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => jobDiaryStore.finishPlan()}
                                disabled={jobDiaryStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(FinishPlan);