import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery"
import {equipmentService} from "./EquipmentService";
import {warehouseStore} from "../../warehouse/WarehouseStore";
import Constants from "../../../common/constants/Constants";

class EquipmentStore {
    @observable id: any = '';
    @observable type: any = '';
    @observable urlViewer: any = '';
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable errors: any = {
        name: ''
    }
    @observable dataRequest: any = {
        id: '',
        name: '',
        unit: '',
        shortName: '',
        images: [],
        note: '',
        expiredTime: '',
        userManual: '',

    }
    @observable listEquipment: any[] = []

    clearForm() {
        equipmentStore.errors = {
            name: ''
        }
        equipmentStore.type = 'add'
        equipmentStore.dataRequest = {
            id: '',
            name: '',
            unit: '',
            shortName: '',
            images: [],
            userManual: '',
            note: ''
        }
    }

    async getEquipments(size?: any) {
        equipmentStore.isLoading = true
        const result = await equipmentService.getEquipments(size)
        equipmentStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            equipmentStore.listEquipment = result.body.data
            warehouseStore.listSupplies = result.body.data
            equipmentStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getEquipmentsAll() {
        equipmentStore.isLoading = true
        const result = await equipmentService.getEquipmentsAll()
        equipmentStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            warehouseStore.listSuppliesAll = result.body.data
        }
    }

    async importData(file: any) {
        let data = {
            fileImport: file
        }
        const result = await equipmentService.importData(data);
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Import thiết bị máy móc thành công!');
            await equipmentStore.getEquipments();
        } else {
            toastUtil.error(result.body.message);
        }
    }


    async getDetail(id: any) {
        equipmentStore.type = "edit"
        equipmentStore.errors = {
            name: '',
            inventory: ''
        }
        const result = await equipmentService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            equipmentStore.dataRequest = result.body
        }else {
            toastUtil.error(result.body.message, 4)
        }
    }

    returnNameFileUpload(url: any){
        const string = url;
        const underscoreIndex = string.indexOf('_');
        let extension: any = string.slice(underscoreIndex + 1);
        return extension
    }


    async add() {

        let {name, unit, shortName, images, note, userManual} = equipmentStore.dataRequest

        let data = {
            name: name,
            unit: unit,
            shortName: shortName,
            images: images,
            userManual: userManual,
            note: note
        }

        if (!name) {
            equipmentStore.errors.name = 'Tên thiết bị không được để trống!'
            return
        } else equipmentStore.errors.name  = ''

        equipmentStore.isLoadingBt = true
        const result = await equipmentService.create(data)
        equipmentStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thêm thiết bị thành công', 1)
            $('#close_add_edit_equipment').trigger('click')
            equipmentStore.clearForm()
            await equipmentStore.getEquipments()
        } else {
            toastUtil.error(result.body.message, 3);
        }

    }

    async update() {
        let {id, name, unit, shortName, images, note, userManual} = equipmentStore.dataRequest

        let data = {
            name: name,
            unit: unit,
            shortName: shortName,
            images: images,
            userManual: userManual,
            note: note
        }

        if (!name) {
            equipmentStore.errors.name = 'Tên thiết bị không được để trống!'
            return
        } else equipmentStore.errors.name  = ''

        equipmentStore.isLoadingBt = true
        const result = await equipmentService.update(id, data)
        equipmentStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật thiết thành công', 2)
            $('#close_add_edit_equipment').trigger('click')
            equipmentStore.clearForm()
            await equipmentStore.getEquipments()
        } else {
            toastUtil.error('Cập nhật thiết bị thất bại', 5)
        }
    }

    async delete() {
        equipmentStore.isLoadingBt = true
        const result = await equipmentService.delete(equipmentStore.id)
        equipmentStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa thiết bị thành công', 3)

            await equipmentStore.getEquipments()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }


}

export const equipmentStore = new EquipmentStore()