import React from 'react';
import {setupStore, TypeActive} from "../SetupStore";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {checkPermission} from "../../../common/utils/Utils";
import {PermissionConst} from "../../../common/constants/Constants";
import {useTranslation} from "react-i18next";

const TabHeader = observer(() => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const changTab = (type: any) => {
        setupStore.tabActive = type;
        navigate({pathname: '/setup', search: `?tab=${type}`,});
    }

    return (
        <div className="tab_header d-flex align-items-center">
            {checkPermission(PermissionConst.laborView) && <div className={`tab ${setupStore.tabActive == TypeActive.labor ? 'active' : ''}`}
                 onClick={() => changTab(TypeActive.labor)}>
                <img src={`assets/ico/setup/ico_labor${setupStore.tabActive == TypeActive.labor ? '_active' : ''}.svg`} alt=""/>
                <span>{t('labor')}</span>
            </div>}
            {checkPermission(PermissionConst.productView) && <div className={`tab ${setupStore.tabActive == TypeActive.product ? 'active' : ''}`}
                 onClick={() => changTab(TypeActive.product)}>
                <img src={`assets/ico/setup/ico_product${setupStore.tabActive == TypeActive.product ? '_active' : ''}.svg`} alt=""/>
                <span>{t('product')}</span>
            </div>}
            {checkPermission(PermissionConst.landView) && <div className={`tab ${setupStore.tabActive == TypeActive.land ? 'active' : ''}`}
                 onClick={() => changTab(TypeActive.land)}>
                <img src={`assets/ico/setup/ico_land${setupStore.tabActive == TypeActive.land ? '_active' : ''}.svg`} alt=""/>
                <span>{t('land')}</span>
            </div>}

            {checkPermission(PermissionConst.medicineView) &&  <div className={`tab ${setupStore.tabActive == TypeActive.medicine ? 'active' : ''}`}
                 onClick={() => changTab(TypeActive.medicine)}>
                <img src={`assets/ico/setup/ico_medicine${setupStore.tabActive == TypeActive.medicine ? '_active' : ''}.svg`} alt=""/>
                <span>{t('medicine')}</span>
            </div>}

            {checkPermission(PermissionConst.fertilizerView) && <div className={`tab ${setupStore.tabActive == TypeActive.fertilizer ? 'active' : ''}`}
                 onClick={() => changTab(TypeActive.fertilizer)}>
                <img src={`assets/ico/setup/ico_fertilizer${setupStore.tabActive == TypeActive.fertilizer ? '_active' : ''}.svg`} alt=""/>
                <span>{t('fertilizer')}</span>
            </div>}

            {checkPermission(PermissionConst.ingredientView) && <div className={`tab ${setupStore.tabActive == TypeActive.ingredient ? 'active' : ''}`}
                 onClick={() => changTab(TypeActive.ingredient)}>
                <img src={`assets/ico/setup/ico_ingredient${setupStore.tabActive == TypeActive.ingredient ? '_active' : ''}.svg`} alt=""/>
                <span>{t('ingredient')}</span>
            </div>}
            {checkPermission(PermissionConst.equipmentView) && <div className={`tab ${setupStore.tabActive == TypeActive.equipment ? 'active' : ''}`}
                 onClick={() => changTab(TypeActive.equipment)}>
                <img src={`assets/ico/setup/ico_equipment${setupStore.tabActive == TypeActive.equipment ? '_active' : ''}.svg`} alt=""/>
                <span>{t('equipment')}</span>
            </div>}
            {checkPermission(PermissionConst.distributorView) && <div className={`tab ${setupStore.tabActive == TypeActive.distributor ? 'active' : ''}`}
                                                                          onClick={() => changTab(TypeActive.distributor)}>
                <img src={`assets/ico/setup/ico_material${setupStore.tabActive == TypeActive.distributor ? '_active' : ''}.svg`} alt=""/>
                <span>{t('distributors')}</span>
            </div>}
        </div>
    );

})

export default TabHeader;