import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {blogStore} from "./BlogStore";
import Loading from "../../common/component/Loading";
import {checkPermission, slug, sttPage, urlImage} from "../../common/utils/Utils";
import {Link} from "react-router-dom";
import NoContent from "../../common/component/NoContent";
import Paginate from "../../common/component/Paginate";
import PopupConfirm from "../../common/component/PopupConfirm";
import {dateUtils} from "../../common/utils/DateUtils";
import ButtonAdd from "../../common/component/ButtonAdd";
import {PermissionConst} from "../../common/constants/Constants";

const BlogPage = () => {

    const changePage = async (data: any) => {
        blogStore.metadata.page = data.selected;
        await blogStore.getBlog();
    }

    useEffect(() => {
        blogStore.getBlog().then()
    }, [])

    return (
        <div className="blog_page">
            <div className="d-flex align-items-center m-3 justify-content-between">
                <h5 className="m-0">Quản lý bài viết</h5>
                {checkPermission(PermissionConst.farmBlogAdd) && <Link to={'/add-blog'}>
                    <ButtonAdd text="Thêm bài viết" dataTarget={'add_edit_blog'} onClick={blogStore.clearForm}/>
                </Link>}
            </div>
            {blogStore.isLoading ? <Loading/> :
                <div className='table-responsive'>
                    {blogStore.listBlog && blogStore.listBlog.length > 0 ?
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th >Tên bài viết</th>
                                <th className="text-center">Ngày đăng</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>

                            {blogStore.listBlog.map((item, i) => {

                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(blogStore.metadata.page, i)}</td>
                                        <td>
                                            {item.thumbnail && <img className="mr-2" width={60} src={urlImage(item.thumbnail)} alt=""/>}
                                            {item.title}
                                        </td>
                                        <td className="text-center">{dateUtils.formatDate(item.createdAt)}</td>
                                        <td width="20%" className="text-center">
                                            <div className="action">
                                                {checkPermission(PermissionConst.farmBlogUpdate) && <button className="bt_action" title={"Sửa"}
                                                        data-toggle="modal" data-target="#add_edit_blog"
                                                        onClick={() => blogStore.getDetailBlog(item.id)}>
                                                    <Link to={`/update-blog/${slug(item.name)}/${item.id}`}>
                                                        <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                    </Link>
                                                </button>}
                                                {checkPermission(PermissionConst.farmBlogDelete) && <button className="bt_action" title="Xóa"
                                                        onClick={() => blogStore.id = item.id}
                                                        data-toggle="modal" data-target="#popup_confirm">
                                                    <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                                </button>}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}

                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Paginate currentPage={blogStore.metadata.page} totalPage={blogStore.metadata.totalPage} changePage={changePage}/>
                    </div>
                    <PopupConfirm isLoading={blogStore.isLoadingBt} action={blogStore.delete}/>
                </div>}
        </div>
    );

}

export default observer(BlogPage);