import React from 'react'
import {urlImage} from "../../../common/utils/Utils";
import {observer} from "mobx-react";
import QRCode from "qrcode.react";
import {trackingSeafoodStore} from "../../trackingSeaFood/TrackingSeafoodStore";
import {trackingSeafoodDetailStore} from "../../trackingSeaFood/trackingDetail/TrackingSeafoodDetailStore";

const Content1 = () => {

    let {trackingImage, productInformation, unitInformation} = trackingSeafoodStore.dataRequest;

    let {info} = trackingSeafoodDetailStore.dataRequest;

    return (
        <div>
            <h2>Thông tin sản phẩm</h2>
            <div className="d-flex info_">
                <div className="info_unit w-50 pr-4">
                    <div className="info_text">
                        <label>Tên sản phẩm</label>
                        <span>{productInformation?.name}</span>
                    </div>
                    <div className="info_text">
                        <label>Diện tích</label>
                        <span>{productInformation.area}</span>
                    </div>
                    <div className="info_text">
                        <label>Giống</label>
                        <span>{productInformation.productType}</span>
                    </div>
                    <div className="info_text">
                        <label>Mã số lô</label>
                        <span>{productInformation.productionBatch}</span>
                    </div>
                </div>
                {trackingImage && <div className="w-50 trackingImage pl-4">
                    <img src={urlImage(trackingImage)} alt=""/>
                </div>}
            </div>
            <div className="d-flex d-lg-none align-items-center justify-content-center mb-5">
                <QRCode id="download_qrCode" level="L" value={window.location.href} size={150}/>
            </div>
            <h2>Thông tin đơn vị</h2>
            <div className="d-flex info_">
                <div className="info_unit w-50 pr-4">
                    <div className="info_text">
                        <label>Tên đại diện</label>
                        <span>{unitInformation.representativeName}</span>
                    </div>
                    <div className="info_text">
                        <label>Số điện thoại</label>
                        <span>{unitInformation.phone}</span>
                    </div>
                    <div className="info_text">
                        <label>Email</label>
                        <span>{unitInformation.email}</span>
                    </div>
                    <div className="info_text">
                        <label>Địa chỉ</label>
                        <span>{unitInformation.address}</span>
                    </div>
                    <div className="info_text">
                        <label>Website</label>
                        <span>{unitInformation.website}</span>
                    </div>
                </div>
                <div className="info_unit w-50 pl-4">
                    <div className="info_text">
                        <label>Loại hình</label>
                        <span>{unitInformation.businessDescription}</span>
                    </div>
                    <div className="info_text">
                        <label>Sản phẩm chính</label>
                        <span>{unitInformation.mainProduct}</span>
                    </div>
                    <div className="info_text">
                        <label>Tổng doanh thu</label>
                        <span>{unitInformation.totalRevenue}</span>
                    </div>
                    <div className="info_text">
                        <label>Thị trường chính</label>
                        <span>{unitInformation.mainMarket}</span>
                    </div>
                    <div className="info_text">
                        <label>Tổng nhân viên</label>
                        <span>{unitInformation.totalLabor}</span>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default observer(Content1)
