import {observable} from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";
import $ from "jquery";
import {trackingService} from "./TrackingService";
import {infoStore} from "../info/InfoStore";


class TrackingStore {
    @observable keyword: string = '';
    @observable date: any = new Date();
    @observable searchYear: any = this.date.getFullYear();
    @observable pageHelper: any = '';
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable isLoadingDetail: boolean = false
    @observable listTracking: any[] = []
    @observable dataFilter: any[] = []
    @observable errors = {
        harvestDate: false,
        userManual: false,
        storageInstructions: false,
        address: false
    }
    @observable dataRequest: any = {
        id: '',
        farmId: '',
        packageInfo: {
            harvestDate: '',
            userManual: '',
            storageInstructions: '',
            address: '',
            more: []
        },
        more: [
            {
                key: 'Màu sản phẩm',
                value: '',
                isError: false
            },
            {
                key: 'Mùi vị',
                value: '',
                isError: false
            },
            {
                key: 'Tiêu chuẩn chất lượng',
                value: '',
                isError: false
            },
        ],
        batchCode: '',
        trackingImage: '',
        images: [],
        barcode: '',
        plan: {farmId: "", name: ''},
        note: ''
    }

    clearForm() {
        trackingStore.dataRequest = {
            id: '',
            farmId: '',
            trackingImage: '',
            images: [],
            batchCode: "",
            barcode: '',
            packageInfo: {
                harvestDate: "",
                expiry: "",
                userManual: "",
                storageInstructions: "",
                address: "",
                more: []
            },
            plan: {
                farmId: "",
                name: ''
            },
            more: [
                {
                    key: 'Màu sản phẩm',
                    value: '',
                    isError: false
                },
                {
                    key: 'Mùi vị',
                    value: '',
                    isError: false
                },
                {
                    key: 'Tiêu chuẩn chất lượng',
                    value: '',
                    isError: false
                },
            ],
            note: ''
        }
    }

    clearErrors() {
        trackingStore.errors = {
            harvestDate: false,
            userManual: false,
            storageInstructions: false,
            address: false
        }
    }

    async getTracking() {
        trackingStore.isLoading = true
        const result = await trackingService.getTracking()
        trackingStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            trackingStore.listTracking = result.body.data
            trackingStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: any, isInfoTracking?: boolean) {
        trackingStore.clearErrors()
        trackingStore.dataRequest.barcode = ''
        trackingStore.isLoadingDetail = true
        const result = await trackingService.getDetail(id)
        trackingStore.isLoadingDetail = false
        if (result.status === HttpStatusCode.OK) {
            if(isInfoTracking){
                await infoStore.getInfo(result.body.farmId)
            }
            trackingStore.dataRequest = result.body;
            if(!trackingStore.dataRequest.packageInfo){
                trackingStore.dataRequest.packageInfo = {
                    harvestDate: '',
                        expiry: '',
                        userManual: '',
                        storageInstructions: '',
                        address: '',
                        more: []
                }
            }
            if (!trackingStore.dataRequest.more) {
                trackingStore.dataRequest.more = [
                    {
                        key: 'Màu sản phẩm',
                        value: ''
                    },
                    {
                        key: 'Mùi vị',
                        value: ''
                    },
                    {
                        key: 'Tiêu chuẩn chất lượng',
                        value: ''
                    },
                ]
            }
            if (!trackingStore.dataRequest.images) {
                trackingStore.dataRequest.images = []
            }
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async updateTracking(isClose?: boolean) {
        trackingStore.clearErrors()
        let isError = false
        let {id, more, packageInfo, barcode, note, trackingImage, images} = trackingStore.dataRequest

        let data = {
            barcode: barcode,
            packageInfo: packageInfo,
            more: more ?? [],
            trackingImage: trackingImage ?? '',
            images: images ?? [],
            note: note ?? ''
        }

        if(!packageInfo.harvestDate) {
            trackingStore.errors.harvestDate = true
        }
        if(!packageInfo.userManual) {
            trackingStore.errors.userManual = true
        }

        if(!packageInfo.storageInstructions) {
            trackingStore.errors.storageInstructions = true
        }

        if (!packageInfo.address){
            trackingStore.errors.address = true
        }

        more && more.map((item: any, i: number) => {
            if (i + 1 <= 3) {
                if (!item.value) {
                    isError = true
                    item.isError = true
                }
            }
        })

        if (trackingStore.errors.harvestDate || trackingStore.errors.userManual || trackingStore.errors.storageInstructions || trackingStore.errors.address) {
            toastUtil.warning('Vui lòng nhập đầy đủ thông tin', 2)
            return
        }
        if (isError) {
            toastUtil.warning('Vui lòng nhập đầy đủ thông tin', 2)
            return
        }
        trackingStore.isLoadingBt = true
        const result = await trackingService.update(id, data)
        trackingStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật truy xuất thành công!', 1)
            if(!isClose){
                $('#close_qr_code').trigger('click')
            }
        } else {
            if (typeof result.body.message === "object") {
                this.errors = result.body.message
            } else {
                toastUtil.error(result.body.message, 2)
            }
        }
    }

    async uploadImages() {
        let {id, more, packageInfo, note, trackingImage, images} = trackingStore.dataRequest
        let data = {
            packageInfo: packageInfo ?? [],
            more: more ?? [],
            trackingImage: trackingImage ?? '',
            images: images ?? [],
            note: note ?? ''
        }

        trackingStore.isLoadingBt = true
        const result = await trackingService.update(id, data)
        trackingStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật hình ảnh thành công!', 1)
            $('#close_upload_images').trigger('click');
        } else {
            toastUtil.error('Cập nhật hình ảnh thất bại', 3)
        }
    }

}

export const trackingStore = new TrackingStore()