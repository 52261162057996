import React, {useEffect} from 'react';
import './SetupStyle.scss'
import TabHeader from "./components/TabHeader";
import {setupStore, TypeActive} from "./SetupStore";
import LaborComponent from "./labor/LaborComponent";
import ProductComponent from "./product/ProductComponent";
import LandComponent from "./land/LandComponent";
import {observer} from "mobx-react";
import {checkPermission, getUrlParameter} from "../../common/utils/Utils";
import FertilizerComponent from './fertilizer/FertilizerComponent';
import MedicineComponent from './medicine/MedicineComponent';
import IngredientComponent from "./ingredient/IngredientComponent";
import EquipmentComponent from "./equipment/EquipmentComponent";
import {PermissionConst} from "../../common/constants/Constants";
import DistributorPage from "./distributor/DistributorPage";
import {productStore} from "./product/ProductStore";
import {medicineStore} from "./medicine/MedicineStore";
import {fertilizerStore} from "./fertilizer/FertilizerStore";
import {ingredientStore} from "./ingredient/IngredientStore";
import {equipmentStore} from "./equipment/EquipmentStore";


const SetupComponent = observer(() => {

    const switchData = () => {
        switch (setupStore.tabActive) {
            case TypeActive.labor:
                return checkPermission(PermissionConst.laborView) && <LaborComponent/>
            case TypeActive.product:
                return checkPermission(PermissionConst.productView) && <ProductComponent/>
            case TypeActive.land:
                return checkPermission(PermissionConst.landView) && <LandComponent/>
            case TypeActive.fertilizer:
                return checkPermission(PermissionConst.fertilizerView) && <FertilizerComponent/>
            case TypeActive.medicine:
                return checkPermission(PermissionConst.medicineView) && <MedicineComponent/>
            case TypeActive.ingredient:
                return checkPermission(PermissionConst.ingredientView) && <IngredientComponent/>
            case TypeActive.equipment:
                return checkPermission(PermissionConst.equipmentView) && <EquipmentComponent/>
            case TypeActive.distributor:
                return checkPermission(PermissionConst.distributorView) && <DistributorPage/>
        }
    }


    useEffect(() => {
        setupStore.tabActive = getUrlParameter('tab') ?? TypeActive.labor
        if (!checkPermission(PermissionConst.laborView)) {
            if (checkPermission(PermissionConst.productView)) {
                setupStore.tabActive = TypeActive.product
                return
            }
            if (checkPermission(PermissionConst.landView)) {
                setupStore.tabActive = TypeActive.land
                return
            }

            if (checkPermission(PermissionConst.fertilizerView)) {
                setupStore.tabActive = TypeActive.fertilizer
                return
            }

            if (checkPermission(PermissionConst.medicineView)) {
                setupStore.tabActive = TypeActive.medicine
                return
            }

            if (checkPermission(PermissionConst.ingredientView)) {
                setupStore.tabActive = TypeActive.ingredient
                return
            }

            if (checkPermission(PermissionConst.equipmentView)) {
                setupStore.tabActive = TypeActive.equipment
                return
            }

            if (checkPermission(PermissionConst.distributorView)) {
                setupStore.tabActive = TypeActive.distributor
                return
            }
        }
        return () => {
            productStore.page = 0
            medicineStore.page = 0
            fertilizerStore.page = 0
            ingredientStore.page = 0
            equipmentStore.page = 0
        }
    }, [])

    return (
        <div className="setup_content">
            <TabHeader/>
            {switchData()}
        </div>
    );
})

export default SetupComponent;