import React from 'react'
import {infoStore} from "../../info/InfoStore";
import { urlImage} from "../../../common/utils/Utils";
import {observer} from "mobx-react";
import QRCode from "qrcode.react";
import {trackingStore} from "../../tracking/TrackingStore";
import {profileStore} from "../../profile/ProfileStore";
import {trackingDetailStore} from "../../tracking/trackingDetail/TrackingDetailStore";
import Input from "../../../common/component/form/Input";

const Content1 = () => {

    let {plan, trackingImage, batchCode} = trackingStore.dataRequest;
    let { state } = trackingDetailStore.dataRequest;

   const returnState = () => {
       switch (state) {
           case "USING":
               return "ĐANG SỬ DỤNG"
           case "EVICTION":
               return "SẢN PHẨM BỊ THU HỒI"
           case "EXPIRED":
               return "SẢN PHẨM ĐÃ HẾT HẠN"
           default :
               return "ĐANG SỬ DỤNG"
       }
   }

    return (
        <div>
            <h2>Thông tin sản phẩm</h2>
            <div className="d-flex info_">
                <div className="info_unit w-50 pr-4">
                    <div className="info_text">
                        <label>Tên sản phẩm</label>
                        <span>{plan?.procedure?.product?.name}</span>
                    </div>
                    <div className="info_text">
                        <label>Diện tích sản xuất</label>
                        <span>{plan?.totalArea} ha</span>
                    </div>
                    <div className="info_text">
                        <label>Giống</label>
                        <span>{plan?.procedure?.product?.treeLike?.name}</span>
                    </div>
                    {profileStore.path == 'truy-xuat' && <div className="info_text">
                        <label>Mã số sản phẩm</label>
                        <span>{plan?.procedure?.product?.shortName}</span>
                    </div>}
                    <div className="info_text">
                        <label>Mã số lô</label>
                        <span>{batchCode}</span>
                    </div>
                    <div className="info_text">
                        <label>Mã số vùng trồng</label>
                        <span>{infoStore.dataInfo.plantingAreaCode}</span>
                    </div>
                </div>
                {trackingImage && <div className="w-50-lg w-100-sm d-flex flex-wrap justify-content-end">
                    <div className="trackingImage mb-sm-2">
                        <img src={urlImage(trackingImage)} alt=""/>
                    </div>
                    {/*<div className={`d-flex ${state ?? "USING"} justify-content-center mt-2`}>*/}
                    {/*    {returnState()}*/}
                    {/*</div>*/}
                </div>}
            </div>
            <div className="qr_code_mb align-items-center justify-content-center mb-5">
                <QRCode id="download_qrCode" level="L" value={window.location.href} size={150}/>
            </div>

            <h2>Thông tin đơn vị</h2>
            <div className="d-flex info_">
                <div className="info_unit w-50 pr-4">
                    <div className="info_text">
                        <label>Tên đơn vị</label>
                        <span>{infoStore.dataInfo.name}</span>
                    </div>
                    <div className="info_text">
                        <label>Tên đại diện</label>
                        <span>{infoStore.dataInfo.representativeName}</span>
                    </div>
                    <div className="info_text">
                        <label>Số điện thoại</label>
                        <span>{infoStore.dataInfo.phone}</span>
                    </div>
                    <div className="info_text">
                        <label>Email</label>
                        <span>{infoStore.dataInfo.email}</span>
                    </div>
                    <div className="info_text">
                        <label>Địa chỉ</label>
                        <span>{infoStore.dataInfo.address}</span>
                    </div>
                    <div className="info_text">
                        <label>Website</label>
                        <span>{infoStore.dataInfo.website}</span>
                    </div>
                    <div className="info_text">
                        <label>Chứng chỉ</label>
                        <span>{infoStore.dataInfo.certificate}</span>
                    </div>
                    <div className="info_text">
                        <label>Năm thành lập</label>
                        <span>{infoStore.dataInfo.foundedYear}</span>
                    </div>

                </div>
                <div className="info_unit w-50 pl-4">
                    <div className="info_text">
                        <label>Loại hình</label>
                        <span>{infoStore.dataInfo.businessDescription}</span>
                    </div>
                    <div className="info_text">
                        <label>Sản phẩm chính</label>
                        <span>{infoStore.dataInfo.mainProduct}</span>
                    </div>
                    <div className="info_text">
                        <label>Tổng doanh thu</label>
                        <span>{infoStore.dataInfo.totalRevenue}</span>
                    </div>
                    <div className="info_text">
                        <label>Thị trường chính</label>
                        <span>{infoStore.dataInfo.mainMarket}</span>
                    </div>
                    <div className="info_text">
                        <label>Tổng nhân viên</label>
                        <span>{infoStore.dataInfo.totalLabor}</span>
                    </div>
                    <div className="info_text">
                        <label>Số đăng ký kinh doanh</label>
                        <span>{infoStore.dataInfo.businessCode}</span>
                    </div>
                    <div className="info_text">
                        <label>Ký hiệu kinh doanh</label>
                        <span>{infoStore.dataInfo.businessShortName}</span>
                    </div>
                    <div className="info_text">
                        <label>Giấy chứng nhận</label>
                        <span>{infoStore.dataInfo.testimonial}</span>
                    </div>

                </div>
            </div>
        </div>
    )

}

export default observer(Content1)
