import React, { useEffect } from 'react';
import {observer} from "mobx-react";
import {dashboardStore} from "./DashboardStore";
import { number_format } from "../../common/utils/Utils";
import "./DashboardStyle.scss"
import {profileStore} from "../profile/ProfileStore";
import {useTranslation} from "react-i18next";
import TextOverflowEllipsis from '../../common/component/TextOverflowEllipsis';


const Dashboard = observer(() => {

    const { t } = useTranslation();

     useEffect(() => {
         if(profileStore.dataFarm.farmId){
             dashboardStore.getDashboard()
         }
     }, [profileStore.dataFarm.farmId])


     let {countProduct, countIngredient, countMedicine, countFertilizer, countLabor, countLandArea, countEquiment} = dashboardStore.dataDashboard

     return (
         <div className="dashboard d-flex justify-content-center">
             <div className="d-flex">
                 <div className="labors">
                     <div className="title d-flex justify-content-between">
                         <span>{t('labor')}</span>
                         <img src="assets/ico/setup/ico_labor.svg" alt=""/>
                     </div>

                     <div className="d-flex justify-content-center">
                         <div className="count_labor">
                             <div>
                                 <p>{number_format(countLabor)}</p>
                                 <span>{t('labor')}</span>
                             </div>
                         </div>
                     </div>
                 </div>
                 <div className="setups">
                     <div className="item">
                         <img src="assets/ico/setup/ico_product.svg" alt=""/>
                         <div>
                             <p>{t('product')}</p>
                             <span>{number_format(countProduct)}</span>
                         </div>
                     </div>
                     <div className="item">
                         <img src="assets/ico/setup/ico_medicine.svg" alt=""/>
                         <div>
                             <p>{t('medicine')}</p>
                             <span>{number_format(countMedicine)}</span>
                         </div>
                     </div>
                     <div className="item">
                         <img src="assets/ico/setup/ico_equipment.svg" alt=""/>
                         <div>
                             <p>{t('equipment')}</p>
                             <span>{number_format(countEquiment)}</span>
                         </div>
                     </div>
                     <div className="item">
                         <img src="assets/ico/setup/ico_land.svg" alt=""/>
                         <div className='land'>
                             <p>{t('land')}</p>
                             <div className='land-item d-flex align-items-end'>
                                <TextOverflowEllipsis value={number_format(countLandArea)} />
                                 <p className="m-0 d-inline-block mb-2">Ha</p>
                             </div>
                         </div>
                     </div>
                     <div className="item">
                         <img src="assets/ico/setup/ico_fertilizer.svg" alt=""/>
                         <div>
                             <p>{t('fertilizer')}</p>
                             <span>{number_format(countFertilizer)}</span>
                         </div>
                     </div>
                     <div className="item">
                         <img src="assets/ico/setup/ico_ingredient.svg" alt=""/>
                         <div>
                             <p>{t('ingredient')}</p>
                             <span>{number_format(countIngredient)}</span>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     );
})

export default Dashboard;