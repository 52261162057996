import React, { useEffect} from 'react';
import Loading from "../../../common/component/Loading";
import {checkPermission, number_format, sttPage} from "../../../common/utils/Utils";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import PopupConfirm from "../../../common/component/PopupConfirm";
import ButtonAdd from "../../../common/component/ButtonAdd";
import {observer} from "mobx-react";
import {ingredientStore} from "./IngredientStore";
import AddOrEditIngredient from "./components/AddOrEditIngredient";
import {PermissionConst} from "../../../common/constants/Constants";
import ButtonImport from "../../../common/component/ButtonImport";
import {importData} from "../../../common/helpers/RequestHelper";
import Button from "../../../common/component/Button";
import {ingredientService} from "./IngredientService";
import {Icon} from "../../../common/component/Icon";


const IngredientComponent = () => {

    const changePage = async (data: any) => {
        ingredientStore.page = data.selected;
        await ingredientStore.getIngredient();
    }

    useEffect(() => {
        ingredientStore.getIngredient();
    }, [])
    
    if (ingredientStore.isLoading) {
        return <Loading/>
    } else return (
        <div className='table-responsive'>
            {checkPermission(PermissionConst.ingredientAdd) && <div className="bt_add">
                <ButtonImport onChange={(e: any) => importData(e, ingredientStore.importData)} className="mr-2"/>
                <Button class="mr-2 button_add text-white" img={true} disabled={ingredientStore.isLoadingBt} onClick={() => ingredientService.exportData()} content="Xuất"/>
                <a style={{width: 108}} className="btn mr-2 button_add text-white" href="/file/Nguyên vật liệu.xlsx" download><Icon name="download" className="text-white mr-1"/> <strong>Tải mẫu</strong></a>
                <ButtonAdd text="Thêm nguyên vật liệu" dataTarget={'add_or_edit_ingredient'}
                           onClick={ingredientStore.clearForm}/>
            </div>}

            {ingredientStore.listIngredient && ingredientStore.listIngredient.length > 0 ?
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">STT</th>
                        <th>Tên nguyên vật liệu</th>
                        <th className="text-center">Giá thành</th>
                        <th className="text-center">Ghi chú</th>
                        {(checkPermission(PermissionConst.ingredientUpdate) || checkPermission(PermissionConst.ingredientDelete)) &&
                        <th className="text-center">Thao tác</th>}
                    </tr>
                    </thead>
                    <tbody>

                    {ingredientStore.listIngredient.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{sttPage(ingredientStore.page, i)}</td>
                                <td>{item.name}</td>
                                <td className="text-center">{number_format(item.price)} đ</td>
                                <td className="text-center">{item.note}</td>
                                {(checkPermission(PermissionConst.ingredientUpdate) || checkPermission(PermissionConst.ingredientDelete)) &&
                                <td className="text-center" width="20%">
                                    <div className="action">
                                        {checkPermission(PermissionConst.ingredientUpdate) &&
                                        <button className="bt_action" title="Sửa" data-toggle="modal"
                                                data-target="#add_or_edit_ingredient"
                                                onClick={() => ingredientStore.getDetail(item.id)}>
                                            <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                        </button>}
                                        {checkPermission(PermissionConst.ingredientDelete) &&
                                        <button className="bt_action" title="Xóa"
                                                onClick={() => ingredientStore.id = item.id}
                                                data-toggle="modal" data-target="#popup_confirm">
                                            <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                        </button>}
                                    </div>
                                </td>}
                            </tr>
                        )
                    })}
                    </tbody>
                </table> : <NoContent/>}

            <div className="d-flex align-items-center justify-content-center mt-4">
                <Paginate currentPage={ingredientStore.page} totalPage={ingredientStore.totalPage}
                          changePage={changePage}/>
            </div>
            <AddOrEditIngredient/>
            <PopupConfirm isLoading={ingredientStore.isLoadingBt} action={ingredientStore.delete}/>
        </div>
    );
}

export default observer(IngredientComponent);
