import React, {Component} from "react";
import {observer} from "mobx-react";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import vi from "date-fns/locale/vi";

registerLocale("vi", vi);

interface DatePickerSingleProps {
    selected: any,
    minDate?: any,
    maxDate?: any,
    disabled?: boolean,
    onChange: any,
    placeholder?: any,
    showTimeSelect?: any
    isError?: any,
    isErrorText?: any,
    dateformat?:any,
    showMonthYearPicker?: boolean
}


@observer
class DatePickerSingle extends Component<DatePickerSingleProps, any> {

    constructor(props: DatePickerSingleProps) {
        super(props);
    }

    handleChangeTime = (date: Date, event: any) => {
        this.props.onChange(date)
    };

    render() {
        return (
            <>
                <div className={`time_range position-relative ${(this.props.isError && !this.props.selected) ? 'date_error' : ''}`}>
                    <DatePicker locale="vi"
                                selected={this.props.selected ? this.props.selected : ''}
                                minDate={this.props.minDate} maxDate={this.props.maxDate}
                                showTimeSelect={this.props.showTimeSelect}
                                disabled={this.props.disabled}
                                dateFormat={this.props.dateformat ?? "dd/MM/yyyy"} placeholderText={this.props.placeholder ?? 'Chọn ngày'}
                                onChange={this.handleChangeTime}
                                showMonthYearPicker={this.props.showMonthYearPicker ?? false}
                    />
                    <i className="fal fa-calendar"/>
                </div>
                {(this.props.isError && !this.props.selected && !this.props.isErrorText) &&
                <p className="error">{this.props.isError}</p>}
            </>
        )
    }

}

export default DatePickerSingle;
