import React from 'react';
import {observer} from "mobx-react";
import {equipmentStore} from "../EquipmentStore";
import Input from "../../../../common/component/form/Input";
import './AddOrEditEquipment.scss'
import {Icon} from "../../../../common/component/Icon";
import uploadFileHelper from "../../../../common/helpers/UploadFileHelper";


const AddOrEditEquipment = () => {

    const uploadTutorial = (e: any) => {
        equipmentStore.dataRequest.images = [e]
    }


    return (
        <div className="modal fade" id="add_or_edit_equipment" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Thiết lập thiết bị máy móc</h5>
                        <button type="button" className="close" id="close_add_edit_equipment" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                       <div className="d-flex form-group">
                           <div className="w-50 pr-2">
                               <label>Tên thiết bị <span className="text-danger">*</span></label>
                               <Input isError={equipmentStore.errors.name} value={equipmentStore.dataRequest.name}
                                      onChange={(e: any) => equipmentStore.dataRequest.name = e.currentTarget.value}
                                      placeholder="Nhập tên thiết bị"/>
                           </div>
                           <div className="w-50 pl-2">
                               <label>Mã thiết bị</label>
                               <Input value={equipmentStore.dataRequest.shortName}
                                      onChange={(e: any) => equipmentStore.dataRequest.shortName = e.currentTarget.value}
                                      placeholder="Nhập mã thiết bị"/>
                           </div>
                       </div>
                        <div className="form-group">
                            <label>Đơn vị</label>
                            <Input value={equipmentStore.dataRequest.unit}
                                   onChange={(e: any) => equipmentStore.dataRequest.unit = e.currentTarget.value}
                                   placeholder="Nhập đơn vị"/>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="formFileSm" className="form-label">Hướng dẫn sử dụng</label>
                            <div className="image-upload-wrap">
                                {equipmentStore.dataRequest.images && equipmentStore.dataRequest.images[0] ? <div>
                                    <span>{equipmentStore.returnNameFileUpload(equipmentStore.dataRequest.images[0])}</span>
                                    <Icon name={'close'} type={"outlined"} className="ic-close" onClick={() => equipmentStore.dataRequest.images = []} />
                                </div> : <>
                                    <input className="file-upload-input" type='file'
                                           onChange={async (e: any) => await uploadFileHelper(e, uploadTutorial)}/>
                                    <div className="drag-text">
                                        <Icon name="file_upload" className="file-upload-icon" type="outlined"/>
                                        <span className="title-upload-file">Tải ảnh lên hoặc upload files</span>
                                    </div></>}
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Ghi chú</label>
                            <div className="input-group">
                                <input value={equipmentStore.dataRequest.note}
                                       onChange={(e: any) => equipmentStore.dataRequest.note = e.currentTarget.value}
                                       className="form-control form-control-lg" placeholder="Nhập ghi chú"/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => equipmentStore.type == "add" ? equipmentStore.add() : equipmentStore.update()}
                                disabled={equipmentStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(AddOrEditEquipment)