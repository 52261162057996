import {
    getRequest,
    postRequest,
    deleteRequest,
    putRequest,
    IApiResponse,
    exportRequest
} from "../../../common/helpers/RequestHelper";
import {planStore} from "../../planning/PlanStore";
import {landStore} from "./LandStore";

class LandService{

    public getLands(): Promise<IApiResponse> {
        return getRequest(`/v1/land${planStore.dataRequest.provinceId ? `?provinceId=${planStore.dataRequest.provinceId}` : ''}${planStore.dataRequest.districtId ? `&districtId=${planStore.dataRequest.districtId}` : ''}`);
    }

    public detailLand(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/land/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/land`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/land/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/land/${id}`, {id});
    }

    public importData(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/land/import`, data);
    }

    public exportData(): Promise<IApiResponse> {
        landStore.isLoadingBt = true
        const result = exportRequest(`/v1/land/export`, 'Đất cơ sở');
        landStore.isLoadingBt = false
        return result
    }

}

export const landService = new LandService()