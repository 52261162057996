import React, { useRef } from 'react';
import {Icon} from "../../../common/component/Icon";
import {warehouseStore} from "../WarehouseStore";
import NoContent from "../../../common/component/NoContent";
import {dateUtils} from "../../../common/utils/DateUtils";
import {number_format} from "../../../common/utils/Utils";
import {observer} from "mobx-react";
import "../WarehouseStyle.scss"
import { useReactToPrint } from 'react-to-print';


const PopupDetailLog = () => {
    const componentRef: any = useRef();

    const returnName = (item: any) => {
        switch (item.suppliesType) {
            case 'PRODUCT':
                return item.product?.name
            case 'MEDICINE':
                return item.medicine?.name
            case 'EQUIPMENT':
                return item.equipment?.name
            case 'FERTILIZER':
                return item.fertilizer?.name
            case 'INGREDIENT':
                return item.ingredient?.name
        }
    }

    const returnType = (item: any) => {
        switch (item.suppliesType) {
            case 'PRODUCT':
                return "Sản phẩm"
            case 'MEDICINE':
                return "Nông dược"
            case 'EQUIPMENT':
                return 'Thiết bị'
            case 'FERTILIZER':
                return "Phân bón"
            case 'INGREDIENT':
                return "Nguyên vật liệu"
        }
    }

    const returnSuppliesType = (item: any) => {
        switch (item.suppliesType) {
            case 'PRODUCT':
                return "KG"
            case 'MEDICINE':
                return item.medicine?.unit?.code
            case 'EQUIPMENT':
                return item.equipment?.unit
            case 'FERTILIZER':
                return item.fertilizer?.unit?.code
            case 'INGREDIENT':
                return item.ingredient?.unit
        }
    }

    const returnCode = (item: any) => {
        switch (item.suppliesType) {
            case 'PRODUCT':
                return item.product?.shortName
            case 'MEDICINE':
                return item.medicine?.shortName
            case 'EQUIPMENT':
                return item.equipment?.shortName
            case 'FERTILIZER':
                return item.fertilizer?.shortName
            case 'INGREDIENT':
                return item.ingredient?.shortName
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div ref={componentRef} className="modal fade" id="popup_detail_log" role="dialog" aria-hidden="true">
            <div className="modal-dialog mt-5" role="document" style={{maxWidth: 1100}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Biên bản {warehouseStore.dataDetail.inventoryType == 'MINUS' ? 'Xuất' : 'Nhập'} Kho #{warehouseStore.dataDetail.code}</h5>
                        <button type="button" className="close" id="close_detail_log" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="d-flex mb-2 align-items-center">
                            <span>Người lập:</span>
                            <div className="font-weight-medium ml-2">{warehouseStore.dataDetail.user?.name}</div>
                        </div>
                        <div className="mb-2 d-flex align-items-center">
                            <span>Bộ phận:</span>
                            <div className="font-weight-medium ml-2">{warehouseStore.dataDetail.labor ? warehouseStore.dataDetail.labor?.role?.name : 'Chủ đơn vị'}</div>
                        </div>
                        <div className="time d-flex align-items-center">
                            <span>Thời gian:</span>
                            <div className="font-weight-medium ml-2">{dateUtils.formatDate(warehouseStore.dataDetail.createdAt, 'HH:mm DD/MM/YYYY')}</div>
                        </div>
                        <div className={`table-responsive mt-3 mb-3 table_job`}>
                            {warehouseStore.listData && warehouseStore.listData.length > 0 ?
                                <table
                                    className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                                    <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <th>Tên</th>
                                        <th className="text-center">Mã vật tư</th>
                                        <th className="text-center">Loại</th>
                                        <th className="text-center">ĐVT</th>
                                        <th className="text-center">Số lượng</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {warehouseStore.listData.map((item: any, i:number) => {
                                        return (
                                            <tr key={i}>
                                                <td>{returnName(item)}</td>
                                                <td className="text-center">{returnCode(item)}</td>
                                                <td className="text-center">{returnType(item)}</td>
                                                <td className="text-center">{returnSuppliesType(item)}</td>
                                                <td className="text-center">{number_format(item.quantity)}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table> : <NoContent height={220}/>}
                        </div>

                        <div className="form-group">
                            <textarea className="form-control" value={`Ghi chú:` + " " + warehouseStore.dataDetail.note} disabled placeholder="Ghi chú"/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className={`btn send-request`} onClick={handlePrint}>
                            <Icon name="print" className="mr-1"  size={26}/> In
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(PopupDetailLog);