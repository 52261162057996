import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import "../styles/profileStyle.scss"
import {profileStore, TabActive} from "../ProfileStore";
import ChangeAccount from "./ChangeAccount";
import ChangePass from "./ChangePass";


const ProfileComponent = () => {

    useEffect(() => {
        profileStore.link = ''
    }, [])

    return (
        <div className="profile">
            <div className={`tab_active d-flex`}>
                <span className={profileStore.activeTab == TabActive.Account ? 'active' : ''}
                      onClick={() => profileStore.activeTab = TabActive.Account}>Thông tin tài khoản</span>
                <span className={profileStore.activeTab == TabActive.ChangePass ? 'active' : ''}
                      onClick={() => profileStore.activeTab = TabActive.ChangePass}>Đổi mật khẩu</span>
            </div>
            <div className="info_user">
                {profileStore.activeTab == TabActive.Account && <ChangeAccount/>}
                {profileStore.activeTab == TabActive.ChangePass && <ChangePass/>}
            </div>
        </div>
    )
}

export default observer(ProfileComponent);
