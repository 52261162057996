import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {getIDParameter} from "../../../../common/utils/Utils";
import {roleStore} from "../RoleStore";
import Input from "../../../../common/component/form/Input";
import {Link} from "react-router-dom";

const UpdateRole = observer(() => {

    const changePermission = (id: any) => {
        roleStore.listPermission && roleStore.listPermission.map((item: any) => {
            if (item.id == id) {
                item.isActive = !item.isActive
                if (item.isActive) {
                    roleStore.dataRequest.permissions && roleStore.dataRequest.permissions.push(item.code)
                } else {
                    roleStore.dataRequest.permissions && roleStore.dataRequest.permissions.map((value: any, i: number) => {
                        if (value == item.code) {
                            roleStore.dataRequest.permissions.splice(i, 1)
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        roleStore.getListPermission()
        let id: any = getIDParameter(4);
        roleStore.getDetail(id)
    }, [])


    return (
        <div className="update_role pl-5 pr-5 pt-4">
            <Link to={'/cms/permission'} className="back d-inline-block color-green mb-4">
                <i className="far color-green mr-2 fa-arrow-left"/>
                Quay lại
            </Link>
            <div className="title mb-4">
                <h4>Cập nhật phân quyền</h4>
            </div>
           <div className="pl-4 pr-4">
               <div className="form-group mb-4">
                   <p>Chức vụ <span className="text-danger">*</span></p>
                   <Input isError={roleStore.errors.name} value={roleStore.dataRequest.name}
                          onChange={(e: any) => roleStore.dataRequest.name = e.currentTarget.value}
                          placeholder="Nhập chức vụ"/>
               </div>
               <div className="form-group h-auto mb-4">
                   <p>Phân quyền cho chức vụ <span className="text-danger">*</span></p>
                   <div className="d-flex align-items-center flex-wrap">
                       {roleStore.listPermission && roleStore.listPermission.map((item, i) => {
                           let isActive = false
                           roleStore.dataRequest.permissions && roleStore.dataRequest.permissions.map((val: any) => {
                               if (item.code == val) {
                                   isActive = true
                               }
                           })
                           item.isActive = isActive
                           return (
                               <div className="form-check w-25 d-flex align-items-center mb-3 pl-4 pr-4" key={i}>
                                   <input className="form-check-input m-0" type="checkbox" checked={item.isActive}
                                          onClick={() => changePermission(item.id)} id={item.code}/>
                                   <label className="form-check-label mb-0" htmlFor={item.code}>{item.name}</label>
                               </div>
                           )
                       })}
                   </div>
               </div>
               <button className="send-request mt-5" onClick={() => roleStore.update()}
                       disabled={roleStore.isLoadingBt}>Cập nhật
               </button>
           </div>
        </div>
    )
})

export default UpdateRole;