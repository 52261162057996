import React, {useEffect} from 'react'
import {observer} from "mobx-react";
import './DetailFarmInformation.scss'
import {getIDParameter, goBack, number_format, urlImage} from "../../../../../common/utils/Utils";
import {managerInformationStore} from "../../ManagerInformationStore";
import {useTranslation} from "react-i18next";

const DetailFarmInformation = () => {
    const { t } = useTranslation();

    useEffect(() => {
        managerInformationStore.getDetailInformationById(getIDParameter(4)).then()
    }, [])
    return (
        <div className='detail_farm__information'>
            <div onClick={() => goBack()} className="back cursor_pointer">
                <i className="far fa-arrow-left"/>
                <span>{t("back")}</span>
            </div>
            <h2>{t("unitInformation")}</h2>
            <div className="d-flex info_">
                <div className="info_unit w-50 pr-4">
                    <div className="info_text">
                        <label>{t("unitName")}</label>
                        <span>{managerInformationStore.dataFarmById?.name ?? "-"}</span>
                    </div>
                    <div className="info_text">
                        <label>{t("namePresentitive")}</label>
                        <span>{managerInformationStore.dataFarmById?.representativeName ?? "-"}</span>
                    </div>
                    <div className="info_text">
                        <label>{t("phoneNumber")}</label>
                        <span>{managerInformationStore.dataFarmById?.phone ?? "-"}</span>
                    </div>
                    <div className="info_text">
                        <label>Email</label>
                        <span>{managerInformationStore.dataFarmById?.email ?? "-"}</span>
                    </div>
                    <div className="info_text">
                        <label>{t("address")}</label>
                        <span>{managerInformationStore.dataFarmById?.address ?? "-"}</span>
                    </div>
                    <div className="info_text">
                        <label>Website</label>
                        <span>{managerInformationStore.dataFarmById?.website ?? "-"}</span>
                    </div>
                    <div className="info_text">
                        <label>{t("certificate")}</label>
                        <span>{managerInformationStore.dataFarmById?.certificate ?? "-"}</span>
                    </div>
                </div>
                <div className="info_unit w-50 pl-4">
                    <div className="info_text">
                        <label>{t("type")}</label>
                        <span>{managerInformationStore.dataFarmById?.businessDescription ?? "-"}</span>
                    </div>
                    <div className="info_text">
                        <label>{t("mainProduct")}</label>
                        <span>{managerInformationStore.dataFarmById?.mainProduct ?? "-"}</span>
                    </div>
                    <div className="info_text">
                        <label>{t("totalRevenue")}</label>
                        <span>{number_format(managerInformationStore.dataFarmById?.totalRevenue) ?? "-"}</span>
                    </div>
                    <div className="info_text">
                        <label>{t("mainMarketing")}</label>
                        <span>{managerInformationStore.dataFarmById?.mainMarket ?? "-"}</span>
                    </div>
                    <div className="info_text">
                        <label>{t("totalStaff")}</label>
                        <span>{managerInformationStore.dataFarmById?.totalLabor ?? "-"}</span>
                    </div>
                    <div className="info_text">
                        <label>{t("testimonial")}</label>
                        <span>{managerInformationStore.dataFarmById?.testimonial ?? "-"}</span>
                    </div>
                    <div className="info_text">
                        <label>{t("foundedYear")}</label>
                        <span>{managerInformationStore.dataFarmById?.foundedYear ?? "-"}</span>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                {managerInformationStore.dataFarmById?.certificates?.length > 0 && <>
                    <h2>{t("certificate")}</h2>
                    <div className="scroll_certificate">
                        <div className="list_certificate"
                             style={{width: `${(managerInformationStore.dataFarmById?.certificates?.length * 278) - 24}px`}}>
                            {managerInformationStore.dataFarmById?.certificates?.map((item: any, i: number) => {
                                return (
                                    <div className="item cursor_pointer" data-toggle="modal"
                                         data-target="#popupZoom" key={i}>
                                        <img src={urlImage(item)} alt=""/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </>}
            </div>
        </div>
    )

}

export default observer(DetailFarmInformation)
