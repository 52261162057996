import {observable} from "mobx";
import {roleService} from "./RoleService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery"
import {unique_arr} from "../../../common/utils/Utils";


class RoleStore {
    @observable isLoading: boolean = false;
    @observable isLoadingBt: boolean = false;
    @observable dataRequest: any = {
        id: '',
        name: '',
        value: [],
        key: '',
        permissions: []
    }
    @observable errors: any = {
        name: '',
        permission: ''
    }
    @observable listRole: any[] = []
    @observable listPermission: any[] = []

    clearForm(){
        roleStore.dataRequest = {
            id: '',
            name: '',
            value: [],
            key: '',
            permissions: []
        }
    }

    async getRoles() {
        roleStore.isLoading = true
        const result = await roleService.getRoles()
        roleStore.isLoading = false
        if (result.status == HttpStatusCode.OK) {
            roleStore.listRole = result.body.data
        }

    }

    async getRoleDefault() {
        roleStore.isLoading = true
        const result = await roleService.getRoleDefault()
        roleStore.isLoading = false
        if (result.status == HttpStatusCode.OK) {
            roleStore.dataRequest = {
                key: result.body.key,
                value: JSON.parse(result.body.value)
            }

            roleStore.listPermission && roleStore.listPermission.forEach((item) => {
                item.isActive = !!roleStore.dataRequest.value.includes(item.code);
            });

        }

    }

    async getDetail(id: any) {
        const result = await roleService.getDetail(id)
        if (result.status == HttpStatusCode.OK) {
            roleStore.dataRequest = result.body
            roleStore.listPermission && roleStore.listPermission.forEach((item) => {
                item.isActive = !!roleStore.dataRequest.value?.includes(item.code);
            });
        }
    }

    async getListPermission() {
        const result = await roleService.getListPermissions()
        if (result.status == HttpStatusCode.OK) {
            roleStore.listPermission = result.body
        }
    }

    async update() {
        let {id, name, permissions} = roleStore.dataRequest;

        let permissionIds: any = []

        if (roleStore.listPermission && permissions) {
            roleStore.listPermission.forEach((item: any) => {
                if (permissions.includes(item.code)) {
                    permissionIds.push(item.id);
                }
            });
        }

        let data = {
            name: name,
            permissionIds: permissionIds ? unique_arr(permissionIds) : []
        }

        roleStore.isLoadingBt = true
        const result = await roleService.update(id, data)
        roleStore.isLoadingBt = false

        if (result.status == HttpStatusCode.OK) {
            toastUtil.success('Cập nhật quyền thành công', 1)
            window.scroll(0, 0)
        } else {
            toastUtil.warning(result.body.message, 2)
        }
    }

    async updateRoleDefault() {
        let {value} = roleStore.dataRequest;

        let permissionIds: any = []

        if (roleStore.listPermission && value) {
            roleStore.listPermission.forEach((item: any) => {
                if (value.includes(item.code)) {
                    permissionIds.push(item.id);
                }
            });
        }

        let data = {
            permissionIds: permissionIds ? unique_arr(permissionIds) : []
        }

        roleStore.isLoadingBt = true
        const result = await roleService.updateDefault( data)
        roleStore.isLoadingBt = false

        if (result.status == HttpStatusCode.OK) {
            toastUtil.success('Cập nhật quyền mặc định thành công', 1)
            window.scroll(0, 0)
        } else {
            toastUtil.warning(result.body.message, 2)
        }
    }

}

export const roleStore = new RoleStore()