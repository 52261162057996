import {getRequest, postRequest, putRequest, deleteRequest, IApiResponse} from "../../../common/helpers/RequestHelper";
import {distributorStore} from "./DistributorStore";

class DistributorService{

    public getDistributor(size: any): Promise<IApiResponse> {
        return getRequest(`/v1/distributor?page=${distributorStore.page}&size=${size ?? 10}${distributorStore.keyword ? `&keyword=${distributorStore.keyword}` : ''}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/distributor/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/distributor`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/distributor/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/distributor/${id}`, {});
    }
}

export const distributorService = new DistributorService()