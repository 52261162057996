import React, {createRef, PureComponent} from 'react';
import GoogleMap from 'google-map-react';
import MapDrawShapeManager from 'google-maps-draw-shape-lib';
import './MapsStyle.scss';
import {observer} from "mobx-react";
import {checkPermission, getIDParameter} from "../../../../common/utils/Utils";
import {landStore} from "../LandStore";
import {observable} from "mobx";
import {planStore} from "../../../planning/PlanStore";
import Loading from "../../../../common/component/Loading";
import {profileStore} from "../../../profile/ProfileStore";
import Select from "../../../../common/component/form/Select";
import {signUpStore} from "../../../auth/signUp/SignUpStore";
import {Icon} from "../../../../common/component/Icon";
import {PermissionConst} from "../../../../common/constants/Constants";
import MyGreatPlace from "./MyGreatPlace";


@observer
class MapLandDetail extends PureComponent<any, any> {
    @observable isLoading: boolean = false

    static defaultProps = {
        mapBootstrap: {
            id: 'map',
            key: 'AIzaSyCn8eV4OJCGnktvuqI5DmfXqb-g1xn6LVk',
            libraries: ['drawing']
        },

        mapOptions: {
            mapTypeId: 'roadmap',
            minZoom: 5,
            maxZoom: 20,
            mapTypeControl: true,
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'satellite']
            },
            zoomControl: true,
            gestureHandling: 'greedy',
            disableDefaultUI: true,
            scrollwheel: true,
            clickableIcons: false,
            rotateControl: false,
            tilt: 0,
        },
        polygonOptions: {
            clickable: false,
            fillColor: "#303030",
            fillOpacity: 0.2,
            strokeColor: "#FF0000",
            strokeWeight: 2,
            strokeOpacity: 1
        },
        initialPointInnerHtml: `<button class="btn-initial-point" title="Initial Point"></button>`,
        deletePointInnerHtml: `<button class="btn-delete-point" title="Delete">X</button></div>`
    };

    mapDrawShapeManagerRef: any = createRef()

    constructor(props: any) {
        super(props);

        this.state = {
            mapLoaded: false,
            drawingMode: false,
            drawFreeHandMode: false,
            shape: [],
            initDrawnShape: []
        };
    }

    onGoogleApiLoaded = async (map: any, maps: any) => {

        const {polygonOptions, initialPointInnerHtml, deletePointInnerHtml} = this.props;
        const {drawingMode, drawFreeHandMode, initDrawnShape} = this.state;
        var paths: any = []
        landStore.id = getIDParameter()
        if (landStore.id) {
            await landStore.getDetailLand(landStore.id, true)
            landStore.dataRequest.location && landStore.dataRequest.location.coordinates && landStore.dataRequest.location.coordinates.length > 0 && paths.push(landStore.dataRequest.location.coordinates ?? [])
            if (landStore.dataRequest.location && landStore.dataRequest.location.coordinates && landStore.dataRequest.location.coordinates.length < 1) {
                var address: any = `${landStore.dataRequest.province?.name} ${landStore.dataRequest.district?.name} ${landStore.dataRequest.ward?.name}`;
                await landStore.searchLocate(address)
            }
        } else {
            landStore.listLand && landStore.listLand.map((item) => {
                if (item.location) {
                    paths.push(item.location.coordinates ?? [])
                }
            })
        }
        var triangle = new google.maps.Polygon({
            paths: paths,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "rgba(92, 92, 92, 0.8)",
            fillOpacity: 0.25,
        });

        if (landStore.id) {
            this.setInfoLand(landStore.dataRequest, map, landStore.defaultCenter, triangle)
        } else {
            landStore.listLand && landStore.listLand.map((item) => {
                if (item.location && item.location.coordinates) {
                    var points: any = [];
                    item.location.coordinates && item.location.coordinates.map((item: any) => {
                        points.push([item.lat, item.lng])
                    })

                    this.setInfoLand(item, map, landStore.getCenter(points))
                }
            })
        }


        triangle.setMap(map);

        this.mapDrawShapeManagerRef.current = new MapDrawShapeManager(
            map,
            this.onDrawCallback,
            drawingMode,
            drawFreeHandMode,
            polygonOptions,
            initialPointInnerHtml,
            deletePointInnerHtml,
            initDrawnShape,
        );

        this.setState({mapLoaded: true});
    };


    setInfoLand(item: any, map: any, position: any, triangle?: any) {
        const contentString =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<div id="bodyContent">' +
            "<p>Tên : " + item.name + "</p>" +
            "<p>Nông hộ : " + item.farmHousehold + "</p>" +
            "<p>Diện tích : " + item.area + " ha</p>" +
            "</div>" +
            "</div>";

        const infoWindow = new google.maps.InfoWindow({
            content: contentString,
            ariaLabel: item.name
        });

        const marker = new google.maps.Marker({
            position: position,
            map
        });

        marker.addListener("click", () => {
                infoWindow.open({
                    anchor: marker,
                    map,
                })
            }
        );

        triangle && triangle.addListener("click", () => {
            infoWindow.open({
                anchor: marker,
                map,
            })
        });
    }


    onDrawCallback = async (shape: any) => {
        landStore.dataRequest.location.coordinates = shape
        this.setState({shape: [...shape], drawingMode: false});
    }

    setDrawingMode = (drawingMode: any) => {
        this.setState({drawingMode}, () => this.mapDrawShapeManagerRef.current.setDrawingMode(drawingMode));
    }

    setDrawFreeHandMode = (drawFreeHandMode: any) => {
        this.setState({drawFreeHandMode}, () => this.mapDrawShapeManagerRef.current.setDrawFreeHandMode(drawFreeHandMode));
        this.setDrawingMode(!this.state.drawingMode)
    }

    locateMap = () => {
        let dataShape: any = {
            lat: '',
            lng: ''
        }
        navigator.geolocation.getCurrentPosition((position) => {
            dataShape = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }
            landStore.zoom = 14
            landStore.defaultCenter = dataShape
        });
    }


    resetDrawnShape = async () => {
        this.setState({shape: []}, () => this.mapDrawShapeManagerRef.current.resetDrawnShape());
    }

    changeProvince = async (provinceId: any) => {
        landStore.dataProvince.provinceId = provinceId
        signUpStore.listWard = []
        landStore.dataProvince.districtId = ''
        signUpStore.listProvince && signUpStore.listProvince.map((item: any) => {
            if (item.id == provinceId) {
                landStore.dataProvince.ward = `${landStore.dataProvince.ward} ${item.name}`
            }
        })
        await signUpStore.getDistricts(provinceId)
    }

    changeDistrict = async (districtId: any) => {
        landStore.dataProvince.districtId = districtId
        signUpStore.listDistrict && signUpStore.listDistrict.map((item: any) => {
            if (item.id == districtId) {
                landStore.dataProvince.ward = `${landStore.dataProvince.ward} ${item.name}`
            }
        })
        await signUpStore.getWards(districtId)
    }


    async componentWillMount() {
        await planStore.getPlanning()
        await landStore.getLands()
        await signUpStore.getProvinces()
    }


    render() {

        const {mapBootstrap, mapOptions} = this.props;
        const {mapLoaded, drawingMode, shape} = this.state;
        if (landStore.isLoading) {
            return <Loading height={650}/>
        } else {
            return (
                <div className="map_land">
                    <div className="map_container">
                        <GoogleMap
                            bootstrapURLKeys={mapBootstrap}
                            options={mapOptions}
                            center={landStore.defaultCenter}
                            zoom={landStore.zoom}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({map, maps}) => this.onGoogleApiLoaded(map, maps)}
                        >
                            {profileStore.path == "map" && landStore.dataRequest.location && landStore.dataRequest.location.coordinates && landStore.dataRequest.location.coordinates.length > 0 &&
                            <MyGreatPlace lat={landStore.defaultCenter.lat} lng={landStore.defaultCenter.lng}
                                          text={landStore.dataRequest.name}/>}
                        </GoogleMap>
                    </div>
                    {mapLoaded &&
                    <>
                        <div className="search_address">
                            <div className="province">
                                <label>Tỉnh / Thành phố</label>
                                <Select value={landStore.dataProvince.provinceId}
                                        onChange={(e: any) => this.changeProvince(e)}
                                        options={signUpStore.listProvince}
                                        placeholder="Chọn tỉnh thành phố"/>
                            </div>
                            <div className="province">
                                <label>Quận / Huyện</label>
                                <Select value={landStore.dataProvince.districtId}
                                        onChange={(e: any) => this.changeDistrict(e)}
                                        options={signUpStore.listDistrict}
                                        placeholder="Chọn quận huyện"/>
                            </div>
                            <div className="province">
                                <label>Phường / Xã</label>
                                <div className="input-group">
                                    <select className="form-control" onChange={async (e: any) => {
                                        landStore.dataProvince.ward = `${landStore.dataProvince.ward} ${e.currentTarget.value}`;
                                        await landStore.searchLocate(landStore.dataProvince.ward)
                                    }}>
                                        <option value="">Chọn phường xã</option>
                                        {signUpStore.listWard && signUpStore.listWard.map((item, i) => {
                                            return (
                                                <option key={i} value={item.name}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <button className="zoom_full" onClick={() => landStore.isFullScreen = !landStore.isFullScreen}>
                            <img src={`/assets/ico/map/ico_${!landStore.isFullScreen ? 'full_screen' : 'minimize'}.svg`}
                                 alt=""/>
                        </button>

                        <button className="locate" onClick={this.locateMap}>
                            <img src="/assets/ico/map/ico_location.svg" alt=""/>
                        </button>
                        {checkPermission(PermissionConst.landUpdate) && profileStore.path == "map" &&
                        <>
                            <div className="controls-container">
                                <button className="btn-control" disabled={drawingMode}
                                        onClick={() => this.setDrawFreeHandMode(false)}>
                                    <img src="/assets/ico/map/ico_drag.svg" alt=""/> Kéo
                                </button>
                                <button className="btn-control" disabled={drawingMode}
                                        onClick={() => this.setDrawFreeHandMode(true)}>
                                    <img src="/assets/ico/map/ico_draw.svg" alt=""/>Vẽ
                                </button>
                                <button className="btn-control" disabled={(!(shape?.length > 0) || drawingMode)}
                                        onClick={this.resetDrawnShape}><img src="/assets/ico/map/ico_remove.svg"
                                                                            alt=""/> Xóa
                                </button>
                            </div>
                            <button className="bt_save" disabled={(!(shape?.length > 0) || drawingMode)}
                                    onClick={() => landStore.update()}>
                                <img src="/assets/ico/map/ico_save.svg" alt=""/> Lưu
                            </button>
                        </>}


                        <button className="zoom_map zoom_plus" disabled={landStore.zoom == 19}
                                onClick={landStore.zoomPlus}>
                            <Icon type={"outlined"} name="add"/>
                        </button>
                        <button className="zoom_map zoom_minus" disabled={landStore.zoom == 6}
                                onClick={landStore.zoomMinus}>
                            <Icon type={"outlined"} name="remove"/>
                        </button>

                    </>
                    }

                </div>
            );
        }

    }
}

export default MapLandDetail