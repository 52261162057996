import React, {useEffect} from 'react';
import Loading from "../../../common/component/Loading";
import {checkPermission, sttPage} from "../../../common/utils/Utils";
import {PermissionConst} from "../../../common/constants/Constants";
import ButtonAdd from "../../../common/component/ButtonAdd";
import NoContent from "../../../common/component/NoContent";
import PopupConfirm from "../../../common/component/PopupConfirm";
import {distributorStore} from "./DistributorStore";
import {dateUtils} from "../../../common/utils/DateUtils";
import AddEditDistributor from "./components/AddEditDistributor";
import {observer} from "mobx-react";
import Paginate from "../../../common/component/Paginate";



const DistributorPage = () => {

    const changePage = async (data: any) => {
        distributorStore.page = data.selected;
        await distributorStore.getDistributor();
    }

    useEffect(() => {
        distributorStore.getDistributor().then()
    }, [])

    return (
        <div>
            <>
                <div className="bt_add">
                    {checkPermission(PermissionConst.distributorAdd) &&
                    <ButtonAdd text="Thêm NPP" dataTarget={'add_edit_distributor'} onClick={distributorStore.clearForm}/>}
                </div>
            </>
            {distributorStore.isLoading ? <Loading/> : <div className='table-responsive'>
                {distributorStore.listDistributor && distributorStore.listDistributor.length > 0 ?
                    <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th className="text-center">STT</th>
                            <th>Tên nhà phân phối</th>
                            <th className="text-center">Địa chỉ</th>
                            <th className="text-center">Số điện thoại</th>
                            <th className="text-center">Ngày tạo</th>
                            <th className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>

                        {distributorStore.listDistributor.map((item: any, i:number) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">{sttPage(distributorStore.page, i)}</td>
                                    <td>{item.name}</td>
                                    <td>{item.address}</td>
                                    <td className="text-center">{item.phone}</td>
                                    <td className="text-center">{dateUtils.formatDate(item.createdAt)}</td>
                                    <td className="text-center">
                                        <div className="action">
                                            {checkPermission(PermissionConst.distributorUpdate) &&
                                            <button className="bt_action" title={"Sửa"} data-toggle="modal"
                                                    data-target="#add_edit_distributor"
                                                    onClick={() => distributorStore.getDetail(item.id)}>
                                                <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                            </button>}
                                            {checkPermission(PermissionConst.distributorDelete) &&
                                            <button className="bt_action" title={"Xóa"}
                                                    onClick={() => distributorStore.id = item.id}
                                                    data-toggle="modal" data-target="#popup_confirm">
                                                <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                            </button>}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table> : <NoContent/>}
                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Paginate currentPage={distributorStore.page} totalPage={distributorStore.totalPage}
                              changePage={changePage}/>
                </div>
            </div>}
            <AddEditDistributor/>
            <PopupConfirm isLoading={distributorStore.isLoadingBt} action={distributorStore.delete}/>
        </div>
    );
}

export default observer(DistributorPage);