import React, {useEffect} from 'react';
import {getUrlParameter, goBack, sttPage} from "../../common/utils/Utils";
import NoContent from "../../common/component/NoContent";
import Paginate from "../../common/component/Paginate";
import {warehouseStore} from "./WarehouseStore";
import {TypeActive} from "../setup/SetupStore";
import {dateUtils} from "../../common/utils/DateUtils";
import {Icon} from "../../common/component/Icon";
import Loading from "../../common/component/Loading";
import {observer} from "mobx-react";
import PopupDetailLog from "./components/PopupDetailLog";

const ImportExportHistory = () => {

    const changePage = async (data: any) => {
        warehouseStore.metadata.page = data.selected;
        await warehouseStore.getHistoryInventory();
    }

    useEffect(() => {
        warehouseStore.tabSupplies = getUrlParameter('tab') ?? TypeActive.product
        warehouseStore.getHistoryInventory().then()
        return () => {
            warehouseStore.listData = []
        }
    }, [])


    return (
        <div className="import_export_history">
            <div className="d-flex align-items-center back_log">
                <button className="btn btn-md d-flex align-items-center mr-3 justify-content-center" onClick={() => goBack()}> <Icon name="chevron_left"/> Quay lại</button>
                <span>Lịch sử xuất nhập</span>
            </div>
            {warehouseStore.isLoading ? <Loading/> : <div className='table-responsive'>
                {warehouseStore.listHistory && warehouseStore.listHistory.length > 0 ?
                    <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th className="text-center">Mã</th>
                            <th className="text-center">Thời gian</th>
                            <th className="text-center">Xuất/Nhập</th>
                            <th className="text-center">Chịu trách nhiệm</th>
                            <th className="text-center">Bộ phận</th>
                            <th className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {warehouseStore.listHistory.map((item, i) => {

                            return (
                                <tr key={i}>
                                    <td className="text-center">#{item.code}</td>
                                    <td className="text-center">{dateUtils.formatDate(item.createdAt, 'HH:mm DD/MM/YYYY')}</td>
                                    <td className="text-center">{item.inventoryType == "PLUS" ? <span className="text_import">Nhập kho</span> : <span className="text_export">Xuất kho</span>}</td>
                                    <td className="text-center">{item.user?.name}</td>
                                    <td className="text-center">{item.labor ? item.labor?.role?.name : 'Chủ đơn vị'}</td>
                                    <td className="text-center" width="18%">
                                        <div className="action">
                                            <button className="bt_action d-flex align-items-center" data-toggle="modal" data-target="#popup_detail_log" onClick={() => {
                                                warehouseStore.getDetail(item.id)
                                                warehouseStore.getDetailHistoryInventory(item.id)
                                            }}>
                                                <img src="/assets/ico/action/ico_detail.svg" alt=""/>
                                                <span className="d-block ml-2">Chi tiết</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table> : <NoContent/>}
            </div>}
            <div className="d-flex align-items-center justify-content-center mt-4">
                <Paginate currentPage={warehouseStore.metadata.page} totalPage={warehouseStore.metadata.totalPage}
                          changePage={changePage}/>
            </div>
            <PopupDetailLog/>
        </div>
    );

}

export default observer(ImportExportHistory);