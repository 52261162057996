import React from 'react';
import {observer} from "mobx-react";
import {urlImage} from "../../../common/utils/Utils";
import {trackingLivestockStore} from "../TrackingLivestockStore";
import uploadFileHelper from "../../../common/helpers/UploadFileHelper";


const UploadImageLivestock = () => {

    const changeImg = (e: any) => {
        trackingLivestockStore.dataRequest.trackingImage = e
    }

    const uploadImg = (e: any) => {
        trackingLivestockStore.dataRequest.images.push(e)
    }


    return (
        <div className="modal fade" id="upload_images_livestock" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{trackingLivestockStore.dataRequest.productInformation.name}</h5>
                        <button type="button" className="close" id="close_upload_images_livestock" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="images_product">
                            <div className="images">
                                {trackingLivestockStore.dataRequest.trackingImage ?
                                    <img className="img_" src={urlImage(trackingLivestockStore.dataRequest.trackingImage)} alt=""/> :
                                    <div className="no_image">
                                        <img src="/assets/ico/ico_album.svg" alt=""/>
                                    </div>
                                }
                                <div className="upload">
                                    <img src="/assets/ico/ico_camera.svg" alt=""/>
                                    <span>Ảnh sản phẩm</span>
                                    <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                           onChange={async (e: any) => await uploadFileHelper(e, changeImg)}/>
                                </div>
                            </div>
                        </div>

                        <h4>Hình ảnh Nông trường & Sản phẩm</h4>
                        <div className="list_image">
                            <div className="list_">
                                <div className="upload" title="Thêm ảnh nông trường & sản phẩm">
                                    <img src="/assets/ico/ico_cloud_upload.svg" alt=""/>
                                    <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                           onChange={async (e: any) => await uploadFileHelper(e, uploadImg)}/>
                                </div>
                                {trackingLivestockStore.dataRequest.images && trackingLivestockStore.dataRequest.images.map((item: any, i: number) => {
                                    return (
                                        <div className="item" key={i}>
                                            <img className="img" src={urlImage(item)} alt=""/>
                                            <div className="close_item"
                                                 onClick={() => trackingLivestockStore.dataRequest.images.splice(i, 1)}>
                                                <img src="/assets/ico/ico_close.svg" alt=""/>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer pt-0">
                        <button type="button" className="btn send-request"
                                onClick={() => trackingLivestockStore.uploadImages()}
                                disabled={trackingLivestockStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(UploadImageLivestock);