import { observable } from 'mobx'
import $ from 'jquery'
import { approvedPackageService } from "./ApprovedPackageService";
import HttpStatusCode from '../../../common/constants/HttpErrorCode';
import { toastUtil } from "../../../common/utils/ToastUtil";
import { TIMES, TYPE_QR_PACKAGE } from '../../upgradePackage/UpgradePackageConstant';
import { convertPrice } from '../../../common/utils/Utils';

export interface IParams {
    keyword?: string | null,
    code?: string | null,
    startDate?: string | number | null,
    endDate?: string | number | null,
    status?: string | null,
    paymentState?: string | null,
}

export const INIT_PARAMS = {
    keyword: "",
    code: "",
    startDate: "",
    endDate: "",
    status: "",
    paymentState: "",
}

class ApprovedPackageStore {
    @observable id: number | string = ''
    @observable page: number = 0
    @observable status: string | null = null
    @observable paymentState: string | null = null
    @observable code: string = ''
    @observable totalPage: number = 0
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable isOpenFilter: boolean = false
    @observable lengthAction: number = 0
    @observable listApprovedPackage: any[] = []
    @observable listDataRequest: any[] = []
    @observable params: IParams = { ...INIT_PARAMS }
    @observable dataDetail: any = {
        code: '',
        packagePrice: {
            bundle: {
                name: ''
            },
            price: '',
            timeUnit: '',
            validityPeriod: '',
        },
        packageQRCodes: [],
        createdAt: '',
        totalPrice: "",
    }
    @observable listStatus: { id: string, name: string }[] = [
        { id: 'PENDING', name: 'Chờ duyệt' },
        { id: 'APPROVED', name: 'Đã duyệt' },
        { id: 'REJECTED', name: 'Từ chối' },
        { id: 'CANCEL', name: 'Hủy' },
    ]
    @observable listPaymentState: { id: string, name: string }[] = [
        { id: 'PAID', name: 'Đã thanh toán' },
        { id: 'REFUND', name: 'Hoàn tiền' },
        { id: 'UNPAID', name: 'Chưa thanh toán' },
    ]

    checkLengthAction = () => {
        const hasValue = (value: any) => (value || value === 0);

        let { startDate, endDate, status, paymentState, } = this.params
        let count = 0;

        if (hasValue(paymentState)) count++;
        if (hasValue(status)) count++;
        if (hasValue(startDate) && hasValue(endDate)) count++;

        return count;
    }

    async getApprovedPackage() {
        this.isLoading = true;
        const result = await approvedPackageService.getApprovedPackage()
        this.lengthAction = this.checkLengthAction()
        this.isLoading = false;
        if (result.status === HttpStatusCode.OK) {
            this.listApprovedPackage = result.body.data;
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: any) {
        const result = await approvedPackageService.getDetail(id);
        if (result.status === HttpStatusCode.OK) {
            let item = result.body || {};
            this.dataDetail = item;

            let itemBundle = item?.packagePrice ? {
                name: item?.packagePrice?.bundle?.name,
                type: `${item?.packagePrice?.validityPeriod || ""} ${TIMES.find(time => time.type === item?.packagePrice?.timeUnit)?.name || ""}`,
                quantity: 1,
                price: item?.packagePrice?.price,
            } : null;

            let giftQRCodeExportBatch = item?.packagePrice?.bundle?.giftQRCodeExportBatch?.qrCodeLimit > 0 ? {
                ...item?.packagePrice?.bundle?.giftQRCodeExportBatch,
                giftQRCode: "giftQRCode",
                type: item?.packagePrice?.bundle?.giftQRCodeExportBatch?.type === TYPE_QR_PACKAGE.PACKAGE_EXPORT_BATCH ? "qrInBatches" : "qrByProduct",
                quantity: 1,
                packageId: item?.packagePrice?.bundle?.giftQRCodeExportBatch?.id,
            } : null

            let giftQRCodeExportProduct = item?.packagePrice?.bundle?.giftQRCodeExportProduct?.qrCodeLimit > 0 ? {
                ...item?.packagePrice?.bundle?.giftQRCodeExportProduct,
                giftQRCode: "giftQRCode",
                type: item?.packagePrice?.bundle?.giftQRCodeExportProduct?.type === TYPE_QR_PACKAGE.PACKAGE_EXPORT_BATCH ? "qrInBatches" : "qrByProduct",
                quantity: 1,
                packageId: item?.packagePrice?.bundle?.giftQRCodeExportProduct?.id,
            } : null

            let itemPackageQRCodes = item.packageQRCodes.map((qrCode: any) => {
                return {
                    ...qrCode?.packageQRCode,
                    quantity: qrCode?.quantity,
                    type: qrCode?.packageQRCode?.type === TYPE_QR_PACKAGE.PACKAGE_EXPORT_BATCH ? "qrInBatches" : "qrByProduct",
                    price: qrCode?.packageQRCode?.price,
                    name: qrCode?.packageQRCode?.name || `Thêm ${convertPrice(qrCode?.packageQRCode?.qrCodeLimit) || 0} mã QR Code `
                }
            })

            this.listDataRequest = [
                ...(itemBundle ? [itemBundle] : []),
                ...(giftQRCodeExportBatch ? [giftQRCodeExportBatch] : []),
                ...(giftQRCodeExportProduct ? [giftQRCodeExportProduct] : []),
                ...itemPackageQRCodes
            ]
        }
    }

    convertTitle(status: string) {
        switch (status) {
            case "APPROVED":
                return "Duyệt"
            case "REJECTED":
                return "Từ chối duyệt"
            case "CANCEL":
                return "Hủy duyệt"
        }
    }

    async changeStatus(id: number | string, status: string) {

        this.isLoadingBt = true
        const result = await approvedPackageService.changeStatus(id, status)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${this.convertTitle(status)} yêu cầu nâng cấp gói thành công`)
            this.listApprovedPackage.map((item: any) => {
                if (item.id == id) {
                    item.status = status
                    item.approveAt = new Date()
                }
            })
            $('.action_footer .bt_cancel').trigger('click')
        } else toastUtil.error(result.body.message)

    }
}

export const approvedPackageStore = new ApprovedPackageStore();


