import React from 'react';

interface IProps {
    class: string,
    content: any,
    disabled?: boolean
    onClick?: any,
    img?: boolean
}


const Button = (item:IProps) => {

    return (
        <button className={`btn ${item.class ?? "bt-action"} `} onClick={() => item.onClick()} disabled={item.disabled}> {item.img && <img className="mr-1" src="/assets/ico/ico_export.png" alt=""/>} <span>{item.content}</span></button>

    );
}

export default Button;