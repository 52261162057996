import React from 'react';
import {useTranslation} from "react-i18next";

interface IProps {
    message?: any,
    height?: any,
    widthImg?: any
}


const NoContent = ({height, message, widthImg}: IProps) =>{
    const { t } = useTranslation();
    return (
        <div className="d-flex align-items-center justify-content-center border w-100" style={{height: height ?? 450}}>
            <div className="text-center">
                <div className="d-flex align-items-center justify-content-center" style={{marginBottom: 25}}>
                    <img src="/assets/ico/no_content.svg" width={widthImg ?? 115} alt=""/>
                </div>
                <h6 className="w-100 text-center" style={{fontSize: 16, color: "#888888"}}>{message ?? t('noContent')}</h6>
            </div>
        </div>
    )
}

export default NoContent;
