import React from 'react'
import { Route, Routes } from 'react-router-dom'
import BuyPackage from '../upgradePackage/components/BuyPackage'
import BankInformation from '../upgradePackage/components/BankInformation'
import HistoryOrderFarmPage from '../historyOrderFarm/HistoryOrderFarmPage'

function RedirectPackagePrice() {
    return (
        <Routes>
            <Route path="/buy-package" element={<BuyPackage/>}/>
            <Route path="/buy-package/:packageId" element={<BuyPackage/>}/>
            <Route path="/bank-information/:packageOrderId" element={<BankInformation/>}/>
            <Route path="/history-order-farm" element={<HistoryOrderFarmPage/>}/>
        </Routes>
    )
}

export default RedirectPackagePrice