import React from 'react';
import {observer} from "mobx-react";
import "./LoginStyle.scss";
import {Navigate} from "react-router-dom";
import {profileStore} from "../../profile/ProfileStore";
import StorageService from "../../../common/service/StorageService";
import Footer from "./Footer";
import {useTranslation} from "react-i18next";
import ScrollToTop from "../../../common/component/ScrollToTop";


const PrivatePolicy = () => {
    const {t, i18n} = useTranslation();

    const changeLanguage = (lng: any) => {
        i18n.changeLanguage(lng).then();
        localStorage.setItem('language', lng)
    };

    if (StorageService.getToken()) {
        if (profileStore.path == "cms") {
            return <Navigate to={'/cms/users'} replace/>
        } else return <Navigate to={'/dashboard'} replace/>
    } else return (
        <div className="login_main">
            <ScrollToTop />
            <div className='container'>
                <div className="private-policy-container">
                    <div className="d-flex justify-content-end">
                        <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" id="changeLanguage"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img className="mr-1"
                                     src={`/assets/ico/${localStorage.getItem('language') == 'en' ? 'england' : "vietnam"}.png`}
                                     alt=""/>
                                <span
                                    className="font-weight-bold">{localStorage.getItem('language') == 'en' ? 'ENG' : "VIE"}</span>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="changeLanguage">
                                <div className="dropdown-item cursor_pointer font-weight-medium"
                                     onClick={() => changeLanguage('vi')}><img src="/assets/ico/vietnam.png"
                                                                               alt=""/> Vietnam
                                </div>
                                <div className="dropdown-item cursor_pointer font-weight-medium"
                                     onClick={() => changeLanguage('en')}><img src="/assets/ico/england.png"
                                                                               alt=""/> English
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="title w-100 text-center font-weight-bold my-8">
                        Chính sách bảo mật
                    </div>
                    <div className="w-100 overflow-hidden flex flex-col">
                        <div
                            className="mb-8 text-justify content"
                            dangerouslySetInnerHTML={{
                                __html: `<h2 style="text-align: justify;">1. Mục đ&iacute;ch v&agrave; phạm vi thu thập th&ocirc;ng tin</h2>
<p style="text-align: justify;">Việc thu thập dữ liệu chủ yếu tr&ecirc;n <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> bao gồm: email, điện thoại, t&ecirc;n đăng nhập, mật khẩu đăng nhập, địa chỉ kh&aacute;ch h&agrave;ng (th&agrave;nh vi&ecirc;n). Đ&acirc;y l&agrave; c&aacute;c th&ocirc;ng tin m&agrave; <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> cần th&agrave;nh vi&ecirc;n cung cấp bắt buộc khi đăng k&yacute; sử dụng dịch vụ v&agrave; để <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> li&ecirc;n hệ x&aacute;c nhận khi kh&aacute;ch h&agrave;ng đăng k&yacute; sử dụng dịch vụ tr&ecirc;n  OMFARM nhằm đảm bảo quyền lợi cho cho người ti&ecirc;u d&ugrave;ng.</p>
<p style="text-align: justify;">C&aacute;c th&agrave;nh vi&ecirc;n sẽ tự chịu tr&aacute;ch nhiệm về bảo mật v&agrave; lưu giữ mọi hoạt động sử dụng dịch vụ dưới t&ecirc;n đăng k&yacute;, mật khẩu v&agrave; hộp thư điện tử của m&igrave;nh. Ngo&agrave;i ra, th&agrave;nh vi&ecirc;n c&oacute; tr&aacute;ch nhiệm th&ocirc;ng b&aacute;o kịp thời cho Ban quản l&yacute;  <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> về những h&agrave;nh vi sử dụng tr&aacute;i ph&eacute;p, lạm dụng, vi phạm bảo mật, lưu giữ t&ecirc;n đăng k&yacute; v&agrave; mật khẩu của b&ecirc;n thứ ba để c&oacute; biện ph&aacute;p giải quyết ph&ugrave; hợp.</p>
<h2 style="text-align: justify;">2. Phạm vi sử dụng th&ocirc;ng tin</h2>
<p style="text-align: justify;"> <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> sử dụng th&ocirc;ng tin th&agrave;nh vi&ecirc;n cung cấp cho c&aacute;c c&ocirc;ng việc sau:</p>
<ul style="text-align: justify;">
<li>Cung cấp c&aacute;c dịch vụ đến Th&agrave;nh vi&ecirc;n;</li>
<li>Gửi c&aacute;c th&ocirc;ng b&aacute;o về c&aacute;c hoạt động trao đổi th&ocirc;ng tin giữa th&agrave;nh vi&ecirc;n v&agrave; <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span>;</li>
<li>Ngăn ngừa c&aacute;c hoạt động ph&aacute; hủy t&agrave;i khoản người d&ugrave;ng của th&agrave;nh vi&ecirc;n hoặc c&aacute;c hoạt động giả mạo Th&agrave;nh vi&ecirc;n;</li>
<li>Li&ecirc;n lạc v&agrave; giải quyết với th&agrave;nh vi&ecirc;n trong những trường hợp đặc biệt;</li>
<li>Kh&ocirc;ng sử dụng th&ocirc;ng tin c&aacute; nh&acirc;n của th&agrave;nh vi&ecirc;n ngo&agrave;i mục đ&iacute;ch x&aacute;c nhận v&agrave; li&ecirc;n hệ c&oacute; li&ecirc;n quan đến giao dịch tại <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span>;</li>
<li>Trong trường hợp c&oacute; y&ecirc;u cầu của ph&aacute;p luật: Ban quản l&yacute;  <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> c&oacute; tr&aacute;ch nhiệm hợp t&aacute;c cung cấp th&ocirc;ng tin c&aacute; nh&acirc;n th&agrave;nh vi&ecirc;n khi c&oacute; y&ecirc;u cầu từ cơ quan tư ph&aacute;p bao gồm: Viện kiểm s&aacute;t, t&ograve;a &aacute;n, cơ quan c&ocirc;ng an điều tra li&ecirc;n quan đến h&agrave;nh vi vi phạm ph&aacute;p luật n&agrave;o đ&oacute; của kh&aacute;ch h&agrave;ng. Ngo&agrave;i ra, kh&ocirc;ng ai c&oacute; quyền x&acirc;m phạm v&agrave;o th&ocirc;ng tin c&aacute; nh&acirc;n của th&agrave;nh vi&ecirc;n.</li>
</ul>
<h2 style="text-align: justify;">3. Thời gian lưu trữ th&ocirc;ng tin</h2>
<p style="text-align: justify;">Dữ liệu c&aacute; nh&acirc;n của Th&agrave;nh vi&ecirc;n sẽ được lưu trữ cho đến khi c&oacute; y&ecirc;u cầu hủy bỏ hoặc tự th&agrave;nh vi&ecirc;n đăng nhập v&agrave; thực hiện hủy bỏ. C&ograve;n lại trong mọi trường hợp th&ocirc;ng tin c&aacute; nh&acirc;n th&agrave;nh vi&ecirc;n sẽ được bảo mật tr&ecirc;n m&aacute;y chủ của  <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span>.</p>
<h2 style="text-align: justify;">4. Những người hoặc tổ chức c&oacute; thể được tiếp cận với th&ocirc;ng tin c&aacute; nh&acirc;n:</h2>
<p style="text-align: justify;">Đối tượng được tiếp cận với th&ocirc;ng tin c&aacute; nh&acirc;n của kh&aacute;ch h&agrave;ng/ th&agrave;nh vi&ecirc;n thuộc một trong những trường hợp sau:</p>
<ul style="text-align: justify;">
<li>C&ocirc;ng Ty CP Chứng Nhận &amp; Gi&aacute;m Định Quốc Tế ISOCERT</li>
<li>C&aacute;c đối t&aacute;c c&oacute; k&yacute; hợp động thực hiện 1 phần dịch vụ do C&ocirc;ng Ty CP Chứng Nhận &amp; Gi&aacute;m Định Quốc Tế ISOCERT cung cấp. C&aacute;c đối t&aacute;c n&agrave;y sẽ nhận được những th&ocirc;ng tin theo thỏa thuận hợp đồng (c&oacute; thể 1 phần hoặc to&agrave;n bộ th&ocirc;ng tin t&ugrave;y theo điều khoản hợp đồng) để tiến h&agrave;nh hỗ trợ người d&ugrave;ng sử dụng dịch vụ do C&ocirc;ng ty cung cấp.</li>
<li>Cơ quan nh&agrave; nước khi c&oacute; y&ecirc;u cầu C&ocirc;ng ty cung cấp th&ocirc;ng tin người d&ugrave;ng để phục vụ qu&aacute; tr&igrave;nh điều tra.</li>
<li>Người mua v&agrave; người b&aacute;n xảy ra tranh chấp v&agrave; y&ecirc;u cầu C&ocirc;ng ty l&agrave; đơn vị h&ograve;a giải.</li>
</ul>
<h2 style="text-align: justify;">5. Địa chỉ của đơn vị thu thập v&agrave; quản l&yacute; th&ocirc;ng tin c&aacute; nh&acirc;n</h2>
<p style="text-align: justify;">Mọi th&ocirc;ng tin li&ecirc;n quan đến bảo mật th&ocirc;ng tin của kh&aacute;ch h&agrave;ng đều được ch&uacute;ng t&ocirc;i lưu trữ tại:</p>
<p style="text-align: justify;">-&nbsp; &nbsp;C&ocirc;ng Ty CP Chứng Nhận &amp; Gi&aacute;m Định Quốc Tế ISOCERT<br />- &nbsp; Địa chỉ: Số 40, L&ocirc; 12A, KĐT mới Định C&ocirc;ng, phường Định C&ocirc;ng, Ho&agrave;ng Mai, H&agrave; Nội<br />- &nbsp; Email: Kimcuongbalamat@gmail.com | Điện Thoại: 0916679199</p>
<h2 style="text-align: justify;">6. Phương tiện v&agrave; c&ocirc;ng cụ để người d&ugrave;ng tiếp cận v&agrave; chỉnh sửa dữ liệu c&aacute; nh&acirc;n của m&igrave;nh</h2>
<p style="text-align: justify;">Th&agrave;nh vi&ecirc;n c&oacute; quyền tự kiểm tra, cập nhật, điều chỉnh hoặc hủy bỏ th&ocirc;ng tin c&aacute; nh&acirc;n của m&igrave;nh bằng c&aacute;ch đăng nhập v&agrave;o t&agrave;i khoản v&agrave; chỉnh sửa th&ocirc;ng tin c&aacute; nh&acirc;n hoặc y&ecirc;u cầu  <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> thực hiện c&ocirc;ng việc n&agrave;y.&nbsp;<br /> <br /> Khi tiếp nhận những phản hồi n&agrave;y, Ban quản l&yacute;   <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> sẽ x&aacute;c nhận lại th&ocirc;ng tin, phải c&oacute; tr&aacute;ch nhiệm trả lời l&yacute; do v&agrave; hướng dẫn th&agrave;nh vi&ecirc;n kh&ocirc;i phục hoặc x&oacute;a bỏ th&ocirc;ng tin c&aacute; nhận kh&aacute;ch h&agrave;ng.</p>
<h2 style="text-align: justify;">7. Cam kết bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n kh&aacute;ch h&agrave;ng</h2>
<p style="text-align: justify;">Th&ocirc;ng tin c&aacute; nh&acirc;n của th&agrave;nh vi&ecirc;n tr&ecirc;n OMFARM được <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> cam kết bảo mật tuyệt đối theo ch&iacute;nh s&aacute;ch bảo vệ th&ocirc;ng tin c&aacute; nh&acirc;n của <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span>. Việc thu thập v&agrave; sử dụng th&ocirc;ng tin của mỗi th&agrave;nh vi&ecirc;n chỉ được thực hiện khi c&oacute; sự đồng &yacute; của kh&aacute;ch h&agrave;ng đ&oacute; trừ những trường hợp ph&aacute;p luật c&oacute; quy định kh&aacute;c.</p>
<p style="text-align: justify;">Kh&ocirc;ng sử dụng, kh&ocirc;ng chuyển giao, cung cấp hay tiết lộ cho b&ecirc;n thứ 3 n&agrave;o kh&ocirc;ng li&ecirc;n quan đến hoạt động v&agrave; vận h&agrave;nh s&agrave;n về th&ocirc;ng tin c&aacute; nh&acirc;n của th&agrave;nh vi&ecirc;n khi kh&ocirc;ng c&oacute; sự cho ph&eacute;p đồng &yacute; từ th&agrave;nh vi&ecirc;n. Trong trường hợp chuyển giao website, Ban quản l&yacute;  <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> sẽ th&ocirc;ng b&aacute;o đến c&aacute;c th&agrave;nh vi&ecirc;n.</p>
<p style="text-align: justify;">Trong trường hợp m&aacute;y chủ lưu trữ th&ocirc;ng tin bị hacker tấn c&ocirc;ng dẫn đến mất m&aacute;t dữ liệu c&aacute; nh&acirc;n th&agrave;nh vi&ecirc;n,  <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> sẽ c&oacute; tr&aacute;ch nhiệm th&ocirc;ng b&aacute;o vụ việc cho cơ quan chức năng điều tra xử l&yacute; kịp thời v&agrave; th&ocirc;ng b&aacute;o cho th&agrave;nh vi&ecirc;n được biết.</p>
<p style="text-align: justify;">Bảo mật tuyệt đối mọi th&ocirc;ng tin giao dịch trực tuyến của Th&agrave;nh vi&ecirc;n bao gồm th&ocirc;ng tin h&oacute;a đơn kế to&aacute;n chứng từ số h&oacute;a tại khu vực dữ liệu trung t&acirc;m an to&agrave;n cấp 1 của  <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span></p>
<p style="text-align: justify;">Ban quản l&yacute;   <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> y&ecirc;u cầu c&aacute;c c&aacute; nh&acirc;n khi đăng k&yacute;/mua h&agrave;ng l&agrave; th&agrave;nh vi&ecirc;n, phải cung cấp đầy đủ th&ocirc;ng tin c&aacute; nh&acirc;n c&oacute; li&ecirc;n quan như: Họ v&agrave; t&ecirc;n, địa chỉ li&ecirc;n lạc, email, số chứng minh nh&acirc;n d&acirc;n, điện thoại, số t&agrave;i khoản, số thẻ thanh to&aacute;n &hellip;., v&agrave; chịu tr&aacute;ch nhiệm về t&iacute;nh ph&aacute;p l&yacute; của những th&ocirc;ng tin tr&ecirc;n. Ban quản l&yacute;  <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> kh&ocirc;ng chịu tr&aacute;ch nhiệm cũng như kh&ocirc;ng giải quyết mọi khiếu nại c&oacute; li&ecirc;n quan đến quyền lợi của Th&agrave;nh vi&ecirc;n đ&oacute; nếu x&eacute;t thấy tất cả th&ocirc;ng tin c&aacute; nh&acirc;n của th&agrave;nh vi&ecirc;n đ&oacute; cung cấp khi đăng k&yacute; ban đầu l&agrave; kh&ocirc;ng ch&iacute;nh x&aacute;c.</p>
<h2 style="text-align: justify;">8. Cơ chế tiếp nhận v&agrave; giải quyết khiếu nại li&ecirc;n quan đến việc th&ocirc;ng tin c&aacute; nh&acirc;n kh&aacute;ch h&agrave;ng</h2>
<p style="text-align: justify;">Th&agrave;nh vi&ecirc;n c&oacute; quyền gửi khiếu nại về việc lộ th&ocirc;ng tin c&aacute;c nh&acirc;n cho b&ecirc;n thứ 3 đến Ban quản trị của <span style="color: #236fa1;"><a style="color: #236fa1;" href="https://omfarm.com.vn/">OMFARM</a></span> đến địa chỉ sau:<br />- &nbsp; &nbsp;C&ocirc;ng Ty CP Chứng Nhận &amp; Gi&aacute;m Định Quốc Tế ISOCERT<br />- &nbsp; &nbsp;Địa chỉ: Số 40, L&ocirc; 12A, KĐT mới Định C&ocirc;ng, phường Định C&ocirc;ng, Ho&agrave;ng Mai, H&agrave; Nội<br />- &nbsp; &nbsp;Giấy chứng nhận đăng k&yacute; doanh nghiệp số: 0108128816 đăng k&yacute; thay đổi lần thứ 11 do sở kế hoạch v&agrave; đầu tư th&agrave;nh phố H&agrave; Nội cấp ng&agrave;y 16/02/2023.<br />- &nbsp; &nbsp;Người đại điện ph&aacute;p luật: VŨ VĂN THAO<br />- &nbsp; &nbsp;Email: Kimcuongbalamat@gmail.com | Điện Thoại: 0916679199</p>
<p style="text-align: justify;">ISOCERT c&oacute; tr&aacute;ch nhiệm thực hiện c&aacute;c biện ph&aacute;p kỹ thuật, nghiệp vụ để x&aacute;c minh c&aacute;c nội dung được phản &aacute;nh. &nbsp; &nbsp;</p>
<p style="text-align: justify;">Thời gian xứ l&yacute; phản &aacute;nh li&ecirc;n quan đến th&ocirc;ng tin c&aacute; nh&acirc;n kh&aacute;ch h&agrave;ng l&agrave; 15 ng&agrave;y.</p>`
                            }}
                        />
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default observer(PrivatePolicy);