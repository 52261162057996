import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {getIDParameter} from "../../common/utils/Utils";
import "./InfoTracking.scss"
import Loading from "../../common/component/Loading";
import {trackingStore} from "../tracking/TrackingStore";
import Header from "./components/Header";
import Content2 from "./components/Content2";
import Content1 from "./components/Content1";
import {Link} from "react-router-dom";
import {dateUtils} from "../../common/utils/DateUtils";
import {trackingLivestockStore} from "../trachkingLivestock/TrackingLivestockStore";


const PreviewTrackingLivestock = () => {

    useEffect(() => {
        window.scroll(0, 0)
        let idTracking: any = getIDParameter();
        trackingLivestockStore.getDetail(idTracking).then()
    }, [])

    let {details} = trackingLivestockStore.dataRequest;

    return (
        <div className="info_tracking_breed">
            <Header/>
            <div>
                <div className="container">
                    <div className="logo d-flex justify-content-center align-items-center">
                        <Link to={'/'}><img src="/assets/images/logo_login.svg" alt=""/></Link>
                    </div>
                    {trackingStore.isLoadingDetail ? <Loading/> :
                        <>
                            <Content1/>
                            <h2>Thông tin chi tiết</h2>
                            <div className="w-100 info_ d-flex">
                                <div className="info_unit w-50 pr-4">
                                    <div className="info_text">
                                        <label>Ngày xuất chuồng</label>
                                        <span>{dateUtils.formatDate(details?.harvestDate)}</span>
                                    </div>
                                    <div className="info_text">
                                        <label>Tổng khối lượng</label>
                                        <span>{details?.netWeight}</span>
                                    </div>
                                    <div className="info_text">
                                        <label>Hướng dẫn xuất bán</label>
                                        <span>{details?.userManual}</span>
                                    </div>
                                    <div className="info_text">
                                        <label>Hướng dẫn vận chuyển</label>
                                        <span>{details?.storageInstructions}</span>
                                    </div>
                                </div>
                            </div>
                            {details?.description && <>
                                <h2>Mô tả quy trình chăn nuôi</h2>
                                <div className="w-100 info_">
                                    <div dangerouslySetInnerHTML={{__html: details?.description}}/>
                                </div>
                            </>}
                            <Content2/>
                        </>}
                </div>
            </div>
        </div>
    );
}

export default observer(PreviewTrackingLivestock);