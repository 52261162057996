import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import Loading from "../../../common/component/Loading";
import {dateUtils} from "../../../common/utils/DateUtils";
import NoContent from "../../../common/component/NoContent";
import {planStore} from "../../planning/PlanStore";
import FinishPlan from "./components/FinishPlan";
import AddEditJob from "./components/AddEditJob";
import {checkPermission} from "../../../common/utils/Utils";
import {PermissionConst} from "../../../common/constants/Constants";
import {diaryStore} from "../DiaryStore";
import {jobDiaryStore} from "./jobDiaryStore";
import ButtonAdd from "../../../common/component/ButtonAdd";
import PopupConfirm from "../../../common/component/PopupConfirm";
import PopupDetail from "./components/PopupDetail";
import {useTranslation} from "react-i18next";
import {landStore} from "../../setup/land/LandStore";
import TextOverflowEllipsis from '../../../common/component/TextOverflowEllipsis';

const JobDiary = () => {

    const { t } = useTranslation();

    useEffect(() => {
        planStore.searchYear = planStore.date.getFullYear()
        callApi().then()
        return () => {
            planStore.id = ''
            diaryStore.landId = ''
            planStore.listLandPlan = []
            planStore.searchYear = planStore.date.getFullYear()
        }
    }, [])

    const filterStarDate = async (e: any) => {
        planStore.id = '';
        diaryStore.landId = ''
        planStore.searchYear = e.currentTarget.value;
        await callApi()
    }

    const callApi = async () => {
        await planStore.getPlanning(100)
        planStore.id = planStore.listPlanning && planStore.listPlanning.length > 0 && planStore.listPlanning[0].id;
        if (planStore.id) {
            await planStore.getLands(planStore.id)
            if (planStore.listLandPlan && planStore.listLandPlan.length > 0) {
                diaryStore.landId = planStore.listLandPlan[0].id
                await jobDiaryStore.getDiary()
                await jobDiaryStore.getActualOutputLand()
            } else diaryStore.landId = ''
        } else {
            jobDiaryStore.listDiary = []
        }
    }

    return (
        <div className="production_log_diary">
            <div className="d-flex align-items-center justify-content-between filter_header">
                <div className="d-flex align-items-center">
                    <img src="/assets/ico/filter.svg" alt=""/>
                    <div className="d-flex form_search align-items-center">
                        <span>{t('year')}:</span>
                        <div className="filter_select">
                            <select onChange={(e: any) => filterStarDate(e)} value={planStore.searchYear}>
                                <option value="" disabled={true}>{t('choose')}</option>
                                {planStore.listYear && planStore.listYear.map((item, i) => {
                                    return <option value={item.id} key={i}>{item.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="d-flex form_search align-items-center">
                        <span>{t('plan')}:</span>
                        <div className="filter_select">
                            <select onChange={async (e: any) => {
                                planStore.id = e.currentTarget.value;
                                await planStore.getLands(planStore.id)
                                if (planStore.listLandPlan && planStore.listLandPlan.length > 0) {
                                    diaryStore.landId = planStore.listLandPlan[0].id
                                    await jobDiaryStore.getDiary()
                                    await jobDiaryStore.getActualOutputLand()
                                } else diaryStore.landId = ''
                            }}
                                    value={planStore.id}>
                                <option value="" disabled>{t('choose')}</option>
                                {planStore.listPlanning && planStore.listPlanning.map((item, i) => {
                                    return <option value={item.id} key={i}>{item.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="d-flex form_search align-items-center">
                        <span>{t('land')}:</span>
                        <div className="filter_select">
                            <select onChange={async (e: any) => {
                                diaryStore.landId = e.currentTarget.value;
                                await jobDiaryStore.getDiary()
                                await jobDiaryStore.getActualOutputLand()
                            }}
                                    value={diaryStore.landId}>
                                <option value="" disabled>{t('choose')}</option>
                                {planStore.listLandPlan && planStore.listLandPlan.map((item, i) => {
                                    return <option value={item.id} key={i}>{item.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                {diaryStore.landId && <div className="d-flex align-items-center">
                    {checkPermission(PermissionConst.diaryUpdate) && <button className="btn btn-finish-plan"
                                                                             onClick={async () =>  await jobDiaryStore.getActualOutputLand()}
                                                                             data-toggle="modal"
                                                                             data-target="#finishPlan">
                        <img
                            src={`/assets/ico/${planStore.dataRequest.actualOutput ? 'ico_edit' : 'ico_show_arrow'}.svg`}
                            alt=""/>
                        <span>{planStore.dataRequest.actualOutput ? t('editResult') : t('endPlan')}</span>
                    </button>}
                    {checkPermission(PermissionConst.diaryUpdate) &&
                    <ButtonAdd text={t('addJob')} className="ml-2" dataTarget={'add_edit_job'}
                               onClick={jobDiaryStore.clearForm}/>}
                </div>}
            </div>
            {jobDiaryStore.isLoading ? <Loading/> :
                <div className='table-responsive'>
                    {jobDiaryStore.listDiary && jobDiaryStore.listDiary.length > 0 ?
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th>{t('jobName')}</th>
                                <th className="text-center">{t('time')}</th>
                                <th className="text-center">{t('note')}</th>
                                {checkPermission(PermissionConst.diaryUpdate) &&
                                <th className="text-center">{t('actions')}</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {jobDiaryStore.listDiary.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{i + 1}</td>
                                        <td><TextOverflowEllipsis value={item.name} line={2}/></td>
                                        <td className="text-center">{dateUtils.formatDate(item.startDate)} - {dateUtils.formatDate(item.endDate)}</td>
                                        <td className="text-center"><TextOverflowEllipsis value={item.note} line={2}/></td>
                                        {checkPermission(PermissionConst.diaryUpdate) &&
                                        <td className="text-center">
                                            <div className="action">
                                                <button className="bt_action" title={t('view')}
                                                        onClick={() => jobDiaryStore.getDetail(item.id)} data-toggle="modal"
                                                        data-target="#popup_detail_job">
                                                    <img src="/assets/ico/action/ico_detail.svg" alt=""/>
                                                </button>
                                                <button className="bt_action" title={t('edit')}
                                                        onClick={() => jobDiaryStore.getDetail(item.id)}
                                                        data-toggle="modal" data-target="#add_edit_job">
                                                    <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                </button>
                                                <button className="bt_action" title={t('delete')}
                                                        onClick={() => jobDiaryStore.jobId = item.id}
                                                        data-toggle="modal" data-target="#popup_confirm">
                                                    <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                                </button>
                                            </div>
                                        </td>}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                </div>
            }

            <FinishPlan/>
            <AddEditJob/>
            <PopupDetail/>
            <PopupConfirm isLoading={jobDiaryStore.isLoadingBt} action={jobDiaryStore.delete}/>
        </div>
    );
}

export default observer(JobDiary)
