import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {checkPermission, number_format, sttPage} from "../../../common/utils/Utils";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import PopupConfirm from "../../../common/component/PopupConfirm";
import ButtonAdd from "../../../common/component/ButtonAdd";
import {fertilizerStore} from "./FertilizerStore";
import AddOrEditFertilizer from './components/AddOrEditFertilizer';
import Loading from "../../../common/component/Loading";
import {PermissionConst} from "../../../common/constants/Constants";
import {medicineStore} from "../medicine/MedicineStore";
import ButtonImport from "../../../common/component/ButtonImport";
import {importData} from "../../../common/helpers/RequestHelper";
import Button from "../../../common/component/Button";
import {fertilizerService} from "./FertilizerService";
import {Icon} from "../../../common/component/Icon";


const FertilizerComponent = () => {

    const changePage = async (data: any) => {
        fertilizerStore.page = data.selected;
        await fertilizerStore.getFertilizer();
    }

    useEffect(() => {
        fertilizerStore.getFertilizer()
        medicineStore.getUnit()
    }, [])

    if (fertilizerStore.isLoading) {
        return <Loading/>
    } else return (
        <div className='table-responsive'>
            {checkPermission(PermissionConst.fertilizerAdd) && <div className="bt_add">
                <ButtonImport onChange={(e: any) => importData(e, fertilizerStore.importData)} className="mr-2"/>
                <Button class="mr-2 button_add text-white" img={true} disabled={fertilizerStore.isLoadingBt} onClick={() => fertilizerService.exportData()} content="Xuất"/>
                <a style={{width: 108}} className="btn mr-2 button_add text-white" href="/file/Phân bón.xlsx" download><Icon name="download" className="text-white mr-1"/> <strong>Tải mẫu</strong></a>
                <ButtonAdd text="Thêm phân bón" dataTarget={'add_or_edit_fertilizer'}
                           onClick={fertilizerStore.clearForm}/>
            </div>}
            {fertilizerStore.listFertilizer && fertilizerStore.listFertilizer.length > 0 ?
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">STT</th>
                        <th>Tên phân bón</th>
                        <th className="text-center">Thành phần</th>
                        <th className="text-center">Lượng sử dụng/Ha</th>
                        <th className="text-center">Khối lượng/Đơn vị (Đóng gói)</th>
                        <th className="text-center">Giá thành</th>
                        {(checkPermission(PermissionConst.fertilizerUpdate) || checkPermission(PermissionConst.fertilizerDelete)) &&
                        <th className="text-center">Thao tác</th>}
                    </tr>
                    </thead>
                    <tbody>

                    {fertilizerStore.listFertilizer.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{sttPage(fertilizerStore.page, i)}</td>
                                <td>{item.name}</td>
                                <td className="text-center">{item.element && item.element[0] ? `N:${item.element[0]}%;` : 'N:0%;'} {item.element && item.element[1] ? `P:${item.element[1]}%;` : 'P:0%;'} {item.element && item.element[2] ? `K:${item.element[2]}%` : 'K:0%;'}</td>
                                <td className="text-center">{item.useForOneHa} {item.unit?.code}</td>
                                <td className="text-center">{item.capacity} {item.unit?.code}</td>
                                <td className="text-center">{number_format(item.price)} đ</td>
                                {(checkPermission(PermissionConst.fertilizerUpdate) || checkPermission(PermissionConst.fertilizerDelete)) &&
                                <td className="text-center">
                                    <div className="action">
                                        {checkPermission(PermissionConst.fertilizerUpdate) &&
                                        <button className="bt_action" title="Sửa" data-toggle="modal"
                                                data-target="#add_or_edit_fertilizer"
                                                onClick={() => fertilizerStore.getDetail(item.id)}>
                                            <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                        </button>}
                                        {checkPermission(PermissionConst.fertilizerDelete) &&
                                        <button className="bt_action" title="Xóa"
                                                onClick={() => fertilizerStore.id = item.id}
                                                data-toggle="modal" data-target="#popup_confirm">
                                            <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                        </button>}
                                    </div>
                                </td>}
                            </tr>
                        )
                    })}
                    </tbody>
                </table> : <NoContent/>}

            <div className="d-flex align-items-center justify-content-center mt-4">
                <Paginate currentPage={fertilizerStore.page} totalPage={fertilizerStore.totalPage}
                          changePage={changePage}/>
            </div>
            <AddOrEditFertilizer/>
            <PopupConfirm isLoading={fertilizerStore.isLoadingBt} action={fertilizerStore.delete}/>
        </div>
    );
}

export default observer(FertilizerComponent)