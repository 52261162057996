import {postRequest, IApiResponse, getRequest, deleteRequest, putRequest} from "../../common/helpers/RequestHelper";
import {procedureStore} from "./ProcedureStore";
import {productStore} from "../setup/product/ProductStore";


class ProcedureService {
    public getProcedure(size?: any): Promise<IApiResponse> {
        return getRequest(`/v1/procedure?page=${procedureStore.page}&size=${size ?? 10}${procedureStore.searchYear ? `&year=${procedureStore.searchYear}` : ''}${procedureStore.searchProduct ? `&productId=${procedureStore.searchProduct}` : ''}`);
    }

    public getProducts(): Promise<IApiResponse> {
        return getRequest(`/v1/product?page=${productStore.page}&size=500`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/procedure/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/procedure`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/procedure/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/procedure/${id}`, {id});
    }
}

export const procedureService = new ProcedureService();