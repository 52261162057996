import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {accountManagerStore} from "./AccountManagerStore";

class AccountManagerService {
    public getAccountManager(): Promise<IApiResponse> {
        return getRequest(`/v1/agency_manage?page=${accountManagerStore.page}&size=10${accountManagerStore.keyword ? `&keyword=${accountManagerStore.keyword}` : ''}`);
    }

    public getDetailAccountManager(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/agency_manage/${id}`);
    }
    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/agency_manage`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/agency_manage/${id}`, data);
    }
    public register(data: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/auth/register`, data, true);
    }
    public updateSSO(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/sso/v1/user/${id}`, data, true);
    }
    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/agency_manage/${id}`, {id});
    }
}

export const accountManagerService = new AccountManagerService()