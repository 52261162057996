import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {planStore} from "../planning/PlanStore";
import {diaryStore} from "./DiaryStore";

class DiaryService {

    public getJobDiary(): Promise<IApiResponse> {
        return getRequest(`/v1/job/plan/${planStore.id}/land/${diaryStore.landId}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/job/${id}`);
    }

    public finishPlan(planId: number, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/plan/${planId}/land/${diaryStore.landId}`, data);
    }


    public getActualOutputLand(planId: number): Promise<IApiResponse> {
        return getRequest(`/v1/plan/${planId}/land/${diaryStore.landId}`);
    }

    public addJob(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/job`, data);
    }

    public editJob(id: number, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/job/${id}`, data);
    }

    public deleteJob(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/job/${id}`, {id});
    }

    public getSchedule(planId: number): Promise<IApiResponse> {
        return getRequest(`/v1/plan/${planId}/schedule`);
    }

    public getProgress(planId: number): Promise<IApiResponse> {
        return getRequest(`/v1/plan/${planId}/progress`);
    }

}

export const diaryService = new DiaryService()