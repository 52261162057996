import React, { useEffect } from 'react';
import "./App.scss";
import { observer } from "mobx-react";
import StorageService from "./common/service/StorageService";
import 'react-toastify/dist/ReactToastify.css';
import { profileStore } from "./modules/profile/ProfileStore";
import Redirect from "./modules/router/Router";
import Nav from './modules/layouts/Nav';
import $ from 'jquery'
import SideBar from './modules/layouts/SideBar';
import RedirectAuth from "./modules/router/RedirectAuth";
import { checkExpirationTime, getPathName, getUrlParameter, parseJwt } from "./common/utils/Utils";
import { landStore } from "./modules/setup/land/LandStore";
import { noticeStore } from "./modules/notice/NoticeStore";
import SignUpComponent from "./modules/auth/signUp/SignUpComponent";
import { infoStore } from "./modules/info/InfoStore";
import InfoTracking from "./modules/infoTracking/InfoTracking";
import PreviewTracking from "./modules/infoTracking/PreviewTracking";
import InfoTrackingBatchCode from "./modules/infoTracking/InfoTrackingBatchCode";
import { signUpStore } from "./modules/auth/signUp/SignUpStore";
import PreviewTrackingSeafood from "./modules/infoTrackingSeafood/PreviewTrackingSeafood";
import InfoTrackingBatchCodeSeafood from "./modules/infoTrackingSeafood/InfoTrackingBatchCodeSeafood";
import InfoTrackingSeafood from "./modules/infoTrackingSeafood/InfoTrackingSeafood";
import { useNavigate } from "react-router-dom";
import InfoTrackingLivestock from "./modules/infoTrackingLivestock/InfoTrackingLivestock";
import InfoTrackingBatchCodeLivestock from "./modules/infoTrackingLivestock/InfoTrackingBatchCodeLivestock";
import PreviewTrackingLivestock from "./modules/infoTrackingLivestock/PreviewTrackingLivestock";
import NotificationExpired from './modules/upgradePackage/components/NotificationExpired';
import RedirectPackagePrice from './modules/router/RedirectPackagePrice';
import TrialPeriodExpired from './modules/trialPeriodExpired/TrialPeriodExpired';

const App = () => {
    const navigate = useNavigate();
    let path = window.location.pathname.split('/')[1]
    let isCheckEpirationTime = checkExpirationTime();

    const switchPath = () => {
        switch (path) {
            case "truy-xuat":
                return <InfoTracking />
            case "truy-xuat-lo":
                return <InfoTrackingBatchCode />
            case "xem-ket-qua-truy-xuat":
                return <PreviewTracking />
            case "truy-xuat-hai-san":
                return <InfoTrackingSeafood />
            case "truy-xuat-lo-hai-san":
                return <InfoTrackingBatchCodeSeafood />
            case "xem-ket-qua-truy-xuat-hai-san":
                return <PreviewTrackingSeafood />
            case "truy-xuat-chan-nuoi":
                return <InfoTrackingLivestock />
            case "truy-xuat-lo-chan-nuoi":
                return <InfoTrackingBatchCodeLivestock />
            case "xem-ket-qua-truy-xuat-chan-nuoi":
                return <PreviewTrackingLivestock />
            case "het-han-dung-thu":
                return (
                    <div className={`body-wrapper w-100 p-0 ${!landStore.isFullScreen ? 'position-relative' : ''}`}>
                        <Nav />
                        <TrialPeriodExpired />
                    </div>
                )
            default:
                return (((profileStore.dataFarm.farmId || localStorage.getItem('farmId')) && profileStore.dataFarm.status === 'ACTIVE') || (profileStore.roleUser === 'admin' || profileStore.roleUser === 'manager')) ? (
                    <>
                        {isCheckEpirationTime.isShowNotification  && <NotificationExpired data={isCheckEpirationTime} />}
                        {!isCheckEpirationTime?.isExpired
                            ? <div className="d-flex justify-content-between w-100">
                                <SideBar />
                                <div className={`body-wrapper h-100vh ${!landStore.isFullScreen ? 'position-relative' : ''}`}>
                                    <Nav />
                                    <div
                                        className={`
                                            main-content calc-h-vh-100px 
                                            ${landStore.isFullScreen ? 'full_screen' : 'position-relative'}
                                            ${checkExpirationTime().isShowNotification ? "calc-h-vh-148px" : ""}
                                        `}>
                                        <Redirect />
                                    </div>
                                </div>
                            </div>
                            : <div className={`
                                body-wrapper w-100 p-0 ${!landStore.isFullScreen ? 'position-relative' : ''}
                                ${checkExpirationTime().isShowNotification ? "calc-h-vh-48px" : ""}
                            `}>
                                <Nav />
                                <RedirectPackagePrice />
                            </div>}
                    </>
                ) : <div>
                    <SignUpComponent />
                </div>
        }
    }

    const handleBackButton = (event: any) => {
        $('.modal-backdrop.fade.show').detach();
        event.preventDefault();
    };

    const getToken = async (token: any) => {
        await signUpStore.sendToken()
        parseJwt(token)
        navigate({ pathname: window.location.pathname })
    }


    useEffect(() => {
        const token = getUrlParameter('token');
        const pathName = getPathName();
        const language = getUrlParameter('lng');
        if (language) {
            localStorage.setItem('language', language)
        }
        profileStore.path = pathName;
        if (pathName == 'cms') {
            profileStore.roleUser = 'admin';
        }
        if (token) {
            StorageService.setToken(token);
            getToken(token).then()
        }

        if (StorageService.getToken()) {
            parseJwt()
            if (profileStore.path !== "cms" && profileStore.dataFarm.farmId) {
                infoStore.getInfo().then()
                noticeStore.callNotice().then()
            }
        }
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        }

    }, [])

    if (!StorageService.getToken() && !getUrlParameter('token')) {
        return <RedirectAuth />
    } else {
        return switchPath()
    }
}

export default observer(App)



