import Constants from "../../../common/constants/Constants";
import {getRequest, postRequest, deleteRequest, putRequest, exportRequest, IApiResponse} from "../../../common/helpers/RequestHelper";
import {fertilizerStore} from "./FertilizerStore";

class FertilizerService{

    public getFertilizer(size?: any): Promise<IApiResponse> {
        return getRequest(`/v1/fertilizer?page=${fertilizerStore.page}&size=${size ?? 10}`);
    }

    public getFertilizerAll(): Promise<IApiResponse> {
        return getRequest(`/v1/fertilizer?page=0&size=${Constants.MAX_SIZE}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/fertilizer/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/fertilizer`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/fertilizer/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/fertilizer/${id}`, {id});
    }

    public importData(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/fertilizer/import`, data);
    }

    public exportData(): Promise<IApiResponse> {
        fertilizerStore.isLoadingBt = true
        const result = exportRequest(`/v1/fertilizer/export`, 'Phân bón');
        fertilizerStore.isLoadingBt = false
        return result
    }

}

export const fertilizerService = new FertilizerService()