import { getRequest, IApiResponse, patchRequest } from '../../../common/helpers/RequestHelper'
import { dateUtils } from '../../../common/utils/DateUtils';
import { approvedPackageStore } from "./ApprovedPackageStore";


class ApprovedPackageService {

  public getApprovedPackage(): Promise<IApiResponse> {
    return getRequest(`/v1/package_order_transaction?page=${approvedPackageStore.page}&size=10
      ${approvedPackageStore.params?.code?.trim() ? `&code=${approvedPackageStore.params?.code?.trim()}` : ''}
      ${approvedPackageStore.params?.paymentState ? `&paymentState=${approvedPackageStore.params?.paymentState}` : ''}
      ${approvedPackageStore.params?.status ? `&status=${approvedPackageStore.params?.status}` : ''}
      ${approvedPackageStore.params?.startDate ? `&startDate=${dateUtils.formatDate(approvedPackageStore.params?.startDate as number, "YYYY-MM-DD")}` : ''}
      ${approvedPackageStore.params?.endDate ? `&endDate=${dateUtils.formatDate(approvedPackageStore.params?.endDate as number, "YYYY-MM-DD")}` : ''}
    `)
  }

  public getDetail(id: number | string): Promise<IApiResponse> {
    return getRequest(`/v1/package_order_transaction/${id}`)
  }

  public changeStatus(id: number | string, status: string): Promise<IApiResponse> {
    return patchRequest(`/v1/package_order_transaction/${id}/change-status-order`, { status: status, note: '' })
  }

}

export const approvedPackageService = new ApprovedPackageService()

