import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import Loading from "../../common/component/Loading";
import {checkPermission, slug, sttPage} from "../../common/utils/Utils";
import NoContent from "../../common/component/NoContent";
import Paginate from "../../common/component/Paginate";
import {PermissionConst} from "../../common/constants/Constants";
import AddOrUpdateTracking from "./components/AddOrUpdateTracking";
import {Link} from "react-router-dom";
import {trackingSeafoodStore} from './TrackingSeafoodStore';
import ButtonAdd from "../../common/component/ButtonAdd";
import UploadImagesSeafood from "./components/UploadImagesSeafood";
import {useTranslation} from "react-i18next";


const TrackingPage = () => {
    const { t } = useTranslation();
    const changePage = async (data: any) => {
        trackingSeafoodStore.page = data.selected;
        await trackingSeafoodStore.getTracking();
    }

    useEffect(() => {
        trackingSeafoodStore.getTracking()

    }, [])

    return (
        <div className="tracking">
            {checkPermission(PermissionConst.trackingUpdate) && <div className="d-flex mt-3 mb-3 justify-content-end pr-4">
                <ButtonAdd className="send-request bt_action" text={t('addAccess')}
                           dataTarget={'addEditTrackingSeaFood'} onClick={trackingSeafoodStore.clearForm}/>
            </div>}
            {trackingSeafoodStore.isLoading ? <Loading/> :
                <div className='table-responsive'>
                    {trackingSeafoodStore.listTracking && trackingSeafoodStore.listTracking.length > 0 ?
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th>{t('product')}</th>
                                <th className="text-center">{t('image')}</th>
                                <th className="text-center">{t('barcode')}</th>
                                <th className="text-center">{t('actions')}</th>
                            </tr>
                            </thead>
                            <tbody>

                            {trackingSeafoodStore.listTracking && trackingSeafoodStore.listTracking.map((item, i) => {
                                let pageHelper = `${window.location.origin}/truy-xuat-hai-san/${slug(item.data?.productInformation?.name)}/${item.id}`

                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(trackingSeafoodStore.page, i)}</td>
                                        <td>{item.data?.productInformation?.name}</td>
                                        <td className="text-center">
                                            <img className="cursor_pointer" style={{width: 28, height: 28, borderRadius: 0}} data-toggle="modal" data-target="#upload_images_seafood" title="Hình ảnh nông trường sản phẩm"
                                                 onClick={async () => await trackingSeafoodStore.getDetail(item.id)} src="/assets/ico/ico_images.svg" alt=""/>
                                        </td>
                                        <td className="text-center">
                                            <img className="cursor_pointer"
                                                 style={{width: 28, height: 28, borderRadius: 0}}
                                                 data-toggle="modal"
                                                 data-target="#addEditTrackingSeaFood"
                                                 onClick={async () => {
                                                     trackingSeafoodStore.pageHelper = pageHelper;
                                                     await trackingSeafoodStore.getDetail(item.id)
                                                 }} title={t('barcode')}
                                                 src="/assets/ico/ico_barcode.svg" alt=""/></td>
                                        <td width="20%" className="text-center">
                                            <div className="action">
                                                {checkPermission(PermissionConst.trackingUpdate) && <button className="bt_action" title={t('view')}>
                                                    <Link to={`/tracking-seafood-batch/${slug(item.data?.productInformation?.name)}/${item.id}`}>
                                                        <img src="/assets/ico/action/ico_detail.svg" alt=""/>
                                                    </Link>
                                                </button>}
                                                {checkPermission(PermissionConst.trackingUpdate) &&
                                                <button className="bt_action" data-toggle="modal"
                                                        data-target="#addEditTrackingSeaFood" title={t('edit')}
                                                        onClick={async () => {trackingSeafoodStore.pageHelper = pageHelper;await trackingSeafoodStore.getDetail(item.id)}}>
                                                    <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                </button>}

                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}

                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Paginate currentPage={trackingSeafoodStore.page} totalPage={trackingSeafoodStore.totalPage}
                                  changePage={changePage}/>
                    </div>
                </div>}
            <AddOrUpdateTracking/>
            <UploadImagesSeafood/>
        </div>
    )
}

export default observer(TrackingPage);