import React, {useEffect, useRef, useState} from 'react';
import {Editor} from "@tinymce/tinymce-react";
import {observer} from "mobx-react";
import {getRequest} from "../helpers/RequestHelper";
import axios from "axios";
import {isImage} from "../helpers/UploadFileHelper";

interface IProps {
    content: any,
    height?: number,
    onChange: any,
    uploadImg?: boolean
}

let bucket = process.env.REACT_APP_BUCKET

const getGenerateUrl = async (files: any, success: any, failure: any) => {
    let result = await getRequest(`/v1/s3/generate-upload-url?fileName=${files.name}&bucket=${bucket}&type=${files.type === "video/mp4" ? 'VIDEO' : isImage(files.type) ? "IMAGE" : "FILE"}`, true)

    if (result.status == 200) {
        await uploadFilesS3(files, result.body, success, failure)
    } else failure(result.body.message)
}

const uploadFilesS3 = async (files: any, data: any, success: any, failure: any) => {
    const uploadFile = axios.create({
        baseURL: data.uploadUrl,
        method: 'PUT',
        headers: {
            'Content-Type': files.type
        }
    })
    let resultGenFile = await uploadFile.put(data.uploadUrl, files)
    if (resultGenFile.status == 200) {
        success(data.imageUrl)
    } else failure('Lỗi upload'!)
}

const TextEditor = observer(({content, height, onChange, uploadImg}: IProps) => {
    const [value, setValue] = useState<any>(content)
    const editorRef = useRef<any>(null)

    const handleChange = (content: any, editor: any) => {
        setValue(content)
        onChange(content)
    }

    const handleImageUpload = async (blobInfo: any, success: any, failure: any) => {
        const formData = new FormData();
        formData.append("files", blobInfo.blob(), blobInfo.filename());
        await getGenerateUrl(blobInfo.blob(), success, failure)
    };

    useEffect(() => {
        setValue(content)
    }, [content])


    return (
        <Editor ref={editorRef}
                apiKey='byq8umzzwaluv36t0ot4g497osmdax8vh4qgnqemqdjabr1p'
                value={value}
                onEditorChange={handleChange}
                init={{
                    height: height ?? 350,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | Lists ' +
                        `link ${uploadImg && 'image'} | Emoticons | Preview`,
                    images_upload_handler: handleImageUpload,
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    automatic_uploads: true,
                }}
        />
    );

})

export default TextEditor;