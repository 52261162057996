import React, {useEffect, useState} from 'react'
import {infoStore} from "../../info/InfoStore";
import {getIDParameter, number_format, slug, urlImage} from "../../../common/utils/Utils";
import {dateUtils} from "../../../common/utils/DateUtils";
import PopupZoom from "../../../common/component/PopupZoom";
import {observer} from "mobx-react";
import {trackingStore} from "../../tracking/TrackingStore";
import {trackingDetailStore} from "../../tracking/trackingDetail/TrackingDetailStore";
import MapLand from "../MapLand";
import {profileStore} from "../../profile/ProfileStore";
import {getRequestOrg} from "../../../common/helpers/RequestHelper";
import NoContent from "../../../common/component/NoContent";
import {Icon} from "../../../common/component/Icon";
import {reviewStore} from "../ReviewStore";
import {jobDiaryStore} from "../../diary/jobDiary/jobDiaryStore";
import {diaryStore} from "../../diary/DiaryStore";
import {planStore} from "../../planning/PlanStore";
import {landService} from "../../setup/land/LandService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {checkFileExtension} from "../../../common/helpers/UploadFileHelper";

const Content2 = () => {
    const [listPost, setListPost] = useState([])
    let {note, images, more} = trackingStore.dataRequest;
    let {land} = trackingDetailStore.dataRequest;

    const getPost = async () => {
        const result = await getRequestOrg(`/omfarm/v1/blog?page=0&size=3`)
        if (result.status === 200) {
            setListPost(result.body.data)
        }
    }

    const returnStar = (val: number) => {
        switch (val) {
            case 5:
                return `${reviewStore.statistic.fiveStar/reviewStore.statistic.sumStar*100}%`
            case 4:
                return `${reviewStore.statistic.fourStar/reviewStore.statistic.sumStar*100}%`
            case 3:
                return `${reviewStore.statistic.threeStar/reviewStore.statistic.sumStar*100}%`
            case 2:
                return `${reviewStore.statistic.twoStar/reviewStore.statistic.sumStar*100}%`
            case 1:
                return `${reviewStore.statistic.oneStar/reviewStore.statistic.sumStar*100}%`
        }
    }

    useEffect(() => {
        getPost().then()
        if(!window.location.pathname.startsWith('/truy-xuat/')){
            let idPlan: any = getIDParameter();
            planStore.id = idPlan
            callApi().then()
            return () => {
                planStore.id = ''
                diaryStore.landId = ''
            }
        }

    }, [])

    const getDetailLand = async (id: any) => {
        const result = await landService.detailLand(id)
        if(result.status === HttpStatusCode.OK){
            trackingDetailStore.dataRequest.land = result.body
        }
    }

    const callApi = async () => {
        await planStore.getLands(planStore.id)
        if (planStore.listLandPlan && planStore.listLandPlan.length > 0) {
            diaryStore.landId = planStore.listLandPlan[0].id
            await getDetailLand(diaryStore.landId)
            await jobDiaryStore.getDiary()
        }
    }

    try {

        let listJob = profileStore.path == 'truy-xuat' ? trackingDetailStore.listPlanJob : jobDiaryStore.listDiary

        return (
            <div>
                <h2>Chất lượng sản phẩm</h2>
                <div className="info_unit">
                    {more && more.map((item: any, i: number) => {
                        return (
                            <div className="info_text" key={i}>
                                <label>{item.key}</label>
                                <span>{item.value}</span>
                            </div>
                        )
                    })}
                </div>
                {note && <>
                    <h2>Ghi chú</h2>
                    <div className="info_unit">
                        <label>{note}</label>
                    </div>
                </>}
                {land && <>
                    <h2>Thông tin đất cơ sở</h2>
                    <div className="w-100 info_unit">
                        {land.name && <div className="info_text">
                            <label>Tên thửa</label>
                            <span>{land.name}</span>
                        </div>}
                        <div className="info_text">
                            <label>Diện tích </label>
                            <span>{land.area} ha</span>
                        </div>
                        <div className="info_text">
                            <label>Phân loại</label>
                            <span>{land.type == "OWN" ? "Sở hữu" : 'Đất thuê'}</span>
                        </div>
                        <div className="info_text">
                            <label>Chủ nông hộ</label>
                            <span>{land.farmHousehold}</span>
                        </div>
                        <div className="info_text">
                            <label>Địa chỉ</label>
                            <span>{land.ward?.name}, {land.district?.name}, {land.province?.name}</span>
                        </div>
                        <div className="info_text">
                            <label>Vị trí</label>
                            <button className="btn btn-sm btn_print" data-toggle="modal"
                                    data-target="#modal_map_land">Xem vị trí
                            </button>
                        </div>
                    </div>

                    <div className="modal fade" id="modal_map_land" role="dialog" aria-hidden="true">
                        <div className="modal-dialog" style={{maxWidth: 1140}} role="document">
                            <div className="modal-content">
                                <div className="modal-header pt-2 pb-2">
                                    <button type="button" className="close" id="close_add_edit" data-dismiss="modal"
                                            aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body pt-0 pb-0">
                                    <div className="w-100 position-relative" style={{height: 480}}>
                                        <MapLand/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}

                <>
                    <h2>Nhật ký sản xuất</h2>
                    <div className="scroll_certificate mb-4 h-auto">
                        <div className="list_certificate h-auto" style={{width: 1200}}>
                            <div className="info_unit mb-0">
                                <div className='table-responsive border'>
                                    {listJob && listJob.length > 0 ?
                                        <table
                                            className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                                            <thead>
                                            <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                                <th className="text-center">STT</th>
                                                <th className="text-center">Ngày làm việc</th>
                                                <th className="text-center">Tên công việc</th>
                                                <th className="text-center">Vật tư sử dụng</th>
                                                <th className="text-center">Hình ảnh</th>
                                                <th className="text-center">Ghi chú</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {listJob.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="text-center">{i + 1}</td>
                                                        <td className="text-center">{dateUtils.formatDate(item.startDate)} - {dateUtils.formatDate(item.endDate)}</td>
                                                        <td>{item.name}</td>
                                                        <td>
                                                            {item.medicines && item.medicines.length > 0 &&
                                                            <div className="d-flex flex-wrap w-100 mb-1">Nông
                                                                dược: {item.medicines.map((item: any, index: number) => {
                                                                    return <div key={index} className="pl-1"><span
                                                                        className="font-weight-medium">{item.medicine?.name}</span> ({item.qty} {item.medicine?.unit?.name})
                                                                    </div>
                                                                })}
                                                            </div>}
                                                            {item.fertilizers && item.fertilizers.length > 0 &&
                                                            <div className="d-flex flex-wrap w-100 mb-1">Phân bón:
                                                                {item.fertilizers.map((item: any, index: number) => {
                                                                    return <div key={index} className="pl-1"><span
                                                                        className="font-weight-medium">{item.fertilizer?.name}</span> ({item.qty} {item.fertilizer?.unit?.name})
                                                                    </div>
                                                                })}
                                                            </div>}
                                                            {item.ingredients && item.ingredients.length > 0 &&
                                                            <div className="d-flex flex-wrap w-100 mb-1">
                                                                Nguyên vật
                                                                liệu: {item.ingredients.map((item: any, index: number) => {
                                                                return <div key={index} className="pl-1"><span
                                                                    className="font-weight-medium">{item.ingredient?.name}</span> ({item.qty} {item.ingredient?.unit})
                                                                </div>
                                                            })}
                                                            </div>}
                                                            {item.equipments && item.equipments.length > 0 &&
                                                            <div className="d-flex flex-wrap w-100 mb-1">Máy móc:
                                                                {item.equipments.map((item: any, index: number) => {
                                                                    return <div key={index} className="pl-1"><span
                                                                        className="font-weight-medium">{item.equipment?.name}</span>
                                                                    </div>
                                                                })}
                                                            </div>}
                                                        </td>
                                                        <td className="text-center">{item.images && item.images.length > 0 &&
                                                        <img width={28} height={28} src={urlImage(item.images[0])}
                                                             alt=""/>}</td>
                                                        <td className="text-center">{item.note ?? '-'}</td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table> : <NoContent/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>

                {images && images.length > 0 && <>
                    <h2>Nông trường & Sản phẩm</h2>
                    <div className="scroll_images">
                        <div className="list_images"
                             style={{width: `${(images.length * 278) - 24}px`}}>
                            {images.map((item: any, i: number) => {
                                return (
                                    <div className="item cursor_pointer d-flex align-items-center"
                                         data-toggle="modal"
                                         data-target="#popupZoom_1" key={i}>
                                        {checkFileExtension(item, 'mp4') ?
                                            <video controls className="w-100"
                                                   src={urlImage(item)}/> :
                                            <img src={urlImage(item)} alt=""/>}

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </>}

                {infoStore.dataInfo.certificates && infoStore.dataInfo.certificates.length > 0 &&
                <div className="mt-5">
                    <h2>Giấy chứng nhận</h2>
                    <div className="scroll_certificate">
                        <div className="list_certificate"
                             style={{width: `${(infoStore.dataInfo.certificates.length * 278) - 24}px`}}>
                            {infoStore.dataInfo.certificates.map((item: any, i: number) => {
                                return (
                                    <div className="item cursor_pointer" data-toggle="modal"
                                         data-target="#popupZoom"
                                         key={i}>
                                        <img src={urlImage(item)} alt=""/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>}

                <PopupZoom listImg={images} id="popupZoom_1" idCarousel="carouselZoomImg2"/>
                <PopupZoom listImg={infoStore.dataInfo.certificates}/>

                {listPost && listPost.length > 0 && <div className="mt-5 post_product">
                    <h2>Bài viết liên quan</h2>
                    <div className="list_post d-flex align-items-center justify-content-between">
                        {listPost.map((value: any, i: number) => {
                            return (
                                <a className="post" href={`https://omfarm.org/blog/${slug(value.title)}/${value.id}`}
                                   target="_blank" key={i}>
                                    <div className="images">
                                        <img src={urlImage(value.introduce?.image)}
                                             alt={value.title}/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">{value.title}</h4>
                                        <span
                                            className="time">{dateUtils.formatDate(value.createdAt)}</span>
                                    </div>
                                </a>
                            )
                        })}
                    </div>
                </div>}

                {profileStore.path == 'truy-xuat' && <div className="review mt-5 p-4">
                    <div className="d-flex justify-content-between flex-wrap">
                        <div className="rating">
                            <div className="rate_">
                                <div className="d-flex title align-items-center">
                                    <h3 className="mb-0 mr-2">{reviewStore.statistic.aggregate}</h3>
                                    {[1, 2, 3, 4, 5].map((val, i) => <Icon name="star" className={reviewStore.statistic.aggregate >= val ? 'active' : ''} key={i}/>)}
                                    {reviewStore.statistic.sumStar > 0 && <p>{number_format(reviewStore.statistic.sumStar)} đánh giá</p>}
                                </div>
                                <div className="content">
                                    {[5, 4, 3, 2, 1].map((val, i) => {
                                        return (
                                            <div className="d-flex mb-2 align-items-center" key={i}>
                                                <div className="val">{val}</div>
                                                <Icon name="star"/>
                                                <div className="progress">
                                                    <div className={`progress-bar rate`}
                                                         role="progressbar"
                                                         style={{width: returnStar(val)}}
                                                         aria-valuemax={100}/>
                                                </div>
                                                <span className="total">{returnStar(val) == '0%' ? '' : returnStar(val)}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="comment">
                            <div className="title d-flex justify-content-between">
                                <input type="text" value={reviewStore.dataRequest.name} onChange={(e: any) => reviewStore.dataRequest.name = e.currentTarget.value} placeholder="Nhập tên..."/>
                                <div className="d-flex right align-items-center">
                                    <span>Đánh giá:</span>
                                    {[1, 2, 3, 4, 5].map((val: any, i: number) => <Icon
                                        onClick={() => reviewStore.dataRequest.star = val}
                                        className={`${reviewStore.dataRequest.star >= val ? 'active' : ''}`}
                                        name="star" key={i}/>)}
                                </div>
                            </div>
                            <div className="content">
                                <textarea rows={4} value={reviewStore.dataRequest.comment}
                                          onChange={(e: any) => reviewStore.dataRequest.comment = e.currentTarget.value}
                                          placeholder="Nhập bình luận về sản phẩm..."/>
                                <div className="d-flex footer justify-content-end align-items-center">
                                    <button className="" disabled={reviewStore.isLoadingBt} onClick={() => reviewStore.sendReview()}>Gửi</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="list_comment">
                        {reviewStore.listReview && reviewStore.listReview.length > 0 && reviewStore.listReview.map((item, i) => {
                            return <div className="comment_" key={i}>
                                <div className="d-flex title align-items-center">
                                    <div
                                        className="avatar d-flex align-items-center justify-content-center">{item.name.charAt(0)}</div>
                                    <div className="name">{item.name}</div>
                                    {[1, 2, 3, 4, 5].map((val, i) => <Icon name="star"
                                                                           className={`${val <= item.star ? 'active' : ''}`}
                                                                           key={i}/>)}
                                </div>
                                <div className="content">
                                    <span>{item.comment}</span>
                                </div>
                                <span className="time">{dateUtils.formatDate(item.createdAt)}</span>
                                {item.repComment && <div className="repComment">
                                    <div className="d-flex title align-items-center">
                                        <div
                                            className="avatar d-flex align-items-center justify-content-center">{item.repComment.name.charAt(0)}</div>
                                        <div className="name">{item.repComment.name}</div>
                                    </div>
                                    <div className="content">
                                        <span>{item.repComment.comment}</span>
                                    </div>
                                </div>}
                            </div>
                        })}
                    </div>
                    {reviewStore.metadata.totalPage > 1 && reviewStore.metadata.totalPage > reviewStore.metadata.page && <div className="d-flex justify-content-center">
                        <button className="readMore" disabled={reviewStore.isLoadingBt} onClick={() => reviewStore.readMore()}>Xem thêm đánh giá</button>
                    </div>}
                </div>}
            </div>
        )

    } catch (e) {
        return <div/>
    }

}

export default observer(Content2)
