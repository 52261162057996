import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {stageStore} from "./StageStore";

class StageService {
    public getStages(): Promise<IApiResponse> {
        return getRequest(`/v1/stage?page=${stageStore.page}&size=10`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/stage/${id}`);
    }

    public add(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/stage`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/stage/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/stage/${id}`, {id: id});
    }
}

export const stageService = new StageService()