import React from 'react'
import { convertPrice } from '../../../common/utils/Utils'
import { observer } from 'mobx-react'
import "../UpgradePackage.scss"
import { upgradePackageStore } from '../UpgradePackageStore'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import TextOverflowEllipsis from '../../../common/component/TextOverflowEllipsis'

function ItemResources({ item, key, isHorizontal }: any) {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const hanldeAddQrCodePackage = (itemQrCode: any) => {
        let index = upgradePackageStore.requestListData.findIndex((requestData: any) => requestData?.packageId === itemQrCode?.id && !requestData?.isFarmPackage)
        if (!(index > -1)) {
            let _itemRequestData = {
                packageId: itemQrCode?.id,
                quantity: 1,
                price: itemQrCode?.price,
                qrCodeLimit: itemQrCode?.qrCodeLimit,
                type: itemQrCode.type
            }
            upgradePackageStore.requestListData.push(_itemRequestData);
        } else {
            let itemRequestData = upgradePackageStore.requestListData[index];
            let quantity = Number(itemRequestData?.quantity) + 1;
            let _itemRequestData = {
                ...itemRequestData,
                quantity: quantity,
            }
            upgradePackageStore.requestListData[index] = _itemRequestData;
        }

        if (!upgradePackageStore.requestListData?.some((item: any) => item?.isFarmPackage) && upgradePackageStore.requestListData?.length === 1) {
            navigate(`/buy-package`, { state: { requestListData: JSON.stringify(upgradePackageStore.requestListData || []) } })
        }
    }

    return (
        <div key={key} className={`${isHorizontal ? "max-w-285px min-w-285px" : ""} item-resources`}>
            <div className='py-18px px-16px'>
                <p className='fw-600 fs-20px text-overflow-ellipsis'>{item?.name || `Thêm ${convertPrice(item?.qrCodeLimit)} mã QR Code `}</p>
                <hr className='bg-00953D w-80px ml-0 my-10px h-1px' />
                <div className='d-flex align-items-center fs-16px fw-600 justify-content-between'>
                    <div className='pr-5px d-flex h-21px'>
                        <TextOverflowEllipsis className='m-0 fs-16px d-inline-block fw-600 max-w-140px' value={convertPrice(item?.price)} />
                        <span className='fs-16px fw-600 pl-5px'>VNĐ/{t("package")}</span>
                    </div>
                    <img
                        src='/assets/ico/action/plus-square.svg'
                        alt=""
                        data-dismiss="modal"
                        className='cursor_pointer icon-add'
                        onClick={() => hanldeAddQrCodePackage(item)}
                    />
                </div>
            </div>
        </div >
    )
}

export default observer(ItemResources)