import React, { useEffect} from 'react';
import {observer} from "mobx-react";
import {reportStore} from "./ReportStore";
import {planStore} from "../planning/PlanStore";
import {number_format} from "../../common/utils/Utils";
import {dateUtils} from "../../common/utils/DateUtils";
import "./ReportStyle.scss";
import Loading from "../../common/component/Loading";
import NoContent from "../../common/component/NoContent";
import {useTranslation} from "react-i18next";



const ReportComponent = () => {

    const { t } = useTranslation();
    const callApi = async() => {
        await planStore.getPlanning(100)
        reportStore.planId = planStore.listPlanning && planStore.listPlanning.length > 0 && planStore.listPlanning[0].id;
        planStore.id = reportStore.planId
        if (reportStore.planId) {
            await planStore.getDetail(reportStore.planId)
            await reportStore.getReport()
            await planStore.getLands(planStore.id)
        } else {
            planStore.clearForm()
            planStore.listLandPlan = []
            reportStore.totalPriceFertilizer = 0
            reportStore.totalPriceMedicine = 0
            reportStore.dataReport.ingredients = []
            reportStore.dataReport.equipments = []
            reportStore.dataReport.fertilizers = []
            reportStore.dataReport.medicines = []
        }
    }


    const filterStarDate = async (e: any) => {
        reportStore.planId = '';
        planStore.searchYear = e.currentTarget.value;
        await callApi()
    }


    const filterPlan = async (e: any) => {
        reportStore.planId = e.currentTarget.value;
        await planStore.getDetail(reportStore.planId)
        await reportStore.getReport()
        await planStore.getLands(reportStore.planId)
    }

    useEffect(() => {

        planStore.searchYear = planStore.date.getFullYear()
        callApi()

        return () => {
            reportStore.planId = ''
            planStore.listLandPlan = []
            planStore.searchYear = planStore.date.getFullYear()
        }
    }, [])

    let items = planStore.dataRequest
    return (
        <div className="report">
            <div className="d-flex align-items-center justify-content-between filter_header">
                <div className="d-flex align-items-center">
                    <img src="/assets/ico/filter.svg" alt=""/>
                    <div className="d-flex form_search align-items-center">
                        <span>{t('year')}:</span>
                        <div className="filter_select">
                            <select onChange={(e: any) => filterStarDate(e)} value={planStore.searchYear}>
                                <option value="">{t('choose')}</option>
                                {planStore.listYear && planStore.listYear.map((item, i) => {
                                    return <option value={item.id}
                                                   key={i}>{item.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="d-flex form_search align-items-center">
                        <span>{t('plan')}:</span>
                        <div className="filter_select">
                            <select onChange={(e: any) => filterPlan(e)} value={reportStore.planId}>
                                <option value="">{t('choose')}</option>
                                {planStore.listPlanning && planStore.listPlanning.map((item, i) => {
                                    return <option value={item.id}
                                                   key={i}>{item.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex header_back border-top-0 h-auto">
                <div>
                    <div className="d-flex align-items-center">
                        <span className="text">{items.name}</span>
                    </div>
                    <div className="d-flex info_plan align-items-center">
                        <div className="info">
                            <label>{t('year')}: </label>
                            <span>{items.year}</span>
                        </div>
                        <div className="info">
                            <label>{t('product')}: </label>
                            <span>{items.procedure?.product?.name}</span>
                        </div>
                        <div className="info">
                            <label>{t('productionArea')}: </label>
                            <span>{items.totalArea} Ha</span>
                        </div>
                        <div className="info">
                            <label>{t('expectedOutput')}: </label>
                            <span>{number_format(items.expectedOutput)}</span>
                        </div>

                        <div className="info">
                            <label>{t('time')}: </label>
                            <span>{dateUtils.formatDate(items.startDate)} - {dateUtils.formatDate(items.endDate)}</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <label style={{marginRight: 12}}>{t('land')}:</label>
                        <span>{planStore.listLandPlan && planStore.listLandPlan.map((value: any, i: number) => {
                            return `${value.name}${i + 1 < planStore.listLandPlan.length ? ', ' : '.'}`
                        })}</span>
                    </div>

                    <div className="d-flex info_plan mt-4 mb-1 align-items-center">
                        <div className="info">
                            <label>{t('actualOutput')}: </label>
                            <span>{items.actualOutput ?? '-'}</span>
                        </div>
                        <div className="info">
                            <label>{t('time')}: </label>
                            <span>{dateUtils.formatDate(items.actualStartDate)} - {dateUtils.formatDate(items.actualEndDate)}</span>
                        </div>
                        <div className="info">
                            <label>{t('totalLabor')}: </label>
                            <span>{reportStore.dataReport.countLabor ? number_format(reportStore.dataReport.countLabor) : '-'}</span>
                        </div>
                        <div className="info">
                            <label>{t('laborCosts')}: </label>
                            <span>{items.totalLaborWage ? number_format(items.totalLaborWage) : '-'} VNĐ</span>
                        </div>
                    </div>

                    <div className="d-flex mb-1 align-items-center">
                        <label style={{marginRight: 12}}>{t('ingredient')}:</label>
                        <span>{reportStore.dataReport && reportStore.dataReport.ingredients && reportStore.dataReport.ingredients.map((value: any, i: number) => {
                            return `${value.material.name} ${value.qty ? (value.qty) : ''} ${i + 1 < reportStore.dataReport.ingredients.length ? ', ' : '.'}`
                        })}</span>
                    </div>

                    <div className="d-flex align-items-center">
                        <label style={{marginRight: 12}}>{t('equipment')}:</label>
                        <span>{reportStore.dataReport && reportStore.dataReport.equipments && reportStore.dataReport.equipments.map((value: any, i: number) => {
                            return `${value.material.name} ${value.qty ? (value.qty) : ''} ${i + 1 < reportStore.dataReport.equipments.length ? ', ' : '.'}`
                        })}</span>
                    </div>
                </div>
            </div>
            <div className="content p-5">
                <div className="d-flex">
                    <div className="table_medicine w-50 pr-4">
                        <div className="d-flex info_content align-items-center">
                            <div className="images">
                                <div className="d-flex justify-content-center">
                                    <img src="assets/ico/setup/ico_medicine.svg" alt=""/>
                                </div>
                                <span>{t('medicine')}</span>
                            </div>
                            <div className="info_report">
                                <p>{t('medicine')}: <span>{reportStore.dataReport && reportStore.dataReport.medicines && reportStore.dataReport.medicines.length}</span>
                                </p>
                                <p>{t('medicine')}: <span>{number_format(reportStore.totalPriceMedicine)} VNĐ</span></p>
                            </div>
                        </div>
                        {reportStore.isLoading ? <Loading/> :
                            <div className='table-responsive'>
                                <table
                                    className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                                    <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <th className="text-center">STT</th>
                                        <th>{t('medicineName')}</th>
                                        <th className="text-center">{t('amountMedicine')}/Ha</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {reportStore.dataReport && reportStore.dataReport.medicines && reportStore.dataReport.medicines.map((item: any, i: number) => {
                                        return (
                                            <tr key={i}>
                                                <td className="text-center">{i + 1}</td>
                                                <td>{item.material.name}</td>
                                                <td className="text-center">{item.material.useForOneHa}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                                {reportStore.dataReport && reportStore.dataReport.medicines && reportStore.dataReport.medicines.length < 1 &&
                                <NoContent height={350}/>}
                            </div>}
                    </div>

                    <div className="table_fertilizer w-50 pl-4">
                        <div className="d-flex info_content align-items-center">
                            <div className="images">
                                <div className="d-flex justify-content-center">
                                    <img src="assets/ico/setup/ico_fertilizer.svg" alt=""/>
                                </div>
                                <span>{t('fertilizer')}</span>
                            </div>
                            <div className="info_report">
                                <p>{t('totalFertilizer')}: <span>{reportStore.dataReport && reportStore.dataReport.fertilizers && reportStore.dataReport.fertilizers.length}</span>
                                </p>
                                <p>{t('totalCostFertilizer')}: <span>{number_format(reportStore.totalPriceFertilizer)} VNĐ</span></p>
                            </div>
                        </div>
                        {reportStore.isLoading ? <Loading/> :
                            <div className='table-responsive'>
                                <table
                                    className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                                    <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <th className="text-center">STT</th>
                                        <th>{t('fertilizerName')}</th>
                                        <th className="text-center">{t('amountFertilizer')}/Ha</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {reportStore.dataReport && reportStore.dataReport.fertilizers && reportStore.dataReport.fertilizers.map((item: any, i: number) => {
                                        return (
                                            <tr key={i}>
                                                <td className="text-center">{i + 1}</td>
                                                <td>{item.material.name}</td>
                                                <td className="text-center">{item.material.useForOneHa}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>

                                {reportStore.dataReport && reportStore.dataReport.fertilizers && reportStore.dataReport.fertilizers.length < 1 &&
                                <NoContent height={350}/>}
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(ReportComponent);