import {observable} from "mobx";
import {getRequest} from "../../common/helpers/RequestHelper";
import {profileStore} from "../profile/ProfileStore";

class DashboardStore {
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable dataDashboard: any = {
        id: '',
        countProduct: '',
        countIngredient: '',
        countMedicine: '',
        countFertilizer: '',
        countLabor: '',
        countLandArea: '',
        countEquiment: ''
    }

    async getDashboard() {
        this.isLoading = true
        const result = await getRequest(`/v1/farm/${profileStore.dataFarm.farmId}/overview`)
        this.isLoading = false

        if (result.status == 200) {
            this.dataDashboard = result.body
        }
    }

}

export const dashboardStore = new DashboardStore()
