import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import PopupConfirm from "../../common/component/PopupConfirm";
import ButtonAdd from "../../common/component/ButtonAdd";
import Loading from "../../common/component/Loading";
import {checkPermission, slug, sttPage} from "../../common/utils/Utils";
import NoContent from "../../common/component/NoContent";
import {procedureStore} from "./ProcedureStore";
import Paginate from "../../common/component/Paginate";
import AddOrEditProcedure from "./components/AddOrEditProcedure";
import {productStore} from "../setup/product/ProductStore";
import {Link} from "react-router-dom";
import FilterComponent from "../../common/component/filter/FilterComponent";
import {dateUtils} from "../../common/utils/DateUtils";
import {PermissionConst} from "../../common/constants/Constants";
import {useTranslation} from "react-i18next";


const ProcedureComponent = () => {

    const { t } = useTranslation();

    const changePage = async (data: any) => {
        procedureStore.page = data.selected;
        await procedureStore.getProcedure();
    }

    useEffect(() => {
        procedureStore.searchYear = procedureStore.date.getFullYear()
        procedureStore.getProcedure()
        procedureStore.getProducts()
        productStore.getCateLv1()
        return () => {
            productStore.categoryId = ''
        }
    }, [])

    return (
        <div className='procedures'>
            <FilterComponent permissionCode={PermissionConst.procedureAdd} buttonAdd={
                <div className="d-flex align-items-center">
                    <Link to={`/procedure/stage`}>
                        <button className="btn btn-md mr-4 btn-outline-green">{t('stage')}</button>
                    </Link>
                    <ButtonAdd text={t('addProcedure')} dataTarget={'add_or_edit_procedure'}
                               onClick={procedureStore.clearForm}/>
                </div>} data={procedureStore.dataFilter}/>
            {procedureStore.isLoading ? <Loading/> : <div className="table-responsive">
                {procedureStore.listProcedure && procedureStore.listProcedure.length > 0 ?
                    <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th>{t('procedure')}</th>
                            <th>{t('product')}</th>
                            <th className="text-center">{t('createdAt')}</th>
                            <th className="text-center">{t('note')}</th>
                            <th className="text-center">{t('actions')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {procedureStore.listProcedure.map((item, index) => (
                            <tr key={index}>
                                <td className="text-center">{sttPage(procedureStore.page, index)}</td>
                                <td>{item.name}</td>
                                <td>{item.product?.name}</td>
                                <td className="text-center">{dateUtils.formatDate(item.createdAt)}</td>
                                <td className="text-center">{item.note}</td>
                                <td className="text-center" width="22%">
                                    <div className="action">
                                        {checkPermission(PermissionConst.jobView) && <button className="bt_action" title={t('view')}>
                                            <Link to={`/procedure-detail/${slug(item.name)}/${item.id}`}>
                                                <img src="/assets/ico/action/ico_detail.svg" alt=""/>
                                            </Link>
                                        </button>}
                                        {checkPermission(PermissionConst.procedureUpdate) &&
                                        <button className="bt_action" data-toggle="modal" title={t('edit')}
                                                data-target="#add_or_edit_procedure"
                                                onClick={() => procedureStore.getDetail(item.id)}>
                                            <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                        </button>}
                                        {checkPermission(PermissionConst.procedureDelete) &&
                                        <button className="bt_action" title={t('delete')}
                                                onClick={() => procedureStore.id = item.id}
                                                data-toggle="modal" data-target="#popup_confirm">
                                            <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                        </button>}
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table> : <NoContent/>}
            </div>}

            <div className="d-flex align-items-center justify-content-center mt-4">
                <Paginate currentPage={procedureStore.page} totalPage={procedureStore.totalPage} changePage={changePage}/>
            </div>
            <AddOrEditProcedure/>
            <PopupConfirm isLoading={procedureStore.isLoadingBt} action={procedureStore.delete}/>
        </div>
    );
}

export default observer(ProcedureComponent);

