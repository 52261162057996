import React, {useEffect} from 'react';
import "./WarehouseStyle.scss"
import {TypeActive} from "../setup/SetupStore";
import {checkPermission, getUrlParameter} from "../../common/utils/Utils";
import TabHeader from "./components/TabHeader";
import {warehouseStore} from "./WarehouseStore";
import ProductPage from "./product/ProductPage";
import FertilizerPage from "./fertilizer/FertilizerPage";
import MedicinePage from "./medicine/MedicinePage";
import IngredientPage from "./ingredient/IngredientPage";
import EquipmentPage from "./equipment/EquipmentPage";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import ImportExportInventory from "./components/ImportExportInventory";
import {productStore} from "../setup/product/ProductStore";
import {medicineStore} from "../setup/medicine/MedicineStore";
import {fertilizerStore} from "../setup/fertilizer/FertilizerStore";
import {ingredientStore} from "../setup/ingredient/IngredientStore";
import {equipmentStore} from "../setup/equipment/EquipmentStore";
import {PermissionConst} from "../../common/constants/Constants";

const WarehousePage = () => {

    const switchData = () => {
        switch (warehouseStore.tabActive) {
            case TypeActive.product:
                return <ProductPage/>
            case TypeActive.fertilizer:
                return <FertilizerPage/>
            case TypeActive.medicine:
                return <MedicinePage/>
            case TypeActive.ingredient:
                return <IngredientPage/>
            case TypeActive.equipment:
                return <EquipmentPage/>
        }
    }

    useEffect(() => {
        warehouseStore.tabActive = getUrlParameter('tab') ?? TypeActive.product
        return () => {
            productStore.page = 0
            medicineStore.page = 0
            fertilizerStore.page = 0
            ingredientStore.page = 0
            equipmentStore.page = 0
        }
    }, [])

    return (
        <div className="warehouse_page">
            <TabHeader/>
            <div className="bt_add">
                {checkPermission(PermissionConst.suppliesInventory) && <>
                    <button className={`btn button_add mr-2 text-white`} onClick={() => {
                        warehouseStore.type = 'PLUS'
                        warehouseStore.clearForm()
                    }} data-toggle="modal"
                            data-target="#import_export_inventory">
                        <img className="mr-1" src="/assets/ico/ico_import.png" alt=""/> <span>Nhập Kho</span>
                    </button>
                    <button className={`btn button_add bg_export text-white mr-2`} onClick={() => {
                        warehouseStore.type = 'MINUS'
                        warehouseStore.clearForm()
                    }} data-toggle="modal"
                            data-target="#import_export_inventory">
                        <img className="mr-1" src="/assets/ico/ico_export.png" alt=""/>
                        <span>Xuất Kho</span></button>
                </>}
                <Link to={`/warehouse/history-log`}>
                    <button className={`btn bt_history`}>
                        Lịch sử
                    </button>
                </Link>
            </div>
            {switchData()}

            <ImportExportInventory/>
        </div>
    );

}

export default observer(WarehousePage);