import Constants from "../../../common/constants/Constants";
import {
    getRequest,
    postRequest,
    deleteRequest,
    putRequest,
    IApiResponse,
    uploadExcel, exportRequest
} from "../../../common/helpers/RequestHelper";
import {medicineStore} from "./MedicineStore";

class MedicineService{

    public getMedicines(size?: any): Promise<IApiResponse> {
        return getRequest(`/v1/medicine?page=${medicineStore.page}&size=${size ?? 10}`);
    }

    public getMedicinesAll(): Promise<IApiResponse> {
        return getRequest(`/v1/medicine?page=0&size=${Constants.MAX_SIZE}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/medicine/${id}`);
    }

    public getUnit(): Promise<IApiResponse> {
        return getRequest(`/v1/unit`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/medicine`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/medicine/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/medicine/${id}`, {id});
    }
    
    public importData(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/medicine/import`, data);
    }

    public exportData(): Promise<IApiResponse> {
        return exportRequest(`/v1/medicine/export`, 'Nông dược');
    }

}

export const medicineService = new MedicineService()