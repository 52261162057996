import React, { useCallback } from 'react';
import { toastUtil } from '../../utils/ToastUtil';

interface IProps {
    value: any,
    onChange: any
    isError?: any,
    type?: any,
    noTextErr?: boolean,
    disabled?: any,
    className?: string,
    placeholder?: string
    isValid?: boolean
    notValue?: boolean
    notCheckTooLongSequence?: boolean
}


const Input = (item: IProps) => {
    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.replace(/^\s+/, '');
        const continuousSequences = e.target.value?.split(/\s+/)?.filter(str => str?.length > 0);
        const hasTooLongSequence = continuousSequences?.some(sequence => sequence?.length > 30);
        if (hasTooLongSequence && !item?.notCheckTooLongSequence) {
            toastUtil.warning('Từ đơn không được quá 30 ký tự liên tiếp!', 1)
            return
        }
        if (item.onChange) item.onChange(e);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.onChange]);

    return (
        <div className="input-group">
            <input type={item.type ?? 'text'}
                value={item.value || ""}
                onChange={(e: any) => !item?.notValue &&  handleChange(e)}
                disabled={item.disabled}
                className={`form-control form-control-lg ${(item.isError && !item.value) || (item.isValid && item.isError) ? 'border_error' : ''} ${item.className}`}
                placeholder={item.placeholder ?? ''}
            />

            {
                item.isValid
                    ? item.isError && <p className="error">{item.isError}</p>
                    : !item.noTextErr && item.isError && <p className="error">{item.isError}</p>}
        </div>
    );
}

export default Input;