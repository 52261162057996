import moment, { Moment } from "moment";
import dayjs, { Dayjs } from "dayjs";

class DateUtils {

    public formatTSDateTimeAtTZ(timeSecond: number, localeFormat: string = "en-US") {
        return new Date(timeSecond * 1000).toLocaleString(localeFormat);
    }


    public formatISODateTimeAtTZ(isoDate: string, localeFormat: string = "vi-VN") {
        let lastChar: string = isoDate.substr(isoDate.length - 1);
        if(lastChar !== "Z") {
            isoDate += "Z"
        }
        return new Date(isoDate).toLocaleString(localeFormat);
    }


    public formatISODateAtTZ(isoDate: string, localeFormat: string = "vi-VN") {
        let lastChar: string = isoDate.substr(isoDate.length - 1);
        if(lastChar !== "Z") {
            isoDate += "Z"
        }
        return new Date(isoDate).toLocaleDateString(localeFormat);
    }


    public formatISOTimeAtTZ(isoDate: string, localeFormat: string = "vi-VN") {
        let lastChar: string = isoDate.substr(isoDate.length - 1);
        if(lastChar !== "Z") {
            isoDate += "Z"
        }
        return new Date(isoDate).toLocaleTimeString(localeFormat);
    }

    public formatDate(timeSecond: number, format?: any) {
        return timeSecond ? moment(timeSecond).format(format ?? 'DD/MM/YYYY') : '';
    }

    public convertTime(date: Moment): number | null {
        if (!date) return null
        else return date.set({hour: 0, minute: 0, second: 0}).unix() * 1000;
    }

    public convertMaxTimeDayjs(date: Dayjs): number | null {
        if (!date) return null
        else return dayjs(date).set('hour', 23).set('minute', 59).set('second', 59).unix() * 1000;
    }

    public checkRangePickerTime = (minDate: dayjs.Dayjs | null, maxDate: dayjs.Dayjs | null) => {
        return {
            disabledHours: () => {
                if (maxDate && minDate && maxDate.isSame(minDate, 'day')) {
                    return Array.from({length: 24}, (_, i) => i).filter(h => h < minDate.hour() || h > maxDate.hour());
                }
                if (maxDate) {
                    return Array.from({length: 24}, (_, i) => i).filter(h => h > maxDate.hour());
                }
                if (minDate) {
                    return Array.from({length: 24}, (_, i) => i).filter(h => h < minDate.hour());
                }
                return [];
            },
            disabledMinutes: (hour: number) => {
                if (minDate && maxDate && hour === minDate.hour() && hour === maxDate.hour()) {
                    return Array.from({length: 60}, (_, i) => i).filter(m => m < minDate.minute() || m > maxDate.minute());
                }
                if (minDate && hour === minDate.hour()) {
                    return Array.from({length: 60}, (_, i) => i).filter(m => m < minDate.minute());
                }
                if (maxDate && hour === maxDate.hour()) {
                    return Array.from({length: 60}, (_, i) => i).filter(m => m > maxDate.minute());
                }
                return [];
            },
            disabledSeconds: (hour: number, minute: number) => {
                if (maxDate && minDate && hour === maxDate.hour() && minute === maxDate.minute() && hour === minDate.hour() && minute === minDate.minute()) {
                    return Array.from({length: 60}, (_, i) => i).filter(s => s < minDate.second() || s > maxDate.second());
                }
                if (minDate && hour === minDate.hour() && minute === minDate.minute()) {
                    return Array.from({length: 60}, (_, i) => i).filter(s => s < minDate.second());
                }
                if (maxDate && hour === maxDate.hour() && minute === maxDate.minute()) {
                    return Array.from({length: 60}, (_, i) => i).filter(s => s > maxDate.second());
                }
                return [];
            }
        }
    }
}

export const dateUtils = new DateUtils();