import React, {useEffect} from 'react'
import {observer} from "mobx-react";
import {managerInformationStore} from "../../information/ManagerInformationStore";
import Loading from "../../../../common/component/Loading";
import {getIDParameter, goBack, number_format, slug, sttPage} from "../../../../common/utils/Utils";
import NoContent from "../../../../common/component/NoContent";
import QRCode from "react-qr-code";
import './DetailTrackingPage.scss'
import {useTranslation} from "react-i18next";
const convertStatusProductTracking = (value: string) => {
    switch (value) {
        case "USING":
            return <span style={{color: '#00953D', fontWeight: '600'}}>Đang sử dụng</span>
        case "EVICTION":
            return <span style={{color: '#747474', fontWeight: '600'}}>Đã thu hồi</span>
        case "EXPIRED":
            return <span style={{color: '#E60A32', fontWeight: '600'}}>Hết hạn sử dụng</span>
        default:
            return null
    }
}
const DetailTrackingPage= () => {
    const { t } = useTranslation();

    useEffect(() => {
        managerInformationStore.getDetailTrackingById(getIDParameter(4)).then(() => {
            managerInformationStore.getDetailPlanTrackingById(getIDParameter(4)).then()
        })
    }, [])
    return (
        <div className='p-4'>
            <div onClick={() => goBack()} className="back cursor_pointer">
                <i className="far fa-arrow-left"/>
                <span>{t("back")}</span>
            </div>
            <div className='d-flex mb-4'>
                <div>
                    {t("nameProduct")} : <span className="font-weight-bold">{managerInformationStore.detailPlanTracking?.plan?.procedure?.product?.name ?? "-"}</span>
                </div>
                <span className='mx-3'>|</span>
                <div>
                    {t("planName")} : <span className="font-weight-bold">{managerInformationStore.detailPlanTracking?.plan?.name ?? "-"}</span>
                </div>
                <span className='mx-3'>|</span>
                <div>
                    {t("actualOuput")} : <span className="font-weight-bold">{managerInformationStore.detailPlanTracking?.plan?.actualOutput ? `${number_format(managerInformationStore.detailPlanTracking?.plan?.actualOutput)} Kg` : "-"} </span>
                </div>
            </div>
        {managerInformationStore.isLoading ? <Loading/> :
                <div className='table-responsive'>
                    {managerInformationStore.listDetailTrackingById && managerInformationStore.listDetailTrackingById.length > 0 ?
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th className="text-center">{t("numberOfOrders")}</th>
                                <th className="text-center">{t("batchCode")}</th>
                                <th className="text-center">{t("netWeight")}</th>
                                <th className="text-center">{t("shippingUnit")}</th>
                                <th className="text-center">{t("consumptionUnit")}</th>
                                <th className="text-center">{t("access")}</th>
                                <th className="text-center">{t("status")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {managerInformationStore.listDetailTrackingById.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(managerInformationStore.page, i)}</td>
                                        <td className="text-center">{item?.batchCode}</td>
                                        <td className="text-center">{item?.info?.netWeight}</td>
                                        <td className="text-center">{item?.info?.shippingPartner}</td>
                                        <td className="text-center">{item?.info?.consumerUnit}</td>
                                        <td className="text-center">
                                            <a href={`${window.location.origin}/truy-xuat-lo/${getIDParameter(3)}/${getIDParameter(4)}/${item.id}`} target='_blank' className="btn_detail__tracking" title={"Mã QR truy xuất sản phẩm"}>
                                                <QRCode level="L" value={`${window.location.origin}/truy-xuat-lo/lua-st25/171/36`} size={32}/>
                                            </a>
                                        </td>
                                        <td className="text-center">{convertStatusProductTracking(item.state)}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                </div>}
        </div>
    )

}

export default observer(DetailTrackingPage)
