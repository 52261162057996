import React, {createRef, PureComponent} from 'react';
import GoogleMap from 'google-map-react';
import MapDrawShapeManager from 'google-maps-draw-shape-lib';
import {observer} from "mobx-react";
import {observable} from "mobx";
import {landStore} from "../setup/land/LandStore";
import MyGreatPlace from "../setup/land/map/MyGreatPlace";
import {trackingDetailStore} from "../tracking/trackingDetail/TrackingDetailStore";


@observer
class MapLand extends PureComponent<any, any> {
    @observable isLoading: boolean = false

    static defaultProps = {
        mapBootstrap: {
            id: 'map',
            key: 'AIzaSyCn8eV4OJCGnktvuqI5DmfXqb-g1xn6LVk',
            libraries: ['drawing']
        },

        mapOptions: {
            mapTypeId: 'satellite',
            minZoom: 5,
            maxZoom: 20,
            mapTypeControl: true,
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'satellite']
            },
            zoomControl: true,
            gestureHandling: 'greedy',
            disableDefaultUI: true,
            scrollwheel: true,
            clickableIcons: false,
            rotateControl: false,
            tilt: 0,
        },
        polygonOptions: {
            clickable: false,
            fillColor: "#303030",
            fillOpacity: 0.2,
            strokeColor: "#FF0000",
            strokeWeight: 2,
            strokeOpacity: 1
        },
    };

    mapDrawShapeManagerRef: any = createRef()

    constructor(props: any) {
        super(props);

        this.state = {
            mapLoaded: false,
            drawingMode: false,
            drawFreeHandMode: false,
            shape: [],
            initDrawnShape: []
        };
    }

    onGoogleApiLoaded = async (map: any, maps: any) => {
        const {land} = trackingDetailStore.dataRequest
        const {polygonOptions, initialPointInnerHtml, deletePointInnerHtml} = this.props;
        const {drawingMode, drawFreeHandMode, initDrawnShape} = this.state;
        var paths: any = []

        if (land.id) {
            if (land.location && land.location.coordinates && land.location.coordinates.length > 0) {
                paths.push(land.location.coordinates ?? [])
                landStore.defaultCenter = {
                    lat: land.location.coordinates[1].lat,
                    lng: land.location.coordinates[1].lng
                }
                var points: any = [];
                land.location.coordinates && land.location.coordinates.map((item: any) => {
                    points.push([item.lat, item.lng])
                })
                landStore.defaultCenter = landStore.getCenter(points);
            }

            var address: any = `${land.province.name} ${land.district.name} ${land.ward.name}`;
            if (land.location && land.location.coordinates && land.location.coordinates.length < 1) {
                await landStore.searchLocate(address, true)
            }

            if (!land.location) {
                await landStore.searchLocate(address, true)
            }
        }

        var triangle = new google.maps.Polygon({
            paths: paths,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "rgba(92, 92, 92, 0.8)",
            fillOpacity: 0.25,
        });

        if (land.id) {
            this.setInfoLand(land, map, landStore.defaultCenter, triangle)
        }


        triangle.setMap(map);

        this.mapDrawShapeManagerRef.current = new MapDrawShapeManager(
            map,
            this.onDrawCallback,
            drawingMode,
            drawFreeHandMode,
            polygonOptions,
            initialPointInnerHtml,
            deletePointInnerHtml,
            initDrawnShape,
        );

        this.setState({mapLoaded: true});
    };


    setInfoLand(item: any, map: any, position: any, triangle?: any) {
        const contentString =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<div id="bodyContent">' +
            "<p>Tên : " + item.name + "</p>" +
            "<p>Nông hộ : " + item.farmHousehold + "</p>" +
            "<p>Diện tích : " + item.area + " ha</p>" +
            "</div>" +
            "</div>";

        const infoWindow = new google.maps.InfoWindow({
            content: contentString,
            ariaLabel: item.name
        });

        const marker = new google.maps.Marker({
            position: position,
            map
        });

        marker.addListener("click", () => {
                infoWindow.open({
                    anchor: marker,
                    map,
                })
            }
        );

        triangle && triangle.addListener("click", () => {
            infoWindow.open({
                anchor: marker,
                map,
            })
        });
    }


    onDrawCallback = async (shape: any) => {
        trackingDetailStore.dataRequest.land.location.coordinates = shape
        this.setState({shape: [...shape], drawingMode: false});
    }


    render() {

        const {mapBootstrap, mapOptions} = this.props;
        const {land} = trackingDetailStore.dataRequest

        return (
            <div className="map_land_tracking">
                <div className={`map_container ${landStore.defaultCenter.lng} ${landStore.defaultCenter.lat}`}>
                    <GoogleMap
                        bootstrapURLKeys={mapBootstrap}
                        options={mapOptions}
                        center={landStore.defaultCenter}
                        zoom={landStore.zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({map, maps}) => this.onGoogleApiLoaded(map, maps)}>
                        <MyGreatPlace lat={landStore.defaultCenter.lat} lng={landStore.defaultCenter.lng}
                                      text={land.name}/>
                    </GoogleMap>
                </div>
            </div>
        );

    }
}

export default MapLand