import {Input} from "antd";
import React, {useCallback} from "react";
import {toastUtil} from "../../utils/ToastUtil";

interface IProps {
    value: any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isBorderError?: boolean;
    isError?: boolean | string;
    type?: string;
    disabled?: boolean;
    noTextErr?: boolean;
    placeholder?: string;
    className?: string;
    handleBlur?: () => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    maxLength?: number;
    showCount?: boolean;
    onKeyDown?: () => void;
    suffix?: string;
    loadingAction?: boolean;
    tabIndex?: number;
    ref?: any;
}

const InputAnt: React.FC<IProps> = ({
                                        value, onChange, isBorderError, isError, type = "text", disabled, noTextErr,
                                        placeholder = "", className = "", handleBlur, onKeyPress, suffix,
                                        maxLength = 200, showCount, onKeyDown, loadingAction, tabIndex, ref
                                    }) => {

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.replace(/^\s+/, '');
        const continuousSequences = e.target.value?.split(/\s+/)?.filter(str => str?.length > 0);
        const hasTooLongSequence = continuousSequences?.some(sequence => sequence?.length > 30);
        if (hasTooLongSequence) {
            toastUtil.warning('Từ đơn không được quá 30 ký tự liên tiếp!', 1)
            return
        }
        if (onChange) onChange(e);

    }, [onChange]);


    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && !loadingAction && onKeyDown) {
            onKeyDown();
        }
    }, [loadingAction, onKeyDown]);

    return (
        <div className="input-group h-auto">
            <Input
                ref={ref}
                tabIndex={tabIndex}
                type={type}
                showCount={showCount}
                value={value || ""}
                maxLength={maxLength}
                onChange={handleChange}
                suffix={suffix}
                disabled={disabled}
                className={`w-100 min-h-42px ${className} ${(!noTextErr && isError && !value && !(typeof value === "string" && String(value)?.trim()) || isBorderError) ? "border_error" : ""}`}
                onKeyDown={handleKeyDown}
                onKeyPress={onKeyPress}
                placeholder={placeholder}
                onBlur={handleBlur}
            />
            {!noTextErr && isError && !value && !(typeof value === "string" && String(value)?.trim()) && (
                <p className="error">{isError}</p>
            )}
        </div>
    );
}

export default React.memo(InputAnt);
